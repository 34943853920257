import React from "react";
import {
    Box,
    Popover,
    Divider,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Person as PersonIcon,
    AccountBox as AccountBoxIcon,
    GroupAdd as GroupAddIcon,
    Tune as TuneIcon,
    DoorFront as DoorFrontIcon
} from "@mui/icons-material";
import PopupState, {
    bindTrigger,
    bindPopover
} from "material-ui-popup-state";

const UserProfile = (props) => {
    const {
        user,

        onUserExit
    } = props;
    const classes = useStyles()

    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <div>
                    <Box className={classes.root} {...bindTrigger(popupState)}>
                        { (user?.firstname || "")?.[0] }
                    </Box>

                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        classes={{
                            paper: classes.popover
                        }}
                    >
    
                        {Boolean(false) && (
                            <>
                                <Box className={classes.mainItem}>
                                    <Box className={classes.mainItemIcon}>
                                        <PersonIcon sx={{color: "#FFFFFF"}}/>
                                    </Box>
                                    <span className={classes.mainItemLabel}>
                                Профиль
                            </span>
                                </Box>
                                <Box className={classes.mainItem}>
                                    <Box className={classes.mainItemIcon}>
                                        <AccountBoxIcon sx={{color: "#FFFFFF"}}/>
                                    </Box>
                                    <span className={classes.mainItemLabel}>
                                Профиль
                            </span>
                                </Box>
    
                                <Divider className={classes.divider}/>
    
                                <Box className={classes.additionalItem}>
                                    <Box className={classes.additionalItemIcon}>
                                        <GroupAddIcon sx={{color: "#0057FF"}}/>
                                    </Box>
                                    <span className={classes.additionalItemLabel}>
                                Добавить аккаунт
                            </span>
                                </Box>
                                <Box className={classes.additionalItem}>
                                    <Box className={classes.additionalItemIcon}>
                                        <TuneIcon sx={{color: "#0057FF"}}/>
                                    </Box>
                                    <span className={classes.additionalItemLabel}>
                                Настройки
                            </span>
                                </Box>
                            </>
                        )}
                        <Box className={classes.additionalItem} onClick={onUserExit}>
                            <Box className={classes.additionalItemIcon}>
                                <DoorFrontIcon sx={{color: "#0057FF"}}/>
                            </Box>
                            <span className={classes.additionalItemLabel}>
                                Выход
                            </span>
                        </Box>

                    </Popover>
                </div>
            )}
        </PopupState>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        width: 32,
        height: 32,
        borderRadius: "100%",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",

        fontSize: 22,
        lineHeight: "27px",
        color: "#3855EC",
        fontWeight: "bold"
    },
    popover: {
        padding: "6px 4px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
        border: "1px solid #E6E6E6",
        backgroundColor: "white",
        minWidth: 220,
        boxSizing: "border-box",
        marginTop: 10
    },
    divider: {
        margin: "12px -12px"
    },

    mainItem: {
        display: "flex",
        alignItems: "center",
        padding: "6px 8px",
        cursor: "pointer",
        borderRadius: 6,

        "&:hover": {
            backgroundColor: "#0057FF",

            "& $mainItemIcon": {
                backgroundColor: "#62B4FF"
            },
            "& $mainItemLabel": {
                color: "white"
            },
        }
    },
    mainItemIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        width: 32,
        height: 32,
        borderRadius: "100%",
        backgroundColor: "#0057FF",
        marginRight: 8
    },
    mainItemLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "#000000",
        fontWeight: "500"
    },

    additionalItem: {
        display: "flex",
        alignItems: "center",
        padding: "6px 8px",
        cursor: "pointer",
        borderRadius: 6,

        "&:hover": {
            backgroundColor: "#0057FF",

            "& $additionalItemIcon": {
                backgroundColor: "#FFFFFF"
            },
            "& $additionalItemLabel": {
                color: "white"
            },
        }
    },
    additionalItemIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        width: 32,
        height: 32,
        borderRadius: "100%",
        backgroundColor: "#F4F4F4",
        marginRight: 8
    },
    additionalItemLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "#787878",
        fontWeight: "500"
    },
}));

export default React.memo(UserProfile)
