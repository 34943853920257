import React, {useEffect, useState} from 'react';
import {Formik} from "formik";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Collapse,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextareaAutosize,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AddOutlined as AddIcon,
    AttachFileOutlined as AttachFileIcon,
    Delete as DeleteIcon,
    Upload as UploadIcon,
    ExpandLess as LessIcon,
    ExpandMore as MoreIcon,
} from "@mui/icons-material";
import {
    getFileId,
    getImageId
} from "../../../../../common/FilesUpload";
import {
    CKEditor,
    DropZone
} from "../../../../../components";
import {makeStyles} from "@mui/styles";

const initialState = {
    characteristic_group_id: '',
    count_step: '',
    description: '',
    external_id: '',
    extraData: [],
    extraFeaturedFilterPages: [],
    file_id: '',
    google_merchant_category_id: '',
    image_id: '',
    images: [],
    metaDescription: '',
    metaKeywords: '',
    metaTitle: '',
    name: '',
    name_one: '',
    parentId: '',
    product_price_from: '',
    seo_canonical: '',
    short_description: '',
    show_in_menu: 0,
    slug: '',
    status: 0,
    title: '',
    unit: '',
    yandex_market_category: '',
};

const MainForm = React.memo((props) => {
    const {
        refFormik,
        category,
        parents,
        characteristics,

        lists,

        isCreate,
    } = props;
    const classes = useStyles();
    const [openFirst, setOpenFirst] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);

    const handleSubmit = () => {
    };

    const handleChange = ({target}) => {
        const {name, value} = target;

        let newForm = refFormik.current.values || {};
        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleChangeBoolean = ({target}, value) => {
        const {name} = target;

        let newForm = refFormik.current.values;
        newForm[name] = value ? 1 : 0;

        refFormik.current.setValues(newForm);
    }

    const handleChangeImage = (event) => {
        const file = event?.target.files?.[0] || null;

        let newValues = refFormik.current?.values || {};
        let image_id = {...newValues.image_id};
        image_id.file = file;
        image_id.source = URL.createObjectURL(file);

        newValues.image_id = image_id;
        refFormik.current.setValues(newValues);
    };

    const handleChangeFile = (event) => {
        const file = event?.target.files?.[0] || null;

        let newValues = refFormik.current?.values;
        let fileId;
        fileId = file;
        fileId.source = URL.createObjectURL(file);

        newValues.file_id = fileId;
        refFormik.current.setValues(newValues);
    }

    const handleAddCharacteristic = () => {
        let newForm = refFormik.current.values;

        if (newForm.extraData === null) {
            newForm.extraData = [];
        }

        newForm.extraData = [...newForm.extraData, {characteristic: '', value: ''}];

        refFormik.current.setValues(newForm);
    }

    const handleDeleteCharacteristic = (index) => {
        let newForm = refFormik.current.values;
        newForm.extraData.splice(index, 1);

        refFormik.current.setValues(newForm);
    }

    const handleChangeCharacteristic = (event, index) => {
        const {value, name} = event.target;
        let newForm = refFormik.current.values;
        newForm.extraData[index][name] = value;

        refFormik.current.setValues(newForm);
    }

    const handleDeleteFile = (fieldName) => {
        refFormik.current.setFieldValue(fieldName, null);
    };

    const handleChangeAutocomplete = (value) => {
        let newForm = refFormik.current.values;
        let newValue = value.filter(val => !newForm.extraFeaturedFilterPages.find(feature => feature.id === val.id));

        newForm.extraFeaturedFilterPages = [...newForm.extraFeaturedFilterPages, ...newValue];

        refFormik.current.setValues(newForm);
    }

    const handleDeleteAutocomplete = (index) => {
        let newForm = refFormik.current.values;

        newForm.extraFeaturedFilterPages.splice(index, 1);
        refFormik.current.setValues(newForm);
    }

    return (
        <Formik
            innerRef={refFormik}
            initialValues={isCreate ? initialState : category}

            onSubmit={handleSubmit}
        >
            {(props) => {
                const {
                    values, errors, touched,
                } = props;
                return (<>
                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}><Typography variant="h5" fontWeight={700}>Название</Typography></Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.name}
                                name="name"
                                placeholder="Введите название"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}><Typography variant="h5" fontWeight={400}>Название в именительном
                            падеже</Typography></Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.name_one}
                                name="name_one"
                                placeholder="Введите название в именительном падеже"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}><Typography variant="h5" fontWeight={700}>Родительская
                            категория</Typography></Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    fullWidth
                                    size="small"
                                    color='primary'
                                    value={values.parentId}
                                    name="parentId"

                                    onChange={handleChange}
                                >
                                    {parents.map(parent => (<MenuItem
                                        key={parent.value}
                                        value={parent.value}
                                        color="primary"
                                    >{parent.label}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Статус</Typography>
                        </Grid>
                        <Grid item xs>
                            <FormControlLabel
                                value={values.status}
                                name="status"
                                color="primary"
                                control={<Checkbox color="primary" checked={Boolean(values.status)}/>}
                                label=""

                                onChange={handleChangeBoolean}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Показывать в меню</Typography>
                        </Grid>
                        <Grid item xs>
                            <FormControlLabel
                                value={values.show_in_menu}
                                name="show_in_menu"
                                color="primary"
                                control={<Checkbox color="primary" checked={Boolean(values.show_in_menu)}/>}
                                label=""

                                onChange={handleChangeBoolean}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>URL-адрес</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.slug}
                                name='slug'
                                placeholder="Введите URL-адрес"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Тип товара по умолчанию</Typography>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    fullWidth
                                    size="small"
                                    color='primary'
                                    value={values.characteristic_group_id}
                                    name="characteristic_group_id"

                                    onChange={handleChange}
                                >
                                    {characteristics.map(category => (
                                        <MenuItem
                                            key={category.value}
                                            value={category.value}
                                            color="primary"
                                        >
                                            {category.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Основное изображение</Typography>
                        </Grid>
                        <Grid item xs>
                            {values.image_id ? (<>
                                <CategoryImage imageId={values.image_id} viewImage/>
                                <CategoryImage
                                    imageId={values.image_id}
                                    viewFile
                                    handleDeleteImage={handleDeleteFile}
                                />
                            </>) : (<Button
                                variant="contained"
                                color="primary"
                            >
                                <label style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <UploadIcon sx={{marginRight: "5px"}}/>
                                    Выберите файл
                                    <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        onChange={(event) => handleChangeImage(event)}
                                    />
                                </label>
                            </Button>)}
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Дополнительные изображения</Typography>
                        </Grid>
                        <Grid item xs>
                            <DropZone
                                notShowTitle
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Заголовок</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.title}
                                name='title'
                                placeholder="Введите заголовок"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Краткое описание</Typography>
                        </Grid>
                        <Grid item xs>
                            <Box mt={-2}>
                                <CKEditor
                                    value={values.short_description}
                                    name="short_description"

                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Описание</Typography>
                        </Grid>
                        <Grid item xs>
                            <Box mt={-2}>
                                <CKEditor
                                    value={values.description}
                                    name="description"

                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Единица (кг, л, шт)</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.units}
                                name='units'
                                placeholder="Введите единицу измерения (кг, л, шт)"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Минимальное количество для заказа</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                type="number"
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.count_step}
                                name='count_step'
                                placeholder="Введите минимальное количество для заказа"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Цена товаров, от</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.product_price_from}
                                name='product_price_from'
                                placeholder="Введите цену товаров, от"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Файл</Typography>
                        </Grid>
                        <Grid item xs>
                            {values.file_id ? (<>
                                <CategoryFile fileId={values.file_id} handleDeleteFile={handleDeleteFile}/>
                            </>) : (<Button
                                variant="contained"
                                color="primary"
                            >
                                <label style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <UploadIcon sx={{marginRight: "5px"}}/>
                                    Выберите файл
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => handleChangeFile(event)}
                                    />
                                </label>
                            </Button>)}
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Внешний ID</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.external_id}
                                name='external_id'
                                placeholder="Введите внешний ID"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Внешний ID</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.external_id}
                                name='external_id'
                                placeholder="Введите внешний ID"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Дополнительные характеристики</Typography>
                        </Grid>
                        <Grid item xs>
                            <Grid container>
                                {(values.extraData !== null && values.extraData.length > 0) ? (<>
                                    {values.extraData.map((data, index) => (<>
                                        <Grid item xs={11} key={index} mb={3} mr={1}>
                                            <Grid container>
                                                <Grid item xs={12} mb={2}>
                                                    <Grid container>
                                                        <Grid item xs={2}>Характеристика</Grid>
                                                        <Grid item xs>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Select
                                                                    fullWidth
                                                                    size="small"
                                                                    color='primary'
                                                                    value={data.characteristic}
                                                                    name="characteristic"

                                                                    onChange={(event) => handleChangeCharacteristic(event, index)}
                                                                >
                                                                    {characteristics.map(category => (<MenuItem
                                                                        key={category.value}
                                                                        value={category.value}
                                                                        color="primary"
                                                                    >{category.label}</MenuItem>))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={2}>Значение</Grid>
                                                        <Grid item xs>
                                                            <TextareaAutosize
                                                                value={data.value}
                                                                name="value"
                                                                placeholder="Значение"
                                                                style={{
                                                                    width: "100%",
                                                                    height: 124,
                                                                    resize: "none",
                                                                    fontSize: "16px",
                                                                    padding: "12px 24px",
                                                                    boxSizing: "border-box",
                                                                }}

                                                                onChange={(event) => handleChangeCharacteristic(event, index)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item key={index}>
                                            <IconButton
                                                color="error"

                                                onClick={() => handleDeleteCharacteristic(index)}
                                            >
                                                <Tooltip title="Удалить характеристику">
                                                    <DeleteIcon/>
                                                </Tooltip>
                                            </IconButton>
                                        </Grid>
                                    </>))}
                                </>) : (<Typography
                                    sx={{
                                        backgroundColor: "rgba(0,0,0, .1)",
                                        paddingX: "10px",
                                        margin: 0,
                                        marginBottom: "10px",
                                        fontSize: "16px",
                                    }}
                                    variant="h6"
                                >
                                    Нет преимуществ. Добавьте преимущества кликнув на + ниже
                                </Typography>)}
                            </Grid>
                            <Grid item xs>
                                <IconButton
                                    sx={{
                                        padding: 0,
                                    }}
                                    color="primary"

                                    onClick={handleAddCharacteristic}
                                >
                                    <AddIcon color="primary"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>ID категории в Google Merchant</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                type="number"
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.google_merchant_category_id}
                                name='google_merchant_category_id'
                                placeholder="Введите ID категории из Google Merchant"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} mb={3}>
                        <Grid item xs={2}>
                            <Typography variant="h5" fontWeight={400}>Категория в Яндекс Маркете</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                color="primary"
                                value={values.yandex_market_category}
                                name='yandex_market_category'
                                placeholder="Введите категорию из Яндекс Маркета"

                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Box className={classes.collapseContainer} mb={3}>
                        <Button
                            fullWidth
                            size="large"
                            sx={{
                                height: "50px",
                                justifyContent: "flex-start",
                            }}
                            color="primary"
                            variant="contained"
                            startIcon={openFirst ? <LessIcon/> : <MoreIcon/>}

                            onClick={() => setOpenFirst(!openFirst)}
                        >
                            Популярные посадочные страницы
                        </Button>
                        <Collapse in={openFirst} timeout="auto">
                            <Box className={classes.collapseBox}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={2}>
                                        <Typography variant="h5" fontWeight={400}>
                                            Популярные посадочные страницы
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Autocomplete
                                            fullWidth
                                            size="small"
                                            multiple
                                            value={values.extraFeaturedFilterPages}
                                            name="extraFeaturedFilterPages"
                                            options={lists}
                                            getOptionLabel={(option) => option.title}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        className={classes.chip}
                                                        size="small"
                                                        color="primary"
                                                        label={option.title}

                                                        onDelete={() => handleDeleteAutocomplete(index)}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Выберите посадочные страницы"
                                                />
                                            )}

                                            onChange={(e, value) => handleChangeAutocomplete(value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </Box>

                    <Box className={classes.collapseContainer}>
                        <Button
                            fullWidth
                            size="large"
                            sx={{
                                height: "50px",
                                justifyContent: "flex-start",
                            }}
                            color="primary"
                            variant="contained"
                            startIcon={openSecond ? <LessIcon/> : <MoreIcon/>}

                            onClick={() => setOpenSecond(!openSecond)}
                        >
                            SEO
                        </Button>
                        <Collapse in={openSecond} timeout="auto">
                            <Box className={classes.collapseBox}>
                                <Grid container alignItems="center" spacing={1} mb={3}>
                                    <Grid item xs={2}>
                                        <Typography variant="h5" fontWeight={400}>
                                            SEO Title
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            color="primary"
                                            value={values.metaTitle}
                                            name='metaTitle'
                                            placeholder="Введите заголовок SEO"

                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" spacing={1} mb={3}>
                                    <Grid item xs={2}>
                                        <Typography variant="h5" fontWeight={400}>
                                            SEO Keywords
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            color="primary"
                                            value={values.metaKeywords}
                                            name='metaKeywords'
                                            placeholder="Введите ключевые слова SEO"

                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" spacing={1} mb={3}>
                                    <Grid item xs={2}>
                                        <Typography variant="h5" fontWeight={400}>
                                            SEO Description
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            color="primary"
                                            value={values.metaDescription}
                                            name='metaDescription'
                                            placeholder="Введите описание SEO"

                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={2}>
                                        <Typography variant="h5" fontWeight={400}>
                                            SEO Canonical
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            color="primary"
                                            value={values.seo_canonical}
                                            name='seo_canonical'
                                            placeholder="Введите SEO Canonical"

                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </Box>
                </>);
            }}
        </Formik>);
});


const CategoryFile = React.memo((props) => {
    const {
        fileId,

        handleDeleteFile
    } = props;
    const [file, setFile] = useState([]);

    const convertFile = async () => {
        if (typeof fileId !== 'object') {
            let convertedFile;
            if (fileId) {
                convertedFile = await getFileId(fileId);
            }
            setFile(convertedFile);
        }
    }

    useEffect(() => {
        (async () => {
            await convertFile();
        })();

    }, [fileId]);
    return (<Box display="flex">
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                padding: "5px",
                background: "#3855EC",
                borderRadius: "4px",
                color: "#fff",
                fontSize: "14px",
                justifyContent: "center",
                marginRight: "5px"
            }}
        >
            <AttachFileIcon
                sx={{
                    marginRight: "5px",
                }}
            />
            {fileId?.name || file.name_original}
        </Box>
        <IconButton
            color="error"

            onClick={() => handleDeleteFile('file_id')}
        >
            <Tooltip title="Удалить файл">
                <DeleteIcon/>
            </Tooltip>
        </IconButton>
    </Box>);
});


const CategoryImage = React.memo((props) => {
    const {
        imageId, viewImage, viewFile,

        handleDeleteImage
    } = props;
    const [image, setImage] = useState([]);

    const convertImage = async () => {
        if (typeof imageId !== "object") {
            let convertedImageObj;
            if (imageId) {
                convertedImageObj = await getImageId(imageId);
            }
            setImage(convertedImageObj);
        }
    }

    useEffect(() => {
        (async () => {
            await convertImage();
        })();
    }, [imageId])
    return (<>
        {Boolean(viewImage) && (<Box
            width={300}
            height={300}
            sx={{
                backgroundColor: "rgba(0,87,255, 0.05)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            mb={2}
        >
            <img src={imageId.source || image.source} style={{
                display: "block", width: 250, objectFit: "cover", borderRadius: 5,
            }} alt=""/>
        </Box>)}
        {Boolean(viewFile) && (<Box display="flex">
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    background: "#3855EC",
                    borderRadius: "4px",
                    color: "#fff",
                    fontSize: "14px",
                    justifyContent: "center",
                    marginRight: "5px"
                }}
            >
                <AttachFileIcon
                    sx={{
                        marginRight: "5px",
                    }}
                />
                {imageId?.file?.name || image.name_original}
            </Box>
            <IconButton
                color="error"

                onClick={() => handleDeleteImage('image_id')}
            >
                <Tooltip title="Удалить файл">
                    <DeleteIcon/>
                </Tooltip>
            </IconButton>
        </Box>)}
    </>);
});

const useStyles = makeStyles({
    collapseContainer: {
        border: "1px solid #0057FF",
        borderRadius: "6px",
    },
    collapseBox: {
        padding: "20px",
    },

    chip: {
        borderRadius: "4px",
        marginRight: "5px",
    }
})

export default MainForm;