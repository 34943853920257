import React from "react";
import {
	Box,
	Grid,
	TextField,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Range,
	getTrackBackground
} from "react-range";
import clsx from "clsx";
import {IMaskInput} from "react-imask";

const RangeNumber = (props) => {
	const {
		label,
		name,

		onChange
	} = props;
	const [from, setFrom] = React.useState("");
	const [to, setTo] = React.useState("");
	const classes = useStyles();

	const handleChangeFrom = (value) => {
		setFrom(value);
		handleSetValue([value, to]);
	}
	const handleChangeTo = (value) => {
		setTo(value);
		handleSetValue([from, value]);
	}

	const handleSetValue = (values) => {
		onChange(name, values);
	}

	return (
		<Box className={classes.root}>
			<Typography className={classes.label}>
				{label}
			</Typography>
			<Box className={clsx({
				[classes.content]: true,
				[classes.contentMultiline]: true,
			})}>
				<Grid container spacing={1} wrap="nowrap" alignItems="center">
					<Grid item>
						<TextField
							value={from}
							label="От"
							placeholder="Введите"
							InputProps={{inputComponent: TextMaskCustom}}
							inputProps={{
								onAccept: handleChangeFrom
							}}
						/>
					</Grid>
					<Grid item>—</Grid>
					<Grid item>
						<TextField
							value={to}
							label="До"
							placeholder="Введите"
							InputProps={{inputComponent: TextMaskCustom}}
							inputProps={{
								onAccept: handleChangeTo
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
	const {onChange, ...other} = props;

	return (
		<IMaskInput
			mask={Number}
			thousandsSeparator=" "
			radix="."
			mapToRadix={[',']}
			scale={2}
			unmask={true}

			inputRef={ref}

			{...other}
		/>
	);
});

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",

		height: "100%",
		width: "100%",
		backgroundColor: "#F4F4F4",
		borderRadius: 6,
		padding: "10px 12px",
		boxSizing: "border-box"
	},

	label: {
		flex: 1,
		fontSize: 16,
		lineHeight: "20px",
		fontWeight: "500",
		color: "#000000"
	},

	content: {
		width: "100%",
		maxWidth: 260
	},
	contentMultiline: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		},
	},
}))

export default React.memo(RangeNumber)