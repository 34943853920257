import React, { Component } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import {
    LoadingButton
} from "@mui/lab";
import {
    Formik
} from "formik";
import * as Yup from "yup";
import {
    Notification,
    NotificationTypes
} from "../../../common/Notification";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import {setCookie} from "../../../helper/cookie";

class Authorization extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                email: "",
                password: "",
            },

            verificationId: null,
            stage: 1,

            isLoading: false
        };

        this.refFormik = React.createRef();
    }

    componentDidMount = () => {}

    onSubmit = async () => {
        if (this.state.isLoading) {
            return null
        }

        await this.onAuth();
    }
    onAuth = async () => {
        this.setState({ isLoading: true });

        const form = {...this.refFormik.current.values};

        const data = await agent.post(`${ urls["apiUsertokenLogin"] }`, {
            ...form,
            app: process.env.REACT_APP_AUTH_KEY
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response?.data || [] }
        })

        if (data.error) {
            this.setState({ isLoading: false });

            const message = (data.error || []).map((t) => t.message).join('<br/>');

            Notification({
                type: NotificationTypes.error,
                message: message || "Ошибка сервера"
            })

            return null
        }

        localStorage.setItem('token', data.token);
        setCookie('adminToken', data.token, {
            domain: ".miasofiahome.ru",
            maxAge:  60 * 60 * 24 * 60,
            path: "/"
        })
        agent.defaults.headers['Authorization'] = `Bearer ${data.token}`;

        const user = await agent.get(urls.userSession).then((res) => {
            return res.data?.user || {}
        }).catch(() => {
            return {}
        })

        this.props.setUser(user);

        this.setState({ isLoading: false });
    }

    changeForm = ({target}) => {
        const {name, value} = target;
        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    render() {
        const {
            form,

            isLoading
        } = this.state;

        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <Formik
                    innerRef={this.refFormik}
                    initialValues={form}
                    validationSchema={validationSchema}
                    onSubmit={this.onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit,
                        } = props;

                        return (
                            <Box width={640} px={8} py={4} borderRadius={4} mx="auto" bgcolor="white" className="box-shadow">

                                <Box mb={4}>
                                    <Typography variant="h1" textAlign="center">Авторизация</Typography>
                                </Box>

                                <form
                                    autocomplete="false"
                                    action=""
                                    onSubmit={handleSubmit}
                                    onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                                >

                                    <Box mb={4}>
                                        <TextField
                                            value={values.email}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                            name="email"
                                            label="Email"
                                            placeholder="mail@mail.ru"
                                            variant="outlined"
                                            fullWidth

                                            onChange={this.changeForm}
                                        />
                                    </Box>

                                    <Box mb={4}>
                                        <TextField
                                            value={values.password}
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                            name="password"
                                            label="Пароль"
                                            type="password"
                                            placeholder="****"
                                            variant="outlined"
                                            fullWidth

                                            onChange={this.changeForm}
                                        />
                                    </Box>

                                    <Box>
                                        <LoadingButton
                                            variant="contained"
                                            size="large"
                                            onClick={handleSubmit}
                                            loading={isLoading}
                                            fullWidth
                                        >
                                            Авторизоваться
                                        </LoadingButton>
                                    </Box>

                                </form>

                            </Box>
                        )
                    }}
                </Formik>
            </Box>
        );
    }
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Некоретно введен Email').required('Заполните поле'),
    password: Yup.string().required('Заполните поле'),
});

export default Authorization
