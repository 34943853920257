import agent from "../agent/agent";

const uploadImages = async ({ file, folderId }) => {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("folderId", folderId);

	return await agent.post(`/api/admin/storage/upload-images`, formData).then((res) => {
		return res.data
	}).catch((err) => {
		return { error: err.response }
	})
}

export {
	uploadImages
}