import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Skeleton,

	FormControl,
	Select,
	MenuItem,

	Box,
	Grid,
	Button,
	Checkbox,
	TextField,
	Typography,
	Autocomplete,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Notification,
	NotificationTypes
} from "../../../../../common/Notification";
import {
	DialogConfirmation
} from "../../../../../components"
import agent from "../../../../../agent/agent";
import productStatus from "../../../../../constants/productStatus";
import {convertorCaseWords} from "../../../../../helper/convertor";

const initFilter = {
	"filter[id]": "",
	"filter[status]": "",
	"filter[brand_id]": "",
	"filter[name][like]": "",
	"filter[code][like]": "",
}

class DialogAddProduct extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			products: [],
			brands: [],
			selectedProducts: [],

			selectProduct: null,
			filter: {...initFilter},

			onSubmit: null,

			isOpenDialog: false,
			isLoadProducts: false
		}

		this.timeOutSearchProducts = null;
		this.refDialogConfirmation = React.createRef();
	}

	open = ({onSubmit}) => {
		this.setState({
			isOpenDialog: true,

			onSubmit
		}, async () => {
			await this.getBrands();
		})
	}
	close = () => {
		this.setState({
			products: [],
			selectedProducts: [],
			filter: {...initFilter},

			selectProduct: null,
			isOpenDialog: false
		})
	}

	// Получение дополнительные данных для таблицы
	getBrands = async () => {
		const response = await agent.get(`/api/brands?per-page=999`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		this.setState({
			brands: response
		})
	}

	// Логика поиска товара
	getProducts = async () => {
		const {filter} = this.state;

		this.setState({isLoadProducts: true})

		let search = [];
		Object.keys(filter).map((key) => {
			if (filter[key] !== "") {
				search.push(`${key}=${filter[key]}`);
			}
		})
		search = search.join("&");

		const response = await agent.get(`/api/admin/products${!!search && `?${search}`}`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		this.setState({
			products: response,
			isLoadProducts: false
		})
	}
	changeFilter = ({target}) => {
		clearTimeout(this.timeOutSearchProducts);

		const {name, value} = target;
		let filter = {...this.state.filter}
		filter[name] = value;
		this.setState({
			filter,
			selectedProducts: []
		});

		this.timeOutSearchProducts = setTimeout(async () => {
			await this.getProducts();
		}, 500)
	}

	// Добавление товара
	changeSelectedProducts = (selectedProducts) => {
		this.setState({selectedProducts});
	}
	submit = (isConfirm) => {
		const {selectedProducts} = this.state;

		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы точно хотите ${this._labelButtonAdd()}?`,
				acceptButtonTitle: "Да, добавить",
				acceptButtonAction: this.submit.bind(this, true)
			})

			return
		}

		this.state.onSubmit({
			productIds: selectedProducts
		})
	}

	_labelButtonAdd = () => {
		const {selectedProducts} = this.state;
		const productsCount = selectedProducts.length;
		const labelProducts = convertorCaseWords(productsCount, [
			'товар',
			'товара',
			'товаров',
		])

		return `добавить ${productsCount} ${labelProducts}`
	}

	render() {
		const {
			products,
			brands,
			filter,
			selectedProducts,

			isLoadProducts,
			isOpenDialog
		} = this.state;
		const {
			classes
		} = this.props;

		return (
			<>

				<Dialog
					open={isOpenDialog}
					fullWidth
					maxWidth="xl"
					onClose={this.close}
				>
					<DialogTitle>
						<Typography variant="h3">Добавление товара</Typography>
					</DialogTitle>

					<DialogContent>

						<Box mb="4px">
							<Grid container alignItems="flex-end" justifyContent="space-between">
								<Grid item>
									<Typography variant="caption">
										Для поиска товаров заполните фильтры
									</Typography>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										size="small"
										onClick={this.submit.bind(this, false)}
									>{this._labelButtonAdd()}</Button>
								</Grid>
							</Grid>
						</Box>

						<TableProducts
							products={products}
							brands={brands}
							filter={filter}
							classes={classes}
							selectedProducts={selectedProducts}

							isLoad={isLoadProducts}

							onChangeFilter={this.changeFilter}
							onChangeSelectedProducts={this.changeSelectedProducts}
						/>
					</DialogContent>

					<DialogActions>
						<Grid container justifyContent="flex-end" spacing="12px">
							<Grid item>
								<Button
									variant="outlined"
									sx={{minWidth: 250}}
									onClick={this.close}
								>Отменить</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									sx={{minWidth: 250}}
									onClick={this.submit.bind(this, false)}
								>{this._labelButtonAdd()}</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>

				<DialogConfirmation
					ref={this.refDialogConfirmation}
				/>

			</>
		)
	}
}

const TableProducts = React.memo((props) => {
	const {
		products,
		brands,
		filter,
		classes,
		selectedProducts,

		isLoad,

		onChangeFilter,
		onChangeSelectedProducts
	} = props;

	const handleChangeSelected = (productId) => {
		let sliceIndex = selectedProducts.findIndex((t) => +t === +productId);

		let newSelectedProducts = [...selectedProducts];
		if (sliceIndex > -1) {
			newSelectedProducts.splice(sliceIndex, 1);
		} else {
			newSelectedProducts.push(productId);
		}

		onChangeSelectedProducts(newSelectedProducts);
	}

	const _productStatus = (item) => {
		return productStatus.find((t) => +t.value === +item)?.label || "Неопределен"
	}

	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell width={40}></TableCell>
					<TableCell width={120}>ИД</TableCell>
					<TableCell>Название</TableCell>
					<TableCell width={120}>Статус</TableCell>
					<TableCell>Артикул (SKY)</TableCell>
					<TableCell>Производитель</TableCell>
				</TableRow>
				<TableRow className={classes.tableHeadSearch}>
					<TableCell/>
					<TableCell>
						<TextField
							value={filter['filter[id]']}
							name="filter[id]"
							size="small"
							placeholder="Введите"
							type="number"
							fullWidth
							onChange={onChangeFilter}
						/>
					</TableCell>
					<TableCell>
						<TextField
							value={filter['filter[name][like]']}
							name="filter[name][like]"
							size="small"
							placeholder="Введите"
							fullWidth
							onChange={onChangeFilter}
						/>
					</TableCell>
					<TableCell>
						<FormControl fullWidth>
							<Select
								value={filter['filter[status]']}
								name="filter[status]"
								onChange={onChangeFilter}
							>
								<MenuItem value="">Сбросить</MenuItem>
								{productStatus.map((status, index) => (
									<MenuItem value={status.value}>{status.label}</MenuItem>
								))}
							</Select>
						</FormControl>
					</TableCell>
					<TableCell>
						<TextField
							value={filter['filter[code][like]']}
							name="filter[code][like]"
							size="small"
							placeholder="Введите"
							fullWidth
							onChange={onChangeFilter}
						/>
					</TableCell>
					<TableCell>
						<FormControl fullWidth>
							<Select
								value={filter['filter[brand_id]']}
								name="filter[brand_id]"
								onChange={onChangeFilter}
							>
								<MenuItem value="">Сбросить</MenuItem>
								{brands.map((brand, index) => (
									<MenuItem key={`select-brand-${brand.id}`} value={brand.id}>{brand.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{Boolean(!isLoad) && (
					<>
						{(products || []).map((product) => (
							<TableRow
								key={`product-table-${product.id}`}
								hover
								onClick={() => handleChangeSelected(product.id)}
							>
								<TableCell>
									<Checkbox
										size="small"
										checked={selectedProducts.includes(product.id)}
									/>
								</TableCell>
								<TableCell>
									{product.id}
								</TableCell>
								<TableCell>
									{product.name}
								</TableCell>
								<TableCell>
									{_productStatus(product.status)}
								</TableCell>
								<TableCell>
									{product.code}
								</TableCell>
								<TableCell>
									{product.brand}
								</TableCell>
							</TableRow>
						))}
					</>
				)}
				{Boolean(isLoad) && (
					<>
						{[0, 1, 2].map((product) => (
							<TableRow key={`product-table-${product.id}`}>
								<TableCell>
									<Skeleton/>
								</TableCell>
								<TableCell>
									<Skeleton/>
								</TableCell>
								<TableCell>
									<Skeleton/>
								</TableCell>
								<TableCell>
									<Skeleton/>
								</TableCell>
								<TableCell>
									<Skeleton/>
								</TableCell>
								<TableCell>
									<Skeleton/>
								</TableCell>
							</TableRow>
						))}
					</>
				)}
			</TableBody>
		</Table>
	)
})

const styles = {
	table: {
		'& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
			padding: "4px 8px",
			borderBottom: "none"
		}
	},
	tableHeadSearch: {
		position: "relative",
		top: -3,

		"& > *": {
			"&:first-child": {
				borderRadius: "0 0 0 6px!important"
			},
			"&:last-child": {
				borderRadius: "0 0 6px 0!important"
			}
		},
		"& .MuiOutlinedInput-root": {
			height: "auto"
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			padding: "2px 4px",
		}
	}
}
DialogAddProduct = withStyles(styles)(DialogAddProduct)

export default DialogAddProduct
