import React from "react";
import {
	Box,
	Grid,
	Typography,
	TransitionGroup
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {numberFormat, phoneFormat} from "../../../../../common/Formater";

const OrderInfo = (props) => {
	const {order} = props;
	const classes = useStyles();

	const _promoLabel = () => {
		const promo = (order?.discounts || []).find((t) => Boolean(String(t.type) === "3"));
		if (!promo) {
			return "-"
		}

		return [
			promo?.name,
			Boolean(promo?.value) && `(${ promo?.value } руб.)`
		].filter((t) => !!t).join(' ')
	};

	return (
		<Box borderRadius={2} overflow="hidden" boxShadow={1} bgcolor="white">
			<Box px={1} py={1} bgcolor="#3855ec">
				<Typography variant="h4" color="white">Информация о заказе #{order.id}</Typography>
			</Box>
			<Box>
				<Grid container className={classes.grids}>
					<Grid item xs={6}>
						<Box>
							<Typography sx={{marginBottom: 2}} variant="h3">Информация о заказе</Typography>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Статус заказа</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{order.status_label}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Приобретенно из</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">-</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Определившийся регион</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">
												{ order?.city?.name || "-" }
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-start">
										<Grid item xs={4}>
											<Typography variant="h5">Комментарий от админа</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{order.admin_note || "-"}</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box>
							<Typography sx={{marginBottom: 2}} variant="h3">Информация о доставке</Typography>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Тип доставки</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{order.delivery_method_name}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container>
										<Grid item xs={4}>
											<Typography variant="h5">Адрес доставки</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{order.delivery_address}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Получатель</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography
												variant="subtitle1">{order.customer_name}; {order.customer_email}; {phoneFormat(order.customer_phone)};</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Стоимость доставки</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{numberFormat(order.delivery_cost, 2, ',')} руб.</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box>
							<Typography sx={{marginBottom: 2}} variant="h3">Информация об оплате</Typography>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Способ оплаты</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{order.payment_method_name}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Статус оплаты</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{order.payment_status_label}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Применено бонусов, руб</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{order.used_bonuses}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Применен промокод</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">
												{_promoLabel()}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box>
							<Typography sx={{marginBottom: 2}} variant="h3">Информация о клиенте</Typography>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Имя клиента</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{order?.customer_name || '-'}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Электронный адрес (email)</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{order?.customer_email || '-'}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container alignItems="flex-end">
										<Grid item xs={4}>
											<Typography variant="h5">Телефон</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant="subtitle1">{phoneFormat(order?.customer_phone || '') || '-'}</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	grids: {
		"& > *": {
			padding: 12,
			boxSizing: "border-box",
			border: "1px solid transparent",

			"&:nth-child(1)": {
				borderRight: "1px solid rgba(0,0,0,0.2)",
				borderBottom: "1px solid rgba(0,0,0,0.2)"
			},
			"&:nth-child(2)": {
				borderBottom: "1px solid rgba(0,0,0,0.2)"
			},
			"&:nth-child(3)": {
				borderRight: "1px solid rgba(0,0,0,0.2)",
			},
		}
	}
}));

export default OrderInfo
