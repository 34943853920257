import React from "react";
import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid, TextField,

} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";

const MarketplacesForm = (props) => {
	const {
		innerRef,
		initialValues,
	} = props;

	const handleSubmit = () => {
	}

	const handleChange = ({target}) => {
		const {name, value} = target;

		let newForm = {...innerRef?.current?.values || {}};
		newForm[name] = value;

		innerRef.current?.setValues(newForm);
	}
	const handleChangeBoolean = ({target}, value) => {
		const {name} = target;

		let newForm = {...innerRef.current.values};
		newForm[name] = value;

		innerRef.current.setValues(newForm);
	}
	const handleChangeAutocomplete = (value, name) => {
		let newForm = {...innerRef.current.values};
		newForm[name] = value?.value || null;

		innerRef.current.setValues(newForm);
	}

	return (
		<Formik
			innerRef={innerRef}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					values,
					errors,
					touched
				} = props;

				return (
					<Box px={2} py={2} bgcolor="white" borderRadius={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={values.market_product_name}
									error={Boolean(touched.market_product_name && errors.market_product_name)}
									helperText={touched.market_product_name && errors.market_product_name}
									name="market_product_name"
									label="Название для маркетплейса"
									fullWidth
									size='small'

									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormControlLabel
									control={<Checkbox/>}
									checked={values.export_yml}
									value={values.export_yml}
									name="export_yml"
									label="Yandex экспорт"
									onChange={handleChangeBoolean}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormControlLabel
									control={<Checkbox/>}
									checked={values.export_avito}
									value={values.export_avito}
									name="export_avito"
									label="AVITO экспорт"
									onChange={handleChangeBoolean}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormControlLabel
									control={<Checkbox/>}
									checked={values.export_2gis}
									value={values.export_2gis}
									name="export_2gis"
									label="2GIS экспорт"
									onChange={handleChangeBoolean}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormControlLabel
									control={<Checkbox/>}
									checked={values.export_yula}
									value={values.export_yula}
									name="export_yula"
									label="YULA экспорт"
									onChange={handleChangeBoolean}
								/>
							</Grid>
						</Grid>
					</Box>
				)
			}}
		</Formik>
	)
}

const validationSchema = Yup.object().shape({});

export default React.memo(MarketplacesForm)