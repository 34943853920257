import React from "react";
import {
	Autocomplete,
	TextField
} from "@mui/material";
import {
	Notification,
	NotificationTypes
} from "../../../../../../common/Notification";
import PropTypes from "prop-types";
import agent from "../../../../../../agent/agent";

const AutocompleteCreated = (props) => {
	const {
		value,
		initOptions,
		name,
		size,
		disabled,

		postUrl,
		postName,

		onChange
	} = props;
	const refAutocomplete = React.createRef();
	const [loading, setLoading] = React.useState(false);
	const [isLoadOptions, setLoadOptions] = React.useState(true);
	const [addedOptions, setAddedOptions] = React.useState([]);
	const [options, setOptions] = React.useState([...initOptions, ...addedOptions]);

	React.useEffect(() => {
		if (initOptions.length > 0) {
			setOptions([...initOptions, ...addedOptions]);
		}
	}, [initOptions]);

	const getApiOptions = async ({ isEverything } = {}) => {
		if (!isLoadOptions && !isEverything) {
			return
		}
		const res = await agent.get(`/api/admin/products/characteristic-variants-by-id/${ name }`).then((res) => {
			return res?.data || []
		}).catch(() => {
			return []
		});
		setOptions([...res, ...addedOptions]);
		setLoadOptions(false);
	}

	const handleChange = (event, newValue) => {
		if (typeof newValue === 'string') {
			handleSetValue({
				label: newValue,
			});
		}
		else if (newValue && newValue.inputValue) {
			// Create a new value from the user input
			handleSetValue({
				label: newValue.inputValue,
			});
		}
		else {
			handleSetValue(newValue);
		}
	}
	const handleSetValue = (value) => {
		if (value !== null && typeof value?.value === "undefined") {
			(async () => {
				await handleCreateValue(value);
			})();

			return
		}

		onChange({
			target: {
				name: name,
				value: value?.id
			}
		})
	}
	const handleCreateValue = async (value) => {
		let valueLabel = value?.label || "";

		const characteristic = props?.characteristic || {};
		if (characteristic.is_float && !/(\d+(?:[\.](?![\.])\d+)*)/.test(valueLabel)) {
			Notification({
				type: NotificationTypes.error,
				message: "Заполните корректно значение (только число с плавающей точкой)",
			})
			return
		}
		if (characteristic.is_float) {
			valueLabel = (value?.label || "").replaceAll(",", ".");
		}
		if (characteristic.is_integer && !/(^\d+?$)/.test(valueLabel)) {
			Notification({
				type: NotificationTypes.error,
				message: "Заполните корректно значение (только целое число)",
			})
			return
		}
		if (typeof valueLabel === "undefined" || typeof valueLabel === null) {
			Notification({
				type: NotificationTypes.error,
				message: "Заполните корректно значение",
			})
			return
		}

		setLoading(true);

		const body = {
			[postName]: name,
			value: valueLabel
		}
		const response = await agent.post(postUrl, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (response.error) {
			setLoading(false);

			Notification({
				type: NotificationTypes.error,
				message: response.error?.data?.message || (response.error?.data || []).map((t) => t?.message || "").filter((t) => !!t).join("<br/>") || "Ошибка сервера"
			})

			return null
		}

		setLoading(false);

		onChange({
			target: {
				name: name,
				value: response.id
			}
		}, {
			isUpdateAll: false
		})


		await getApiOptions({ isEverything: true });

		Notification({
			type: NotificationTypes.success,
			message: "Запись была успешно сохранена"
		})

		document.body.focus();
	}

	const _filterOptions = (options, params) => {
		const { inputValue } = params;
		const searchInput = inputValue.toLowerCase();

		const filtered = [...options]
			.filter((t) => ((t?.value || "").toLowerCase()).indexOf(searchInput) > -1)
			.sort((a, b) => {
				const labelA = (a?.value || "").toLowerCase();
				const labelB = (b?.value || "").toLowerCase();

				if (labelA < searchInput || labelB < searchInput) {
					return -1
				}
				if (labelA > searchInput || labelB > searchInput) {
					return 1
				}
				return 0
			});

		const isExisting = options.some((option) => (inputValue === option.value));
		if (inputValue !== '' && !isExisting) {
			filtered.push({
				inputValue,
				value: `Добавить "${inputValue}"`,
			});
		}

		return filtered;
	}
	const _getOptionLabel = (option) => {
		if (typeof option === 'string') {
			return option;
		}
		if (option.inputValue) {
			return option.inputValue;
		}
		if (Boolean(option?.value)) {
			return option?.value
		}

		const findOption = (options || []).find((t) => (t.value === option || t.id === option));
		return findOption?.value || "";
	}
	const _renderInput = (params) => {
		return (
			<TextField
				{...params}
				size={size}
			/>
		)
	}

	return (
		<Autocomplete
			value={value}
			options={options}
			loading={loading || isLoadOptions}
			disabled={disabled}

			autoComplete={true}
			autoHighlight={true}
			fullWidth={true}
			blurOnSelect={true}
			autoSelect={false}

			onChange={handleChange}
			filterOptions={_filterOptions}
			getOptionLabel={_getOptionLabel}
			renderInput={_renderInput}

			onOpen={getApiOptions}

			renderOption={(props, option) => <li {...props}>{option?.label || option?.value}</li>}
		/>
	)
}

AutocompleteCreated.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	size: PropTypes.string,
	options: PropTypes.array.isRequired,

	postUrl: PropTypes.string.isRequired,
	postName: PropTypes.string.isRequired,

	onChange: PropTypes.func.isRequired
}
AutocompleteCreated.defaultProps = {
	size: "small"
}

export default React.memo(AutocompleteCreated)
