import { createTheme } from '@mui/material/styles';

import {
    MuiButton,
    MuiCheckbox,
    MuiInputBase,
    MuiDialogTitle,
    MuiDialogContent,
    MuiDialogActions,
    MuiOutlinedInput,
    MuiPagination,
    MuiTable,
    MuiTableContainer,
    MuiFormControl,
    MuiBackdrop
} from './overrides';
import {
    palette,
    typography
} from "./common";

const theme = createTheme({
    palette,
    typography,

    components: {
        MuiButton,
        MuiCheckbox,
        MuiInputBase,
        MuiDialogTitle,
        MuiDialogContent,
        MuiDialogActions,
        MuiOutlinedInput,
        MuiPagination,
        MuiTable,
        MuiTableContainer,
        MuiFormControl,
        MuiBackdrop
    },
});

export default theme;
