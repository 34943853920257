import agent from "../agent/agent";

const GET_NOTIFICATION = "notifications/GET_NOTIFICATION";
const GET_QUEUE = "notifications/GET_QUEUE";
const CHANGE_OPEN = "notifications/CHANGE_OPEN";
const REMOVE_NOTIFICATION_ID = "notifications/REMOVE_NOTIFICATION_ID";
const REMOVE_ALL_NOTIFICATIONS = "notifications/REMOVE_ALL_NOTIFICATIONS";

const initialState = {
    notifications: [],
    queue: [],

    newCount: 0,
    newCountQueue: 0,

    isOpen: false
}

export function getQueue () {
    return async function (dispatch) {
        const response = await agent.get(`/api/admin/queue`).then((res) => {
            return res.data?.data
        }).catch((err) => {
            return []
        });
        dispatch({
            type: GET_QUEUE,
            queue: response
        })
    }
}
export function removeQueue (itemId) {
    return async function (dispatch) {
        await agent.put(`/api/admin/queue/multiple-update`, {
            ids: [itemId]
        });
        dispatch(getQueue());
    }
}

export function getNotification () {
    return async function (dispatch) {

    }
}
export function changeOpen (isOpen) {
    return {
        type: CHANGE_OPEN,
        isOpen
    }
}
export function removeNotification (notificationId) {
    return {
        type: REMOVE_NOTIFICATION_ID,
        notificationId
    }
}
export function removeAllNotifications () {
    return {
        type: REMOVE_ALL_NOTIFICATIONS
    }
}

export default function NotificationsState(state = initialState, action = {}) {
    switch (action.type) {
        case GET_NOTIFICATION: {
            let notifications = action.notifications

            return {
                ...state,
                notifications
            }
        }
        case GET_QUEUE: {
            let queue = action.queue || []

            return {
                ...state,
                queue,
                newCountQueue: queue.length
            }
        }
        case CHANGE_OPEN: {
            let isOpen = action.isOpen

            return {
                ...state,
                isOpen
            }
        }
        case REMOVE_NOTIFICATION_ID: {
            let notifications = [...state.notifications];
            notifications.splice(notifications.findIndex((t) => t.id === action.notificationId), 1);

            return {
                ...state,
                notifications,
                newCount: notifications.length
            }
        }
        case REMOVE_ALL_NOTIFICATIONS: {
            let notifications = [];

            return {
                ...state,
                notifications,
                newCount: notifications.length
            }
        }

        default:
            return state;
    }
}
