import React from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	Typography,
	IconButton,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	DialogConfirmation
} from "../../../../../components";
import ProductRow from "./ProductRow";
import DialogProductAdd from "./DialogProductAdd";
import DialogProductSettings from "./DialogProductSettings";
import BigNumber from "bignumber.js";
import {convertorNumber} from "../../../../../helper/convertor";
import agent from "../../../../../agent/agent";

class Products extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			counterUpdate: 0
		};

		this.refsProductsTable = {};
		this.refDialogProductAdd = React.createRef();
		this.refDialogConfirmation = React.createRef();
		this.refDialogProductSettings = React.createRef();
		this.timeoutUpdate = null;
		this.timeOutLastRefProduct = null;
	}

	// Редактирование опций товара
	changeOptionsProduct = (product, index, form, isSave) => {
		if (!isSave) {
			this.refDialogProductSettings.current.open({
				product,
				index,
				onSubmit: this.changeOptionsProduct.bind(this)
			})

			return
		}

		let products = [...this.props.products];
		products[index] = product;

		this.refDialogProductSettings.current.close();

		this.props.onChangeProducts(products);
	}

	// Удаление товара из списка
	deleteProductFromList = (product, index, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: "Вы точно хотите удалить товар?",
				acceptButtonAction: this.deleteProductFromList.bind(this, product, index, true)
			})

			return
		}

		let newProducts = [...this.props.products];
		newProducts.splice(index, 1);

		this.props.onChangeProducts(newProducts);
	}

	// Редактирование кол-во товара в заказе
	changeProductQuantity = (newQuantity, index) => {
		let products = [...this.props.products];
		products[index].quantity = newQuantity;
		this.props.onChangeProducts(products)
	}

	// Редактирование discounts товара в заказе
	changeProductDiscounts = async (discounts, index) => {
		let products = [...this.props.products];
		products[index].discounts = discounts;
		this.props.onChangeProducts(products)
	}

	//Редактирование external_data в заказе
	changeProductExternalData = (product, index) => {
		clearTimeout(this.timeoutUpdate)
		const {
			orderId,
			products
		} = this.props;
		let newProducts = [...products];
		newProducts[index] = product;

		this.props.onChangeProducts(newProducts)

		this.timeoutUpdate = setTimeout(async () => {
			const body = {
				external_data: {...JSON.parse(product.external_data)},
			}
			const response = await agent.put(`api/admin/orders/${orderId}/items/${product.id}`, body)
				.then(res => res.data)
				.catch(err => {
					return {error: err.response.data.message}
				});
		}, 500)
	}

	// Обновление всего товара
	changeProductFull = (product, index) => {
		let products = [...(this.props.products || [])];
		products[index] = product;
		this.props.onChangeProducts(products);
	}

	// Добавление товара
	addProduct = (product, isAdd) => {
		if (!isAdd) {
			this.refDialogProductAdd.current.open({
				onSubmit: this.addProduct.bind(this)
			})

			return
		}

		let products = [...this.props.products];
		products.push(product);

		this.refDialogProductAdd.current.close()

		this.props.onChangeProducts(products);
	}

	// Отмена новых цен в таблице товаров
	cancelAllNewPrices = async () => {

		await Promise.all(Object.keys(this.refsProductsTable || {}).map(async (key) => {
			await this.refsProductsTable[key].resetOtherPrice();
		}));

		// Object.keys(this.refsProductsTable || {}).map((key) => {
		// 	this.refsProductsTable[key].resetOtherPrice();
		// })
		this.setState({
			counterUpdate: this.state.counterUpdate + 1
		})
	}

	pushRefToRefsProductsTable = (index, ref) => {
		clearTimeout(this.timeOutLastRefProduct);
		this.refsProductsTable = {
			...this.refsProductsTable,
			[index]: ref
		};
		this.timeOutLastRefProduct = setTimeout(() => {
			this.setState({
				counterUpdate: this.state.counterUpdate + 1
			})
		}, 500);
	}

	// Вспомогтальеные фунции
	_totalProductLabel = () => {
		let price = 0;
		Object.keys(this.refsProductsTable || {}).map((key) => {
			if (this.refsProductsTable[key]) {
				const productPrice = this.refsProductsTable[key]._getCalcTotalPrice();
				price = productPrice + price;
			}
		});

		return `Итого: ${ convertorNumber(price, 2, ',') } руб.`

		const { products } = this.props;
		const totalPrice = products.reduce((value, product) => {
			let price = product?.newPrice || product.price;
			if (product.price !== product?.product?.price) {
				price = product?.product?.price
			}

			const priceOptions = (product?.options || [])
				.reduce((value, option) => {
					const variantPrice = +option?.variants?.[0]?.price || 0;
					return value + variantPrice
				}, 0);

			return value + new BigNumber(price).plus(priceOptions).multipliedBy(product.quantity).toNumber()
		}, 0)

		return `Итого: ${ convertorNumber(totalPrice, 2, ',') } руб.`
	}
	_isVisibleBlockNewPrices = () => {
		let isVisible = false;
		Object.keys(this.refsProductsTable || {}).map((key) => {
			if (Boolean(this.refsProductsTable[key]?.state?.isNewPrice)) {
				isVisible = true
			}
		});
		return isVisible
	}

	render() {
		const {
			products,
			classes,
			websiteId,
			userCityId
		} = this.props;

		return (
			<>
				<Box className={classes.root}>

					<Box className={classes.head}>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item>
								<Typography variant="h4" color="secondary">Заказанные позиции</Typography>
							</Grid>
							<Grid item>
								<Button
									size="small"
									color="secondary"
									variant="contained"
									onClick={this.addProduct.bind(this, {}, false)}
								>
									Добавить товар
								</Button>
							</Grid>
						</Grid>
					</Box>

					<Box className={classes.body}>
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell>Товар</TableCell>
									<TableCell>Артикул</TableCell>
									<TableCell>Цена</TableCell>
									<TableCell>Промокод</TableCell>
									<TableCell>Бонусы</TableCell>
									<TableCell>Кол-во</TableCell>
									<TableCell>Итог строки</TableCell>
									<TableCell>Действия</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{products.map((product, index) => (
									<ProductRow
										ref={this.pushRefToRefsProductsTable.bind(this, index)}
										key={`ProductRow-${product.id}-${index}`}
										product={JSON.stringify(product)}
										index={index}
										websiteId={websiteId}
										userCityId={userCityId}

										onDeleteProductFromList={this.deleteProductFromList}
										onChangeOptionsProduct={this.changeOptionsProduct}
										onChangeProductQuantity={this.changeProductQuantity}
										onChangeProductExternalData={this.changeProductExternalData}
										onChangeProductFull={this.changeProductFull}
										onChangeProductDiscounts={this.changeProductDiscounts}
									/>
								))}
								<TableRow>
									<TableCell colSpan={8} align="right">
										<Typography variant="h5">
											{this._totalProductLabel()}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>

						{Boolean(this._isVisibleBlockNewPrices()) && (
							<Box className={classes.informationMessage}>
								На некоторые товары цены изменились со времени предыдущей версии заказа!<br/>
								Мы зафиксировали их в ячейке "другая цена". Оставьте как есть, если требуется сохранить
								цены на момент заказа первой версии заказа.<br/>
								Или нажмите "сохранить цены предыдущей версии заказа" чтобы оставить цены актуальными.<br/>
								<Button
									variant="contained"
									onClick={this.cancelAllNewPrices}
								>
									Сохранить цены предыдущей версии заказа
								</Button>
							</Box>
						)}
					</Box>

				</Box>

				<DialogConfirmation
					ref={this.refDialogConfirmation}
				/>
				<DialogProductSettings
					ref={this.refDialogProductSettings}
					websiteId={websiteId}
					userCityId={userCityId}
				/>
				<DialogProductAdd
					ref={this.refDialogProductAdd}
					websiteId={websiteId}
					userCityId={userCityId}
				/>
			</>
		);
	}
}

const styles = {
	root: {
		borderRadius: 6,
		overflow: "hidden"
	},
	head: {
		padding: "12px 24px",
		backgroundColor: "#3855EC"
	},
	body: {},
	table: {
		borderSpacing: 0,
		borderTop: "1px solid #62B4FF",

		"& .MuiTableHead-root .MuiTableRow-root": {
			backgroundColor: "#3855EC",
		},
		"& .MuiTableRow-root .MuiTableCell-root": {
			borderRadius: "0!important",
			padding: "4px 8px",
			fontSize: 14,
			lineHeight: "16px",
			fontWeight: "500"
		},
	},

	informationMessage: {
		marginTop: 12,
		padding: "12px 24px",
		border: "1px solid rgba(229, 57, 53, 0.7)",
		backgroundColor: "rgba(229, 57, 53, 0.05)",
		borderRadius: 4,

		fontSize: 16,
		lineHeight: "26px",
		fontWeight: "500",
		color: "#e53935",
		textAlign: "center",
		letterSpacing: "0.1em",
		textTransform: "uppercase",

		"& button": {
			marginTop: 4,
			textTransform: "initial"
		}
	}
}
Products = withStyles(styles)(Products)

export default Products
