import React, {Component} from 'react';
import {Box, Typography} from "@mui/material";
import {
    ListAltOutlined as ListIcon
} from "@mui/icons-material";
import {withStyles} from "@mui/styles";
import {ControlsComponent, TableComponent} from "./components";
import {filterParse, filterStringify} from "../../../helper/filter";
import agent from "../../../agent/agent";

const initFilter = {
    page: 1,
}

class Characteristics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            characteristics: [],

            filter: {...initFilter},
            pagination: {},

            isLoading: true,
        };
    }

    componentDidMount = async () => {
        await this.setFilterPage();

        await this.getCharacteristics();
    }


    //Установка страницы при обновлении страницы
    setFilterPage = () => {
        const filter = this._getFilterPage();

        this.setState({filter});
    }

    getCharacteristics = async () => {
        this.setState({
            characteristics: [],
            isLoading: true,
        })
        const {filter} = this.state;
        const url = `/admin/api-shop-characteristics/list?ngrestCallType=list&fields=id,name,slug,type,show_in_filter,show_in_market,show_in_product,show_in_product_card,collapse_in_filter,sort,measure,in_search&sort=-id&page=${filter.page}`;

        const {data, headers} = await agent.get(url)
            .catch(err => {
                return {
                    data: [],
                    headers: {}
                }
            })

        const pagination = {
            count: headers?.['x-pagination-page-count'] || 1,
            perPage: headers?.['x-pagination-per-page'] || 20,
            totalCount: headers?.['x-pagination-total-count'] || 0,
        }

        this.setState({
            characteristics: data,
            pagination,
            isLoading: false,
        })
    }

    //Получение номера страницы при обновлении страницы
    _getFilterPage = () => {
        const search = window.location?.search || '';

        let filter = {};

        filter = {
            ...filter,
            ...initFilter,
            ...filterParse(search, initFilter, {})
        }

        return filter
    }

    // Работы с фильтром страницы
    _changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        const urlFilter = filterStringify(this.state.filter);
        window.history.replaceState(null, null, `/characteristics?${urlFilter}`);

        if (!isFastStart) {
            return null
        }

        await this.getCharacteristics();
    }

    _routeCharacteristicProductsPage = (id) => {
        this.props.history.push(`/characteristics/characteristic_variants/${id}`);
    };

    render() {
        const {
            classes
        } = this.props;
        const {
            characteristics,
            filter,
            pagination,

            isLoading
        } = this.state;
        return (
            <>
                <Box className={classes.head}>
                    <Box className={classes.headLogo}>
                        <ListIcon sx={{color: "#0057FF", fontSize: 42}}/>
                        <Typography variant="h1">Управление характеристиками</Typography>
                    </Box>
                </Box>
                <Box>
                    <ControlsComponent
                        filter={filter}
                        pagination={pagination}
                    />
                </Box>

                <Box>
                    <TableComponent
                        characteristics={characteristics}
                        filter={filter}
                        pagination={pagination}
                        isLoading={isLoading}
                        onChangeFilter={this._changeFilter}
                        onRouteProductsPage={this._routeCharacteristicProductsPage}
                    />
                </Box>
            </>
        );
    }
}

const styles = {
    head: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16
    }, headLogo: {
        display: "flex", alignItems: "center",

        "& h1": {
            marginLeft: 12
        }
    },
}

const CharacteristicsStyles = withStyles(styles)(Characteristics);

export default CharacteristicsStyles
