import React from 'react';

import {Box, Grid, IconButton, Tooltip} from "@mui/material";
import {AddOutlined as AddIcon, AttachFileOutlined as AttachFileIcon, Delete as DeleteIcon,} from '@mui/icons-material';

import {Formik} from "formik";
import {DropZoneFiles} from "../../../../../components";
import {filesGetFromUpload} from "../../../../../helper/files-upload";

const FilesForm = (props) => {
    const {
        initialValues,

        innerRef,
    } = props;

    const handleSubmit = () => {
    };

    const handleDeleteFile = () => {
        innerRef?.current?.setFieldValue('file_id', null);
    };

    const handleChangeFile = (event) => {
        const file = event?.target.files?.[0] || null;
        let newValues = innerRef?.current?.values || {};
        let file_id = {...newValues.file_id};
        file_id = file;
        file_id.source = URL.createObjectURL(file);

        newValues.file_id = file_id;
        innerRef.current.setValues(newValues);
    }
    const handleChangeFiles = (files) => {
      let newValues = innerRef?.current?.values || {};
      newValues.files = files;
      innerRef.current.setValues(newValues);
    }

    return (
        <>
            <Box px={2} py={2} bgcolor="white" borderRadius={2}>
                <Formik
                    innerRef={innerRef}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched
                        } = props;

                        return (
                            <>
                                <Box>
                                    <Box mb={3}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={1}>
                                                Файл
                                            </Grid>
                                            <Grid item xs>
                                                {values.file_id
                                                    ? (
                                                        <>
                                                            <Box display="flex">
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "5px",
                                                                        background: "#3855EC",
                                                                        borderRadius: "4px",
                                                                        color: "#fff",
                                                                        fontSize: "14px",
                                                                        justifyContent: "center",
                                                                        marginRight: "5px"
                                                                    }}
                                                                >
                                                                    <AttachFileIcon
                                                                        sx={{
                                                                            marginRight: "5px",
                                                                        }}
                                                                    />
                                                                    <FileUploadInfo
                                                                      info={values.file_id}
                                                                    />
                                                                </Box>
                                                                <IconButton
                                                                    color="error"

                                                                    onClick={handleDeleteFile}
                                                                >
                                                                    <Tooltip title="Удалить файл">
                                                                        <DeleteIcon/>
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </Box>
                                                        </>
                                                    )
                                                    : (
                                                        <IconButton color="primary">
                                                            <label style={{
                                                                cursor: "pointer",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}>
                                                                <AddIcon color="primary"/>
                                                                <input type="file" hidden
                                                                       onChange={(e) => handleChangeFile(e)}/>
                                                            </label>
                                                        </IconButton>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <DropZoneFiles
                                      value={values.files}
                                      onChange={handleChangeFiles}
                                    />
                                </Box>

                            </>
                        );
                    }}
                </Formik>
            </Box>
        </>
    );
};
const FileUploadInfo = React.memo((props) => {
   const { info } = props;
   const [fileName, setFileName] = React.useState("");
   React.useEffect(() => {
       (async () => {
           await handleGetFileInfo();
       })();
   }, [info]);

   const handleGetFileInfo = async () => {
       if (!!info?.name) {
           return
       }
       const fileInfo = await filesGetFromUpload({ fileId: info })
       setFileName(fileInfo?.name_original)
   }

   return (<>
       {info?.name || fileName}
   </>)
});

export default FilesForm;
