export default [
	{ value: "0", label: "Не округлять" },
	{ value: "1", label: "Округлить до целого (в меньшую сторону)" },
	{ value: "2", label: "Округлить до целого (в большую сторону)" },
	{ value: "3", label: "Округлить до ближайшего целого" },
	{ value: "4", label: "Округлить до 10 (в меньшую сторону). Пример: 16 округлится до 10; 298 округлится до 290" },
	{ value: "5", label: "Округлить до 10 (в большую сторону). Пример: 16 округлится до 20; 293 округлится до 300" },
	{ value: "6", label: "Округлить до ближайшей 10. Пример: 16 округлится до 20; 293 округлится до 290" },
	{ value: "7", label: "Округлить до 100 (в меньшую сторону). Пример: 160 округлится до 100; 2980 округлится до 2900" },
	{ value: "8", label: "Округлить до 100 (в большую сторону). Пример: 160 округлится до 200; 2930 округлится до 3000" },
	{ value: "9", label: "Округлить до ближайшей 100. Пример: 160 округлится до 200; 2930 округлится до 2900" },
	{ value: "10", label: "До 99 (Пример: 199, 299, 399)" }
]