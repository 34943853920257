import React from "react";
import {
    TextField
} from "@mui/material";

const TextFieldComponent = (props) => {
    const { itemKey } = props;

    return (
        <TextField
            {...props}
            name={itemKey}
            variant="outlined"
            fullWidth
        />
    )
}

export default TextFieldComponent
