import {palette} from "./index";

export default {
    table: {
        style: {
            backgroundColor: "transparent"
        }
    },
    headRow: {
        style: {
            backgroundColor: palette.primary.main,
            color: "white",
            position: "st",
            marginBottom: -3,
            borderRadius: "6px 6px 0 0",

            "& .MuiCheckbox-root": {
                color: "white"
            }
        },
    },
    headCells: {
        style: {
            paddingLeft: 8,
            paddingRight: 8,

            fontSize: '15px',
            lineHeight: "19px",
            fontWeight: "700",
            borderLeft: 1,
            borderColor: 'rgba(98, 180, 255, 0.5)',
            borderStyle: 'solid',

            "&:first-child": {
                borderLeft: 0
            }
        },
    },
    rows: {
        style: {
            fontSize: 14,
            lineHeight: '18px',
            color: "#434343",

            marginTop: 3,
            backgroundColor: "#fcfcfc",

            whiteSpace: "initial!important",

            '&:nth-child(2n)': {
                backgroundColor: "#F4F4F4"
            },
            '&:nth-of-type(n)': {
                borderBottomWidth: 0
            }
        },
        highlightOnHoverStyle: {
            backgroundColor: '#F4F4F4',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
        selectedHighlightStyle: {
            '&:nth-of-type(n)': {
                backgroundColor: '#C8C8C8',
            },
            '&:nth-of-type(2n)': {
                backgroundColor: '#B2B2B2'
            },
        }
    },
    cells: {
        style: {
            padding: "4px 8px",
            borderLeftWidth: 1,
            borderStyle: "solid",
            borderColor: '#E6E6E6',

            '&:nth-child(1)': {
                borderLeftWidth: 0,
            }
        }
    },
};
