import React from "react";
import {
	Grid,
	Checkbox,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	Typography,
	TableCell,
	TableRow,
	TextField,
	Button,
	Tooltip, FormControlLabel, Box
} from "@mui/material";
import {withStyles} from "@mui/styles";
import {CameraAlt as CameraAltIcon, Delete as DeleteIcon} from "@mui/icons-material"
import {IMaskInput} from "react-imask";
import {UploadImages} from "../../../../../components"
import DialogSelectImage from "./DialogSelectImage";
import productPriceType from "../../../../../constants/productPriceType";

class ExtraOptionRow extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			option: props.option
		}
	}

	changeForm = ({target}) => {
		const {name, value} = target;

		this.sendChangeForm({
			name,
			value
		})
	}
	changeFormSelect = ({target}) => {
		const {name, value} = target;

		this.sendChangeForm({
			name,
			value
		})
	}
	changeFormCheckbox = ({target}, value) => {
		const {name} = target;

		this.sendChangeForm({
			name,
			value: Number(value)
		})
	}
	changeFormFile = (imageId) => {
		this.sendChangeForm({
			name: "image_id",
			value: imageId
		})
	}

	sendChangeForm = (event) => {
		this.props.onChange(
			event,
			this.props.idxExtraOption,
			this.props.idxOption
		)
	}
	onDelete = () => {
		this.props.onDelete(this.props.idxExtraOption, this.props.idxOption)
	}

	render() {
		const {
			classes,
			idxOption,
			errorVariant,
			extraOption,

			disabledForm,
			disabledFormPrice,

			isOptionPresetGroup,
			isVisibleDimensions,
			isVisibleLogisticianData,

			minimize
		} = this.props;
		const option = JSON.parse(this.props.option);
		const errorOptions = errorVariant?.variants?.[idxOption] || {};
		const isDisabledStatus = Boolean(!!disabledForm && !Boolean(option?.option_preset_disable_sync_status));
		const isDisabledPriceValue = Boolean(!!disabledFormPrice && !Boolean(option?.option_preset_disable_sync_prices));

		if (minimize) {
			const variantIndex = option?.variantIndex;
			const variantError = this.props?.errorVariant?.[variantIndex] || {};
			return (
				<TableRow hover>
					<TableCell>
						<Grid container direction="column">
							<Grid item>
								<FormControlLabel
									name="status"
									control={<Checkbox size="small" sx={Boolean(isDisabledStatus) ? {
										opacity: 0.4
									} : {}}/>}
									label="Активен"
									disabled={isDisabledStatus}
									checked={Boolean(option.status)}
									onChange={this.changeFormCheckbox}
								/>
							</Grid>
							<Grid item>
								<FormControlLabel
									name="option_preset_disable_sync_status"
									control={<Checkbox  size="small"/>}
									label="Ред."
									checked={Boolean(option?.option_preset_disable_sync_status)}
									onChange={this.changeFormCheckbox}
								/>
							</Grid>
						</Grid>
					</TableCell>
					<TableCell>
						{option.name}
					</TableCell>
					<TableCell>
						<Grid container direction="column" pt={1}>
							<Grid item sx={{flex: 1, minWidth: 100}}>
								<TextField
									value={String(option.price_value)}
									className={classes.textField}
									error={Boolean(variantError.price_value && variantError.price_value)}
									helperText={variantError.price_value && variantError.price_value}
									name="price_value"
									size="small"
									placeholder="Введите"
									fullWidth
									disabled={isDisabledPriceValue}
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{
										scale: 2
									}}

									onChange={this.changeForm}
								/>
							</Grid>
							<Grid item>
								<FormControlLabel
									name="option_preset_disable_sync_prices"
									control={<Checkbox size="small" />}
									label="Ред."
									checked={Boolean(option?.option_preset_disable_sync_prices)}
									onChange={this.changeFormCheckbox}
								/>
							</Grid>
						</Grid>
					</TableCell>
					<TableCell>
						<Grid container direction="column" pt={1}>
							<Grid item sx={{flex: 1, minWidth: 100}}>
								<TextField
									value={String(option.price_old_value)}
									className={classes.textField}
									error={Boolean(variantError.price_old_value && variantError.price_old_value)}
									helperText={variantError.price_old_value && variantError.price_old_value}
									name="price_old_value"
									size="small"
									placeholder="Введите"
									fullWidth
									disabled={isDisabledPriceValue}
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{scale: 2}}

									onChange={this.changeForm}
								/>
							</Grid>
							<Grid item>
								<FormControlLabel
									name="option_preset_disable_sync_prices"
									control={<Checkbox size="small" />}
									label="Ред."
									checked={Boolean(option?.option_preset_disable_sync_prices)}
									onChange={this.changeFormCheckbox}
								/>
							</Grid>
						</Grid>
					</TableCell>
					<TableCell align="center">
						{ option.sort }
					</TableCell>

					<TableCell width={60} align="center">
						<Checkbox
							value={Boolean(option.is_product === 1)}
							checked={Boolean(option.is_product === 1)}
							size="small"
							name="is_product"
							disabled={isDisabledStatus}
							sx={Boolean(isDisabledStatus) ? {
								opacity: 0.4
							} : {}}
							onChange={this.changeFormCheckbox}
						/>
					</TableCell>

					{Boolean(isVisibleLogisticianData) && (
						<>
							<TableCell width={90}>
								<Tooltip title={String(option.volume || '')} arrow>
									<TextField
										value={String(option.volume || '')}
										className={classes.textField}
										name="volume"
										size="small"
										placeholder="Введите"
										fullWidth
										InputProps={{
											inputComponent: CustomInputAmount
										}}
										inputProps={{
											scale: 4
										}}
										onChange={this.changeForm}
									/>
								</Tooltip>
							</TableCell>
							<TableCell width={90}>
								<Tooltip title={String(option.weight || "")} arrow>
									<TextField
										value={String(option.weight || "")}
										className={classes.textField}
										size="small"
										name="weight"
										placeholder="Введите"
										fullWidth
										InputProps={{
											inputComponent: CustomInputAmount
										}}
										inputProps={{
											scale: 4
										}}
										onChange={this.changeForm}
									/>
								</Tooltip>
							</TableCell>
							<TableCell width={90}>
								<Tooltip title={String(option.seats || '')} arrow>
									<TextField
										value={String(option.seats || '')}
										className={classes.textField}
										name="seats"
										size="small"
										placeholder="Введите"
										fullWidth
										InputProps={{
											inputComponent: CustomInputAmount
										}}
										inputProps={{
											scale: 4
										}}
										onChange={this.changeForm}
									/>
								</Tooltip>
							</TableCell>
						</>
					)}

					{Boolean(isVisibleDimensions) && (
						<>
							<TableCell width={60}>
								<Tooltip title={String(option.size_width || '')} arrow>
									<TextField
										value={String(option.size_width || '')}
										className={classes.textField}
										name="size_width"
										size="small"
										placeholder="Введите"
										fullWidth
										InputProps={{ inputComponent: CustomInputAmount }}
										inputProps={{ scale: 2 }}
										onChange={this.changeForm}
									/>
								</Tooltip>
							</TableCell>
							<TableCell width={60}>
								<Tooltip title={String(option.size_depth || '')} arrow>
									<TextField
										value={String(option.size_depth || '')}
										className={classes.textField}
										name="size_depth"
										size="small"
										placeholder="Введите"
										fullWidth
										InputProps={{ inputComponent: CustomInputAmount }}
										inputProps={{ scale: 2 }}
										onChange={this.changeForm}
									/>
								</Tooltip>
							</TableCell>
							<TableCell width={60}>
								<Tooltip title={String(option.size_height || '')} arrow>
									<TextField
										value={String(option.size_height || '')}
										className={classes.textField}
										name="size_height"
										size="small"
										placeholder="Введите"
										fullWidth
										InputProps={{ inputComponent: CustomInputAmount }}
										inputProps={{ scale: 2 }}
										onChange={this.changeForm}
									/>
								</Tooltip>
							</TableCell>
							<TableCell width={60}>
								<Tooltip title={String(option.size_length || '')} arrow>
									<TextField
										value={String(option.size_length || '')}
										className={classes.textField}
										name="size_length"
										size="small"
										placeholder="Введите"
										fullWidth
										InputProps={{ inputComponent: CustomInputAmount }}
										inputProps={{ scale: 2 }}
										onChange={this.changeForm}
									/>
								</Tooltip>
							</TableCell>
						</>
					)}

					<TableCell className={classes.fileUpload}>
						<Grid container spacing={1} direction="column">
							<Grid item>
								<UploadImages
									value={option.image_id}
									multiple={false}
									folderId={process.env.REACT_APP_FOLDER_IMAGES_OPTIONS_ID}
									size="small"
									onChange={this.changeFormFile}
								/>
							</Grid>
							{!Boolean(option.image_id) && (
								<Grid item>
									<ButtonSelectedImage
										value={option.image_id}
										multiple={false}
										folderId={process.env.REACT_APP_FOLDER_IMAGES_OPTIONS_ID}
										option={option}
										extraOption={extraOption}
										size="small"
										disabled={disabledForm}
										onChange={this.changeFormFile}
									/>
								</Grid>
							)}
						</Grid>
					</TableCell>
					{Boolean(false) && (
						<TableCell align="right">
							<Tooltip title={<React.Fragment>
								<img src={`${process.env.REACT_APP_HOST_API}api/storage/image/${option.image_id}_w-280.webp`}/>
							</React.Fragment>} arrow placement="left">
								<img src={`${process.env.REACT_APP_HOST_API}api/storage/image/${option.image_id}_w-42.webp`}/>
							</Tooltip>
						</TableCell>
					)}
				</TableRow>
			)
		}
		return (
			<TableRow>
				<TableCell width={30}>
					{Boolean(isOptionPresetGroup) && (
						<Grid container spacing="0" justifyContent="center" alignItems="center" sx={{ marginBottom: "12px" }}>
							<Grid item>
								<Checkbox
									name="option_preset_disable_sync_status"
									size="small"
									checked={Boolean(option?.option_preset_disable_sync_status)}
									onChange={this.changeFormCheckbox}
								/>
							</Grid>
							<Grid item sx={{marginTop: "-8px"}}>
								<Typography>
									Ред.
								</Typography>
							</Grid>
						</Grid>
					)}

					<Checkbox
						checked={Boolean(option.status)}
						disabled={isDisabledStatus}
						name="status"
						size="small"
						sx={Boolean(isDisabledStatus) ? {
							opacity: 0.4
						} : {}}
						onChange={this.changeFormCheckbox}
					/>
				</TableCell>
				<TableCell>
					<Tooltip title={option.name || ""} arrow>
						<TextField
							value={option.name || ""}
							className={classes.textField}
							name="name"
							size="small"
							placeholder="Введите"
							fullWidth
							disabled={disabledForm}
							error={Boolean(errorOptions.name && errorOptions.name)}
							helperText={errorOptions.name && errorOptions.name}
							onChange={this.changeForm}
						/>
					</Tooltip>
				</TableCell>
				<TableCell width={100}>
					{Boolean(isOptionPresetGroup) && (
						<Grid container spacing="0" justifyContent="center" alignItems="center" sx={{ marginBottom: "12px" }}>
							<Grid item>
								<Checkbox
									name="option_preset_disable_sync_prices"
									size="small"
									value={Boolean(option?.option_preset_disable_sync_prices)}
									checked={Boolean(option?.option_preset_disable_sync_prices)}
									onChange={this.changeFormCheckbox}
								/>
							</Grid>
							<Grid item sx={{marginTop: "-8px"}}>
								<Typography>
									Своя цена
								</Typography>
							</Grid>
						</Grid>
					)}

					<Tooltip title={String(option.price_value || "")} arrow>
						<TextField
							value={String(option.price_value)}
							className={classes.textField}
							error={Boolean(errorOptions.price_value && errorOptions.price_value)}
							helperText={errorOptions.price_value && errorOptions.price_value}
							name="price_value"
							size="small"
							placeholder="Введите"
							fullWidth
							disabled={isDisabledPriceValue}
							InputProps={{
								inputComponent: CustomInputAmount
							}}
							inputProps={{
								min: -999999999,
								scale: 2
							}}

							onChange={this.changeForm}
						/>
					</Tooltip>
				</TableCell>
				<TableCell width={100}>
					<Tooltip title={String(option.price_old_value || "")} arrow>
						<TextField
							value={String(option.price_old_value)}
							className={classes.textField}
							error={Boolean(errorOptions.price_old_value && errorOptions.price_old_value)}
							helperText={errorOptions.price_old_value && errorOptions.price_old_value}
							name="price_old_value"
							size="small"
							placeholder="Введите"
							fullWidth
							disabled={isOptionPresetGroup}
							InputProps={{
								inputComponent: CustomInputAmount
							}}
							inputProps={{
								min: -999999999,
								scale: 2
							}}

							onChange={this.changeForm}
						/>
					</Tooltip>
				</TableCell>
				<TableCell width={80}>
					<Tooltip title={String(option.supplier_price || "")} arrow>
						<TextField
							value={String(option.supplier_price || "")}
							className={classes.textField}
							name="supplier_price"
							size="small"
							placeholder="Введите"
							fullWidth
							disabled={disabledForm}
							InputProps={{
								inputComponent: CustomInputAmount
							}}
							inputProps={{
								scale: 2
							}}

							onChange={this.changeForm}
						/>
					</Tooltip>
				</TableCell>
				<TableCell width={64}>
					<FormControl
						className={classes.formControl}
						fullWidth
						size="small"
						disabled={disabledForm}
					>
						<Select
							value={option.price_type}
							name="price_type"
							sx={{padding: 0}}
							onChange={this.changeFormSelect}
						>
							{productPriceType.map((item, index) => (
								<MenuItem
									value={item.value}
									sx={{
										padding: "0 4px",
										fontSize: 12
									}}
								>
									{item.labelShort || item.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</TableCell>
				<TableCell width={164}>
					<Tooltip title={option.sku} arrow>
						<TextField
							value={option.sku}
							className={classes.textField}
							name="sku"
							size="small"
							placeholder="Введите"
							fullWidth
							multiline
							rows={4}
							disabled={disabledForm}
							onChange={this.changeForm}
						/>
					</Tooltip>
				</TableCell>
				<TableCell width={36}>
					<TextField
						value={String(option.sort || "")}
						className={classes.textField}
						size="small"
						name="sort"
						placeholder="Введите"
						fullWidth
						disabled={disabledForm}
						InputProps={{
							inputComponent: CustomInputAmount
						}}
						inputProps={{
							scale: 0
						}}
						onChange={this.changeForm}
					/>
				</TableCell>
				<TableCell width={38} align="center">
					<Checkbox
						value={Boolean(option.is_product === 1)}
						checked={Boolean(option.is_product === 1)}
						size="small"
						name="is_product"
						disabled={disabledForm}
						onChange={this.changeFormCheckbox}
					/>
				</TableCell>

				{Boolean(isVisibleLogisticianData) && (
					<>
						<TableCell width={76}>
							<Tooltip title={String(option.volume || '')} arrow>
								<TextField
									value={String(option.volume || '')}
									className={classes.textField}
									name="volume"
									size="small"
									placeholder="Введите"
									fullWidth
									disabled={disabledForm}
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{
										scale: 4
									}}
									onChange={this.changeForm}
								/>
							</Tooltip>
						</TableCell>
						<TableCell width={76}>
							<Tooltip title={String(option.weight || "")} arrow>
								<TextField
									value={String(option.weight || "")}
									className={classes.textField}
									size="small"
									name="weight"
									placeholder="Введите"
									fullWidth
									disabled={disabledForm}
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{
										scale: 4
									}}
									onChange={this.changeForm}
								/>
							</Tooltip>
						</TableCell>
						<TableCell width={76}>
							<Tooltip title={String(option.seats || '')} arrow>
								<TextField
									value={String(option.seats || '')}
									className={classes.textField}
									name="seats"
									size="small"
									placeholder="Введите"
									fullWidth
									disabled={disabledForm}
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{
										scale: 4
									}}
									onChange={this.changeForm}
								/>
							</Tooltip>
						</TableCell>
					</>
				)}

				{Boolean(isVisibleDimensions) && (
					<>
						<TableCell width={52}>
							<Tooltip title={String(option.size_width || '')} arrow>
								<TextField
									value={String(option.size_width || '')}
									className={classes.textField}
									name="size_width"
									size="small"
									placeholder="Введите"
									fullWidth
									disabled={disabledForm}
									InputProps={{ inputComponent: CustomInputAmount }}
									inputProps={{ scale: 2 }}
									onChange={this.changeForm}
								/>
							</Tooltip>
						</TableCell>
						<TableCell width={52}>
							<Tooltip title={String(option.size_depth || '')} arrow>
								<TextField
									value={String(option.size_depth || '')}
									className={classes.textField}
									name="size_depth"
									size="small"
									placeholder="Введите"
									fullWidth
									disabled={disabledForm}
									InputProps={{ inputComponent: CustomInputAmount }}
									inputProps={{ scale: 2 }}
									onChange={this.changeForm}
								/>
							</Tooltip>
						</TableCell>
						<TableCell width={52}>
							<Tooltip title={String(option.size_height || '')} arrow>
								<TextField
									value={String(option.size_height || '')}
									className={classes.textField}
									name="size_height"
									size="small"
									placeholder="Введите"
									fullWidth
									disabled={disabledForm}
									InputProps={{ inputComponent: CustomInputAmount }}
									inputProps={{ scale: 2 }}
									onChange={this.changeForm}
								/>
							</Tooltip>
						</TableCell>
						<TableCell width={52}>
							<Tooltip title={String(option.size_length || '')} arrow>
								<TextField
									value={String(option.size_length || '')}
									className={classes.textField}
									name="size_length"
									size="small"
									placeholder="Введите"
									fullWidth
									disabled={disabledForm}
									InputProps={{ inputComponent: CustomInputAmount }}
									inputProps={{ scale: 2 }}
									onChange={this.changeForm}
								/>
							</Tooltip>
						</TableCell>
					</>
				)}

				<TableCell width="84px" className={classes.fileUpload}>
					<Grid container spacing={1}>
						<Grid item>
							<UploadImages
								value={option.image_id}
								multiple={false}
								folderId={process.env.REACT_APP_FOLDER_IMAGES_OPTIONS_ID}
								size="small"
								disabled={disabledForm}
								onChange={this.changeFormFile}
							/>
						</Grid>
						{!Boolean(option.image_id) && (
							<Grid item>
								<ButtonSelectedImage
									value={option.image_id}
									multiple={false}
									folderId={process.env.REACT_APP_FOLDER_IMAGES_OPTIONS_ID}
									option={option}
									extraOption={extraOption}
									size="small"
									disabled={disabledForm}
									onChange={this.changeFormFile}
								/>
							</Grid>
						)}
					</Grid>
				</TableCell>
				<TableCell width={32} align="right">
					<IconButton onClick={this.onDelete} disabled={disabledForm} color="error">
						<DeleteIcon sx={{fontSize: 20}}/>
					</IconButton>
				</TableCell>
			</TableRow>
		);
	}
};
const ButtonSelectedImage = React.memo((props) => {
	const {
		option,
		extraOption,

		onChange
	} = props;
	const [isOpenDialog, setOpenDialog] = React.useState(false);

	const changeOpenDialog = () => {
		setOpenDialog(!isOpenDialog)
	}
	const handelChange = (imageId) => {
		onChange(imageId);
		setOpenDialog(false);
	}

	return (
		<>
			<Button
				sx={{
					padding: "4px 6px",
					height: "auto",

					fontSize: 12,
					lineHeight: "12px",
					textTransform: "initial",
					whiteSpace: "nowrap",
				}}
				variant="contained"
				startIcon={<CameraAltIcon/>}
				onClick={changeOpenDialog}
			>
				<span className="label">Выбрать из<br/>существующих</span>
			</Button>

			{Boolean(isOpenDialog) && (
				<DialogSelectImage
					option={option}
					extraOption={extraOption}
					onChange={handelChange}
					onClose={changeOpenDialog}
				/>
			)}
		</>
	)
});

const CustomInputAmount = ({inputRef, ...rest}) => (
	<IMaskInput
		ref={inputRef}
		{...rest}

		mask={Number}
		thousandsSeparator=""
		radix="."
		mapToRadix={[',']}
		scale={rest?.scale}
	/>
)


const styles = {
	textField: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			padding: "2px 6px",
		}
	},
	formControl: {
		"& .MuiSvgIcon-root": {
			right: 0,
			fontSize: 15
		},
		"& .MuiOutlinedInput-root": {
			height: "auto",
			// fontSize: 12
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			padding: "2px 6px",
		}
	},
	fileUpload: {
		"@media(max-width: 1500px)": {
			"& .MuiButton-root": {
				minWidth: "initial"
			},
			"& .MuiButton-startIcon": {
				margin: 0
			},
			"& span.label": {
				display: "none"
			}
		}
	},
}
ExtraOptionRow = withStyles(styles)(ExtraOptionRow)

export default ExtraOptionRow
