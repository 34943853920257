import React from "react";
import {
    Box,
    Grid,

    TextField
} from "@mui/material";
import {
    Formik
} from "formik";
import {
    CKEditor
} from "../../../../../components";
import * as Yup from "yup";

const DescriptionCharacteristicsForm = (props) => {
    const {
        innerRef,
        initialValues
    } = props;

    const handleSubmit = () => {}

    const handleChange = ({ target }) => {
        const { name, value } = target;

        let newForm = {...innerRef.current.values};
        newForm[name] = value;

        innerRef.current.setValues(newForm);
    }

    return (
        <Formik
            innerRef={innerRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => {
                const {
                    values,
                    errors,
                    touched
                } = props;

                return (
                    <Box px={2} py={4} bgcolor="white" borderRadius={2}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                {/*<TextField*/}
                                {/*    value={values.short_description}*/}
                                {/*    error={Boolean(touched.short_description && errors.short_description)}*/}
                                {/*    helperText={touched.short_description && errors.short_description}*/}
                                {/*    name="short_description"*/}
                                {/*    label="Краткое описание"*/}
                                {/*    fullWidth*/}

                                {/*    onChange={handleChange}*/}
                                {/*/>*/}
                                <CKEditor
                                    value={values.short_description}
                                    name="short_description"
                                    label="Краткое описание"
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>

                        <Box my={2} sx={{marginLeft: -2, marginRight: -2, height: '1px', backgroundColor: "rgba(0,0,0,0.2)"}}/>

                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <CKEditor
                                    label="Описание"
                                    value={values.description}
                                    name="description"
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>

                        <Box my={2} sx={{marginLeft: -2, marginRight: -2, height: '1px', backgroundColor: "rgba(0,0,0,0.2)"}}/>
                    </Box>
                )
            }}
        </Formik>
    )
}

const validationSchema = Yup.object().shape({

});

export default DescriptionCharacteristicsForm
