import React from "react";
import {
  Box,
  Grid,
  Button,
  Drawer,
  Tooltip,
  Typography,
  IconButton,

  List,
  ListItem
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Close as CloseIcon
} from "@mui/icons-material";
import {
  compose
} from "recompose";
import {
  connect
} from "react-redux";
import NotificationCard from "./NotificationCard";
import QueueCard from "./QueueCard";
import {changeOpen, removeAllNotifications, removeNotification, removeQueue} from "../../states/notifications";

class SideBarNotifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  // Закрыть сайтбар
  closeNotifications = () => {
    this.props.changeOpen(false);
  }

  // Удалить уведомление из списка
  removeNotification = (notificationId) => {
    this.props.removeNotification(notificationId);
  }
  removeQueue = (notificationId) => {
    this.props.removeQueue(notificationId);
  }
  removeAllNotifications = () => {
    this.props.removeAllNotifications();
  }

  render() {
    const {
      classes,

      notifications,
      queue,

      queueCount,

      isOpen,
    } = this.props;

    return (
      <Drawer
        open={isOpen}
        anchor="right"
      >
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h3" sx={{color: "white"}}>Уведомления</Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Закрыть" arrow>
                  <IconButton onClick={this.closeNotifications}>
                    <CloseIcon sx={{color: "white"}}/>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>

          <List className={classes.list}>
            {Boolean(notifications.length) && (
              <ListItem>
                <Button variant="text" onClick={this.removeAllNotifications}>
                  Удалить все уведомления
                </Button>
              </ListItem>
            )}

            {notifications.map((notification) => (
              <ListItem key={`ListItem-${notification.id}`}>
                <NotificationCard
                  notification={notification}
                  onRemoveNotification={this.removeNotification}
                />
              </ListItem>
            ))}
            {queue.map((notification) => (
              <ListItem key={`ListItem-${notification.id}`}>
                <QueueCard
                  notification={notification}
                  onRemoveNotification={this.removeQueue}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    )
  }
}


const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    width: 420,
    overflow: "hidden"
  },
  header: {
    padding: "16px",
    boxSizing: "border-box",
    backgroundColor: "#62B4FF"
  },
  list: {
    overflow: "auto"
  }
};
SideBarNotifications = withStyles(styles)(SideBarNotifications);

SideBarNotifications = compose(
  connect(
    state => ({
      notifications: state?.notifications?.notifications || [],
      notificationsCount: state?.notifications?.newCount || [],

      queue: state?.notifications?.queue || [],
      queueCount: state?.notifications?.newCountQueue || [],

      isOpen: state?.notifications?.isOpen
    }),
    dispatch => ({
      changeOpen: (value) => dispatch(changeOpen(value)),
      removeNotification: (notificationId) => dispatch(removeNotification(notificationId)),
      removeQueue: (notificationId) => dispatch(removeQueue(notificationId)),
      removeAllNotifications: (notificationId) => dispatch(removeAllNotifications(notificationId)),
    }),
  ),
)(SideBarNotifications);

export default SideBarNotifications
