import React, {Component} from 'react';
import {ControlCategoryEdit, HeaderCategoryEdit, MainForm} from "./components";
import {Box} from "@mui/material";
import agent from "../../../agent/agent";
import {EditForm} from "../../regionalPrices/RegionalPriceEdit/components";

class CategoryEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: {},
            categoryId: props.match.params.id,

            parents: [],
            characteristics: [],
            lists: [],

            isLoading: true,
            isCreate: true,
        };

        this.refForm = React.createRef();
    }

    componentDidMount = async () => {
        await this.getLists();
        await this.getServices();
        await this.getCategory();
    }

    getCategory = async () => {
        const id = this.state.categoryId;

        if (id !== undefined) {
            const url = `/admin/api-shop-categories/${id}?ngrestCallType=update&expand=parentId%2Cunit%2CmetaTitle%2CmetaKeywords%2CmetaDescription%2Cfile_id%2CextraData%2CextraFeaturedFilterPages&fields=id%2Cname%2Cname_one%2CparentId%2Cstatus%2Cshow_in_menu%2Cslug%2Ccharacteristic_group_id%2Cimage_id%2Cimages%2Ctitle%2Cshort_description%2Cdescription%2Cunit%2Ccount_step%2Cproduct_price_from%2CmetaTitle%2CmetaKeywords%2CmetaDescription%2Cseo_canonical%2Cfile_id%2Cexternal_id%2CextraData%2CextraFeaturedFilterPages%2Cgoogle_merchant_category_id%2Cyandex_market_category`;

            const category = await agent.get(url)
                .then((res) => res.data)
                .catch((err) => {
                    return {};
                });

            this.setState({
                category,
                isLoading: false,
                isCreate: false
            });
        } else {
            this.setState({
                category: {},
                isLoading: false,
                isCreate: true,
            });
        }
    };

    getServices = async () => {
        const url = "/admin/api-shop-categories/services?ngrestCallType=services&fields=id";

        const services = await agent.get(url)
            .then(res => res.data.service)
            .catch(err => {
                return [];
            });

        this.setState({
            parents: services.parentId.selectdata,
            characteristics: services.characteristic_group_id.selectdata
        })
    }

    getLists = async () => {
        const url = "/admin/api-shop-filter-page/list?fields=id,title";

        const lists = await agent.get(url)
            .then(res => res.data)
            .catch(err => {
                return [];
            })

        this.setState({lists});
    }

    _saveChange = () => {
    };
    _duplicateCategory = () => {
    };


    _resetForm = () => {
    };

    _deleteCategory = () => {
    };

    //Перенаправление на страницу редактирования категории
    _routeCategoryPage = () => {
        this.props.history.push(`/categories`)
    }

    render() {
        const {
            category,
            categoryId,

            parents,
            characteristics,
            lists,

            isLoading,
            isCreate,
        } = this.state;
        return (
            <>
                <HeaderCategoryEdit
                    category={category}
                    isCreate={isCreate}

                    onBack={this._routeCategoryPage}
                />
                <Box>
                    <ControlCategoryEdit
                        isCreate={isCreate}

                        onBack={this._routeCategoryPage}
                        onReset={this._resetForm}
                        onDelete={this._deleteCategory}
                        onDuplicate={this._duplicateCategory}
                        onSave={this._saveChange}
                    />
                    <Box px={2} py={4} bgcolor="white" borderRadius={2}>

                        {Boolean(!isLoading) && (
                            <MainForm
                                refFormik={this.refForm}
                                category={category}
                                isCreate={isCreate}
                                parents={parents}
                                characteristics={characteristics}
                                lists={lists}
                            />
                        )}

                        {Boolean(isLoading) && (
                            <>Loading</>
                        )}

                    </Box>
                </Box>
            </>
        );
    }
}

export default CategoryEdit;