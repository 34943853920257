import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Typography,
	ButtonGroup,
} from "@mui/material";

class DialogMultiChangeParams extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			fit: null,
			border: null,

			isOpen: false
		}
	}

	open = ({ onSubmit }) => {
		this.setState({
			onSubmit,

			isOpen: true
		})
	}
	close = () => {
		this.setState({
			fit: null,
			border: null,

			isOpen: false,
		})
	}

	changeFit = (fit) => {
		this.setState({ fit })
	}
	changeBorder = (border) => {
		this.setState({ border })
	}

	submit = () => {
		this.state.onSubmit({
			fit: this.state.fit,
			border: this.state.border,
		})
	}

	render () {
		const {
			fit,
			border,

			isOpen
		} = this.state;
		const {
			totalCount
		} = this.props;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3">
						Массовое изменение параметров ({totalCount} шт.)
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container spacing={2} alignItems="center" justifyContent="center" flexDirection="column">
						<Grid item>
							<ButtonGroup>
								<Button
									variant={Boolean(fit === "crop") ? "contained" : "outlined"}
									onClick={this.changeFit.bind(this, "crop")}
								>Обрезать</Button>
								<Button
									variant={Boolean(fit === "contain") ? "contained" : "outlined"}
									onClick={this.changeFit.bind(this, "contain")}
								>Не обрезать</Button>
							</ButtonGroup>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1" textAlign="center" mb={0.5}>Добавить поля</Typography>
							<ButtonGroup>
								<Button
									variant={Boolean(border === 0) ? "contained" : "outlined"}
									onClick={this.changeBorder.bind(this, 0)}
									color="inherit"
								>0px</Button>
								<Button
									variant={Boolean(border === 5) ? "contained" : "outlined"}
									onClick={this.changeBorder.bind(this, 5)}
								>5px</Button>
								<Button
									variant={Boolean(border === 10) ? "contained" : "outlined"}
									onClick={this.changeBorder.bind(this, 10)}
								>10px</Button>
								<Button
									variant={Boolean(border === 15) ? "contained" : "outlined"}
									onClick={this.changeBorder.bind(this, 15)}
								>15px</Button>
								<Button
									variant={Boolean(border === 30) ? "contained" : "outlined"}
									onClick={this.changeBorder.bind(this, 30)}
								>30px</Button>
							</ButtonGroup>
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button variant="outlined" color="primary" onClick={this.close.bind(this)}>Отмена</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								disabled={fit === null && border === null}
								onClick={this.submit.bind(this)}
							>Применить</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

export default DialogMultiChangeParams