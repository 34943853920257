import React, {Component} from 'react';
import {withStyles} from "@mui/styles";
import {Box, Button, Typography} from "@mui/material";
import {AddBoxOutlined as AddBoxOutlinedIcon, LoyaltyOutlined as LoyaltyIcon} from "@mui/icons-material";
import {Controls, TableComponent} from "./components";
import agent from "../../../agent/agent";
import {filterParse, filterStringify} from "../../../helper/filter";
import {Notification, NotificationTypes} from "../../../common/Notification";

const initFilter = {
    page: 1,
}

class Promotions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promotions: [],

            filter: {...initFilter},
            pagination: {},

            isLoading: true,
        }
    }

    componentDidMount = async () => {
        await this.setFilterPage();
        // ----------------------------
        await this.getPromotions();
    }


    //Установка страницы при обновлении страницы
    setFilterPage = () => {
        const filter = this._getFilterPage();

        this.setState({filter});
    }

    //Логика получения акций
    getPromotions = async () => {
        const {filter} = this.state;

        const {
            data,
            headers
        } = await agent.get(`/admin/api-shop-promotion/list?ngrestCallType=list&expand=extraDiscount%2CextraBonus%2CextraUrl&fields=id%2Cid%2Cimage_id%2Cstarted_at%2Cended_at%2Ctitle%2Cslug%2Cproduct_compilation_id%2CextraDiscount%2CextraBonus%2Cmin_total%2Cstatus%2CextraUrl&sort=id&page=${filter.page}`)
            .catch((err) => {
                return {
                    data: [],
                    headers: {},
                }
            });

        const pagination = {
            count: headers?.['x-pagination-page-count'] || 1,
            perPage: headers?.['x-pagination-per-page'] || 20,
            totalCount: headers?.['x-pagination-total-count'] || 0,
        }

        this.setState({
            promotions: data,
            pagination,
            isLoading: false,
        })
    }

    //Логика изменения статуса категории
    _onChangePromotionStatus = async (row, activeStatus) => {
        const body = {
            status: activeStatus,
        }

        const response = await agent.put(`/admin/api-shop-promotion/${row.id}?ngrestCallType=update&fields=status`, body)
            .then().catch();

        await this.getPromotions();
    }

    //Получение номера страницы при обновлении страницы
    _getFilterPage = () => {
        const search = window.location?.search || '';

        let filter = {};

        filter = {
            ...filter,
            ...initFilter,
            ...filterParse(search, initFilter, {})
        }

        return filter
    }

    // Работы с фильтром страницы
    _changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        const urlFilter = filterStringify(this.state.filter);
        window.history.replaceState(null, null, `/promotions?${urlFilter}`);

        if (!isFastStart) {
            return null
        }

        await this.getPromotions();
    }

    render() {
        const {classes} = this.props;
        const {
            promotions,

            filter,
            pagination,

            isLoading
        } = this.state;
        return (
            <>
                <Box className={classes.head}>
                    <Box className={classes.headLogo}>
                        <LoyaltyIcon sx={{color: "#0057FF", fontSize: 42}}/>
                        <Typography variant="h1">Управление акциями</Typography>
                    </Box>
                    <Button variant="contained" startIcon={<AddBoxOutlinedIcon sx={{color: "white"}}/>}>
                        Добавить акцию
                    </Button>
                </Box>
                <Box>
                    <Controls filter={filter} pagination={pagination}/>
                </Box>

                <Box>
                    <TableComponent
                        promotions={promotions}

                        filter={filter}
                        pagination={pagination}

                        isLoading={isLoading}

                        onChangeFilter={this._changeFilter}
                        onChangePromotionStatus={this._onChangePromotionStatus}
                    />
                </Box>
            </>
        );
    }
}

const styles = {
    head: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16
    }, headLogo: {
        display: "flex", alignItems: "center",

        "& h1": {
            marginLeft: 12
        }
    },
}

const PromotionsStyles = withStyles(styles)(Promotions);

export default PromotionsStyles