import React from 'react';
import {
    Box,
    Button,
    Grid
} from "@mui/material";
import {
    ArrowDropDownCircleOutlined as BackIcon,
    CheckCircleOutlineOutlined as CheckIcon,
    DeleteOutlineOutlined as DeleteIcon,
    LayersOutlined as DoubleIcon,
    ReplyOutlined as ResetIcon,
    SaveOutlined as SaveIcon
} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";

const ControlCategoryEdit = React.memo((props) => {
    const {
        onSave,
        isCreate
    } = props;
    const classes = useStyles();

    const onSaveAndContinue = () => {
        onSave();
    }

    const onSaveAndRoute = async () => {
        await onSave(true);
    }

    return (
        <Box className={classes.container}>
            <Grid container justifyContent='space-between' spacing={1}>
                {/*<Grid item xs>*/}
                {/*    <Button*/}
                {/*        className={classes.Button}*/}
                {/*        variant="contained"*/}
                {/*        size="medium"*/}
                {/*        startIcon={<DoubleIcon sx={{transform: 'rotate(-135deg)',}}/>}*/}

                {/*        onClick={onDuplicate}*/}
                {/*    >*/}
                {/*        Дублировать*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
                <Grid item xs={5}>
                    <Button
                        className={classes.Button}
                        variant="contained"
                        size="medium"
                        startIcon={<CheckIcon/>}

                        onClick={onSaveAndContinue}
                    >
                        {isCreate ? "Создать и продолжить" : "Сохранить и продолжить"}
                    </Button>
                </Grid>
                <Grid item xs>
                    <Button
                        className={classes.Button}
                        variant="contained"
                        size="medium"
                        startIcon={<SaveIcon/>}
                        onClick={onSaveAndRoute}
                    >
                        {isCreate ? "Создать" : "Сохранить"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
});

const useStyles = makeStyles({
    container: {
        backgroundColor: '#ffffff',
        padding: '8px 9px',
        marginBottom: '12px',
        borderRadius: 1,
    },
    Button: {
        width: "100%",
        '&:disabled': {
            background: '#62B4FF',
            color: '#ffffff',
            '& svg': {
                fill: '#ffffff',
                opacity: .5,
            },
        }
    },
});

export default ControlCategoryEdit;