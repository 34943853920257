const getVisibleColumns = (pageName, initColumns, defaultSettings = {}) => {
    const productsVisibleColumns = localStorage.getItem(`${pageName}-visible-columns`);
    let _visibleColumns = {...initColumns};
    if (!!productsVisibleColumns) {
        _visibleColumns = {
            ..._visibleColumns,
            ...JSON.parse(productsVisibleColumns)
        }
    }

    if (Object.keys(defaultSettings).length > 0) {
        Object.keys(_visibleColumns).map((key) => {
            let keyName = _visibleColumns[key]?.key || '';

            _visibleColumns[key] = {
                ..._visibleColumns[key],
                ...defaultSettings[keyName]
            }
        })
    }

    return _visibleColumns
}
const setVisibleColumns = (pageName, columns) => {
    localStorage.setItem(`${pageName}-visible-columns`, JSON.stringify(columns));
}

export {
    getVisibleColumns,
    setVisibleColumns
}
