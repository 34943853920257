import React from "react";
import {
    Typography
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";

class RowToggleFile extends React.PureComponent {
    render() {
        const {
            value,
            classes
        } = this.props;

        return (
            <>
                Тут файл или файлы
            </>
        )
    }
}

const styles = {

}
const StylesRowToggleFile = withStyles(styles)(RowToggleFile)

export default StylesRowToggleFile
