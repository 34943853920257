import React from 'react';
import {withStyles} from "@mui/styles";
import {Box, Typography} from "@mui/material";

class Controls extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    _labelTotalVisible = () => {
        const {totalCount, perPage, count} = this.props?.pagination || {};
        const {page} = this.props?.filter || {};

        const startCount = (+page > 1) ? ((+page - 1) * +perPage + 1) : 1;
        const endCount = (+page === +count) ? totalCount : (+page === 1) ? (page * perPage) : (1 * perPage * page);

        return `Показано ${startCount}—${endCount} из ${totalCount}`
    }

    render() {
        const {
            classes
        } = this.props;
        return (
            <Box className={classes.root}>
                <Box className={classes.rootLeft}>
                    <Typography className={classes.grayText}>
                        {this._labelTotalVisible()}
                    </Typography>
                </Box>
            </Box>

        );
    }
}

const styles = {
    root: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between"
    },
    rootLeft: {
        display: "flex",
        alignItems: "center",
        "& > *": {
            marginLeft: 8,
            "&:first-child": {
                marginLeft: 0
            }
        }
    },
    rootRight: {
        display: "flex",
        alignItems: "center",
        "& > *": {
            marginLeft: 8,
            "&:first-child": {
                marginLeft: 0
            }
        }
    },

    grayText: {
        fontSize: 14,
        lineHeight: "18px",
        color: "#787878",
    },
    buttonText: {
        fontSize: 14,
        lineHeight: "18px",
        fontWeight: "400",
        color: "#0057FF",
        textTransform: "initial"
    },
    buttonWhite: {},
}

const StylesControls = withStyles(styles)(Controls);

export default StylesControls