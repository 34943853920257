import React from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Delete as DeleteIcon,
    Save as SaveIcon
} from "@mui/icons-material";

const FavoritesContent = (props) => {
    const {
        data,
        onChange
    } = props;
    const classes = useStyles();
    const [favoriteName, setFavoriteName] = React.useState("");

    // Логика работы с добавление избранного
    const handleChangeFavoriteName = ({ target }) => {
        setFavoriteName(target.value)
    }
    const handleAddFavoriteName = () => {
        let newData = [...data];

        newData.push({
            name: favoriteName,
            url: window.location.href
        })

        onChange(newData)
        setFavoriteName("")
    }

    // Удаление избранного
    const handleRemoveFavorite = (index) => {
        let newData = [...data];
        newData.splice(index, 1);
        onChange(newData)
    }

    // Открытие избранного в новой вкладке
    const handleOpenFavorite = (item) => {
        window.open(item.url);
    }

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                {(data || []).map((item, index) => (
                    <Grid key={`favorite-card-${ index }`} item sm={4}>
                        <Box className={classes.favoriteCard}>
                            <Box className={classes.favoriteCardBody} onClick={() => handleOpenFavorite(item)}>
                                { item.name }
                            </Box>
                            <Box className={classes.favoriteCardDelete} onClick={() => handleRemoveFavorite(index)}>
                                <DeleteIcon sx={{color: "#62B4FF"}}/>
                            </Box>
                        </Box>
                    </Grid>
                ))}

                <Grid item sm={4}>
                    <Box className={classes.formCard}>
                        <TextField
                            value={favoriteName}
                            placeholder="Введите название"
                            fullWidth
                            onChange={handleChangeFavoriteName}
                        />
                        <Button
                            variant="contained"
                            disabled={!favoriteName}
                            startIcon={<SaveIcon sx={{color: "white"}}/>}
                            onClick={handleAddFavoriteName}
                        >
                            Сохранить
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto"
    },

    favoriteCard: {
        display: "flex",

        width: "100%",
        height: 56,
        backgroundColor: "#0057FF",
        borderRadius: 6
    },
    favoriteCardBody: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        padding: "0 16px",

        fontSize: 18,
        lineHeight: "22px",
        fontWeight: "500",
        color: "white",

        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer"
        }
    },
    favoriteCardDelete: {
        width: 56,
        borderLeft: "1px solid #62B4FF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },

    formCard: {
        display: "flex",
        alignItems: "center",

        width: "100%",
        height: 56,
        padding: "10px 12px",
        boxSizing: "border-box",
        backgroundColor: "#F4F4F4",
        borderRadius: 6,

        "& .MuiFormControl-root": {
            flex: 1
        },
        "& button": {
            height: 36,
            marginLeft: 8,
            padding: "0 12px"
        },
    }
}))

export default FavoritesContent
