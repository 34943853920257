import React from "react";
import {
	Grid,
	Radio,
	FormControlLabel
} from "@mui/material";
import {

} from "@mui/styles";

const TabsSites = (props) => {
	const {
		value,
		avaible,
		options,
		onChange
	} = props;

	const handleChange = ({ target }) => {
		const { value } = target;
		onChange(Number(value));
	}

	return (
		<Grid container spacing={1}>
			{options.map((option) => (
				<Grid item>
					<FormControlLabel
						value={ option.id }
						label={ option.name }
						checked={ Boolean(value === option.id) }
						control={<Radio />}
						disabled={!Boolean(avaible.includes(option.id))}
						onChange={handleChange}
					/>
				</Grid>
			))}
		</Grid>
	)
}

export default TabsSites
