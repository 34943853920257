import React from "react";
import {
    Box,
    Switch, Tooltip,
    Typography,
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";

class VisibleColumns extends React.PureComponent {

    clickCard = () => {
        const {name, value, onChange} = this.props;

        onChange({
            target: {name}
        }, value)
    }

    render() {
        const {
            title,
            value,
            name,

            classes,

            onChange
        } = this.props;

        return (
            <Box
                className={classes.card}
                onClick={this.clickCard}
            >

                <Tooltip
                    PopperProps={{disablePortal: true}}
                    classes={{
                        tooltip: classes.cardTooltip
                    }}
                    title={title}
                >
                    <Typography className={classes.cardTitle}>
                        {title}
                    </Typography>
                </Tooltip>

                <Switch
                    name={name}
                    checked={!value}
                />

            </Box>
        );
    }
}

const styles = {
    card: {
        display: "flex",
        alignItems: "center",
        height: "33px",

        flex: 1,
        backgroundColor: "#F4F4F4",
        borderRadius: 6,
        padding: "12px 8px",
        boxSizing: "border-box",
        cursor: "pointer",

        "&:hover": {
            backgroundColor: "#DAEDFF",
        }
    },
    cardTitle: {
        fontSize: 13,
        lineHeight: "19px",
        color: "black",
        flex: 1,
        userSelect: "none",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },

    cardTooltip: {
        fontSize: '14px',
    }
}
const StylesVisibleColumns = withStyles(styles)(VisibleColumns)

export default StylesVisibleColumns
