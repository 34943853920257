import React, {Component} from "react";
import {
    Box,
    Typography
} from "@mui/material";
import commonInitState from "../../../common/InitState";
import {
    ControlsComponent,
    Filter as FilterComponent,
    Table as TableComponent
} from "./components";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import {filterStringify, filterToBack} from "../../../helper/filter";
import {
    ShoppingBasket as ShoppingBasketIcon,
} from "@mui/icons-material";
import {withStyles} from "@mui/styles";

const initFilter = {
    "filter[order_number][like]": "",
    "filter[date][gte]": "",
    "filter[date][lte]": "",
    "filter[extraWebsites]": [],
    user_id: "",
    delivery_method_id: "",
    payment_method_id: "",
    cost: "",
    date: "",
    status: "",

    page: 1
};
const visibleColumns = {
    id: {name: "Заказ №", omit: false, sortable: true},
    created_at: {name: "Дата покупки", omit: false, sortable: true},
    customer_name: {name: "Доставка на имя", omit: false, sortable: true},
    customer_email: {name: "Email", omit: false, sortable: true},
    customer_phone: {name: "Телефон", omit: false, sortable: true},
    city: {name: "Город", omit: false, sortable: true},
    personal_discount: {name: "Скидка", omit: false, sortable: true},
    cost: {name: "Цена", omit: false, sortable: true},
    status_label: {name: "Статус", omit: false, sortable: true},
    active: {name: "", omit: false, sortable: false, right: true},
};
const pageName = "orders";

class Orders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            favoriteFilters: [],

            filter: {...initFilter},
            filterApplied: {...initFilter},
            visibleColumns: {...visibleColumns},
            pagination: {
                count: 1
            },

            isLoading: false,
            isOpenFilter: false,
        };
    }

    componentDidMount = async () => {
        await this.initState();
        await this.getOrders();
    }

    initState = async () => {
        const {filter, visibleColumns, favoriteFilters} = commonInitState({
            name: pageName,
            initFilter: this.state.filter,
            initFavoriteFilters: this.state.favoriteFilters,
            initVisibleColumns: this.state.visibleColumns
        });

        await this.setState({
            filter,
            visibleColumns,
            favoriteFilters
        })
    }

    getOrders = async () => {
        const filter = filterToBack(this.state.filter);
        const {orders, headers} = await agent.get(`${urls.getOrders}${filter}&expand=city`).then((res) => {
            const data = res.data;
            const headers = res.headers;

            return {
                orders: data,
                headers: headers
            }
        }).catch((err) => {
            return {orders: [], header: {}}
        });
        const pagination = {
            count: headers?.['x-pagination-page-count'] || 1,
            perPage: headers?.['x-pagination-per-page'] || 20,
            totalCount: headers?.['x-pagination-total-count'] || 0,
        };

        this.setState({
            orders: orders,
            pagination: pagination,
            isLoading: false,
            filterApplied: this.state.filter
        })
    }

    changeFilter = (filter, isFastStart) => {
        this.setState({filter}, async () => {
            const urlFilter = filterStringify(filter);

            window.history.replaceState(null, null, `/orders?${urlFilter}`);

            if (isFastStart) {
                await this.getOrders();
            }
        });
    }
    clearFilter = () => {
        this.setState({filter: initFilter}, async () => {
            const urlFilter = filterStringify(initFilter);

            window.history.replaceState(null, null, `/orders?${urlFilter}`);

            await this.getOrders();
        });
    }

    _openFilter = () => {
        this.setState({isOpenFilter: !this.state.isOpenFilter});
    }
    _changeFavorite = (favoriteFilters) => {
        localStorage.setItem(`${pageName}-favorite-filters`, JSON.stringify(favoriteFilters));
        this.setState({favoriteFilters});
    }
    _changeColumns = () => {
    }

    render() {
        const {
            classes
        } = this.props;
        const {
            orders,

            filter,
            pagination,
            filterApplied,
            visibleColumns,
            favoriteFilters,

            isOpenFilter
        } = this.state;

        return (
            <>

                <Box className={classes.head}>
                    <Box className={classes.headLogo}>
                        <ShoppingBasketIcon sx={{color: "#0057FF", fontSize: 42}}/>
                        <Typography variant="h1">Управление заказами</Typography>
                    </Box>
                </Box>

                <Box mb={2}>
                    <FilterComponent
                        filter={filter}

                        onChange={this.changeFilter}
                        onClear={this.clearFilter}
                        onSearch={this.getOrders}
                    />
                </Box>

                <Box>
                    <ControlsComponent
                        filter={filter}
                        pagination={pagination}
                    />
                </Box>

                <TableComponent
                    data={orders}
                    filter={filter}
                    pagination={pagination}
                    visibleColumns={visibleColumns}

                    onChangeFilter={this.changeFilter}
                />

            </>
        );
    }
}

const styles = {
    head: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16
    }, headLogo: {
        display: "flex", alignItems: "center",

        "& h1": {
            marginLeft: 12
        }
    },
}

const OrdersStyles = withStyles(styles)(Orders);

export default OrdersStyles
