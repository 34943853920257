import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST_API,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
});
axiosInstance.interceptors.response.use((response) => response, (error) => {
    const errorResponse = error?.response;
    const errorData = errorResponse?.data;

    let errorMessage = '';
    if (Array.isArray(errorData)) {
        errorMessage = errorData.map((t) => t?.message || t).join(';</br>')
    }

    console.log('errorResponse ', errorResponse);
    console.log('errorMessage ', errorMessage);

    error.response.data.errorMessage = errorMessage;
    throw error;
});

export default axiosInstance
