import React from "react";
import {
	TextField
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	CKEditor
} from "../../../../../../components"

class TextFieldCustom extends React.PureComponent {
	render () {
		const {
			classes,

			...other
		} = this.props;

		return (
			<CKEditor
				{...other}
			/>
		)
	}
}

const styles = {
	root: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		}
	}
}
TextFieldCustom = withStyles(styles)(TextFieldCustom)

export default TextFieldCustom