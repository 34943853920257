import React from "react";
import {
	Box,
	Grid,
	Tooltip,
	TextField,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Help as HelpIcon
} from "@mui/icons-material";
import {Formik} from "formik";
import * as Yup from "yup";

const SeoForm = (props) => {
	const {
		innerRef,
		initialValues,
	} = props;
	const classes = useStyles();

	const handleSubmit = () => {
	}

	const handleChange = ({target}) => {
		const {name, value} = target;

		let newForm = {...innerRef?.current?.values || {}};
		newForm[name] = value;

		innerRef.current?.setValues(newForm);
	}
	const handleChangeBoolean = ({target}, value) => {
		const {name} = target;

		let newForm = {...innerRef.current.values};
		newForm[name] = value;

		innerRef.current.setValues(newForm);
	}
	const handleChangeAutocomplete = (value, name) => {
		let newForm = {...innerRef.current.values};
		newForm[name] = value?.value || null;

		innerRef.current.setValues(newForm);
	}

	return (
		<Formik
			innerRef={innerRef}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					values,
					errors,
					touched
				} = props;

				return (
					<Box px={2} py={2} bgcolor="white" borderRadius={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={values.slug}
									error={Boolean(touched.slug && errors.slug)}
									helperText={touched.slug && errors.slug}
									name="slug"
									className={classes.textField}
									label={<React.Fragment>
										<Box display="flex" alignItems="center" gap="2px">
											Ссылка
											<Tooltip title="Ссылка формируется автоматически из названия товара. При создании нового товара - оставляйте это поле пустым. При копировании товара - очищайте поле." arrow>
												<HelpIcon sx={{color: "#3855EC", fontSize: 16}}/>
											</Tooltip>
										</Box>
									</React.Fragment>}
									fullWidth
									size='small'
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={values.metaTitle}
									error={Boolean(touched.metaTitle && errors.metaTitle)}
									helperText={touched.metaTitle && errors.metaTitle}
									name="metaTitle"
									className={classes.textField}
									label={<React.Fragment>
										<Box display="flex" alignItems="center" gap="2px">
											SEO Title
											<Tooltip title="Поле заполняется автоматическим SEO-шаблоном из SitisCMS. При необходимости ручного значения впишите его сюда" arrow>
												<HelpIcon sx={{color: "#3855EC", fontSize: 16}}/>
											</Tooltip>
										</Box>
									</React.Fragment>}
									fullWidth
									size='small'

									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={values.metaKeywords}
									error={Boolean(touched.metaKeywords && errors.metaKeywords)}
									helperText={touched.metaKeywords && errors.metaKeywords}
									name="metaKeywords"
									className={classes.textField}
									label={<React.Fragment>
										<Box display="flex" alignItems="center" gap="2px">
											SEO Keywords
											<Tooltip title="Поле заполняется автоматическим SEO-шаблоном из SitisCMS. При необходимости ручного значения впишите его сюда" arrow>
												<HelpIcon sx={{color: "#3855EC", fontSize: 16}}/>
											</Tooltip>
										</Box>
									</React.Fragment>}
									fullWidth
									size='small'

									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={values.metaDescription}
									error={Boolean(touched.metaDescription && errors.metaDescription)}
									helperText={touched.metaDescription && errors.metaDescription}
									name="metaDescription"
									className={classes.textField}
									label={<React.Fragment>
										<Box display="flex" alignItems="center" gap="2px">
											SEO Description
											<Tooltip title="Поле заполняется автоматическим SEO-шаблоном из SitisCMS. При необходимости ручного значения впишите его сюда" arrow>
												<HelpIcon sx={{color: "#3855EC", fontSize: 16}}/>
											</Tooltip>
										</Box>
									</React.Fragment>}
									fullWidth
									size='small'

									onChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Box>
				)
			}}
		</Formik>
	)
}

const validationSchema = Yup.object().shape({
	slug: Yup.string().required('Заполните поле')
});
const useStyles = makeStyles(() => ({
	textField: {
		"& .MuiInputLabel-root": {
			pointerEvents: "initial"
		}
	}
}));

export default React.memo(SeoForm)
