import React, {Component} from 'react';
import {
    Table,
    Box,
    Checkbox,
    Grid,
    IconButton, Pagination,
    Skeleton,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip, TableContainer
} from "@mui/material";
import moment from "moment";
import {MoreVert as MoreIcon} from "@mui/icons-material";
import {getImageId} from "../../../../../common/FilesUpload";
import {TableRowToggleImage} from "../../../../../components";
import {makeStyles} from "@mui/styles";

const TableComponent = (props) => {
    const {
        promotions,

        filter,
        pagination,

        isLoading,

        onChangeFilter,
        onChangePromotionStatus,
    } = props;
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newFilter = {...filter};
        newFilter.page = page;

        onChangeFilter(newFilter, true);
    }

    const handleChangeChecked = (row, e) => {
        e.stopPropagation();
        const checked = e.target.checked ? 1 : 0;
        onChangePromotionStatus(row, checked);
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell width="50px">Изображение</TableCell>
                            <TableCell>Начало</TableCell>
                            <TableCell>Окончание</TableCell>
                            <TableCell>Заголовок</TableCell>
                            <TableCell>URL-адрес</TableCell>
                            <TableCell>Подборка товаров</TableCell>
                            <TableCell>Скидка</TableCell>
                            <TableCell>Бонусы</TableCell>
                            <TableCell>Минимальная сумма заказа</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell>Ссылка</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Boolean(isLoading) ? (
                            <>
                                {[0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    )
                                )}
                            </>
                        ) : (
                            <>
                                {

                                    promotions.map((row, index) => (
                                            <TableRow
                                              hover
                                                key={`row-item-${index}`}
                                            >
                                                <TableCell>{row.id || ''}</TableCell>
                                                <TableCell>
                                                    <Box className={classes.imageContainer}>
                                                        <TableRowToggleImage value={row.image_id}/>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{moment(row.started_at * 1000).format('DD.MM.YYYY HH:mm')}</TableCell>
                                                <TableCell>{moment(row.ended_at * 1000).format('DD.MM.YYYY HH:mm')}</TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell>{row.slug}</TableCell>
                                                <TableCell>{row.product_compilation_id}</TableCell>
                                                <TableCell>{row.extraDiscount || '-'}</TableCell>
                                                <TableCell>{row.extroBonus || '-'}</TableCell>
                                                <TableCell>{row.min_total}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.status}

                                                        onClick={(e) => handleChangeChecked(row, e)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {row.extraUrl
                                                        ? <span dangerouslySetInnerHTML={{__html: row.extraUrl}}/>
                                                        : ''
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={2}>
                <Pagination
                    page={Number(filter.page)}
                    count={pagination.count || 1}

                    onChange={handleChangePage}
                />
            </Box>
        </>
    );
};

const useStyles = makeStyles({
    imageContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
})

export default TableComponent;
