import React from "react";
import {
    Box,
    Grid,
    Tooltip,
    Typography,

    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {numberFormat} from "../../../../../common/Formater";
import agent from "../../../../../agent/agent";
import {convertorNumber} from "../../../../../helper/convertor";
import BigNumber from "bignumber.js";

const OrderItems = (props) => {
    const {
        data
    } = props;
    const classes = useStyles();

    return (
        <Box borderRadius={2} overflow="hidden" boxShadow={1} bgcolor="white">
            <Box px={1} py={1} bgcolor="#3855ec">
                <Typography variant="h4" color="white">Заказанные позиции</Typography>
            </Box>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Изображение</TableCell>
                        <TableCell>Товар</TableCell>
                        <TableCell>Выбранные опции</TableCell>
                        <TableCell>Артикул произв.</TableCell>
                        <TableCell>Цена</TableCell>
                        <TableCell>Доп скидка %</TableCell>
                        <TableCell>Кол-во</TableCell>
                        <TableCell>Итог строки</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((row, idx) => (
                            <RowProduct
                                key={`table-product-${idx}`}
                                row={row}
                                classes={classes}
                            />
                        ))
                    }
                </TableBody>
            </Table>
        </Box>
    )
}
const RowProduct = React.memo((props) => {
    const {
        row,
        classes
    } = props;
    const [extraProduct, setExtraProduct] = React.useState({})
    const [productPrice, setProductPrice] = React.useState(() => {
        const price = row?.price;
        const priceOptions = (row?.options || []).reduce((value, option) => {
              const variantPrice = +option?.variants?.[0]?.price || 0;
              return value + variantPrice
          }, 0);

        return new BigNumber(price).plus(priceOptions).toNumber();
    });
    React.useEffect(() => {
        (async () => {
            await getExtraProduct();
        })();
    }, [])

    // Получение товара с расширенными параметрами
    const getExtraProduct = async () => {
        const slug = `/api/admin/products/${row.product_id}?expand=extraOptions`;
        const response = await agent.get(slug).then((res) => {
            return res.data
        }).catch(() => {
            return {}
        })

        setExtraProduct(response)
    }


    // Всепомогательные фунции по отрисовки
    const _renderSelectedOptions = () => {
        const options = row.options;
        if (options) {
            if (Object.keys(options).length <= 0) {
                return null
            }

            let grids = [];
            Object.keys(options).map((key) => {
                const option = options[key];
                const optionItem = (option.variants || [])?.[0] || {};
                const variantGroupName = (option?.variantGroups || []).find((t) => Boolean(String(t.id) === String(optionItem?.variant_group_id)))?.name || '';

                grids.push({
                    optionLabel: option.name,
                    variantGroupName: variantGroupName,
                    optionSku: option?.variants?.[0]?.sku || "",
                    ...optionItem,
                })
            });

            return (
                <Grid container rowSpacing="3px">
                    {grids.map((grid, index) => (
                        <Grid key={`product-${row.product_id}-${index}`} item xs={12}>
                            <Typography>
                                <span>{grid.optionLabel}: </span>
                                <span
                                  style={{fontWeight: "500"}}
                                  dangerouslySetInnerHTML={{
                                      __html: `${[grid.variantGroupName, grid.name].filter((t) => !!t).join(' ')}<br/>(SKU: ${grid.optionSku})`
                                  }}
                                />
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            );
        }
    }

    return (
        <TableRow hover>
            <TableCell align="center">
                <Tooltip
                    title={(<React.Fragment>
                        <img
                            className={classes.productTooltipImageId}
                            src={`${process.env.REACT_APP_HOST_API}api/storage/image/${row.product.image_id}_w-400.webp`}
                        />
                    </React.Fragment>)}
                    arrow
                >
                    <img
                        className={classes.productImageId}
                        src={`${process.env.REACT_APP_HOST_API}api/storage/image/${row.product.image_id}_w-100.webp`}
                    />
                </Tooltip>
            </TableCell>
            <TableCell>{row.product_name}</TableCell>
            <TableCell>
                {_renderSelectedOptions()}
            </TableCell>
            <TableCell>{extraProduct.sku}</TableCell>
            <TableCell>
				<span style={{whiteSpace: "nowrap"}}>
					{numberFormat(productPrice, 2)} руб.
				</span>
            </TableCell>
            <TableCell>0%</TableCell>
            <TableCell>{row.quantity}</TableCell>
            <TableCell>
				<span style={{whiteSpace: "nowrap"}}>
					{numberFormat(row.cost, 2)} руб.
				</span>
            </TableCell>
        </TableRow>
    )
})

const useStyles = makeStyles(() => ({
    table: {
        borderSpacing: 0,
        borderTop: "1px solid #62B4FF",

        "& .MuiTableHead-root": {
            backgroundColor: "#3855ec",
        },
        "& .MuiTableHead-root .MuiTableCell-root": {
            padding: "4px 8px",
            fontSize: 14,
            lineHeight: "16px",
            fontWeight: "500",
            borderRadius: "0!important"
        },
    },

    productImageId: {
        width: 32,
        height: 32,
        objectFit: "contain",
        borderRadius: 4
    },
    productTooltipImageId: {
        maxWidth: 300,
        maxHeight: 300
    }
}));

export default OrderItems
