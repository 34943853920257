import {combineReducers} from 'redux';

import global from '../states/global';
import products from '../states/products';
import notifications from '../states/notifications';

const rootReducer = combineReducers({
  global,
  products,
  notifications
});

export default rootReducer
