import React from "react";
import {
	Box,
	Grid,
	TextField,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import ProductDot from "./ProductDot";
import ProductCard from "./ProductCard";

const ImageSection = (props) => {
	const {
		config,
		imageId,
		products,
		activeView,
		annotations,
		isLoadProducts,

		onCreate,
		onRemove,
		onChange,
	} = props;
	const imageUrl = `${process.env.REACT_APP_HOST_API}api/storage/image/${imageId}_border-default_fit-default_w-${ config.width }_h-${ config.height }.webp`;
	const classes = useStyles();

	return (
		<Box display="flex" flexDirection="column" alignItems="flex-start">

			<SectionInformation/>

			<Box mt={2}/>

			<Box
				className={classes.rootSection}
				id="image-annotations-image-container"
				sx={{
					width: config.width,
					height: config.height,
				}}
			>
				<img src={imageUrl} className={classes.mainImage}/>

				{(annotations || []).map((_item, index) => (
					<ProductDot
						key={`ProductDot-${ _item.product_id }-${ activeView }`}
						item={_item}
						config={config}
						product={products.find((t) => t.related_product_id === _item.product_id)}

						onRemove={onRemove.bind(this, index)}
						onChange={onChange.bind(this, index)}
					/>
				))}
			</Box>

			<Box mt={4}/>

			<SectionProducts
				products={products}
				isLoad={isLoadProducts}

				onCreate={onCreate}
			/>

		</Box>
	)
};
const SectionInformation = () => {
	return (
		<Grid container spacing={0.1}>
			<Grid item xs={12}>
				<Typography variant="caption">Удалить: клик правой кнопкой мышки по точке</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="caption">Перенести: зажать левую кнопку мышки и перетащить точку</Typography>
			</Grid>
		</Grid>
	)
};
const SectionProducts = (props) => {
	const {
		products,
		isLoad,
		onCreate
	} = props;

	const [search, setSearch] = React.useState('');
	const handleChangeSearch = ({ target }) => {
		setSearch(target.value);
	};

	const _products = () => {
		if (!search.length) {
			return products
		}
		const _search = search.toLowerCase();
		return [...products].filter((t) => {
			const name = (t?.relatedProduct?.name || '').toLowerCase();
			return Boolean(name.includes(_search))
		});
	}

	return (
		<>
			<Box mb={1} width="420px">
				<TextField
					value={search}
					fullWidth
					label="Поиск"
					placeholder="Введите поисковый запрос"
					onChange={handleChangeSearch}
				/>
			</Box>
			<Grid container spacing={1}>
				{_products().map((product) => (
					<Grid key={`products-${product.id}`} item xs={3}>
						<ProductCard
							item={product}
							onCreate={onCreate}
						/>
					</Grid>
				))}
			</Grid>

			{Boolean(isLoad) && (
				<Typography mt={2} variant="caption" sx={{ fontSize: 20 }}>
					Загружаем список товаров...
				</Typography>
			)}
		</>
	)
};

const useStyles = makeStyles(() => ({
	rootSection: {
		// maxWidth: 1024,
		borderRadius: "10px",
		position: "relative",
		// overflow: "hidden"
	},
	mainImage: {
		width: "100%",
		height: "100%",
		objectFit: "cover",

		maxWidth: "100%",
		pointerEvents: "none"
	}
}));

export default ImageSection
