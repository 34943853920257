import React, {Component} from 'react';
import {ControlComponent, HeaderComponent, MainFormComponent} from "./components";
import {Box} from "@mui/material";
import agent from "../../../agent/agent";
import {Notification, NotificationTypes} from "../../../common/Notification";

class CharacteristicCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            characteristicId: props.match.params.id,
        };

        this.refMainForm = React.createRef();
    }

    createCharacteristicVariant = async () => {
        await this.refMainForm.current.handleSubmit();
        const errors = this.refMainForm.current.errors;
        if (Object.keys(errors).length === 0) {
            const form = this.refMainForm.current.values;
            const {characteristicId} = this.state;
            const body = {
                characteristic_id: Number(characteristicId),
                value: form.value || null,
                slug: form.slug || null,
                image_id: form.image_id,
                additional_description: form.additional_description || null,
                sort: form.sort || null
            }
            const isValidData = this.isValidData(body);

            if (isValidData) {
                const response = await agent.post(`/admin/api-shop-characteristic-variants`, body)
                    .then((res) => {
                        Notification({
                            message: "Вариант успешно создан",
                            type: NotificationTypes.success
                        });
                        this._routeCharacteristicVariantsPage();
                    })
                    .catch((err) => {
                        Notification({
                            message: err.response.data[0].message,
                            type: NotificationTypes.error
                        });
                    })
            }
        }
    };

    _routeCharacteristicVariantsPage = () => {
        const id = this.state.characteristicId;
        this.props.history.push(`/characteristics/characteristic_variants/${id}`);
    }

    isValidData = (body) => {
        const keys = Object.keys(body);
        let isValid = true;

        keys.forEach(key => {
            if (body[key] === null) {
                isValid = false;
            }
        })

        return isValid;
    }

    render() {
        const {
            characteristicId
        } = this.state
        return (
            <>
                <HeaderComponent
                    onRouteBack={this._routeCharacteristicVariantsPage}
                />

                <Box>
                    <ControlComponent
                        onSave={this.createCharacteristicVariant}
                    />

                    <Box px={2} py={4} bgcolor="white" borderRadius={2}>
                        <MainFormComponent
                            refFormik={this.refMainForm}
                            createCharacteristicVariant={this.createCharacteristicVariant}
                        />
                    </Box>
                </Box>
            </>
        );
    }
}

export default CharacteristicCreate;