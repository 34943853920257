import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/index.css";
import 'react-notifications-component/dist/theme.css'

import App from './Root';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
