import agent from "../agent/agent";
import queryString from "query-string";

const GET_PAGE_PRODUCTS_SETTINGS = "products/GET_PAGE_PRODUCTS_SETTINGS";
const SET_ACTIVE_PRESET = "products/SET_ACTIVE_PRESET";
const SHOP_CHARACTERISTIC_GROUPS = "products/SHOP_CHARACTERISTIC_GROUPS";

const initialState = {
	commonColumns: [],
	presets: [],
	shopCharacteristicGroups: [],

	preset: {},
}

export function getPageProductsSettings () {
	return async function (dispatch) {
		const response = await agent.get(`/api/admin/products/grid-presets`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		})

		let preset = {}
		if (!!window.location.search) {
			const parseFilterSearch = queryString.parse(window.location.search);
			if (parseFilterSearch?.preset) {
				preset = (response.presets || []).find((t) => t.slug === parseFilterSearch?.preset) || {}
			}
		}

		const presets = (response.presets || []).reduce((acc, item) => {
			if (acc.find((t) => t.slug === item.slug)) {
				return acc
			}
			return [...acc, item]
		}, []);

		await dispatch({
			type: GET_PAGE_PRODUCTS_SETTINGS,
			commonColumns: response.common_columns || [],
			presets: presets,
			preset: preset,
		})
	}
}
export function getShopCharacteristicGroups () {
	return async function (dispatch) {
		const response = await agent.get(`/admin/api-shop-characteristic-groups/list?ngrestCallType=list&expand=productsCount%2CcharacteristicsCount&fields=id%2Cid%2Cname%2Cslug%2Csort%2Cis_for_product_set%2CproductsCount%2CcharacteristicsCount&sort=-sort&page=1`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		dispatch({
			type: SHOP_CHARACTERISTIC_GROUPS,
			shopCharacteristicGroups: response
		});
	}
}
export function setActivePreset (preset) {
	return {
		type: SET_ACTIVE_PRESET,
		preset: preset
	}
}

export default function ProductsState(state = initialState, action = {}) {
	switch (action.type) {
		case GET_PAGE_PRODUCTS_SETTINGS: {
			let commonColumns = action.commonColumns
			let presets = action.presets
			let preset = action.preset

			return {
				...state,
				commonColumns,
				presets,
				preset
			}
		}
		case SET_ACTIVE_PRESET: {
			let preset = action.preset

			return {
				...state,
				preset
			}
		}
		case SHOP_CHARACTERISTIC_GROUPS: {
			let shopCharacteristicGroups = action.shopCharacteristicGroups

			return {
				...state,
				shopCharacteristicGroups
			}
		}
		default:
			return state;
	}
}
