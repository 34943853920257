// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import CategoryProducts from './CategoryProducts';

export default compose(
    connect(
        state => ({}),
        dispatch => ({}),
    ),
)(CategoryProducts);
