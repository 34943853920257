import React, {Component} from 'react';
import {filterParse, filterStringify} from "../../../helper/filter";
import agent from "../../../agent/agent";
import {Box, Button, Typography} from "@mui/material";
import {AddBoxOutlined as AddBoxOutlinedIcon, ListAltOutlined as ListIcon} from "@mui/icons-material";
import {withStyles} from "@mui/styles";
import Controls from "./components/Controls";
import {TableComponent} from "./components";

const initFilter = {
    page: 1,
}

class CategoryProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],

            categoryId: props.match.params.id,

            filter: {...initFilter},
            pagination: {},

            isLoading: true,
        };
    }

    componentDidMount = async () => {
        await this.setFilterPage();
        await this.getCategoryProducts();
    }


    //Установка страницы при обновлении страницы
    setFilterPage = () => {
        const filter = this._getFilterPage();

        this.setState({filter});
    }

    getCategoryProducts = async () => {
        const {filter, categoryId} = this.state;
        const url = `/admin/api-shop-category-assignment/list?ngrestCallType=list&fields=product_id,category_id,sort&page=${filter.page}&id=${categoryId}&sort=${filter.sort || "sort"}`

        let {data, headers} = await agent.get(url)
            .catch((err) => {
                return {
                    data: [],
                    headers: {},
                }
            })

        data = await Promise.all(data.map(async (product) => {
            const productName = await agent.get(`/shopadmin/taggable?api=api-shop-products&labelField=name&valueField=id&ids=${product.product_id}`)
                .then(res => res.data[0])
                .catch(err => '');
            const categoryName = await agent.get(`/shopadmin/taggable?api=api-shop-categories&labelField=full_name&valueField=id&ids=${product.category_id}`)
                .then(res => res.data[0])
                .catch(err => '');
            const mainPhoto = await agent.get(`/shopadmin/taggable?api=api-shop-products&labelField=main_photo_id&valueField=id&ids=${product.product_id}`)
                .then(res => res.data[0])
                .catch(err => '');

            return {
                ...product,
                category_name: categoryName,
                product_name: productName,
                main_photo_id: mainPhoto,
            }
        }))

        const pagination = {
            count: headers?.['x-pagination-page-count'] || 1,
            perPage: headers?.['x-pagination-per-page'] || 20,
            totalCount: headers?.['x-pagination-total-count'] || 0,
        }

        this.setState({
            products: data,
            pagination,
            isLoading: false,
        });
    }

    //Получение номера страницы при обновлении страницы
    _getFilterPage = () => {
        const search = window.location?.search || '';

        let filter = {};

        filter = {
            ...filter,
            ...initFilter,
            ...filterParse(search, initFilter, {})
        }

        return filter
    }

    // Работы с фильтром страницы
    _changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        const urlFilter = filterStringify(this.state.filter);
        window.history.replaceState(null, null, `/categories/category_products/${this.state.categoryId}?${urlFilter}`);

        if (!isFastStart) {
            return null
        }

        this.setState({
            products: [],
            isLoading: true,
        })
        await this.getCategoryProducts();
    }

    render() {
        const {classes} = this.props;
        const {
            products,
            filter,
            pagination,
            isLoading,
        } = this.state;

        return (
            <>
                <Box className={classes.head}>
                    <Box className={classes.headLogo}>
                        <ListIcon sx={{color: "#0057FF", fontSize: 42}}/>
                        <Typography variant="h1">Товары категории</Typography>
                    </Box>
                </Box>
                <Box>
                    <Controls filter={filter} pagination={pagination}/>
                </Box>
                <Box>
                    <TableComponent
                        products={products}
                        filter={filter}
                        pagination={pagination}
                        isLoading={isLoading}
                        onChangeFilter={this._changeFilter}
                    />
                </Box>
            </>
        );
    }
}


const styles = {
    head: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16
    }, headLogo: {
        display: "flex", alignItems: "center",

        "& h1": {
            marginLeft: 12
        }
    },
}

const CategoryProductsStyles = withStyles(styles)(CategoryProducts);


export default CategoryProductsStyles