// @flow
import {compose} from 'recompose';
import {connect} from 'react-redux';

import RegionalPrices from './RegionalPrices';

export default compose(
    connect(
        state => ({}),
        dispatch => ({}),
    ),
)(RegionalPrices);