const getPopularFilter = (name) => {
    const popularFilterStore = localStorage.getItem(`${name}-popular-filter`);
    let popularFilter = [];
    if (popularFilterStore) {
        popularFilter = JSON.parse(popularFilterStore);
    }

    return popularFilter
}
const setPopularFilter = (name, list) => {
    localStorage.setItem(`${name}-popular-filter`, JSON.stringify(list));
}

export {
    getPopularFilter,
    setPopularFilter
}
