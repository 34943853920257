import React, {Component} from "react";
import {
    Box,
    Grid,
    Button,
    Typography,

    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    MainForm as MainFormComponent,
    SpecificationsForm as SpecificationsFormComponent
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../common/Notification";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";

class ProductMultiEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attributes: [],
            brands: [],
            categories: [],
            extraCategories: [],
            sberbankTaxType: [],

            sessionInfo: {},

            sessionId: props?.match?.params?.sessionId || null,

            isShowBackdrop: false
        };

        this.refMainFormComponent = React.createRef();
        this.refSpecificationsFormComponent = React.createRef();
    }

    componentDidMount = async () => {
        await this.getAttributes();
        await this.getSessionInfo();
        await this.getServices();
    }

    // Инициализация страницы
    getAttributes = async () => {
        const data = await agent.get(urls.getProductsColumns).then((res) => {
            return res.data
        }).catch(() => {
            return []
        });

        this.setState({attributes: data})
    }
    getSessionInfo = async () => {
        let sessionInfo = sessionStorage.getItem(`product-multi-change-${this.state.sessionId}`);

        if (!sessionInfo) {
            return null
        }

        sessionInfo = JSON.parse(sessionInfo);

        this.setState({sessionInfo});
    }
    getServices = async () => {
        const data = await agent.get(urls.getServices).then((res) => {
            return res.data.service
        }).catch((err) => {
            return {}
        });

        const brands = data?.brand_id?.selectdata || [];
        const categories = data?.category_id?.selectdata || [];
        const extraCategories = data?.extraCategories?.relationdata?.items || [];
        const sberbankTaxType = data?.sberbank_tax_type?.selectdata || [];

        this.setState({
            brands,
            categories,
            extraCategories,
            sberbankTaxType
        })
    }


    // Логика работы страницы
    applyChanges = async () => {
        this.setState({ isShowBackdrop: true });

        const body = await this._getBody();

        const isSuccess = await agent.put(`${ urls.editMultiProduct }`, body).then((res) => {

            Notification({
                type: NotificationTypes.success,
                message: res.data?.success
            })

            return true
        }).catch((err) => {
            return false
        })

        this.setState({ isShowBackdrop: false });
    }


    // Помощьники по странице
    _getBody = () => {
        const mainForm = this.refMainFormComponent?.current?.values || {};
        const specificationsForm = this.refSpecificationsFormComponent?.current?.values || {};
        const allForms = {...mainForm, ...specificationsForm};

        const filteredForm = Object.keys({...allForms})
            .map((key) => {
                const item = allForms[key];

                if (!item.apply) {
                    return null
                }

                return {...item, key: key}
            }).filter((t) => !!t);

        const data = {};
        filteredForm.map((item) => {
            const valueIsArray = Boolean(typeof item.value === 'object');

            if (valueIsArray) {
                if (Array.isArray(item.value)) {
                    data[item.key] = (item.value || []).map((item) => item.id);
                } else {
                    data[item.key] = item.value.value;
                }
            } else {
                data[item.key] = item.value;
            }
        });

        const body = {
            filter: this._getBodyFilter(),
            data
        };

        return body
    }
    _getBodyFilter = () => {
        const sessionInfo = {...this.state.sessionInfo || {}};

        let sessionFilter = sessionInfo.filter || {};
        delete sessionFilter.fields;
        delete sessionFilter.page;

        let filter = {};

        // Если выбраны только товары
        if (Boolean(!sessionInfo?.selected?.isAll && (sessionInfo?.selected?.ids || []).length > 0)) {
            filter.id = sessionInfo?.selected?.ids || [];
        }

        // Если выбраны все за исключением товаров
        if (Boolean(sessionInfo?.selected?.isAll)) {
            filter = {...filter, ...sessionFilter};

            if ((sessionInfo?.selected?.ids || []).length > 0) {
                filter = {
                    ...filter,
                    not: {
                        id: sessionInfo?.selected?.ids || []
                    }
                };
            }
            if (!!sessionFilter.name) {
                filter = {
                    ...filter,
                    name: {
                        like: sessionFilter.name
                    }
                };
            }
        }

        return filter
    }


    // Рутер
    _routeGoBack = () => {
        sessionStorage.removeItem(`product-multi-change-${ this.state.sessionId }`);
        this.props.history.goBack();
    }

    render() {
        const {
            attributes,
            categories,
            brands,

            isShowBackdrop
        } = this.state;

        return (
            <>
                <Box mb={2}>
                    <Typography variant="h1">Массовое изменение товаров</Typography>
                </Box>

                <Box mb={2}>
                    <MainFormComponent
                        innerRef={this.refMainFormComponent}

                        categories={categories}
                        brands={brands}
                    />
                </Box>

                <Box mb={2}>
                    {Boolean(attributes.length > 0) && (
                        <SpecificationsFormComponent
                            innerRef={this.refSpecificationsFormComponent}
                            attributes={attributes}
                        />
                    )}
                </Box>

                <Box mt="84px"/>

                <Box position="fixed" px={2} py={1} bgcolor="white" borderRadius={2} width="calc(100% - 80px)" zIndex={10} bottom="16px" className="box-shadow">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button variant="outlined" fullWidth onClick={this._routeGoBack}>Отмена</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth onClick={this.applyChanges}>Применить</Button>
                        </Grid>
                    </Grid>
                </Box>


                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
}

export default ProductMultiEdit
