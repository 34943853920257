import agent from "../agent/agent";

const getIdCharacteristicTextarea = async (
	{
		characteristic,
		text
	}
) => {
	const uri = `/admin/api-shop-characteristic-variants`;
	const body = {
		characteristic_id: characteristic.id,
		value: text
	};

	const responsePost = await agent.post(uri, body).then((res) => {
		return res.data
	}).catch((err) => {
		return { error: err.response }
	})
	if (!responsePost.error) {
		return responsePost.id
	}

	const responseGet = await agent.get(`/admin/api-shop-characteristics/${ characteristic.id }?expand=variants`).then((res) => {
		return res.data?.variants || []
	}).catch((err) => {
		return []
	});
	const searchValue = responseGet.find((t) => t.value === text)?.id;

	return searchValue || undefined
}

export {
	getIdCharacteristicTextarea
}