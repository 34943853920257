import React from "react";
import {
    Box,
    Grid,
    Typography,
    TextField,
    Switch,
    Divider,
    Button,

    FormControl,
    Select,
    MenuItem
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";
import {
    RemoveCircleOutline as RemoveCircleOutlineIcon,
    SaveOutlined as SaveOutlinedIcon,
    ArrowDropDown as DownIcon,
} from "@mui/icons-material";
import {
    Notification,
    NotificationTypes
} from "../../common/Notification";
import clsx from "clsx";

const initForm = {
    status: "true"
}

class ChangeAttributes extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            form: {...initForm}
        }
    }

    onChangeFormCommon = ({target}) => {
        const {name, value} = target;

        let form = {...this.state.form}
        form[name] = value;

        this.setState({
            form
        })
    }
    onChangeFormBoolean = ({target}, value) => {
    }


    resetForm = () => {
        this.setState({
            form: {...initForm}
        })
    }

    submit = () => {
        this.props.onSubmit(this.state.form)
    }

    render() {
        const {
            classes
        } = this.props;
        const {
            form
        } = this.state;

        return (
            <Box>

                <Box className={classes.graySection}>
                    <Typography className={classes.graySectionLabel}>Статус</Typography>

                    <FormControl fullWidth>
                        <Select
                            value={form.status}
                            name="status"
                            onChange={this.onChangeFormCommon}

                            IconComponent={props => <DownIcon {...props} sx={{fill: "#0057FF"}}/>}
                        >
                            <MenuItem value="true">Включено</MenuItem>
                            <MenuItem value="false">Выключено</MenuItem>
                        </Select>
                    </FormControl>
                </Box>


                <Box mt="12px"/>
                <Divider/>
                <Box mt="12px"/>
                <Grid container spacing>
                    <Grid xs={6} item>
                        <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<RemoveCircleOutlineIcon/>}
                            onClick={this.resetForm}
                        >
                            Отмена
                        </Button>
                    </Grid>
                    <Grid xs={6} item>
                        <Button
                            variant="contained"
                            fullWidth
                            startIcon={<SaveOutlinedIcon sx={{color: "#62B4FF"}}/>}
                            onClick={this.submit}
                        >
                            Сохранить
                        </Button>
                    </Grid>
                </Grid>

            </Box>
        );
    }
}

const styles = {
    graySection: {
        display: "flex",
        alignItems: "center",

        backgroundColor: "#F4F4F4",
        borderRadius: 6,
        padding: 24,

        "& $graySectionLabel": {
            width: 300
        },
        "& .MuiFormControl-root": {
            flex: 1
        },
        "& .MuiFormControl-root .MuiOutlinedInput-root": {
            fontWeight: "700"
        },
    },
    graySectionLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "#000000"
    }
}
const StylesChangeAttributes = withStyles(styles)(ChangeAttributes)

export default StylesChangeAttributes
