import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    IconButton,

    Autocomplete
} from "@mui/material";
import {
    Delete as DeleteIcon
} from "@mui/icons-material";
import {
    Formik
} from "formik";
import * as Yup from "yup";
import agent from "../../../../../agent/agent";

const StoresForm = (props) => {
    const {
        innerRef,
        initialValues
    } = props;
    const [stores, setStores] = useState([]);

    useEffect(() => {
        ( async () => {
            await getStores();
        })();
    }, []);

    const getStores = async () => {
        const data = await agent.get('api/stores').then((res) => {
            return res.data.map((item) => {
                return {
                    label: item.title,
                    value: item.id
                }
            })
        }).catch((err) => {
            return []
        });

        setStores(data);
    }

    const handleSubmit = () => {}
    const handleChangeAutocomplete = (value, index) => {
        let newForm = {...innerRef.current.values};
        let extraStores = [...newForm.extraStores];

        extraStores[index]['store_id'] = value.value;

        innerRef.current.setValues(newForm);
    }
    const handleChangeQuantity = (value, index) => {
        let newForm = {...innerRef.current.values};
        let extraStores = [...newForm.extraStores];

        extraStores[index]['quantity'] = value;

        innerRef.current.setValues(newForm);
    }
    const handleAddExtraStores = () => {
        let newForm = {...innerRef.current.values};
        let extraStores = [...newForm.extraStores];

        extraStores.push({
            store_id: "",
            quantity: ""
        });

        newForm.extraStores = extraStores;
        innerRef.current.setValues(newForm);
    }
    const handleRemoveExtraStores = (index) => {
        let newForm = {...innerRef.current.values};
        let extraStores = [...newForm.extraStores];

        extraStores.splice(index, 1);

        newForm.extraStores = extraStores;
        innerRef.current.setValues(newForm);
    }

    const _labelAutocomplete = (storeId) => {
        if (!storeId) {
            return ''
        }

        return stores.find((t) => t.value === storeId)?.label
    }

    return (
        <Formik
            innerRef={innerRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => {
                const {
                    values,
                    errors,
                    touched
                } = props;

                return (
                    <Box px={2} py={4} bgcolor="white" borderRadius={2}>
                        <Grid container spacing={4}>
                            {values.extraStores.map((data, idx) => (
                                <Grid item container spacing={2} wrap="nowrap" alignItems="center">
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            disablePortal
                                            size="small"
                                            name="category_id"
                                            options={stores}
                                            value={{value: data.store_id, label: _labelAutocomplete(data.store_id)}}
                                            onChange={(event, value) => handleChangeAutocomplete(value, idx)}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Магазин"
                                                fullWidth
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={data.quantity}
                                            type="number"
                                            label="Количество"
                                            size="small"
                                            fullWidth
                                            onChange={({target}) => handleChangeQuantity(target.value, idx)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => handleRemoveExtraStores(idx)}>
                                            <DeleteIcon color="primary"/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                        <Box mt={2}>
                            <Button variant="outlined" fullWidth onClick={handleAddExtraStores}>
                                Добавить
                            </Button>
                        </Box>
                    </Box>
                )
            }}
        </Formik>
    )
}

const validationSchema = Yup.object().shape({});

export default StoresForm
