import React from 'react';
import {
    Box,
    Checkbox, IconButton,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import {
    ListAltOutlined as ListIcon,
    MoreVert as MoreIcon
} from "@mui/icons-material";

const TableComponent = (props) => {
    const {
        characteristics,
        filter,
        pagination,

        isLoading,

        onChangeFilter,
        onRouteProductsPage,
    } = props;

    const handleChangePage = (event, page) => {
        let newFilter = {...filter};
        newFilter.page = page;

        onChangeFilter(newFilter, true);
    };

    const handleRouteProductsPage = (event, id) => {
        event.stopPropagation();
        onRouteProductsPage(id);
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Название</TableCell>
                            <TableCell>URL - адрес</TableCell>
                            <TableCell>Тип значения</TableCell>
                            <TableCell>Показывать в фильтре</TableCell>
                            <TableCell>Экспортировать в Яндекс.Маркет</TableCell>
                            <TableCell>Показывать на странице товара</TableCell>
                            <TableCell>Показывать в каталоге в карточке товара</TableCell>
                            <TableCell>Свернуть в фильтре</TableCell>
                            <TableCell>Сортировка</TableCell>
                            <TableCell>Единица измерения</TableCell>
                            <TableCell>Участвует в поиске</TableCell>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(isLoading) ? (
                            <>
                                {[0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    )
                                )}
                            </>
                        ) : (
                            <>
                                {
                                    characteristics.map((row, index) => (
                                            <TableRow
                                              hover
                                                key={`row-item-${index}`}
                                            >
                                                <TableCell>{row.id || ''}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.slug}</TableCell>
                                                <TableCell>{row.type}</TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.show_in_filter}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.show_in_market}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.show_in_product}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.show_in_product_card}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.collapse_in_filter}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {row.sort}
                                                </TableCell>
                                                <TableCell>
                                                    {row.measure}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.in_search}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Перейти к товарам этой характеристики">
                                                        <IconButton onClick={(e) => handleRouteProductsPage(e, row.id)}>
                                                            <ListIcon color="primary"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton>
                                                        <Tooltip title="Опции">
                                                            <MoreIcon color="primary"/>
                                                        </Tooltip>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>


            <Box mt={2}>
                <Pagination
                    page={Number(filter.page)}
                    count={pagination.count || 1}

                    onChange={handleChangePage}
                />
            </Box>
        </>
    );
};

export default TableComponent;
