import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	TextField,
	Typography,
	Autocomplete, Select, MenuItem, FormControl,

	Table,
	TableBody,
	TableRow,
	TableCell,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {

} from "@mui/styles";
import {Notification, NotificationTypes} from "../../../../../common/Notification";
import agent from "../../../../../agent/agent";
import {convertorNumber} from "../../../../../helper/convertor";
import {getModificationId} from "../../../../../common/Modification";

const initFilter = {
	"filter[id]": "",
	"filter[name][like]": "",
	"filter[code][like]": "",
	"filter[brand_id]": "",
	"filter[status]": "1",
}

class DialogProductAdd extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			products: [],
			brands: [],
			selectProduct: null,
			selectProductOptions: {},

			filter: {...initFilter},

			searchProduct: "",

			onSubmit: null,

			isOpen: false,
			isOpenBackdrop: false,
			isLoadProducts: false
		}
	}

	open = ({ onSubmit }) => {
		this.setState({
			onSubmit,
			isOpen: true
		}, () => {
			(async () => {
				await this.getBrands();
			})();
		})
	}
	close = () => {
		this.setState({
			products: [],
			selectProduct: null,
			selectProductOptions: {},
			searchProduct: "",

			isOpen: false
		})
	}

	// Получение дополнительные данных для таблицы
	getBrands = async () => {
		const response = await agent.get(`/api/brands?per-page=999`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		this.setState({
			brands: response
		})
	}

	// Логика поиска товара
	changeSearch = async (event, searchProduct) => {
		clearTimeout(this.timeOutSearchProducts);

		await this.setState({
			searchProduct,
			isLoadProducts: Boolean(searchProduct)
		});
		if (!searchProduct) {
			return
		}

		this.timeOutSearchProducts = setTimeout(async () => {
			await this.getProducts()
		}, 1000)
	}
	changeFilter = ({ target }) => {
		const { name, value } = target;

		let filter = {...this.state.filter};
		filter[name] = value;

		this.setState({ filter })
	}
	getProducts = async () => {
		const { userCityId, websiteId } = this.props;
		this.setState({ isLoadProducts: true })

		let filterString = [];
		Object.keys(this.state.filter).map((key) => {
			if (this.state.filter?.[key]) {
				filterString.push(`${key}=${this.state.filter?.[key]}`);
			}
		})

		const response = await agent.get(`/api/admin/products?${filterString.join('&')}&expand=extraOptions,extraProductSetParents&city_id=${userCityId}&website_id=${websiteId}`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		this.setState({
			products: response,
			isLoadProducts: false
		})
	}

	changeSelectProduct = async (event, product) => {
		this.setState({ isOpenBackdrop: true })

		const { userCityId, websiteId } = this.props;
		const productId = product?.id;
		const selectProduct = await agent.get(`/api/admin/products/${ productId }?expand=options.variants,extraProductSetParents,options.variantGroups&city_id=${userCityId}&website_id=${websiteId}`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		})

		this.setState({
			selectProduct,
			selectProductOptions: {},

			products: [],

			isOpenBackdrop: false
		})
	}
	changeSelectOptions = async (event, value, option) => {
		let selectProductOptions = {...this.state.selectProductOptions}
		selectProductOptions[option.id] = value?.id;
		this.setState({ selectProductOptions })
	}

	// Вспомогательный фунции
	_getOptionLabel = (item) => {
		const labelStatus = Boolean(item.status === 1) ? "" : " (ОТКЛЮЧЕН)";

		return `(${ item.code }) ${ item.name }${ labelStatus }`
	}
	_getOptionLabelOption = (option, options = []) => {
		if (typeof option !== "object") {
			option = options.find((t) => t.id === option);
		}

		return `${ option?.name || option } (${ convertorNumber(option.price, 2, ',') } руб.)`
	}
	_getTotalPriceProduct = () => {
		const { selectProduct, selectProductOptions } = this.state;

		if (Object.keys(selectProduct || {}).length <= 0) {
			return 0
		}

		let productPrice = +selectProduct?.price;
		let optionsPrice = 0;
		Object.keys(selectProductOptions).map((optionId) => {
			const option = (selectProduct?.options || []).find((t) => +t.id === +optionId);
			optionsPrice = +optionsPrice + +(option?.options || []).find((t) => +t.id === +selectProductOptions[optionId])?.price || 0
		})

		return productPrice + optionsPrice
	}

	onSubmit = async () => {
		const { selectProduct, selectProductOptions, onSubmit } = this.state;
		if (Object.keys(selectProduct || {}).length <= 0) {
			Notification({
				message: "Выберите товар",
				type: NotificationTypes.error
			})

			return
		}
		if (Object.keys(selectProductOptions || {}).length < (selectProduct.options || []).length) {
			Notification({
				message: "Выберите опции товара",
				type: NotificationTypes.error
			})

			return;
		}

		const productPrice = this._getTotalPriceProduct();
		const productOptions = (selectProduct?.options || []).map((option) => {
			option.variants = (option.variants || []).map((variant) => {
				delete variant.status;
				delete variant.supplier_price;
				delete variant.extraExclusions;
				delete variant.extraCharacteristicVariants;
				delete variant.size_depth;
				delete variant.size_height;
				delete variant.size_length;
				delete variant.size_width;
				return variant
			});
			delete option.status;

			return option;
		});

		let options = [];
		Object.keys(selectProductOptions).map((key) => {
			const option = productOptions.find((t) => +t.id === +key) || {};
			const variant = (option?.variants || []).find((t) => +t.id === +selectProductOptions[key]);

			options.push({
				...option,
				variants: [variant]
			})
		})

		const product = {
			options: options,
			product: {
				external_data: {},
				...selectProduct,
				image_id: selectProduct.main_photo_id,
				productSetAllParentsCount: [...(selectProduct?.extraProductSetParents || [])].length,
			},
			product_id: selectProduct.id,
			price: productPrice,
			cost: productPrice,
			origin: productPrice,
			quantity: "1"
		}

		onSubmit(product, true)
	}

	render () {
		const {
			filter,
			products,
			brands,
			selectProduct,
			searchProduct,
			selectProductOptions,

			isOpen,
			isOpenBackdrop,
			isLoadProducts,
		} = this.state;

		return (
			<>

				<Dialog
					fullWidth
					maxWidth="md"

					open={isOpen}
					onClose={this.close}
				>
					<DialogTitle>
						<Typography variant="h3">Добавление товара</Typography>
					</DialogTitle>
					<DialogContent>

						<Box mb={2}>
							<Grid container spacing={2} wrap="nowrap">
								<Grid xs={1} item>
									<Typography variant="overline" display="block" style={{marginBottom: "8px"}}>ID</Typography>
									<TextField
										value={filter['filter[id]']}
										name="filter[id]"
										fullWidth
										variant="outlined"
										placeholder="ID товара"
										onChange={this.changeFilter}
									/>
								</Grid>
								<Grid xs={5} item>
									<Typography variant="overline" display="block" style={{marginBottom: "8px"}}>Наименование товара</Typography>
									<TextField
										value={filter['filter[name][like]']}
										name="filter[name][like]"
										fullWidth
										variant="outlined"
										placeholder="Наименование товара"
										onChange={this.changeFilter}
									/>
								</Grid>
								<Grid xs={2} item>
									<Typography variant="overline" display="block" style={{marginBottom: "8px"}}>Артикул</Typography>
									<TextField
										value={filter['filter[code][like]']}
										name="filter[code][like]"
										fullWidth
										variant="outlined"
										placeholder="Артикул"
										onChange={this.changeFilter}
									/>
								</Grid>
								<Grid xs={4} item>
									<Typography variant="overline" display="block" style={{marginBottom: "8px"}}>Производитель</Typography>
									<FormControl fullWidth label="Производитель">
										<Select
											value={filter['filter[brand_id]']}
											name="filter[brand_id]"
											onChange={this.changeFilter}
										>
											<MenuItem value="">Сбросить</MenuItem>
											{brands.map((brand, index) => (
												<MenuItem key={`select-brand-${brand.id}`} value={brand.id}>{brand.name}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item>
									<Button variant="contained" sx={{marginTop: "21px", height: "36px"}} onClick={this.getProducts}>
										Поиск
									</Button>
								</Grid>
							</Grid>
						</Box>

						<Box>
							<Table>
								<TableBody>
									{Boolean(!isLoadProducts) && (
										<>
											{products.map((product) => (
												<TableRow
													key={`product-table-${product.id}`}
													hover
													onClick={() => this.changeSelectProduct(null, product)}
												>
													<TableCell>
														{product.id}
													</TableCell>
													<TableCell>
														{product.name}
													</TableCell>
													<TableCell>
														{product.code}
													</TableCell>
													<TableCell>
														{product.brand}
													</TableCell>
												</TableRow>
											))}
										</>
									)}
									{Boolean(isLoadProducts) && (
										<TableRow>
											<TableCell>Идет загрузка товаров</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Box>


						{Boolean(selectProduct) && (
							<>
								<Box mb={2}>
									<Typography variant="h5">Выбранный товар</Typography>
								</Box>
								<Box>
									<Box px={1} py={0.5} borderRadius="8px" boxShadow="0 0 4px -1px #00000063">
										<TableRow hover>
											<TableCell sx={{color: "#000000"}}>
												{selectProduct.id}
											</TableCell>
											<TableCell sx={{color: "#000000"}}>
												{selectProduct.name}
											</TableCell>
											<TableCell sx={{color: "#000000"}}>
												{selectProduct.code}
											</TableCell>
											<TableCell sx={{color: "#000000"}}>
												{selectProduct.brand}
											</TableCell>
										</TableRow>
									</Box>
								</Box>

								{Boolean((selectProduct?.options || []).length > 0) && (
									<Grid sx={{marginTop: "8px"}} container rowSpacing="10px">
										{(selectProduct?.options || []).map(( extraOption, index ) => (
											<Grid key={`extraOption-${ index }`} item xs={12}>
												<Typography variant="subtitle1" sx={{marginBottom: "8px"}}>{ extraOption.name }</Typography>
												<Autocomplete
													value={selectProductOptions[extraOption.id]}
													options={extraOption?.variants || []}
													fullWidth
													size="small"
													multiple={extraOption.is_multiple}
													renderInput={(params) => <TextField {...params}/>}
													getOptionLabel={(value) => this._getOptionLabelOption(value, extraOption?.variants || [])}
													onChange={(event, value) => this.changeSelectOptions(event, value, extraOption)}
												/>
											</Grid>
										))}
									</Grid>
								)}
							</>
						)}

					</DialogContent>
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h4" align="right">
									Стоимость товара: {this._getTotalPriceProduct()} руб.
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Button variant="outlined" fullWidth onClick={this.close}>Отменить</Button>
							</Grid>
							<Grid item xs={6}>
								<Button variant="contained" fullWidth onClick={this.onSubmit}>Добавить</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>


				<Backdrop open={isOpenBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		)
	}
}

export default DialogProductAdd
