// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import PricesEdit from './PricesEdit';

export default compose(
    connect(
        state => ({}),
        dispatch => ({}),
    ),
)(PricesEdit);
