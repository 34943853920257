import React from "react";
import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid, TextField,

} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import moment from "moment";

const AdditionallyForm = (props) => {
	const {
		innerRef,
		initialValues,
	} = props;

	const handleSubmit = () => {
	}

	const handleChange = ({target}) => {
		const {name, value} = target;

		let newForm = {...innerRef?.current?.values || {}};
		newForm[name] = value;

		innerRef.current?.setValues(newForm);
	}
	const handleChangeBoolean = ({target}, value) => {
		const {name} = target;

		let newForm = {...innerRef.current.values};
		newForm[name] = value;

		innerRef.current.setValues(newForm);
	}
	const handleChangeAutocomplete = (value, name) => {
		let newForm = {...innerRef.current.values};
		newForm[name] = value?.value || null;

		innerRef.current.setValues(newForm);
	}

	return (
		<Formik
			innerRef={innerRef}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					values,
					errors,
					touched
				} = props;

				return (
					<Box px={2} py={2} bgcolor="white" borderRadius={2}>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<TextField
									value={moment(values.created_at * 1000).format("DD.MM.YYYY HH:mm:ss")}
									label="Дата создания"
									fullWidth
									disabled
									size='small'

									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									value={moment(values.updated_at * 1000).format("DD.MM.YYYY HH:mm:ss")}
									label="Дата изменения"
									fullWidth
									disabled
									size='small'

									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									value={values.reviews_rating || "0"}
									label="Рейтинг товара"
									fullWidth
									disabled
									size='small'

									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									value={values.reviews_count || "0"}
									label="Кол-во отзывов"
									fullWidth
									disabled
									size='small'

									onChange={handleChange}
								/>
							</Grid>

							{Boolean(false) && (
								<>
									<Grid item xs={4}>
										<TextField
											value={values.barcode}
											error={Boolean(touched.barcode && errors.barcode)}
											helperText={touched.barcode && errors.barcode}
											name="barcode"
											label="BarCode"
											fullWidth
											size='small'

											onChange={handleChange}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											value={values.country}
											error={Boolean(touched.country && errors.country)}
											helperText={touched.country && errors.country}
											name="country"
											label="Страна"
											fullWidth
											size='small'

											onChange={handleChange}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											value={values.unit}
											error={Boolean(touched.unit && errors.unit)}
											helperText={touched.unit && errors.unit}
											name="unit"
											label="Ед. изм"
											fullWidth
											size='small'

											onChange={handleChange}
										/>
									</Grid>
								</>
							)}
						</Grid>
					</Box>
				)
			}}
		</Formik>
	)
}

const validationSchema = Yup.object().shape({
	slug: Yup.string().required('Заполните поле')
});

export default React.memo(AdditionallyForm)