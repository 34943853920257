import React from "react";
import {
	Box
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";

class RowSelectArray extends React.PureComponent {
	render() {
		const {
			value,
			options,

			classes
		} = this.props;
		const selectedValue = options[value] || "";
		if (!selectedValue) {
			return null
		}

		return (
			<Box className={classes.brand}>
				{ selectedValue }
			</Box>
		)
	}
}

const styles = {
	brand: {
		fontSize: 12,
		lineHeight: "14px",
		fontWeight: "500",
		textTransform: "uppercase"
	}
}
RowSelectArray = withStyles(styles)(RowSelectArray)

export default RowSelectArray