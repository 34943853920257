import React, {Component} from 'react';
import {filterParse, filterStringify} from "../../../helper/filter";
import agent from "../../../agent/agent";
import {withStyles} from "@mui/styles";
import {Box, Button, Typography} from "@mui/material";
import {AddBoxOutlined as AddBoxOutlinedIcon, ListAltOutlined as ListIcon} from "@mui/icons-material";
import {ControlsComponent, TableComponent} from "./components";

const initFilter = {
    page: 1,
}

class CharacteristicVariants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            variants: [],

            characteristicId: props.match.params.id,

            filter: {...initFilter},
            pagination: {},

            isLoading: true,
        };
    }

    componentDidMount = async () => {
        await this.setFilterPage();

        await this.getCharacteristicVariants();
    }


    //Установка фильтров при обновлении страницы
    setFilterPage = () => {
        const filter = this._getFilterPage();

        this.setState({filter});
    }

    getCharacteristicVariants = async () => {
        this.setState({
            variants: [],
            isLoading: true,
        })
        const {filter, characteristicId} = this.state;
        const url = `/admin/api-shop-characteristic-variants/list?expand=productsCount&fields=id,characteristic_id,value,slug,image_id,sort,productsCount&page=${filter.page}&id=${characteristicId}`

        const {data, headers} = await agent.get(url)
            .catch((err) => {
                return {
                    data: [],
                    headers: {},
                }
            })

        const pagination = {
            count: headers?.['x-pagination-page-count'] || 1,
            perPage: headers?.['x-pagination-per-page'] || 20,
            totalCount: headers?.['x-pagination-total-count'] || 0,
        }

        this.setState({
            variants: data,
            pagination,
            isLoading: false,
        });
    }

    //Получение фильтров при обновлении страницы
    _getFilterPage = () => {
        const search = window.location?.search || '';

        let filter = {};

        filter = {
            ...filter,
            ...initFilter,
            ...filterParse(search, initFilter, {})
        }

        return filter
    }

    // Работы с фильтром страницы
    _changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});
        const {characteristicId} = this.state;

        const urlFilter = filterStringify(this.state.filter);
        window.history.replaceState(null, null, `/characteristics/characteristic_variants/${characteristicId}?${urlFilter}`);

        if (!isFastStart) {
            return null
        }

        await this.getCharacteristicVariants();
    }

    _routeCreateVariantPage = () => {
        const {characteristicId} = this.state;
        this.props.history.push(`/characteristics/characteristic_create/${characteristicId}`);
    }

    render() {
        const {
            classes
        } = this.props;

        const {
            variants,
            characteristicId,
            filter,
            pagination,
            isLoading,
        } = this.state;
        return (
            <>
                <Box className={classes.head}>
                    <Box className={classes.headLogo}>
                        <ListIcon sx={{color: "#0057FF", fontSize: 42}}/>
                        <Typography variant="h1">Варианты характеристики</Typography>
                    </Box>
                    <Button
                        variant="contained"
                        startIcon={<AddBoxOutlinedIcon sx={{color: "white"}}/>}

                        onClick={this._routeCreateVariantPage}
                    >
                        Добавить вариант
                    </Button>
                </Box>

                <Box>
                    <ControlsComponent
                        filter={filter}
                        pagination={pagination}
                    />
                </Box>

                <Box>
                    <TableComponent
                        variants={variants}
                        filter={filter}
                        pagination={pagination}
                        isLoading={isLoading}
                        onChangeFilter={this._changeFilter}
                    />
                </Box>
            </>
        );
    }
}

const styles = {
    head: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16
    }, headLogo: {
        display: "flex", alignItems: "center",

        "& h1": {
            marginLeft: 12
        }
    },
}

const CharacteristicVariantsStyles = withStyles(styles)(CharacteristicVariants);


export default CharacteristicVariantsStyles