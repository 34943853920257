import React from "react";
import {
    Box,
    Grid,
    Button,
    Dialog,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,

    IconButton
} from "@mui/material";
import {
    Close as CloseIcon
} from "@mui/icons-material";

class DialogConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,

            title: "",
            message: "",
            acceptButtonTitle: "",
            cancelButtonTitle: "",
            acceptButtonAction: "",
            cancelButtonAction: "",
            hideCancel: false
        }
    }

    onOpen = (props) => {
        this.setState({
            open: true,

            title: props.title || '',
            message: props.message || '',
            acceptButtonTitle: props.acceptButtonTitle || 'Применить',
            cancelButtonTitle: props.cancelButtonTitle || '',
            acceptButtonAction: props.acceptButtonAction || '',
            cancelButtonAction: props.cancelButtonAction || '',

            hideCancel: props?.hideCancel || false
        })
    }
    onClose = () => {
        this.setState({
            open: false
        })
    }

    onAccept = () => {
        this.setState({
            open: false
        });

        if (!this.state.acceptButtonAction) {
            return null
        }

        this.state.acceptButtonAction();
    }
    onCancel = () => {
        this.setState({
            open: false
        });

        if (!this.state.cancelButtonAction) {
            return null
        }

        this.state.cancelButtonAction();
    }

    render() {
        const {
            open,

            title,
            message,
            acceptButtonTitle,
            cancelButtonTitle,

            hideCancel
        } = this.state;

        return (
            <Dialog open={open} fullWidth maxWidth="md" onClose={this.onCancel}>
                <DialogTitle>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h3">{ title || "Подтверждение" }</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={this.onCancel}>
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography
                            variant="h4"
                            dangerouslySetInnerHTML={{
                                __html: (message || 'Вы уверены?')
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} justifyContent="flex-end">
                        {Boolean(!hideCancel) && (
                          <Grid item>
                              <Button variant="outlined" fullWidth onClick={this.onCancel}>
                                  Отменить
                              </Button>
                          </Grid>
                        )}
                        <Grid item>
                            <Button variant="contained" fullWidth onClick={this.onAccept}>
                                {acceptButtonTitle || 'Применить'}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DialogConfirmation
