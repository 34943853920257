import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {setAdminLocked, setCurrentBuildVersion, setUser} from '../states/global';
import {
    DialogOldVersion
} from "./component";

import MainRoute from "./Main";
import agent from "../agent/agent";
import urls from "../variables/urls";
import NotAuth from "./NotAuth";
import axios from "axios";
import {getMetaData} from "../helper/meta";
import {deleteCookie, setCookie} from "../helper/cookie";
import {getQueue} from "../states/notifications";

class Router extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.timeOutCheckVersion = null;
        this.timeOutApiAdminTimestamp = null;
        this.refDialogOldVersion = React.createRef();
    }

    componentDidMount = async () => {
        await this.getUser();

        await this.setCurrentVersion();
        await this.checkVersion();
        await this.startApiAdminTimestamp();
    }
    componentDidUpdate = async (prevProps, prevState) => {

        // Слежка за пользователем
        if (this.props.user && this.props.user !== prevProps.user) {
            await this.startApiAdminTimestamp();
        }
        if (!this.props.user && this.props.user !== prevProps.user) {
            clearTimeout(this.timeOutApiAdminTimestamp);
        }
    }

    setCurrentVersion = async () => {
        const currentBuildVersion = await axios.get(`/index.html`, {}).then((res) => {
            return getMetaData(res.data, "build-version")
        }).catch((err) => {
            return ""
        });
        this.props.setCurrentBuildVersion(currentBuildVersion);
    }
    checkVersion = async () => {
        clearTimeout(this.timeOutCheckVersion);

        // const timestamp = new Date().getTime();
        const oldVersion = this.props.global?.currentBuildVersion;
        const currentBuildVersion = await axios.get(`/index.html`, {}).then((res) => {
            return getMetaData(res.data, "build-version")
        }).catch((err) => {
            return ""
        });
        if (oldVersion !== currentBuildVersion) {
            this.refDialogOldVersion.current.open();
        }

        this.timeOutCheckVersion = setTimeout(async () => {
            await this.checkVersion();
        }, (1000 * 60))
    }

    getUser = async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            this.setState({ isLoading: false });

            return null
        }

        const user = await agent.get(urls.userSession).then((res) => {
            return res.data?.user || {}
        }).catch((err) => {
            return null
        });

        if (!user) {
            localStorage.removeItem('token');
            deleteCookie('adminToken', {
              domain: ".miasofiahome.ru",
              path: "/"
            });

            this.setState({ isLoading: false });

            return null
        }

        setCookie('adminToken', token, {
            domain: ".miasofiahome.ru",
            maxAge:  60 * 60 * 24 * 60,
            path: "/"
        });

        await this.props.setUser(user);
        await this.props.getQueue();
        this.setState({ isLoading: false });
    }
    startApiAdminTimestamp = async () => {
        clearTimeout(this.timeOutApiAdminTimestamp);

        const response = await agent.get(`/admin/api-admin-timestamp`).then((res) => {
            return res.data
        }).catch((err) => {
            return null
        });
        if (!response) {
            // localStorage.removeItem("token");
            // deleteCookie("adminToken", {
            //   domain: ".miasofiahome.ru",
            //   path: "/"
            // });
            // agent.defaults.headers['Authorization'] = "";
            //
            // this.props.setUser(null);

            return
        }

        this.props.setAdminLocked(response?.locked || []);

        this.timeOutApiAdminTimestamp = setTimeout(async () => {
            await this.startApiAdminTimestamp();
        }, 30000)
    }

    render() {
        const { isLoading } = this.state;
        const { user } = this.props.global;

        if (isLoading) {
            return null
        }

        return (
            <>
                <VisibleContent visible={!user}>
                    <NotAuth/>
                </VisibleContent>
                <VisibleContent visible={user}>
                    <MainRoute/>
                </VisibleContent>

                <DialogOldVersion
                  ref={this.refDialogOldVersion}
                />
            </>
        )
    }
}
const VisibleContent = React.memo((props) => {
    if (!props.visible) {
        return null;
    }

    return props.children;
})

export default compose(
    connect(
        state => ({
            global: state.global,
            user: state.global.user,
        }),
        dispatch => ({
            getQueue: () => dispatch(getQueue()),
            setUser: (user) => dispatch(setUser(user)),
            setAdminLocked: (adminLocked) => dispatch(setAdminLocked(adminLocked)),
            setCurrentBuildVersion: (version) => dispatch(setCurrentBuildVersion(version)),
        }),
    ),
)(Router);
