import React from "react";
import {
	Box, Grid,
	TextField,
	Typography,
} from "@mui/material";

class Client extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {

		}
	}

	changeData = ({ target }) => {
		const { value, name } = target;

		let data = {...this.props.data};
		data[name] = value;

		this.props.onChange(data);
	}

	render () {
		const {
			data
		} = this.props;

		return (
			<Box px={3} py={2} bgcolor="white" borderRadius="8px">

				<Typography variant="h4" sx={{marginBottom: "20px"}}>Информация о клиенте</Typography>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography display="block" variant="overline" sx={{marginBottom: "8px"}}>Имя</Typography>
						<TextField
							value={data.customer_name}
							name="customer_name"
							placeholder="Имя пользователя"
							fullWidth
							onChange={this.changeData}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography display="block" variant="overline" sx={{marginBottom: "8px"}}>Email</Typography>
						<TextField
							value={data.customer_email}
							name="customer_email"
							placeholder="Email пользователя"
							fullWidth
							onChange={this.changeData}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography display="block" variant="overline" sx={{marginBottom: "8px"}}>Телефон</Typography>
						<TextField
							value={data.customer_phone}
							name="customer_phone"
							placeholder="Телефон пользователя"
							fullWidth
							onChange={this.changeData}
						/>
					</Grid>
				</Grid>

			</Box>
		)
	}
}

export default Client