import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	TextField,
	Typography,
	Autocomplete,
	FormHelperText
} from "@mui/material";
import {

} from "@mui/styles";
import agent from "../../../../../agent/agent";
import urls from "../../../../../variables/urls";
import {convertorNumber} from "../../../../../helper/convertor";

class DialogProductSettings extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			product: {},
			selectedOptions: {},
			productOptions: [],

			isOpen: false,
			isErrorInitData: false
		}
	}

	open = ({ product, index, onSubmit }) => {
		this.setState({
			product,
			index,
			onSubmit,
			isOpen: true,
			isErrorInitData: false
		}, async () => {
			await this.getProductOptions();
		})
	}
	close = () => {
		this.setState({
			isOpen: false,
			isErrorInitData: false
		})
	}
	submit = () => {
		const { apiProduct, product, index, productOptions } = this.state;
		const productExtraOptions = productOptions || [];

		let priceOptions = 0;
		let selectedOptions = {...this.state.selectedOptions}

		let options = [];
		Object.keys(selectedOptions).map((key) => {
			const option = productExtraOptions.find((t) => +t.id === +key) || {};
			const variant = (option?.variants || []).find((t) => +t.id === +selectedOptions[key]);

			options.push({
				...option,
				variants: [variant]
			})
		})

		let newProduct = {...product}
		newProduct.price = +apiProduct.price;
		newProduct.options = options;

		this.state.onSubmit(newProduct, index, selectedOptions, true)
	}

	// Получение списка опций
	getProductOptions = async () => {
		const { userCityId, websiteId } = this.props;

		const expand = 'expand=extraOptions,variants';
		const fields = 'fields=id,name,price_old,price,extraOptions';
		const ngrestCallType = "ngrestCallType=update";

		const search = [ngrestCallType, fields, expand].join('&')
		const product = await agent.get(`${urls.getProduct}/${this.state.product.product_id}?${search}&city_id=${userCityId}&website_id=${websiteId}`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});
		const options = await agent.get(`${urls.getProduct}/${this.state.product.product_id}/options?${search}&city_id=${userCityId}&website_id=${websiteId}`).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		})

		const productOptions = this.state.product?.options || [];
		const extraOptions = (options || []).map((option) => {
			const variantGroups = [...productOptions].find((t) => +t.id === +option.id)?.variantGroups || [];
			return {
				...option,
				variants: (option?.variants || []).map((_variant) => {
					return {
						..._variant,
						variantGroup: variantGroups.find((t) => +t.id === +_variant.variant_group_id),
					}
				}),
				variantGroups
			}
		});

		let isErrorInitData = false;

		let selectedOptions = {}
		extraOptions.map((option) => {
			let value = [...productOptions].find((t) => +t.id === +option.id)?.variants?.[0] || null;
			if (!option.is_multiple) {
				value = value || null;
			}
			selectedOptions[option.id] = value?.id;
			if (!value) {
				isErrorInitData = true
			}
		});

		this.setState({
			apiProduct: product,
			productOptions: extraOptions,
			selectedOptions,

			isErrorInitData: isErrorInitData
		})
	}
	changeSelectedOptions = (event, value, option) => {
		let selectedOptions = {...this.state.selectedOptions}
		selectedOptions[option.id] = value?.id;

		this.setState({ selectedOptions })
	}

	// Вспомогательные функции
	_getLabelOption = (option, options = []) => {
		if (typeof option !== "object") {
			option = options.find((t) => t.id === option);
		}

		const _name = [
			option?.variantGroup?.name,
			(option?.name || option)
		].filter((t) => !!t).join(' ');
		return `${ _name } (${ convertorNumber(option.price, 2, ',') } руб.)`
	}

	render () {
		const {
			product,
			selectedOptions,
			productOptions,

			isOpen,
			isErrorInitData
		} = this.state;

		return (
			<Dialog
				maxWidth="sm"
				fullWidth
				open={isOpen}
			>
				<DialogTitle>
					<Typography variant="h3">Настройки товара</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container spacing={2}>
						{productOptions.map((productOption, index) => (
							<Grid key={`product-option-${ index }`} item xs={12}>
								<Autocomplete
									value={selectedOptions[productOption.id]}
									options={productOption?.variants || []}
									fullWidth
									size="small"
									multiple={productOption.is_multiple}
									renderInput={(params) => <TextField {...params} label={productOption.name}/>}
									getOptionLabel={(value) => this._getLabelOption(value, productOption?.variants || [])}
									onChange={(event, value) => this.changeSelectedOptions(event, value, productOption)}
								/>
							</Grid>
						))}
						{Boolean(isErrorInitData) && (
							<Grid item xs={12}>
								<FormHelperText error>
									Параметры товара были изменены контент-менеджерами. Заполните параметры снова
								</FormHelperText>
							</Grid>
						)}
					</Grid>
				</DialogContent>

				<DialogActions>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Button
								variant="outlined"
								color="primary"
								fullWidth
								onClick={this.close}
							>Отменить</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								onClick={this.submit}
							>Применить</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

export default DialogProductSettings
