import React from "react";
import {
	Box,
	TextField,
	IconButton,
	Typography,
	Backdrop,
	CircularProgress,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell, Grid, Tooltip
} from "@mui/material";
import {
	Delete as DeleteIcon,
	AddAPhoto as AddAPhotoIcon,
	HideImage as HideImageIcon,
} from "@mui/icons-material";
import {
	Formik
} from "formik";
import * as Yup from "yup";
import DropZone from "./DropZon";
import {
	Notification,
	NotificationTypes
} from "../../../../../common/Notification";
import {imageUpload} from "../../../../../common/FilesUpload";
import agent from "../../../../../agent/agent";
import {checkValidImageLoading} from "../../../../../helper/check-valid-image-loading";
import CharacteristicsAutocomplete from "../Characteristics/form/Autocomplete";

const ImagesForm = (props) => {
	const {
		innerRef,
		extraWebsites,
		initialValues,
		characteristics,
		initCharacteristics
	} = props;
	const [hideImages, setHideImages] = React.useState([]);
	const [isOpenBackdrop, setOpenBackdrop] = React.useState(false);

	const [characteristicMainBanner, setCharacteristicMainBanner] = React.useState(null);
	React.useEffect(() => {
		let _items = [];
		characteristics.map((_item) => {
			(_item?.characteristicAssignments || []).map((_t) => {
				_items.push(_t)
			})
		});
		const _item = _items.find((t) => Boolean(t?.characteristic?.slug === 'tip-bannera'))?.characteristic || null;
		setCharacteristicMainBanner(_item);
	}, [characteristics]);


	React.useEffect(() => {
		( async () => {
			await handleGetListPhoto();
		})();
	}, []);

	const handleSubmit = () => {
	}

	const handleChangeImage = async (event) => {
		const file = event?.target.files?.[0] || null;
		const checkFile = await checkValidImageLoading(file);
		if (checkFile.error) {
			Notification({
				title: "",
				message: checkFile.error,
				type: NotificationTypes.error
			})
			return
		}

		const imageResponse = await imageUpload(file);
		const imageId = imageResponse?.image?.id;
		if (!imageId) {
			Notification({
				message: "Ошибка загрузки изображения",
				type: NotificationTypes.error
			})

			return
		}

		let newValues = innerRef?.current?.values || {};
		newValues.main_photo_id = imageId;
		innerRef.current.setValues(newValues);
	}
	const handleChangeCaption = (event) => {
		const value = event?.target.value || '';

		let newValues = innerRef?.current?.values || {};
		newValues.main_photo_caption = value;
		innerRef.current.setValues(newValues);
	}
	const handleSetMainPhoto = (newImageMain) => {
		let values = innerRef.current.values;

		let images = [...(values.images || [])];
		images.push({
			imageId: values.main_photo_id,
			caption: values.main_photo_caption || ""
		});

		values.main_photo_id = values.images?.[newImageMain]?.imageId;
		values.main_photo_caption = values.images?.[newImageMain]?.caption || "";

		images.splice(newImageMain, 1);
		values.images = images;

		innerRef.current.setValues(values);
	}

	const handleChangeBannerImageId = async (event) => {
		const file = event?.target?.files?.[0] || null;
		if (!file) {
			let newValues = innerRef?.current?.values || {};
			newValues.bannerImageId = null;
			innerRef.current.setValues(newValues);
			return
		}

		const checkFile = await checkValidImageLoading(file);
		if (checkFile.error) {
			Notification({
				title: "",
				message: checkFile.error,
				type: NotificationTypes.error
			})
			return
		}

		const imageResponse = await imageUpload(file);
		const imageId = imageResponse?.image?.id;
		if (!imageId) {
			Notification({
				message: "Ошибка загрузки изображения",
				type: NotificationTypes.error
			})

			return
		}

		let newValues = innerRef?.current?.values || {};
		newValues.bannerImageId = imageId;
		innerRef.current.setValues(newValues);
	}
	const handleChangeBannerCaption = async ({ target }) => {
		const { name, value } = target;

		let values = innerRef.current.values;
		values.extraValues[name] = value;
		innerRef.current.setValues(values);
	}

	// Логика работы с перемещение и возратом отключенных фотографий
	const handleGetListPhoto = async () => {
		const folderId = process.env.REACT_APP_HIDE_IMAGES_PRODUCT_FOLDER_ID || "101";
		const productId = initialValues?.id;

		const search = `${productId}_disabled_`;
		const responseHideImages = await agent.get(`${process.env.REACT_APP_HOST_API}admin/api-admin-storage/data-files?folderId=${folderId}&page=1&expand=createThumbnail,createThumbnailMedium,isImage,sizeReadable&sort=name_original&search=${search}`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		});
		if (responseHideImages.length <= 0) {
			setHideImages([]);
			return []
		}

		const filterFiles = responseHideImages.filter((image) => {
			return Boolean((image?.name_original || "").indexOf(search) > -1);
		});
		if (filterFiles.length <= 0) {
			setHideImages([]);
			return
		}

		setHideImages(filterFiles);
	}
	const handleHidePhoto = async (index) => {
		setOpenBackdrop(true);

		const productId = initialValues?.id;

		let values = innerRef.current.values;
		let images = [...(values.images || [])];
		let imageId = images[index]?.imageId;

		// Информация об файле
		const responseInfoFile = await agent.get(`/admin/api-admin-storage/image-info?id=${ imageId }`).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		});
		if (responseInfoFile?.error) {
			setOpenBackdrop(false);
			Notification({
				title: "Ошибка информации об изображения",
				message: responseInfoFile.error?.data?.message || "Ошибка сервера",
				type: NotificationTypes.error
			})
			return
		}

		// Перемещение фото в новую папку
		const responseMove = await agent.post(`/admin/api-admin-storage/filemanager-move-files`, {
			currentFolderId: responseInfoFile?.file?.folder_id,
			currentPageId: "1",
			fileIds: [responseInfoFile.file_id],
			toFolderId: process.env.REACT_APP_HIDE_IMAGES_PRODUCT_FOLDER_ID || "101",
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.right }
		})
		if (responseMove?.error) {
			setOpenBackdrop(false);
			Notification({
				title: "Ошибка перемещения изображения",
				message: responseMove.error?.data?.message || "Ошибка сервера",
				type: NotificationTypes.error
			})
			return
		}

		// Переименование файла
		const responseRenameFile = await agent.put(`/admin/api-admin-storage/file-update?id=${responseInfoFile.file_id}&pageId=1`, {
			name_original: `${productId}_disabled_${imageId}`
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (responseRenameFile?.error) {
			setOpenBackdrop(false);
			Notification({
				title: "Ошибка переменования изображения",
				message: responseRenameFile.error?.data?.message || "Ошибка сервера",
				type: NotificationTypes.error
			})
			return
		}

		// Удаление фото из массива фото
		images.splice(index, 1);
		values.images = images;
		innerRef.current.setValues(values);

		// Сохрание в товаре нового состава изображения
		const responseUpdateProduct = await agent.put(`/api/admin/products/${ productId }`, {
			images: images
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		setOpenBackdrop(false);

		await handleGetListPhoto();

		Notification({
			message: "Фото успешно скрыто",
			type: NotificationTypes.success
		})
	}
	const handleHidePhotoMulti = async (indexses) => {
		setOpenBackdrop(true);

		const productId = initialValues?.id;

		await Promise.all(indexses.map(async (index) => {
			let values = innerRef.current.values;
			let images = [...(values.images || [])];
			let imageId = images[index]?.imageId;

			// Информация об файле
			const responseInfoFile = await agent.get(`/admin/api-admin-storage/image-info?id=${ imageId }`).then((res) => {
				return res.data
			}).catch((err) => {
				return { error: err.response }
			});
			if (responseInfoFile?.error) {
				setOpenBackdrop(false);
				Notification({
					title: "Ошибка информации об изображения",
					message: responseInfoFile.error?.data?.message || "Ошибка сервера",
					type: NotificationTypes.error
				})
				return
			}

			// Перемещение фото в новую папку
			const responseMove = await agent.post(`/admin/api-admin-storage/filemanager-move-files`, {
				currentFolderId: responseInfoFile?.file?.folder_id,
				currentPageId: "1",
				fileIds: [responseInfoFile.file_id],
				toFolderId: process.env.REACT_APP_HIDE_IMAGES_PRODUCT_FOLDER_ID || "101",
			}).then((res) => {
				return res.data
			}).catch((err) => {
				return { error: err.right }
			})
			if (responseMove?.error) {
				setOpenBackdrop(false);
				Notification({
					title: "Ошибка перемещения изображения",
					message: responseMove.error?.data?.message || "Ошибка сервера",
					type: NotificationTypes.error
				})
				return
			}

			// Переименование файла
			const responseRenameFile = await agent.put(`/admin/api-admin-storage/file-update?id=${responseInfoFile.file_id}&pageId=1`, {
				name_original: `${productId}_disabled_${imageId}`
			}).then((res) => {
				return res.data
			}).catch((err) => {
				return {error: err.response}
			})
			if (responseRenameFile?.error) {
				setOpenBackdrop(false);
				Notification({
					title: "Ошибка переменования изображения",
					message: responseRenameFile.error?.data?.message || "Ошибка сервера",
					type: NotificationTypes.error
				})
				return
			}

			// Удаление фото из массива фото
			images.splice(index, 1);
			values.images = images;
			innerRef.current.setValues(values);

			// Сохрание в товаре нового состава изображения
			const responseUpdateProduct = await agent.put(`/api/admin/products/${ productId }`, {
				images: images
			}).then((res) => {
				return res.data
			}).catch((err) => {
				return {error: err.response}
			})
		}));

		setOpenBackdrop(false);
		await handleGetListPhoto();
		Notification({
			message: "Фото успешно скрыто",
			type: NotificationTypes.success
		})
	}
	const handleReturnPhoto = async (file) => {
		setOpenBackdrop(true);

		// Запрос информации об файле
		const responseFileInfo = await agent.get(`/admin/api-admin-storage/file-info?id=${ file.id }`).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (responseFileInfo?.error) {
			setOpenBackdrop(false);
			Notification({
				title: "Ошибка информации об изображения",
				message: responseFileInfo.error?.data?.message || "Ошибка сервера",
				type: NotificationTypes.error
			})
			return
		}

		const productId = initialValues?.id;
		const imageId = (responseFileInfo?.images || []).find((t) => t.filter_id === 0)?.id;

		// Перенос фото в новую папку
		const responseMove = await agent.post(`/admin/api-admin-storage/filemanager-move-files`, {
			currentFolderId: process.env.REACT_APP_HIDE_IMAGES_PRODUCT_FOLDER_ID || "101",
			currentPageId: "1",
			fileIds: [file.id],
			toFolderId: process.env.REACT_APP_PRODUCTS_IMAGES_PRODUCT_FOLDER_ID || "1",
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.right }
		})
		if (responseMove?.error) {
			setOpenBackdrop(false);
			Notification({
				title: "Ошибка перемещения изображения",
				message: responseMove.error?.data?.message || "Ошибка сервера",
				type: NotificationTypes.error
			})
			return
		}

		// Переименование файла
		const responseRenameFile = await agent.put(`/admin/api-admin-storage/file-update?id=${file.id}&pageId=1`, {
			name_original: `${productId}_enabled_${imageId}`
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (responseRenameFile?.error) {
			setOpenBackdrop(false);
			Notification({
				title: "Ошибка переменования изображения",
				message: responseRenameFile.error?.data?.message || "Ошибка сервера",
				type: NotificationTypes.error
			})
			return
		}

		// Добавление фото в массива фото
		let values = innerRef.current.values;
		let images = [...(values.images || [])];
		images.push({
			imageId: imageId,
			caption: null
		});
		values.images = images;
		innerRef.current.setValues(values);

		// Сохрание в товаре нового состава изображения
		const responseUpdateProduct = await agent.put(`/api/admin/products/${ productId }`, {
			images: images
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		setOpenBackdrop(false);
		Notification({
			message: "Фото успешно возвращено",
			type: NotificationTypes.success
		})

		await handleGetListPhoto();

		setOpenBackdrop(false);
	}

	const handleChangeImagesList = (images) => {
		let values = innerRef.current.values;
		values.images = images;
		innerRef.current.setValues(values);
	}

	return (
		<>

			<Formik
				innerRef={innerRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {
						values,
						errors,
						touched
					} = props;

					return (
						<>

							<Box px={2} py={2} bgcolor="white" borderRadius={2}>

								<Box mb={4}>
									<Box mb={2}>
										<Typography variant="h3">Изображение</Typography>
									</Box>

									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell width={80}>Изображение</TableCell>
												<TableCell>Описание</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<RowImageMain
												main_photo_id={values.main_photo_id}
												main_photo_caption={values.main_photo_caption}
												onChangeImage={handleChangeImage}
												onChangeCaption={handleChangeCaption}
											/>
										</TableBody>
									</Table>
								</Box>

								<DropZone
									images={values.images}

									onChange={handleChangeImagesList}
									onSetMainPhoto={handleSetMainPhoto}
									onHidePhoto={handleHidePhoto}
									onHidePhotoMulti={handleHidePhotoMulti}
								/>

								{Boolean((extraWebsites || []).includes(2) && Boolean(values.is_product_set)) && (
									<Box mt={4}>
										<Box mb={2}>
											<Typography variant="h3">Изображение для баннера</Typography>
											<Typography variant="caption">Используется для cilek-kids.ru</Typography>
										</Box>

										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell width={80}>Изображение</TableCell>
													<TableCell>Положение текста</TableCell>
													<TableCell width={40} align="center"/>
												</TableRow>
											</TableHead>
											<TableBody>
												<RowBannerMain
													main_photo_id={values.bannerImageId}
													main_photo_caption={values.extraValues[characteristicMainBanner?.id] || null}
													characteristic={characteristicMainBanner}
													initCharacteristics={initCharacteristics}
													onChangeImage={handleChangeBannerImageId}
													onChangeCaption={handleChangeBannerCaption}
												/>
											</TableBody>
										</Table>
									</Box>
								)}

							</Box>

							{Boolean(hideImages.length > 0) && (
								<>
									<Box mt="40px"/>
									<Box px={2} py={2} bgcolor="white" borderRadius={2}>
										<Box mb={2}>
											<Typography variant="h3">Отключенные изображения для этого товара</Typography>
										</Box>
										<Grid container columnSpacing={2} rowSpacing={2}>
											{hideImages.map((image) => (
												<Grid item xs={4}>
													<Box boxShadow="0 0 2px 1px rgba(0,0,0,0.2)" borderRadius={4} px={2} py={1}>
														<Grid container spacing={1}>
															<Grid item>
																<div style={{borderRadius: "4px"}}>
																	<img width={96} height={96} style={{
																		objectFit: 'cover',
																		borderRadius: "4px"
																	}} src={image?.createThumbnailMedium?.source} alt=""/>
																</div>
															</Grid>
															<Grid item ml='auto'>
																<Grid container spacing={2}>
																	<Grid item>
																		<Tooltip title="Вернуть фото" arrow>
																			<IconButton
																				sx={{
																					width: 96,
																					height: 96,
																					background: "rgba(67, 160, 71, 0.05)",
																					borderRadius: 1,
																					border: "1px solid rgba(67, 160, 71, 0.7)"
																				}}
																				onClick={handleReturnPhoto.bind(this, image)}
																			>
																				<HideImageIcon color="success" sx={{pointerEvents: "none"}}/>
																			</IconButton>
																		</Tooltip>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Box>
												</Grid>
											))}
										</Grid>
									</Box>
								</>
							)}
						</>
					);
				}}
			</Formik>

			<Backdrop open={isOpenBackdrop}>
				<CircularProgress/>
			</Backdrop>
		</>
	)
}
const RowImageMain = React.memo((data) => {
	const {
		main_photo_id,
		main_photo_caption,

		showDelete
	} = data;

	return (
		<TableRow hover>
			<TableCell width={80}>
				<Box width={80} height={80} borderRadius={1} bgcolor="rgba(0,0,0,0.1)">
					<label style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: '100%',
						cursor: "pointer"
					}}>
						{Boolean(!!main_photo_id) ? (
							<img src={`${process.env.REACT_APP_HOST_API}api/storage/image/${ main_photo_id }_w-400_h-400.webp`} style={{width: '100%', height: '100%', objectFit: "contain"}}/>
						) : (
							<AddAPhotoIcon
								color="primary"
							/>
						)}
						<input type="file" accept="image/jpg,image/jpeg,image/png" hidden onChange={(event) => data.onChangeImage(event)}/>
					</label>
				</Box>
			</TableCell>
			<TableCell>
				<TextField
					value={main_photo_caption}
					size="small"
					placeholder="Описание"
					onChange={(event) => data.onChangeCaption(event)}
				/>
			</TableCell>
			{Boolean(showDelete) && (
				<TableCell align="right">
					<Tooltip title="Удалить">
						<IconButton
							color="primary"

						>
							De
						</IconButton>
					</Tooltip>
				</TableCell>
			)}
		</TableRow>
	)
});
const RowBannerMain = React.memo((data) => {
	const {
		main_photo_id,
		main_photo_caption,
		characteristic,
		initCharacteristics,

		onChangeCaption
	} = data;

	const [initCharOptions, setInitCharOptions] = React.useState([]);
	React.useEffect(() => {
		let _items = [];
		initCharacteristics.map((_item) => {
			(_item?.characteristics || []).map((_t) => {
				_items.push(_t)
			})
		});
		const _item = _items.find((t) => Boolean(t?.slug === 'tip-bannera'))?.values || [];
		setInitCharOptions(_item);
	}, [initCharacteristics, characteristic]);

	return (
		<TableRow hover>
			<TableCell width={80}>
				<Box width={80} height={80} borderRadius={1} bgcolor="rgba(0,0,0,0.1)">
					<label style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: '100%',
						cursor: "pointer"
					}}>
						{Boolean(!!main_photo_id) ? (
							<img src={`${process.env.REACT_APP_HOST_API}api/storage/image/${ main_photo_id }_w-400_h-400.webp`} style={{width: '100%', height: '100%', objectFit: "contain"}}/>
						) : (
							<AddAPhotoIcon
								color="primary"
							/>
						)}
						<input type="file" accept="image/jpg,image/jpeg,image/png" hidden onChange={(event) => data.onChangeImage(event)}/>
					</label>
				</Box>
			</TableCell>
			<TableCell>
				{Boolean(characteristic) && (
					<CharacteristicsAutocomplete
						value={main_photo_caption}
						initOptions={initCharOptions}
						placeholder="Выберите"
						name={characteristic?.id}
						multiple={false}
						onChange={onChangeCaption}
					/>
				)}
			</TableCell>
			<TableCell align="center">
				<Tooltip title="Удалить">
					<IconButton
						color="error"
						onClick={data.onChangeImage.bind(this, null)}
					>
						<DeleteIcon/>
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
});

const validationSchema = Yup.object().shape({});

Array.prototype.swap = (function (i, j) {
	try {
		[i, j] = [j, i];
	} catch (e) {
	}
	if (i) {
		return function (i, j) {
			[this[i], this[j]] = [this[j], this[i]];
			return this;
		}
	} else {
		return function (i, j) {
			var temp = this[i];
			this[i] = this[j];
			this[j] = temp;
			return this;
		}
	}
})();

export default React.memo(ImagesForm)
export {
	RowImageMain
}
