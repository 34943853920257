import React, {Component} from 'react';
import agent from "../../../agent/agent";
import moment from "moment";
import {ControlRegionalPriceCreate, CreateForm, HeaderRegionalPriceCreate} from "./components";
import {Box} from "@mui/material";
import {Notification, NotificationTypes} from "../../../common/Notification";

const initialValues = {
    name: '',
    status: '',
    price_rounding_method: '',
    price_value: '',
    product_compilation_id: '',
    extraRegions: [],
    started_at_date: '',
    started_at_hour: '',
    ended_at_date: '',
    ended_at_hour: '',
}


class RegionalPriceCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regionalPrice: {},

            productCompilationId: [],
            priceRoundingMethod: [],
            extraRegions: [],

            regionalPriceId: props.match.params.id || '',

            isLoading: true,
            isCreate: true,
        }

        this.refCreateForm = React.createRef();
        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        const {regionalPriceId} = this.state;

        if (regionalPriceId !== '') {
            await this.getRegionalPrice();
            await this.setState({isCreate: false})
        }

        await this.getServices();
    }

    //Получение данных при дублировании
    getRegionalPrice = async () => {
        const {regionalPriceId} = this.state;
        const regionalPrice = await agent.get(`/admin/api-shop-price-rule/${regionalPriceId}?ngrestCallType=update&expand=extraRegions&fields=id%2Cstarted_at%2Cended_at%2Cname%2Cstatus%2Cproduct_compilation_id%2Cprice_value%2Cprice_rounding_method%2CextraRegions`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return {};
            })

        const data = {
            ...regionalPrice,
            started_at_date: moment(regionalPrice?.started_at * 1000).format('YYYY-MM-DD'),
            started_at_hour: moment(regionalPrice?.started_at * 1000).format('HH:mm'),
            ended_at_date: moment(regionalPrice?.ended_at * 1000).format('YYYY-MM-DD'),
            ended_at_hour: moment(regionalPrice?.ended_at * 1000).format('HH:mm'),
        }

        this.setState({
            regionalPrice: data,
        })
    }

    //Создание новоой региональный цены
    createRegionalPrice = async (routeInEdit = false) => {
        const formData = this._getDataForm();

        const data = await agent.post(`/admin/api-shop-price-rule`, formData)
            .then((res) => res.data)
            .catch((err) => {
                return {error: err.response};
            })
        if (data.error) {
            Notification({
                message: "Ошибка добавления региональный цены",
                type: NotificationTypes.error
            });

            return
        }

        Notification({
            message: `Региональная цена успешно добавлена`,
            type: NotificationTypes.success,
        })

        if (routeInEdit) {
            this.props.history.push(`/regionalPrices/edit/${data.id}`);
        }

    }

    //Получение сервисов
    getServices = async () => {
        const service = await agent.get(`/admin/api-shop-price-rule/services?ngrestCallType=services&fields=id`)
            .then((res) => {
                return res.data.service;
            })
            .catch((err) => {
                return {};
            })

        this.setState({
            productCompilationId: service.product_compilation_id.selectdata,
            priceRoundingMethod: service.price_rounding_method.selectdata,
            extraRegions: service.extraRegions.relationdata.items,
            isLoading: false,
        })
    }

    //Получение данных из формы
    _getDataForm = () => {
        const formData = {};
        const main = this.refCreateForm.current.values || {};

        //----------------------------------------------------------------------
        const endedDate = main.ended_at_date + ' ' + main.ended_at_hour;
        const startedDate = main.started_at_date + ' ' + main.started_at_hour;

        //----------------------------------------------------------------------
        formData.ended_at = Number(moment(endedDate).format("X"));
        formData.extraRegions = main.extraRegions;
        formData.name = main.name;
        formData.price_rounding_method = Number(main.price_rounding_method);
        formData.price_value = Number(main.price_value);
        formData.product_compilation_id = Number(main.product_compilation_id);
        formData.started_at = Number(moment(startedDate).format("X"));
        formData.status = main.status ? 1 : 0;

        return formData;
    }

    //Сброс полей формы
    _resetForm = () => {
        const {isCreate, regionalPrice} = this.state;
        if (isCreate) {
            this.refCreateForm.current.setValues(initialValues);
        } else {
            this.refCreateForm.current.setValues(regionalPrice);
        }
    }

    //Логика возвращения обратно на страницу региональных цен
    _routeRegionalPricePage = () => {
        this.props.history.push(`/regionalPrices`)
    }

    render() {
        const {
            regionalPrice,

            productCompilationId,
            priceRoundingMethod,
            extraRegions,

            regionalPriceId,

            isLoading,
            isCreate,
        } = this.state;
        return (
            <>
                <HeaderRegionalPriceCreate
                    isCreate={isCreate}

                    onBack={this._routeRegionalPricePage}
                />

                <Box>
                    <ControlRegionalPriceCreate
                        onBack={this._routeRegionalPricePage}
                        onReset={this._resetForm}
                        onCreate={this.createRegionalPrice}
                    />

                    <Box px={2} py={4} bgcolor="white" borderRadius={2}>

                        {Boolean(!isLoading) && (
                            <CreateForm
                                initialValues={initialValues}
                                innerRef={this.refCreateForm}
                                regionalPrice={regionalPrice}
                                productCompilationId={productCompilationId}
                                priceRoundingMethod={priceRoundingMethod}
                                extraRegions={extraRegions}
                                isCreate={isCreate}
                            />
                        )}

                        {Boolean(isLoading) && (
                            <>Loading</>
                        )}
                    </Box>
                </Box>
            </>
        );
    }
}

export default RegionalPriceCreate;