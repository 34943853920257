import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Grid,
	Button,
	Typography,
} from "@mui/material";

class DialogOldVersion extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false
		}
	}

	open = () => {
		this.setState({
			isOpen: true
		})
	}
	close = () => {
		this.setState({
			isOpen: false
		})
	}

	updatePage = () => {
		window.location.reload();
	}

	render () {
		return (
			<Dialog
				open={this.state.isOpen}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>
					<Typography variant="h3">Обновить</Typography>
				</DialogTitle>

				<DialogContent>
					<Typography variant="h5">
						Система была обновлена. Пожалуйста, сохраните свои изменения. (Если нажать «отмена», то форма появится снова через 1 минуту.)
					</Typography>
				</DialogContent>

				<DialogActions>
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item>
							<Button variant="outlined" onClick={this.close}>Закрыть</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" onClick={this.updatePage}>
								Обновить страницу
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

export default DialogOldVersion