import React from "react";

import TextField from "./TextField";
import Checkbox from "./Checkbox";
import Characteristic from "./Characteristic";

class FilterBase extends React.PureComponent {
    render() {
        const {
            type,
            group
        } = this.props;

        return (
            <>

                {Boolean(group === "common" && (type === "text" || type === "string" || type === "common" || type === "number")) && (
                    <TextField {...this.props}/>
                )}

                {Boolean(type === "checkbox") && (
                    <Checkbox {...this.props}/>
                )}


                {Boolean(group === "characteristic") && (
                    <Characteristic {...this.props}/>
                )}

            </>
        )
    }
}

export default FilterBase
