import React, {Component} from 'react';
import agent from "../../../agent/agent";
import HeaderRegionalPriceEdit from "./components/HeaderRegionalPriceEdit";
import {ControlRegionalPriceEdit, EditForm} from "./components";
import {Box} from "@mui/material";
import moment from "moment";
import {DialogConfirmation} from "../../../components";
import {Notification, NotificationTypes} from "../../../common/Notification";

class RegionalPriceEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionalPrice: {},
            productCompilationId: [],
            priceRoundingMethod: [],
            extraRegions: [],

            regionalPriceId: props.match.params.id,

            isLoading: true,
        }

        this.refEditForm = React.createRef();
        this.refDialogConfirmation = React.createRef();

    }

    componentDidMount = async () => {
        await this.getRegionalPrice();
        await this.getServices();
    }


    getRegionalPrice = async () => {
        const {regionalPriceId} = this.state;

        const regionalPrice = await agent.get(`/admin/api-shop-price-rule/${regionalPriceId}?ngrestCallType=update&expand=extraRegions&fields=id%2Cstarted_at%2Cended_at%2Cname%2Cstatus%2Cproduct_compilation_id%2Cprice_value%2Cprice_rounding_method%2CextraRegions`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return {};
            })

        const data = {
            ...regionalPrice,
            started_at_date: moment(regionalPrice?.started_at * 1000).format('YYYY-MM-DD'),
            started_at_hour: moment(regionalPrice?.started_at * 1000).format('HH:mm'),
            ended_at_date: moment(regionalPrice?.ended_at * 1000).format('YYYY-MM-DD'),
            ended_at_hour: moment(regionalPrice?.ended_at * 1000).format('HH:mm'),
        }

        this.setState({
            regionalPrice: data,
        })
    }

    //Логика для сохранения изменений региональный цены
    editRegionalPrice = async () => {
        const {regionalPriceId} = this.state;
        const formData = this._getDataEdit();

        const data = await agent.put(`/admin/api-shop-price-rule/${regionalPriceId}`, formData)
            .then((res) => res.data)
            .catch((err) => {
                return {error: err.response};
            })
        if (data.error) {
            Notification({
                message: "Ошибка изменения региональный цены",
                type: NotificationTypes.error
            });

            return
        }

        Notification({
            message: `Региональная цена '${this.state.regionalPrice.name}' успешно изменена`,
            type: NotificationTypes.success,
        })
    }

    getServices = async () => {
        const service = await agent.get(`/admin/api-shop-price-rule/services?ngrestCallType=services&fields=id`)
            .then((res) => {
                return res.data.service;
            })
            .catch((err) => {
                return {};
            })

        this.setState({
            productCompilationId: service.product_compilation_id.selectdata,
            priceRoundingMethod: service.price_rounding_method.selectdata,
            extraRegions: service.extraRegions.relationdata.items,
            isLoading: false,
        })
    }

    _getDataEdit = () => {
        const formData = {};
        const main = this.refEditForm.current.values || {};

        //----------------------------------------------------------------------
        const endedDate = main.ended_at_date + ' ' + main.ended_at_hour;
        const startedDate = main.started_at_date + ' ' + main.started_at_hour;

        //----------------------------------------------------------------------
        formData.ended_at = Number(moment(endedDate).format("X"));
        formData.extraRegions = main.extraRegions;
        formData.id = this.state.regionalPriceId;
        formData.name = main.name;
        formData.price_rounding_method = Number(main.price_rounding_method);
        formData.price_value = Number(main.price_value);
        formData.product_compilation_id = Number(main.product_compilation_id);
        formData.started_at = Number(moment(startedDate).format("X"));
        formData.status = main.status ? 1 : 0;

        return formData;
    }

    //Удаление региональный цены
    _deleteRegionalPrice = async (isConfirm) => {
        const id = this.state.regionalPriceId;

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотите удалить региональную цену?",
                acceptButtonTitle: "Да, удалить",
                cancelButtonTitle: "Отменить",
                acceptButtonAction: this._deleteRegionalPrice.bind(this, true),
            })

            return
        }

        const responseDelete = await agent.delete(`/admin/api-shop-price-rule/${id}`)
            .then((res) => res.data)
            .catch((err) => {
                return {error: err.response}
            });

        if (responseDelete.error) {
            Notification({
                message: "Ошибка удаления региональный цены",
                type: NotificationTypes.error
            });

            return
        }

        Notification({
            message: `Региональная цена '${this.state.regionalPrice.name}' успешно удалена`,
            type: NotificationTypes.success,
        })

        this._routeRegionalPricePage();
    }

    //Сброс отредактированных данных
    _resetForm = () => {
        this.refEditForm.current.setValues(this.state.regionalPrice);
    }

    //Дублирование региональной цены
    _duplicateRegionalPrice = () => {
        this.props.history.push(`/regionalPrices/create/${this.state.regionalPriceId}`)
    }

    //Логика возвращения обратно на страницу региональных цен
    _routeRegionalPricePage = () => {
        this.props.history.push(`/regionalPrices`)
    }

    render() {
        const {
            regionalPrice,
            productCompilationId,
            priceRoundingMethod,
            extraRegions,

            isLoading
        } = this.state;

        return (
            <>
                <HeaderRegionalPriceEdit
                    regionalPrice={regionalPrice}

                    onBack={this._routeRegionalPricePage}
                />

                <Box>
                    <ControlRegionalPriceEdit
                        onBack={this._routeRegionalPricePage}
                        onReset={this._resetForm}
                        onDelete={this._deleteRegionalPrice}
                        onDuplicate={this._duplicateRegionalPrice}
                        onSave={this.editRegionalPrice}
                    />
                    <Box px={2} py={4} bgcolor="white" borderRadius={2}>

                        {Boolean(!isLoading) && (
                            <EditForm
                                innerRef={this.refEditForm}
                                productCompilationId={productCompilationId}
                                priceRoundingMethod={priceRoundingMethod}
                                extraRegions={extraRegions}
                                initialValues={regionalPrice}
                            />
                        )}

                        {Boolean(isLoading) && (
                            <>Loading</>
                        )}

                    </Box>
                </Box>

                <DialogConfirmation ref={this.refDialogConfirmation}/>
            </>
        );
    }
}

export default RegionalPriceEdit;