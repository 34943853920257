import React from "react";
import {
	Box,
	Grid,
	Collapse
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Formik
} from "formik";
import SectionItems from "./SectionItems";
import agent from "../../../../../agent/agent";


class Characteristics extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			initCharacteristics: [],
		};
	}

	componentDidMount = async () => {
		await this.getInitCharacteristics();
	}
	componentDidUpdate = async (prevProps) => {
		if (prevProps.visible !== this.props.visible && this.props.visible) {
			await this.getInitCharacteristics();
		}
	}

	getInitCharacteristics = async () => {
		const { productId } = this.props;
		const { initCharacteristics } = this.state;
		if (initCharacteristics.length > 0) {
			return
		}

		const res = await agent.get(`/api/admin/products/${ productId }/characteristics`).then((res) => {
			return res?.data || []
		}).catch(() => {
			return []
		});
		this.setState({
			initCharacteristics: res
		});
		this.props.onChangeInitProductCharacteristics(res);
	}

	changeForm = ({ target }, {isUpdateAll} = {}) => {
		const { name, value } = target;

		let newForm = this.props.innerRef?.current?.values || {};
		let newExtraValues = {...newForm.extraValues};
		newExtraValues[name] = value;
		newForm.extraValues = newExtraValues;

		this.props.innerRef?.current.setValues(newForm);
	}

	render () {
		const {
			innerRef,
			initialValues,

			sections,

			classes
		} = this.props;
		const {
			initCharacteristics,
		} = this.state;
		const isExchangeHash = Boolean(initialValues?.exchange_hash);

		return (
			<Formik
				innerRef={innerRef}
				initialValues={initialValues}
				onSubmit={() => {}}
			>
				{(props) => {
					const { values } = props;

					return (
						<Box>
							<Grid container spacing={2}>
								{(sections || [])
									.sort((a, b) => {
										if (Number.parseFloat(a.sort || 1) > Number.parseFloat(b.sort || 1)) {
											return -1
										}
										if (Number.parseFloat(a.sort || 1) < Number.parseFloat(b.sort || 1)) {
											return 1
										}
										return 0
									})
									.map((section, index) => (
									<Grid key={`section-${values.characteristic_group_id}-${ index }`} item xs={12}>
										<Box className={classes.section}>
											<Box className={classes.sectionHead}>
												{ section?.name || "" }
											</Box>
											<Box className={classes.sectionBody}>
												<SectionItems
													extraValues={values.extraValues}
													onChange={this.changeForm}
													sectionIndex={index}
													sectionID={section.id}
													isExchangeHash={isExchangeHash}
													initCharacteristics={(initCharacteristics || []).find((t) => t.name === section.name)?.characteristics || []}

													{...section}
												/>
											</Box>
										</Box>
									</Grid>
								))}
							</Grid>
						</Box>
					)
				}}
			</Formik>
		)
	}
}

const styles = {
	section: {
		backgroundColor: "white",
		borderRadius: 6,
		overflow: "hidden"
	},
	sectionHead: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "15px 24px",
		boxSizing: "border-box",
		userSelect: "none",

		fontSize: 18,
		lineHeight: "24px",
		color: "black",
		fontWeight: "500"
	},
	sectionBody: {
		backgroundColor: "white",
		padding: "15px 24px",
	},
}
Characteristics = withStyles(styles)(Characteristics)

export default Characteristics
