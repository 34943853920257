import React from "react";
import {
	Box,
	Grid,
	TextField,
	Typography
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import clsx from "clsx";
import {IMaskInput} from "react-imask";

class BaseEditPlugin extends React.PureComponent {
	onAccept = (name, value) => {
		this.props.onChange({
			target: {
				name,
				value
			}
		});
	}
	onChange = () => {}

	render() {
		const {
			classes,
			label,
			isOnlyForm,
			valueGte,
			valueLte,
			name
		} = this.props;

		return (
			<Box className={classes.root}>
				<Typography className={classes.label}>
					{label}
				</Typography>
				<Box className={clsx({
					[classes.content]: true,
					[classes.contentMultiline]: this.props.multiline,
				})}>
					<Grid container spacing="8px" alignItems="center" wrap="nowrap">
						<Grid item>
							<TextField
								value={valueGte}
								name={`filter[${ name }][gte]`}

								placeholder="Введите"
								fullWidth

								inputProps={{
									onAccept: this.onAccept.bind(this, `filter[${ name }][gte]`)
								}}
								InputProps={{
									inputComponent: CustomInputAmount
								}}

								onChange={this.onChange}
							/>
						</Grid>
						<Grid item>
							<Typography variant="h3">-</Typography>
						</Grid>
						<Grid item>
							<TextField
								placeholder="Введите"
								fullWidth

								value={valueLte}
								name={`filter[${ name }][lte]`}

								inputProps={{
									onAccept: this.onAccept.bind(this, `filter[${ name }][lte]`),
								}}
								InputProps={{
									inputComponent: CustomInputAmount
								}}

								onChange={this.onChange}
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		)
	}
}
const CustomInputAmount = React.forwardRef(function TextMaskCustom(props, ref) {
	const {...other} = props;

	return (
		<IMaskInput
			{...other}
			mask={Number}
			thousandsSeparator=" "
			radix="."

			inputRef={ref}
			unmask={true}
		/>
	);
})

const styles = {
	root: {
		display: "flex",

		height: "100%",
		width: "100%",
		backgroundColor: "#F4F4F4",
		borderRadius: 6,
		padding: "10px 12px",
		boxSizing: "border-box"
	},

	label: {
		flex: 1,
		fontSize: 16,
		lineHeight: "20px",
		fontWeight: "500",
		color: "#000000"
	},

	content: {
		width: "100%",
		maxWidth: 260
	},
	contentMultiline: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		}
	},
}
BaseEditPlugin = withStyles(styles)(BaseEditPlugin)

export default BaseEditPlugin
