import React from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    PagesVisibleColumns
} from "../../../../../components";

const AttributesContent = (props) => {
    const {
        data,
        onChange,
        onResetDefaultSettings
    } = props;
    const classes = useStyles();
    const [search, setSearch] = React.useState("");

    const handleChangeData = ({target}, value) => {
        const {name} = target;

        let newData = [...data];
        newData[name].omit = !value;
        newData[name].isCustom = true;

        onChange(newData)
    }
    const handleEnableAll = () => {
        let newData = [...data];
        Object.keys(newData).map((key) => {
            newData[key].omit = false;
        })
        onChange(newData)
    }
    const handleEverythingOff = () => {
        let newData = [...data];
        Object.keys(newData).map((key) => {
            newData[key].omit = true;
        })
        onChange(newData)
    }
    const handleResetToDefault = async () => {
        let newData = [...data];
        Object.keys(newData).map((key) => {
            if (newData[key].isCustom) {
                newData[key].omit = true;
            }
            newData[key].isCustom = false;
        })
        onResetDefaultSettings(newData)
    }

    const handleChangeSearch = ({ target }) => {
        setSearch(target.value)
    }

    const _labelVisibleColumns = () => {
        const total = Object.keys(data).length;
        const onCount = Object.keys(data).filter((key) => !data[key].omit).length;

        return `Включенно ${onCount} из ${total}`
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.head}>
                <Typography className={classes.headTitle}>Колонки</Typography>
                <Box className={classes.controls}>
                    <TextField
                      value={search}
                      sx={{marginRight: 2}}
                      size="small"
                      placeholder="Введите наименование фильтра"
                      onChange={handleChangeSearch}
                    />
                    <Typography className={classes.totalVisible}>
                        {_labelVisibleColumns()}
                    </Typography>
                    <Button variant="text" onClick={handleEnableAll}>Включить всё</Button>
                    <Button variant="text" onClick={handleEverythingOff}>Выключить всё</Button>
                    <Button variant="text" onClick={handleResetToDefault}>Сбросить</Button>
                </Box>
            </Box>
            <Box className={classes.body}>
                <Grid container spacing={1}>
                    {Object.keys(data).map((key) => {
                        if (data[key]?.nameField === "id") {
                            return null
                        }
                        const item = data[key];
                        const lowerLabel = (item.label).toLowerCase();
                        const lowerSearch = search.toLowerCase();

                        if (!!lowerSearch && lowerLabel.indexOf(lowerSearch) === -1) {
                            return null
                        }
                        // if (item.is_readonly) {
                        //     return null
                        // }

                        return (
                          <Grid className={classes.attributeCard} key={`attribute-${key}`} item>
                              <PagesVisibleColumns
                                name={key}
                                title={data[key]?.label}
                                value={data[key]?.omit}

                                onChange={handleChangeData}
                              />
                          </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },

    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: 14,
        borderBottom: "1px solid #E6E6E6",
        marginBottom: 16
    },
    headTitle: {
        fontSize: 18,
        lineHeight: "23px",
        fontWeight: "500",
        color: "#000000"
    },

    controls: {
        display: "flex",
        alignItems: "center",
        "& > *": {
            marginLeft: 8,
            textTransform: "initial",
            fontWeight: "400",

            "&:first-child": {
                marginLeft: 0,
                marginRight: 12
            }
        }
    },
    totalVisible: {
        fontSize: 14,
        lineHeight: "18px",
        color: "#787878"
    },

    body: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        // paddingRight: "12px",

        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: "none",
        },
        '&::-webkit-scrollbar-thumb': {
            background: "#0057FF",
            borderRadius: "8px",
        }
    },

    attributeCard: {
        width: "14.2%",
    }
}))

export default React.memo(AttributesContent)
