import React from "react";
import {
	TextField,
	Autocomplete
} from "@mui/material";
import PropTypes from "prop-types";
import agent from "../../agent/agent";

class AutocompleteCities extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			options: [],

			isLoad: false
		}
	}

	componentDidMount = async () => {
		await this.getOptions();
	}

	getOptions = async () => {
		const options = await agent.get('/admin/api-cms-website/list?ngrestCallType=index').then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		})

		this.setState({
			options
		})
	}

	onChange = (params, value) => {
		value = (value || []).map((t) => t?.id || t);
		value = value.reduce((acc, item) => {
			if (acc.includes(item)) {
				return acc; // если значение уже есть, то просто возвращаем аккумулятор
			}
			return [...acc, item]; // добавляем к аккумулятору и возвращаем новый аккумулятор
		}, []);

		const event = {
			target: {
				name: this.props.name,
				value: value || []
			}
		}

		this.props.onChange(event);
	}

	_renderInput = (params) => {
		const {
			label,
			caption,
			error,
			helperText
		} = this.props;

		return (
			<TextField
				{...params}

				label="Выбор сайта"
				error={error}
				helperText={helperText || caption || ""}
			/>
		)
	}
	_filterOptions = (options, state) => {
		const searchLower = (state?.inputValue || "").toLowerCase();
		return [...options].filter((item) => {
			const cityNameLower = (item?.name || "").toLowerCase();

			return Boolean( cityNameLower.indexOf(searchLower) > -1 )
		})
	}
	_getOptionLabel = (option) => {
		const { options } = this.state;
		if (typeof option === "number") {
			option = options.find((t) => t.id === option);
		}
		return option?.name || ''
	}

	render () {
		const {
			options
		} = this.state;
		const {
			value
		} = this.props;

		return (
			<form style={{width: "100%"}} action="javascript:void(0);" autoComplete={false}>
				<Autocomplete
					value={value}

					options={options}

					size="small"

					multiple={true}
					fullWidth={true}
					autoComplete={true}
					disablePortal={true}
					filterSelectedOptions={true}
					getOptionLabel={this._getOptionLabel}
					filterOptions={this._filterOptions}

					renderInput={this._renderInput}

					onChange={this.onChange}
				/>
			</form>
		)
	}
}

AutocompleteCities.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	caption: PropTypes.string,
}

export default AutocompleteCities
