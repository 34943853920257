// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import PhotoCropping from './PhotoCropping';
import {getShopCharacteristicGroups} from "../../../states/products";

export default compose(
  connect(
    state => ({
      shopCharacteristicGroups: state?.products?.shopCharacteristicGroups || []
    }),
    dispatch => ({
      getShopCharacteristicGroups: () => dispatch(getShopCharacteristicGroups())
    }),
  ),
)(PhotoCropping);
