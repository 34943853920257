import React from "react";
import {
    Box,
    Typography,
    TextField,

    Autocomplete
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";
import agent from "../../agent/agent";

class BaseSelectArray extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
        }
    }

    getOptions = async () => {
        const { isApiOptions } = this.props;
        if (!isApiOptions) {
            return
        }

        if (this.state.options.length > 0) {
            return;
        }

        const res = await agent.get(`/api/admin/products/characteristic-variants-by-id/`)
    }

    _options = () => {
        const { options: propsOptions, isApiOptions } = this.props;
        const { options: stateOptions } = this.state;
        const options = Boolean(isApiOptions) ? stateOptions : propsOptions;
        const isArray = Boolean(Object.prototype.toString.call(options) === '[object Array]');
        if (!isArray) {
            return Object.keys({...options}).map((key) => {
                return {
                    value: key,
                    label: options[key]
                }
            })
        }
        return []
    }
    _filterOptions = (list, params) => {
        const search = (params?.inputValue || "").toLowerCase();

        return [...list].filter((t) => {
            return Boolean((t.label || '').toLowerCase().includes(search))
        })
    }
    _getOptionLabel = (option) => {
        const label = option?.label || "";
        if (!!label) {
            return label
        }

        const options = this._options();
        return options.find((t) => t.value === option)?.label || ""
    }

    onChange = ({ target }, value) => {
        if (this.props.multiple) {
            const event = {
                target: {
                    value: [...(value || [])].map((t) => t?.value || t),
                    name: this.props.nameField
                }
            };
            this.props.onChange(event);
            return
        }

        const event = {
            target: {
                value: value?.value,
                name: this.props.nameField
            }
        };
        this.props.onChange(event);
    }

    render() {
        const {
            classes,
            label,
            isOnlyForm,

            ...otherProps
        } = this.props;
        const options = this._options();

        if (!!isOnlyForm) {
            return (
              <Autocomplete
                value={otherProps.value}
                multiple={otherProps.multiple}

                disablePortal
                fullWidth
                size="small"
                options={options || []}
                renderInput={(params) => <TextField {...params} placeholder="Выберите" label={label}/>}
                autoComplete={true}

                filterOptions={this._filterOptions}
                getOptionLabel={this._getOptionLabel}

                onChange={this.onChange}
              />
            )
        }

        return (
            <Box className={classes.root}>
                <Typography className={classes.label}>
                    { label }
                </Typography>
                <Box className={classes.content}>
                    <Autocomplete
                        value={otherProps.value}

                        disablePortal
                        fullWidth
                        size="small"
                        options={options || []}
                        renderInput={(params) => <TextField {...params} placeholder="Выберите" />}
                        autoComplete={true}

                        filterOptions={this._filterOptions}
                        getOptionLabel={this._getOptionLabel}

                        onChange={this.onChange}
                        onOpen={this.getOptions}
                    />
                </Box>
            </Box>
        )
    }
}

const styles = {
    root: {
        display: "flex",

        height: "100%",
        width: "100%",
        backgroundColor: "#F4F4F4",
        borderRadius: 6,
        padding: "10px 12px",
        boxSizing: "border-box"
    },

    label: {
        flex: 1,
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: "500",
        color: "#000000"
    },
    content: {
        width: "100%",
        maxWidth: 260
    }
}
const StylesBaseSelectArray = withStyles(styles)(BaseSelectArray)

export default StylesBaseSelectArray
