// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import CharacteristicCreate from './CharacteristicCreate';

export default compose(
    connect(
        state => ({}),
        dispatch => ({}),
    ),
)(CharacteristicCreate);
