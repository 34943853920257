import React from "react";
import {
	TextField,
	Autocomplete
} from "@mui/material";
import PropTypes from "prop-types";
import agent from "../../agent/agent";

class AutocompleteCities extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			options: [],

			isLoad: false
		}
	}

	componentDidMount = async () => {
		await this.getOptions();
	}

	getOptions = async () => {
		const options = await agent.get('/api/cities').then((res) => {
			return res.data?.cities || []
		}).catch(() => {
			return []
		})

		this.setState({
			options
		})
	}

	onChange = (params, value) => {
		const event = {
			target: {
				name: this.props.name,
				value: value?.id || null
			}
		}

		this.props.onChange(event);
	}

	_valueConvertor = () => {
		const { value } = this.props;
		const { options } = this.state;

		return options.find((t) => t.id === value) || null
	}
	_renderInput = (params) => {
		const {
			label,
			caption
		} = this.props;

		return (
			<TextField
				{...params}

				label={label}
				helperText={caption || ""}
			/>
		)
	}
	_isOptionEqualToValue = (item) => {
		return Boolean(this.props.value === item?.id)
	}
	_filterOptions = (options, state) => {
		const searchLower = (state?.inputValue || "").toLowerCase();
		return [...options].filter((item) => {
			const cityNameLower = (item?.name || "").toLowerCase();

			return Boolean( cityNameLower.indexOf(searchLower) > -1 )
		})
	}

	render () {
		const {
			options
		} = this.state;
		const {
			value
		} = this.props;

		return (
			<form action="javascript:void(0);" autoComplete={false}>
				<Autocomplete
					value={this._valueConvertor()}

					options={options}

					size="small"

					fullWidth={true}
					autoComplete={true}
					disablePortal={true}
					filterSelectedOptions={true}
					getOptionLabel={(t) => t.name}
					filterOptions={this._filterOptions}
					isOptionEqualToValue={this._isOptionEqualToValue}

					renderInput={this._renderInput}

					onChange={this.onChange}
				/>
			</form>
		)
	}
}

AutocompleteCities.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	caption: PropTypes.string,
}

export default AutocompleteCities