import {palette} from "../common";

export default {
    styleOverrides: {
        root: {
            backgroundColor: 'transparent',
            borderCollapse: 'separate',
            borderSpacing: "0 3px",
            '& .MuiTableHead-root': {
                backgroundColor: palette.primary.main,
                '& .MuiTableCell-head': {
                    fontWeight: 700,
                    fontSize: 15,
                    color: "#FFFFFF",
                    borderRight: '1px solid #62B4FF',
                    borderLeft: '1px solid #62B4FF',
                    "&:first-child": {
                        borderLeft: 0,
                        borderRadius: '6px 0 0 0',
                    },
                    "&:last-child": {
                        borderRight: 0,
                        borderRadius: '0 6px 0 0',
                    }
                }
            },
            '& .MuiTableBody-root': {
                '& .MuiTableRow-root': {
                    height: "100%",
                    position: "relative",
                    backgroundColor: "#fcfcfc",
                    cursor: "pointer",

                    "& > .MuiButtonBase-root": {
                        width: 52,
                        height: 52,
                        position: 'sticky',
                        right: 0,
                        borderRadius: 5,
                        background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%)",
                    },

                    "&:nth-child(2n)": {
                        backgroundColor: "#F4F4F4",
                        "& > .MuiButtonBase-root": {
                            background: "linear-gradient(90deg, rgba(244,244,244, 0) 0%, rgba(244,244,244, 1) 100%)",
                            // backgroundColor: "rgba(244,244,244, 0.8)",
                        }
                    },
                },
                '& .MuiTableCell-body': {
                    height: "100%",
                    borderRight: '1px solid #E6E6E6',
                    borderLeft: '1px solid #E6E6E6',
                    fontWeight: 400,
                    fontSize: 14,
                    color: '#434343',
                    borderBottom: 0,
                    padding: "5px 12px",
                    "&:first-child": {
                        borderLeft: 0,
                    },
                    "&:last-child": {
                        borderRight: 0,
                    },
                    "& .MuiCheckbox-root": {
                        color: "#C8C8C8",
                    },
                    "& .Mui-checked": {
                        color: palette.primary.main,
                    },
                }
            },
          "& .MuiTableRow-hover:hover": {
            cursor: "pointer",
            "& > * ": {
              background: "#cbd2f8!important"
            }
          }
        }
    }
}
