import React from 'react';
import {Autocomplete, Box, Chip, CircularProgress, createFilterOptions, Grid, TextField} from "@mui/material";

import {Formik} from "formik";
import agent from "../../../../../agent/agent";

const filter = createFilterOptions();
let timeout = null;

const TagsBenefitsForm = (props) => {
    const {
        initialValues,

        searchTags,

        innerRef,

        searchLoading,

        onChangeSearch,
        clearSearchTagValue,
    } = props;


    const createNewTag = async (name) => {
        const body = {
            name: name,
        };

        const data = await agent.post(`/admin/api-shop-tags`, body)
            .then(res => res.data)
            .catch(err => {
                return [];
            })

        const tag = {
            id: data.id,
            name: data.name,
        }

        return tag;
    }

    const handleSubmit = () => {
    };

    const handleChangeAutocomplete = async (value) => {
        let newForm = {...innerRef.current.values};
        const create = !!value.find(val => val.inputValue);

        if (!create) {
            const valArr = value.map(val => val.name || val);
            let newValue = valArr.filter(val => !newForm.extraTags.includes(val));


            newForm.extraTags = [...newForm.extraTags, newValue[0]];

            innerRef.current.setValues(newForm);
            clearSearchTagValue();
        } else if (create) {
            const valArr = value.map(val => val.inputValue || val);
            let postValue = valArr.filter(val => !newForm.extraTags.includes(val));
            const newValue = await createNewTag(postValue[0]);

            postValue = [];
            newForm.extraTags = [...newForm.extraTags, newValue.name];

            innerRef.current.setValues(newForm);
            clearSearchTagValue();
        }
    }


    const handleDeleteTag = (index) => {
        let newForm = {...innerRef.current.values};
        newForm.extraTags.splice(index, 1);
        innerRef.current.setValues(newForm);
    }

    return (
        <>
            <Box px={2} py={2} bgcolor="white" borderRadius={2}>
                <Formik
                    innerRef={innerRef}
                    initialValues={initialValues}

                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched
                        } = props;
                        return (
                            <Grid container alignItems="center">
                                <Grid item xs={1}>
                                    Теги
                                </Grid>
                                <Grid item xs>
                                    <Autocomplete
                                        multiple
                                        value={values.extraTags}
                                        options={searchTags}
                                        loading={searchLoading}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => handleChangeAutocomplete(value)}
                                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                        renderTags={(tagValue) =>
                                            tagValue.map((option, index) => (
                                                <Chip
                                                    size="small"
                                                    color="primary"
                                                    label={option}
                                                    onDelete={() => handleDeleteTag(index)}
                                                />
                                            ))
                                        }
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const {inputValue} = params;

                                            const isExisting = options.some((option) => inputValue.toLowerCase() === option.name.toLowerCase());
                                            if (inputValue !== '' && inputValue.length >= 2 && !isExisting && !searchLoading) {
                                                filtered.push({
                                                    inputValue,
                                                    name: `Добавить тег "${inputValue}"`,
                                                });
                                            }

                                            return filtered;

                                        }}
                                        freeSolo
                                        size="small"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onInput={(e) => onChangeSearch(e)}
                                                InputLabelProps={{shrink: true}}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: searchLoading ?
                                                        <CircularProgress color="primary" size={20}/> : null
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        );
                    }}
                </Formik>
            </Box>
        </>
    );
};

export default TagsBenefitsForm;