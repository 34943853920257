import React from "react";
import {
	Box,
	TextField,
	Typography
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	AutocompleteCities
} from "../../../../../components";

class AdminContent extends React.PureComponent {

	changeData = ({ target }) => {
		const { name, value } = target;

		let data = {...this.props.data}
		data[name] = value;

		this.props.onChange(data);
	}

	render () {
		const {
			data,
			classes
		} = this.props;

		return (
			<Box className={classes.root} px={3} py={2} bgcolor="white" borderRadius="8px">

				<Typography variant="h4" sx={{marginBottom: "20px"}}>Комментарий</Typography>

				<TextField
					value={data.admin_note}

					fullWidth
					name="admin_note"
					placeholder="Введите сообщение"

					multiline
					rows={6}

					onChange={this.changeData}
				/>

				<Box mt="30px"/>

				<AutocompleteCities
					label="Город для региональных цен"
					caption="После изменения города, необходимо заного заполнить товары"

					value={data.user_city_id}
					name="user_city_id"
					onChange={this.changeData}
				/>

			</Box>
		)
	}
}

const styles = {
	root: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		}
	}
}
AdminContent = withStyles(styles)(AdminContent);

export default AdminContent
