import React, {Component} from 'react';
import {
    Box,
    Checkbox,
    IconButton, Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import moment from "moment";
import {MoreVert as MoreIcon} from "@mui/icons-material";
import {getImageId} from "../../../../../common/FilesUpload";

const TableComponent = (props) => {
    const {
        regionalPrices,

        filter,
        pagination,

        isLoading,

        onChangeFilter,
        onChangeRegionalPriceStatus,
        routePageEdit
    } = props;

    const handleClickRow = (row, event) => {
        const isDisableRoute = Boolean(event.target.closest(".disable-route"));
        if (isDisableRoute) {
            return
        }

        routePageEdit(row.id);
    }

    const handleChangePage = (event, page) => {
        let newFilter = {...filter};
        newFilter.page = page;

        onChangeFilter(newFilter, true);
    }

    const handleChangeChecked = (row, e) => {
        e.stopPropagation();
        const checked = e.target.checked ? 1 : 0;
        onChangeRegionalPriceStatus(row, checked);
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Начало</TableCell>
                            <TableCell>Окончание</TableCell>
                            <TableCell>Название</TableCell>
                            <TableCell>Подборка товаров</TableCell>
                            <TableCell>Коэффициент для цены (%)</TableCell>
                            <TableCell>Способ округления цены</TableCell>
                            <TableCell>Статус</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Boolean(isLoading) ? (
                            <>
                                {[0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    )
                                )}
                            </>
                        ) : (
                            <>
                                {
                                    regionalPrices.map((row, index) => (
                                            <TableRow
                                              hover
                                                key={`row-item-${index}`}
                                                onClick={(event) => handleClickRow(row, event)}
                                            >
                                                <TableCell>{row.id || ''}</TableCell>
                                                <TableCell>{moment(row.started_at * 1000).format('DD.MM.YYYY HH:mm')}</TableCell>
                                                <TableCell>{moment(row.ended_at * 1000).format('DD.MM.YYYY HH:mm')}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.product_compilation_id}</TableCell>
                                                <TableCell>{row.price_value}</TableCell>
                                                <TableCell>{row.price_rounding_method}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.status}

                                                        onClick={(e) => handleChangeChecked(row, e)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={2}>
                <Pagination
                    page={Number(filter.page)}
                    count={pagination.count || 1}

                    onChange={handleChangePage}
                />
            </Box>
        </>
    );
};

class TableImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageObj: [],
        }
    }

    componentDidMount = async () => {
        await this.convertImage();
    }

    //Конвертация изображения
    convertImage = async () => {
        const {imageId} = this.props;
        let convertedImageObj;
        if (imageId) {
            convertedImageObj = await getImageId(imageId);
        }
        this.setState({imageObj: convertedImageObj});
    }

    render() {
        const {imageObj} = this.state;
        return (
            <img src={imageObj.source} style={{
                width: 124,
                height: 124,
                objectFit: "cover",
                borderRadius: 5,
            }} alt=""/>
        )
    }
}

export default TableComponent;
