import React from "react";
import {
	Box,
	Typography,
	TextField
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import clsx from "clsx";

class BaseString extends React.PureComponent {
	render() {
		const {
			classes,
			label,
			isOnlyForm,

			...otherProps
		} = this.props;

		if (!!isOnlyForm) {
			return (
				<TextField
					placeholder="Введите"
					fullWidth
					className={clsx({
						[classes.contentMultiline]: this.props.multiline,
					})}

					{...otherProps}
				/>
			)
		}

		return (
			<Box className={classes.root}>
				<Typography className={classes.label}>
					{label}
				</Typography>
				<Box className={clsx({
					[classes.content]: true,
					[classes.contentMultiline]: this.props.multiline,
				})}>
					<TextField
						placeholder="Введите"
						fullWidth

						{...otherProps}
					/>
				</Box>
			</Box>
		)
	}
}

const styles = {
	root: {
		display: "flex",

		height: "100%",
		width: "100%",
		backgroundColor: "#F4F4F4",
		borderRadius: 6,
		padding: "10px 12px",
		boxSizing: "border-box"
	},

	label: {
		flex: 1,
		fontSize: 16,
		lineHeight: "20px",
		fontWeight: "500",
		color: "#000000"
	},

	content: {
		width: "100%",
		maxWidth: 260
	},
	contentMultiline: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		}
	},
}
const StylesBaseString = withStyles(styles)(BaseString)

export default StylesBaseString
