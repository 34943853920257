import React from 'react';
import {Box, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {KeyboardBackspace as KeyboardBackspaceIcon} from "@mui/icons-material";

const HeaderRegionalPriceCreate = (props) => {
    const {
        isCreate,

        onBack,
    } = props;
    return (
        <Box mb={2}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Tooltip title="Вернуться в региональные цены">
                                <IconButton onClick={onBack}>
                                    <KeyboardBackspaceIcon color="primary"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h1">{isCreate ? "Создание региональной цены" : "Дублирование региональной цены"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HeaderRegionalPriceCreate;