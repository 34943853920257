import React from "react";
import {
    Box,
    Grid,

    Select,
    Checkbox,
    MenuItem,
    TextField,
    Typography,
    InputLabel,
    FormControl,
    FormHelperText,
    FormControlLabel, Autocomplete
} from "@mui/material";
import {
    Formik
} from "formik";
import * as Yup from "yup";
import {IMaskInput} from "react-imask";

const initialValues = {
    category_id: {
        value: null,
        apply: false
    },
    brand_id: {
        value: null,
        apply: false
    },
    is_hit: {
        value: null,
        apply: false
    },
    is_special: {
        value: null,
        apply: false
    },
    is_new: {
        value: null,
        apply: false
    },
    export_yml: {
        value: null,
        apply: false
    },
    export_avito: {
        value: null,
        apply: false
    },
    export_2gis: {
        value: null,
        apply: false
    },
    export_yula: {
        value: null,
        apply: false
    },
    price_new: {
        value: "",
        apply: false
    }
};

const MainForm = (props) => {
    const {
        innerRef,

        categories,
        brands
    } = props;

    const handleSubmit = () => {}

    const handleChange = ({ target }) => {
        const { name, value } = target;

        let newForm = {...innerRef?.current?.values || {}};
        let newValue = {...newForm[name]};
        newValue.value = value;

        newForm[name] = newValue;

        innerRef.current?.setValues(newForm);
    }
    const handleChangeAutocomplete = (value, name) => {
        let newForm = {...innerRef?.current?.values || {}};
        let newValue = {...newForm[name]};
        newValue.value = value;

        newForm[name] = newValue;

        innerRef.current?.setValues(newForm);
    }
    const handleChangeBoolean = ({ target }, value) => {
        const { name } = target;

        let newForm = {...innerRef.current.values};
        let newValue = {...newForm[name]};
        newValue.apply = value;

        newForm[name] = newValue;

        innerRef.current.setValues(newForm);
    }

    return (
        <Formik
            innerRef={innerRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => {
                const {
                    values,
                    errors,
                    touched
                } = props;

                return (
                    <Box px={2} py={4} bgcolor="white" borderRadius={2}>
                        <Grid container spacing={2}>

                            {/* Категория */}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>Категория</Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.category_id.value}
                                            name="category_id"
                                            fullWidth
                                            displayEmpty

                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value={null}>Выберите</MenuItem>
                                            {(categories || []).map((item, idx) => (
                                                <MenuItem key={`categories-${idx}`} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.category_id.apply} value={values.category_id.apply} name="category_id" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                            {/* Производитель */}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>Производитель</Typography>
                                    <Autocomplete
                                        fullWidth
                                        disableClearable
                                        disablePortal
                                        name="brand_id"
                                        placeholder="Выберите"
                                        options={brands}
                                        value={values.brand_id.value}
                                        id="value"
                                        size="small"
                                        onChange={(event, value) => handleChangeAutocomplete(value, 'brand_id')}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            fullWidth
                                        />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.brand_id.apply} value={values.brand_id.apply} name="brand_id" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                            {/* Хит продаж */}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>Хит продаж</Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.is_hit.value}
                                            name="is_hit"
                                            fullWidth
                                            displayEmpty

                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value={null}>Выберите</MenuItem>
                                            {(OptionsBoolean || []).map((item, idx) => (
                                                <MenuItem key={`is_hit_value-${idx}`} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.is_hit.apply} value={values.is_hit.apply} name="is_hit" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                            {/* Спецпредложение */}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>Спецпредложение</Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.is_special.value}
                                            name="is_special"
                                            fullWidth
                                            displayEmpty

                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value={null}>Выберите</MenuItem>
                                            {(OptionsBoolean || []).map((item, idx) => (
                                                <MenuItem key={`is_special_value-${idx}`} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.is_special.apply} value={values.is_special.apply} name="is_special" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                            {/* Новинка */}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>Новинка</Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.is_new.value}
                                            name="is_new"
                                            fullWidth
                                            displayEmpty

                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value={null}>Выберите</MenuItem>
                                            {(OptionsBoolean || []).map((item, idx) => (
                                                <MenuItem key={`is_new_value-${idx}`} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.is_new.apply} value={values.is_new.apply} name="is_new" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                            {/* YML экспорт */}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>YML экспорт</Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.export_yml.value}
                                            name="export_yml"
                                            fullWidth
                                            displayEmpty

                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value={null}>Выберите</MenuItem>
                                            {(OptionsBoolean || []).map((item, idx) => (
                                                <MenuItem key={`export_yml_value-${idx}`} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.export_yml.apply} value={values.export_yml.apply} name="export_yml" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                            {/*AVITO экспорт*/}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>AVITO экспорт</Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.export_avito.value}
                                            name="export_avito"
                                            fullWidth
                                            displayEmpty

                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value={null}>Выберите</MenuItem>
                                            {(OptionsBoolean || []).map((item, idx) => (
                                                <MenuItem key={`export_avito_value-${idx}`} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.export_avito.apply} value={values.export_avito.apply} name="export_avito" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                            {/*2GIS экспорт*/}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>2GIS экспорт</Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.export_2gis.value}
                                            name="export_2gis"
                                            fullWidth
                                            displayEmpty

                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value={null}>Выберите</MenuItem>
                                            {(OptionsBoolean || []).map((item, idx) => (
                                                <MenuItem key={`export_2gis_value-${idx}`} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.export_2gis.apply} value={values.export2gis_apply} name="export_2gis_apply" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                            {/*YULA экспорт*/}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>YULA экспорт</Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.export_yula.value}
                                            name="export_yula"
                                            fullWidth
                                            displayEmpty

                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value={null}>Выберите</MenuItem>
                                            {(OptionsBoolean || []).map((item, idx) => (
                                                <MenuItem key={`export_yula_value-${idx}`} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.export_yula.apply} value={values.export_yula.apply} name="export_yula" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                            {/*Цена*/}
                            <Grid item container spacing={3}>
                                <Grid item sx={{flex: 1}}>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>Цена</Typography>
                                    <TextField
                                        value={values.price_new.value}
                                        name="price_new"
                                        size="small"
                                        placeholder="Введите"
                                        fullWidth
                                        InputProps={{
                                            inputComponent: TextMaskCustom
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{marginBottom: 1}}>&nbsp;</Typography>
                                    <FormControlLabel control={<Checkbox/>} checked={values.price_new.apply} value={values.price_new.apply} name="price_new" label="Изменить" onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                )
            }}
        </Formik>
    )
}
const OptionsBoolean = [
    {value: 0, label: "Нет"},
    {value: 1, label: "Да"},
];
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    const handleOnAccept = (value) => {
        onChange({ target: { name: props.name, value } })
    }

    return (
        <IMaskInput
            {...other}
            mask={Number}
            thousandsSeparator=" "
            radix="."

            inputRef={ref}
            onAccept={handleOnAccept}
        />
    );
});

const validationSchema = Yup.object().shape({});

export default MainForm
