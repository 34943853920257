import React, { Component } from "react";
import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import {
    Controls as ControlsComponent,
    OrderInfo as OrderInfoComponent,
    OrderItems as OrderItemsComponent
} from "./components";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import moment from "moment";

class Order extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: {},

            orderId: props?.match?.params?.id
        };

        this.timeOutCheckCrmOrderId = null;
        this.timeOutEventView = null;
    }

    componentDidMount = async () => {
        await this.getOrder();

        await this.eventViewOrderStart();
    }
    componentWillUnmount = async () => {
        await this.eventViewOrderEnd();
    }

    getOrder = async () => {
        const data = await agent.get(`${ urls.getOrder }/${ this.state.orderId }?expand=items,items.product,user,city,is_exported_1c`).then((res) => {
            return res.data
        }).catch(() => {
            return {}
        });

        if (!data?.external_data?.crm_order_id) {
            this.timeOutCheckCrmOrderId = setTimeout(async () => {
                await this.checkCrmOrderId();
            }, 30 * 1000);
        }

        this.setState({
            order: data
        })
    }
    checkCrmOrderId = async () => {
        clearTimeout(this.timeOutCheckCrmOrderId);

        const data = await agent.get(`${ urls.getOrder }/${ this.state.orderId }`).then((res) => {
            return res.data
        }).catch(() => {
            return {}
        });
        if (!data?.external_data?.crm_order_id) {
            this.timeOutCheckCrmOrderId = setTimeout(async () => {
                await this.checkCrmOrderId();
            }, 30 * 1000);

            return
        }

        let order = {...this.state.order};
        let external_data = {...(order.external_data || {})};
        external_data.crm_order_id = data?.external_data?.crm_order_id;
        order.external_data = external_data;
        this.setState({ order });
    }

    eventViewOrderStart = async () => {
        clearTimeout(this.timeOutEventView);
        await agent.get(`/admin/api-shop-orders/${ this.state.orderId }?ngrestCallType=view&fields=id`);
        this.timeOutEventView = setTimeout(async () => {
            await this.eventViewOrderStart();
        }, 5 * 1000);
    }
    eventViewOrderEnd = async () => {
        clearTimeout(this.timeOutEventView);
    }

    _routeEditPage = () => {
        this.props.history.push(`/orders/edit/${ this.state.order.id }`, {
            initialOrder: this.state.order
        })
    }

    render() {
        const {
            order
        } = this.state;
        const {
            adminLocked
        } = this.props;
        const isCancel = Boolean(order.status === 5);
        const crmOrderId = order?.external_data?.crm_order_id;
        const orderEdit = adminLocked.find((t) => String(t.lock_pk) === String(order.id));
        const isExported1c = Boolean(order?.is_exported_1c || false);

        return (
            <>

                <Box mb={2}>
                    <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" spacing={8}>
                        <Grid item>
                            <Typography variant="h1">
                                Заказ №{order.screen_id} | {moment(order.created_at * 1000).format("DD.MM.YYYY HH:mm:ss")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ControlsComponent
                              crmOrderId={crmOrderId}
                              isCancel={isCancel}
                              orderEdit={orderEdit}
                              isExported1c={isExported1c}
                              onRouteEditPage={this._routeEditPage}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={2}>
                    <OrderInfoComponent order={order}/>
                </Box>

                <Box mb={2}>
                    <OrderItemsComponent
                        data={order.items || []}
                    />
                </Box>

            </>
        );
    }
}

export default Order
