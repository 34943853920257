import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Box,
	Grid,
	Button,
	Divider,
	Toolbar,
	Tooltip,
	Typography,
	IconButton,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
  Delete as DeleteIcon, DragHandle as DragHandleIcon
} from "@mui/icons-material";
import {
	Formik
} from "formik";
import * as Yup from "yup";
import {
	Notification,
	NotificationTypes
} from "../../../../../common/Notification";
import DialogAddProduct from "../ProductsCollection/DialogAddProduct";
import agent from "../../../../../agent/agent";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";

const RelatedSimilarProductsForm = (props) => {
	const {
		innerRef,
		initialValues
	} = props;
	const refDialogAddProduct = React.createRef();
	const [isShowBackdrop, setShowBackdrop] = React.useState(false);

	const handleSubmit = () => {
	}

	const openDialogAdd = (name) => {
		if (name === "extraRelated") {
			refDialogAddProduct.current.open({
				onSubmit: addProductExtraRelated
			})
		}
		if (name === "extraSame") {
			refDialogAddProduct.current.open({
				onSubmit: addProductExtraSame
			})
		}
	}
	const addProductExtraRelated = async ({productIds}) => {
		await handleChange("extraRelated", productIds);
	}
	const addProductExtraSame = async ({productIds}) => {
		await handleChange("extraSame", productIds);
	}

	const handleChange = async (name, productIds) => {
		refDialogAddProduct.current.close();

		let newForm = {...innerRef.current.values};
		newForm[name] = [...newForm[name], ...productIds];

		const errorIds = await handleChangeProducts(name, productIds);
		if (Array.isArray(errorIds) && errorIds.length > 0) {
			errorIds.map((item) => {
				const sliceIndex = newForm[name].findIndex((t) => t === item.productId);
				newForm[name].splice(sliceIndex, 1);
			});
			Notification({
				message: errorIds.map((t) => t.message).join("<br/>"),
				type: NotificationTypes.error
			});
			return
		}

		Notification({
			type: NotificationTypes.success,
			message: "Товар успешно добавлен"
		})

		innerRef.current.setValues(newForm);
	}
	const handleDeleteProduct = async (name, productId) => {
		let newForm = {...innerRef.current.values};
		newForm[name] = [...newForm[name]];

		let spliceIndex = newForm[name].findIndex((t) => +t === +productId);
		newForm[name].splice(spliceIndex, 1);

		const isSuccess = await handleChangeProductsRemove(name, productId);
		if (!isSuccess) {
			return
		}

		innerRef.current.setValues(newForm);
	}

	const handleChangeProducts = async (name, items) => {
		let actionUrl = "";
		if (name === "extraRelated") {
			actionUrl = "/admin/api-shop-related-assignments";
		}
		if (name === "extraSame") {
			actionUrl = "/admin/api-shop-same-product-assignment";
		}

		setShowBackdrop(true);

		const errors = await Promise.all(items.map(async (productId) => {
			let actionBody = {
				"product_id": innerRef.current.values.id,
        "sort": 1
			};
			if (name === "extraSame") {
				actionBody.same_product_id = productId;
			}
			if (name === "extraRelated") {
				actionBody.related_id = productId;
			}

			const response = await agent.post(actionUrl, actionBody).then((res) => {
				return null
			}).catch((err) => {
				return err.response
			});
			if (response) {
				return {
					message: response?.data?.[0]?.message || "Ошибка сервера",
					productId
				}
			}

			return null
		}));

		setShowBackdrop(false);

		return errors.filter((t) => !!t)
	}
	const handleChangeProductsRemove = async (name, itemId) => {
		let actionUrl = "";
		if (name === "extraRelated") {
			actionUrl = `/admin/api-shop-related-assignments/${ String(innerRef.current.values.id) },${ itemId }`;
		}
		if (name === "extraSame") {
			actionUrl = `/admin/api-shop-same-product-assignment/${ String(innerRef.current.values.id) },${ itemId }`;
		}

		setShowBackdrop(true);

		const response = await agent.delete(actionUrl).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response || "Ошибка сервера" }
		});
		if (response?.error) {
			Notification({
				type: NotificationTypes.error,
				message: response?.error?.data?.message || "Ошибка сервера"
			})

			setShowBackdrop(false);

			return false
		}

		Notification({
			type: NotificationTypes.success,
			message: "Товар удален"
		})

		setShowBackdrop(false);
		return true
	}
  const handleChangeSortProducts = async (name, itemId, newSort) => {
    let actionUrl = "";
    if (name === "extraRelated") {
      actionUrl = `/admin/api-shop-related-assignments/${ String(innerRef.current.values.id) },${ itemId }`;
    }
    if (name === "extraSame") {
      actionUrl = `/admin/api-shop-same-product-assignment/${ String(innerRef.current.values.id) },${ itemId }`;
    }

    setShowBackdrop(true);

    let actionBody = {
      "product_id": innerRef.current.values.id,
      "sort": newSort
    };
    if (name === "extraSame") {
      actionBody.same_product_id = itemId;
    }
    if (name === "extraRelated") {
      actionBody.related_id = itemId;
    }

    await agent.put(actionUrl, actionBody).then((res) => {
      return res
    }).catch((err) => {
      return {error: err.response}
    });
    setShowBackdrop(false);
  }

  const handleChangeSort = async (fieldName, { oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return
    }

    let newForm = {...innerRef.current.values};
    let items = [...(newForm?.[fieldName] || [])];
    let itemPrimary = items?.[oldIndex];
    items.splice(oldIndex, 1);
    items.insert(newIndex, itemPrimary);

    for (const element of items) {
			const elementIndex = items.findIndex((t) => t === element);
      await handleChangeSortProducts(fieldName, element, elementIndex);
    }

    newForm[fieldName] = items;
    innerRef.current.setValues(newForm);
  }

	return (
		<form autoComplete="off">
			<Formik
				innerRef={innerRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {
						values
					} = props;

					return (
						<Box px={2} py={2} bgcolor="white" borderRadius={2}>
							<Box>
								<Grid container alignItems="center" justifyContent="space-between" sx={{marginBottom: "8px"}}>
									<Grid item>
										<Typography variant="h3">К этому товару подходят</Typography>
									</Grid>
									<Grid item>
										<Button variant="contained" onClick={openDialogAdd.bind(this, "extraRelated")}>Добавить
											товар</Button>
									</Grid>
								</Grid>

                <SortableProducts
                  products={values.extraRelated}
                  onDelete={(productId) => handleDeleteProduct('extraRelated', productId)}


                  lockAxis="y"
                  useDragHandle={true}
                  onSortEnd={handleChangeSort.bind(this, 'extraRelated')}
                />
							</Box>

							<Divider sx={{margin: "24px 0"}}/>

							<Box>
								<Grid container alignItems="center" justifyContent="space-between" sx={{marginBottom: "8px"}}>
									<Grid item>
										<Typography variant="h3">Похожие товары</Typography>
									</Grid>
									<Grid item>
										<Button variant="contained" onClick={openDialogAdd.bind(this, "extraSame")}>Добавить товар</Button>
									</Grid>
								</Grid>

                <SortableProducts
                  products={values.extraSame}
                  onDelete={(productId) => handleDeleteProduct('extraSame', productId)}


                  lockAxis="y"
                  useDragHandle={true}
                  onSortEnd={handleChangeSort.bind(this, 'extraSame')}
                />
							</Box>
						</Box>
					)
				}}
			</Formik>


			<DialogAddProduct
				ref={refDialogAddProduct}
			/>

			<Backdrop open={isShowBackdrop}>
				<CircularProgress/>
			</Backdrop>

		</form>
	)
}

const SortableProducts = SortableContainer((props) => {
  const {
    products,
    onDelete
  } = props;
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell width={40}/>
          <TableCell width={64}>Фото</TableCell>
          <TableCell>Название товара</TableCell>
          <TableCell width={240}>Артикул</TableCell>
          <TableCell width={42} align="right"/>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((productId, index) => (
          <SortableProduct
            index={index}
            productId={productId}
            onDelete={onDelete}
          />
        ))}
      </TableBody>
    </Table>
  )
})
const SortableProduct = SortableElement((props) => {
  const {
    productId,
    onDelete
  } = props;
  const classes = useStyles();
  const [product, setProduct] = React.useState({});
  const productStatus = Boolean(product.status);

  React.useEffect(() => {
    (async () => {
      await getProduct();
    })();
  }, [productId])

  const getProduct = async () => {
    const response = await agent.get(`/admin/api-shop-products/${productId}?fields=id,name,main_photo_id,code,status`).then((res) => {
      return res.data
    }).catch(() => {
      return {}
    });
    setProduct(response);
  }
  const handleDeleteProduct = () => {
    onDelete(productId)
  }

  return (
    <TableRow hover>
      <TableCell width={40} align="center">
        <SortableDragHandle classes={classes}/>
      </TableCell>
      <TableCell>
        <img
          className={classes.productImage}
          src={`${process.env.REACT_APP_HOST_API}api/storage/image/${product?.main_photo_id}_w-500_h-500.webp`}
        />
      </TableCell>
      <TableCell>
        {product?.name}{!productStatus && " (ОТКЛЮЧЕН)"}
      </TableCell>
      <TableCell>
        {product?.code}
      </TableCell>
      <TableCell align="right">
        <Tooltip title="Удалить товар" arrow>
          <IconButton onClick={handleDeleteProduct}>
            <DeleteIcon color="error"/>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
});
const SortableDragHandle = SortableHandle(({ classes }) => (
  <Box className={classes.buttonSorted}>
    <DragHandleIcon/>
  </Box>
))

const TableProducts = React.memo((props) => {
	const {
		products,
		onDelete
	} = props;
	const classes = useStyles();

	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell width={64}>Фото</TableCell>
					<TableCell>Название товара</TableCell>
					<TableCell width={240}>Артикул</TableCell>
					<TableCell width={42} align="right"/>
				</TableRow>
			</TableHead>
			<TableBody>
				{products.map((productId) => (
					<TableProductRow
						productId={productId}
						onDelete={onDelete}
					/>
				))}
			</TableBody>
		</Table>
	)
})
const TableProductRow = React.memo((props) => {
	const {
		productId,
		onDelete
	} = props;
	const classes = useStyles();
	const [product, setProduct] = React.useState({});
	const productStatus = Boolean(product.status);

	React.useEffect(() => {
		(async () => {
			await getProduct();
		})();
	}, [productId])

	const getProduct = async () => {
		const response = await agent.get(`/admin/api-shop-products/${productId}?fields=id,name,main_photo_id,code,status`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});
		setProduct(response);
	}
	const handleDeleteProduct = () => {
		onDelete(productId)
	}

	return (
		<TableRow hover>
			<TableCell>
				<img
					className={classes.productImage}
					src={`${process.env.REACT_APP_HOST_API}api/storage/image/${product?.main_photo_id}_w-500_h-500.webp`}
				/>
			</TableCell>
			<TableCell>
				{product?.name}{!productStatus && " (ОТКЛЮЧЕН)"}
			</TableCell>
			<TableCell>
				{product?.code}
			</TableCell>
			<TableCell align="right">
				<Tooltip title="Удалить товар" arrow>
					<IconButton onClick={handleDeleteProduct}>
						<DeleteIcon color="error"/>
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
})

const useStyles = makeStyles(() => ({
	table: {
		borderSpacing: "initial",

		"& .MuiTableRow-head .MuiTableCell-root": {
			padding: "4px 8px"
		}
	},
	productImage: {
		width: 50,
		height: 50,
		objectFit: "contain",
		borderRadius: 4,
		backgroundColor: "white"
	}
}))
const validationSchema = Yup.object().shape({});

export default React.memo(RelatedSimilarProductsForm)
