import React, {useState} from 'react';
import {Formik} from "formik";
import {
    Box, Button,
    Checkbox, Collapse,
    FormControl,
    FormControlLabel, FormGroup,
    Grid,
    IconButton, MenuItem,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ClearOutlined as ClearIcon,
    ExpandLessOutlined as ExpandLessIcon,
    ExpandMoreOutlined as ExpandMoreIcon, SearchOutlined as SearchIcon
} from "@mui/icons-material";
import * as Yup from "yup";


const CreateForm = (props) => {
    const {
        initialValues,
        regionalPrice,

        productCompilationId,
        priceRoundingMethod,
        extraRegions,

        isCreate,

        innerRef,
    } = props;

    const [searchVal, setSearchVal] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [openSettingsPrice, setOpenSettingsPrice] = useState(false);
    const [openSettingsRegions, setOpenSettingsRegions] = useState(false);

    const onSubmit = () => {
    }

    const handleChange = ({target}) => {
        const {name, value} = target;

        let newForm = {...innerRef?.current?.values || {}};
        newForm[name] = value;

        innerRef.current?.setValues(newForm);
    }

    const handleChangeBoolean = ({target}, value) => {
        const {name} = target;

        let newForm = {...innerRef.current.values};
        newForm[name] = value;

        innerRef.current.setValues(newForm);
    }

    const handleChangeDate = ({target}) => {
        const name = target.name;
        const value = target.value;
        let newForm = {...innerRef.current.values};
        newForm[name] = value;

        innerRef.current.setValues(newForm);
    }

    const handleStartedDateReset = () => {
        if (isCreate) {
            innerRef.current.setFieldValue(`started_at_date`, initialValues.started_at_date);
            innerRef.current.setFieldValue(`started_at_hour`, initialValues.started_at_hour);
        } else {
            innerRef.current.setFieldValue(`started_at_date`, regionalPrice.started_at_date);
            innerRef.current.setFieldValue(`started_at_hour`, regionalPrice.started_at_hour);
        }
    }

    const handleEndedDateReset = () => {
        if (isCreate) {
            innerRef.current.setFieldValue(`ended_at_date`, initialValues.ended_at_date);
            innerRef.current.setFieldValue(`ended_at_hour`, initialValues.ended_at_hour);
        } else {
            innerRef.current.setFieldValue(`ended_at_date`, regionalPrice.ended_at_date);
            innerRef.current.setFieldValue(`ended_at_hour`, regionalPrice.ended_at_hour);
        }
    }

    const handleChangeRegions = ({target}, boolValues) => {
        if (boolValues) {
            const {name, value} = target;
            let newForm = {...innerRef.current.values};
            newForm[name] = [
                ...innerRef.current.values.extraRegions,
                {value: String(value)}
            ];
            innerRef.current.setValues(newForm);
        } else {
            const {name, value} = target;
            let newForm = {...innerRef.current.values};
            newForm[name] = [
                ...innerRef.current.values.extraRegions.filter(item => item.value !== String(value))
            ];
            innerRef.current.setValues(newForm);
        }
    }

    const selectAllRegions = ({target}, boolValue) => {
        if (boolValue) {
            const newForm = {...innerRef.current.values};
            newForm.extraRegions = [
                ...extraRegions.filter(region => region.label.match(new RegExp(searchVal, "i"))).map(region => {
                    return {
                        value: String(region.value),
                    };
                })
            ];
            innerRef.current.setValues(newForm);
            setSelectAll(boolValue);
        } else {
            const newForm = {...innerRef.current.values};
            newForm.extraRegions = [];
            innerRef.current.setValues(newForm);
            setSelectAll(boolValue);
        }
    }

    return (
        <Formik
            innerRef={innerRef}
            initialValues={isCreate ? initialValues : regionalPrice}

            onSubmit={onSubmit}
        >
            {(props) => {
                const {
                    values,
                    errors,
                    touched,
                } = props;
                return (
                    <>
                        <Box mb={3}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <Typography variant="h6">Начало</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <TextField
                                                type='date'
                                                name='started_at_date'
                                                value={values.started_at_date}
                                                defaultValue={values.started_at_date}
                                                error={Boolean(touched.started_at_date && errors.started_at_date)}
                                                helperText={touched.started_at_date && errors.started_at_date}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onChange={handleChangeDate}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="time"
                                                fullWidth
                                                value={values.started_at_hour}
                                                error={Boolean(touched.started_at_hour && errors.started_at_hour)}
                                                helperText={touched.started_at_hour && errors.started_at_hour}
                                                name="started_at_hour"

                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="Сбросить дату начала" arrow>
                                                <IconButton onClick={handleStartedDateReset}>
                                                    <ClearIcon color="primary"/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mb={3}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <Typography variant="h6">Окончание</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <TextField
                                                type='date'
                                                name='ended_at_date'
                                                value={values.ended_at_date}
                                                defaultValue={values.ended_at_date}
                                                error={Boolean(touched.ended_at_date && errors.ended_at_date)}
                                                helperText={touched.ended_at_date && errors.ended_at_date}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onChange={handleChangeDate}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="time"
                                                fullWidth
                                                value={values.ended_at_hour}
                                                error={Boolean(touched.ended_at_hour && errors.ended_at_hour)}
                                                helperText={touched.ended_at_hour && errors.ended_at_hour}
                                                name="ended_at_hour"

                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="Сбросить дату окончания" arrow>
                                                <IconButton onClick={handleEndedDateReset}>
                                                    <ClearIcon color="primary"/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mb={3}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <Typography variant="h3" sx={{
                                        fontWeight: 700
                                    }}>Название</Typography>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        value={values.name}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                        name="name"

                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mb={3}>
                            <Grid container alignItems='center'>
                                <Grid item xs={1}>
                                    <Typography variant="h6">Статус</Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox/>}
                                        checked={Boolean(values.status)}
                                        value={values.status}
                                        name="status"
                                        label=''
                                        onChange={handleChangeBoolean}/>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mb={3}>
                            <Grid container justifyContent='space-between'>
                                <Grid item xs={1}>
                                    <Typography variant="h3" sx={{
                                        fontWeight: 700
                                    }}>Подборка товаров</Typography>
                                </Grid>
                                <Grid item xs>
                                    <FormControl fullWidth>
                                        <TextField
                                            select
                                            value={String(values.product_compilation_id)}
                                            defaultValue={String(values.product_compilation_id)}
                                            error={Boolean(touched.product_compilation_id && errors.product_compilation_id)}
                                            helperText={touched.product_compilation_id && errors.product_compilation_id}
                                            name='product_compilation_id'

                                            onChange={handleChange}
                                        >
                                            {productCompilationId.map(data => (
                                                <MenuItem key={data.value} value={data.value}>{data.label}</MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mb={3}>
                            <Button
                                sx={{
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    border: "1px solid #3855EC",
                                    justifyContent: "flex-start",
                                    height: 50,
                                }}
                                fullWidth
                                variant='contained'
                                startIcon={openSettingsPrice ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                size="large"

                                onClick={() => setOpenSettingsPrice(!openSettingsPrice)}
                            >
                                Настройки цены
                            </Button>
                            <Collapse
                                sx={{
                                    border: "1px solid #3855EC",
                                    borderTop: 0,
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 5,
                                }}
                                in={openSettingsPrice}
                                timeout={0}
                            >
                                <Box sx={{
                                    padding: "30px 20px 20px 20px",
                                }}>
                                    <Box mb={3}>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item xs={1}>
                                                <Typography variant="h6">Коэффициент для цен (%)</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    fullWidth
                                                    value={values.price_value}
                                                    error={Boolean(touched.price_value && errors.price_value)}
                                                    helperText={touched.price_value && errors.price_value}
                                                    name="price_value"

                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item xs={1}>
                                                <Typography variant="h6">Способ округления цены</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        select
                                                        value={String(values.price_rounding_method)}
                                                        error={Boolean(touched.price_rounding_method && errors.price_rounding_method)}
                                                        helperText={touched.price_rounding_method && errors.price_rounding_method}
                                                        name='price_rounding_method'

                                                        onChange={handleChange}
                                                    >
                                                        {priceRoundingMethod.map(data => (
                                                            <MenuItem key={data.value}
                                                                      value={data.value}>{data.label}</MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Collapse>
                        </Box>

                        <Box>
                            <Button
                                sx={{
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    border: "1px solid #3855EC",
                                    justifyContent: "flex-start",
                                    height: 50,
                                }}
                                fullWidth
                                variant='contained'
                                startIcon={openSettingsRegions ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                size="large"

                                onClick={() => setOpenSettingsRegions(!openSettingsRegions)}
                            >
                                Регионы
                            </Button>
                            <Collapse
                                sx={{
                                    border: "1px solid #3855EC",
                                    borderTop: 0,
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 5,
                                }}
                                in={openSettingsRegions}
                                timeout={0}
                            >
                                <Box sx={{
                                    padding: "30px 20px 20px 20px",
                                }}>
                                    <Box mb={3}>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item xs={1}>
                                                <Typography variant="h6">Регионы</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Grid item mb={2}>
                                                    <TextField
                                                        fullWidth
                                                        value={searchVal}
                                                        onChange={(e) => setSearchVal(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: <SearchIcon sx={{paddingRight: "5px"}}
                                                                                        fontSize="24px"/>
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid container>
                                                    <Grid item>
                                                        {searchVal === "" && (
                                                            <FormControlLabel
                                                                value={selectAll}
                                                                name="selectAll"
                                                                control={
                                                                    <Checkbox
                                                                        value={selectAll}/>
                                                                }
                                                                label="Выбрать все"
                                                                checked={selectAll}

                                                                onChange={selectAllRegions}
                                                            />
                                                        )}
                                                        <FormGroup>
                                                            {extraRegions
                                                                .filter(region => region.label.match(new RegExp(searchVal, "i")))
                                                                .map(region => (
                                                                    <>
                                                                        {values.extraRegions.find(reg => Number(reg.value) === region.value)
                                                                            ? (
                                                                                <FormControlLabel
                                                                                    key={region.value}
                                                                                    value={values.extraRegions}
                                                                                    name="extraRegions"
                                                                                    control={
                                                                                        <Checkbox
                                                                                            key={region.value}
                                                                                            value={region.value}/>
                                                                                    }
                                                                                    label={region.label}
                                                                                    checked
                                                                                    onChange={handleChangeRegions}
                                                                                />
                                                                            )
                                                                            : (
                                                                                <FormControlLabel
                                                                                    key={region.value}
                                                                                    value={values.extraRegions}
                                                                                    name="extraRegions"
                                                                                    control={
                                                                                        <Checkbox
                                                                                            key={region.value}
                                                                                            value={region.value}/>
                                                                                    }
                                                                                    label={region.label}
                                                                                    checked={false}
                                                                                    onChange={handleChangeRegions}
                                                                                />
                                                                            )
                                                                        }
                                                                    </>
                                                                ))}
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Collapse>
                        </Box>
                    </>
                );
            }}
        </Formik>
    );
};

export default CreateForm;