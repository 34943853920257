import React, {useState} from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    Typography,

    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import {
    AutocompleteSites,
    FilterCategories,
    FilterDateRange
} from "../../../../../components";
import moment from "moment";

const sizes = {
    xl: 2,
    lg: 4,
    xs: 6
};

const Filter = (props) => {
    const {filter} = props;

    const filterChange = ({target}) => {
        const {name, value} = target;

        let newFilter = {
            ...filter,
            [name]: value
        };

        props.onChange(newFilter);
    }
    const filterChangeDateRange = (date) => {
        const newFilter = {
            ...filter,
        }
        newFilter["filter[date][gte]"] = moment(date[0]).format('YYYY-MM-DD');
        newFilter["filter[date][lte]"] = moment(date[1]).format('YYYY-MM-DD');

        props.onChange(newFilter)
    }

    const onSearch = () => {
        props.onSearch();
    }
    const onClear = () => {
        props.onClear();
    }

    return (
        <Box p={2} bgcolor="white" borderRadius={2}>
            <Grid container spacing={2}>
                <Grid item xs={sizes.xl} lg={sizes.lg} xl={sizes.xl}>
                    <TextField
                        value={filter['filter[order_number][like]']}
                        name="filter[order_number][like]"
                        label="ID заказа (№заказа)"
                        placeholder="Введите номер заказа"
                        size={"small"}
                        variant={"outlined"}
                        fullWidth

                        onChange={filterChange}
                    />
                </Grid>
                <Grid item xs={sizes.xl} lg={sizes.lg} xl={sizes.xl}>
                    <FilterDateRange
                        value={[filter['filter[date][gte]'], filter['filter[date][lte]']]}
                        onChange={filterChangeDateRange}
                    />
                </Grid>
                <Grid item xs={sizes.xl} lg={sizes.lg} xl={sizes.xl}>
                    <AutocompleteSites
                      value={filter['filter[extraWebsites]'] || []}
                      name="filter[extraWebsites]"
                      onChange={filterChange}
                    />
                </Grid>
            </Grid>

            <Box mt={2}/>

            <Grid container spacing={2}>
                <Grid item>
                    <Button
                        variant={"contained"}
                        size={"small"}
                        sx={{minWidth: 160}}
                        onClick={onSearch}
                    >Применить</Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={"outlined"}
                        size={"small"}
                        sx={{minWidth: 160}}
                        onClick={onClear}
                    >Сбросить</Button>
                </Grid>
            </Grid>
        </Box>
    )
}

// let timeout = null;
// const SearchUserComponent = (props) => {
//     const {
//         filter,
//
//         onChange
//     } = props;
//     const [searchValue, setSearchValue] = useState('');
//     const [searchLoading, setSearchLoading] = useState(false);
//     const [user, setUser] = useState({});
//
//     //Логика поиска коллекций
//     const onChangeSearchCollections = (e) => {
//         clearTimeout(timeout);
//         if (e.target.value.length >= 2) {
//             timeout = setTimeout(() => {
//                 const searchValue = e.target.value;
//                 setUser([]);
//                 searchValue(searchValue);
//                 setSearchLoading(true);
//                 timeout = setTimeout(async () => {
//                     if (searchValue.length >= 2) {
//                         // await getCollections(searchValue);
//                     } else {
//                         setUser([]);
//                     }
//                 }, 1000);
//             }, 100);
//         } else {
//             setUser([]);
//             setSearchLoading(false);
//         }
//     }
//
//     return (
//         <div>
//
//         </div>
//     );
// };

export default React.memo(Filter)
