import React, {useState} from "react";
import {
	Box,
	Grid,
	Avatar,
	Checkbox,
	Typography,
	Pagination,
	IconButton,
	Tooltip
} from "@mui/material";
import {
	Edit as EditIcon
} from "@mui/icons-material";
import {
	makeStyles
} from "@mui/styles";
import DataTable from "react-data-table-component";
import customStylesTable from "../../../../../theme/common/table";
import {numberFormat, phoneFormat} from "../../../../../common/Formater";
import {Link} from "react-router-dom";
import clsx from "clsx";
import moment from "moment";

const TableComponent = (props) => {
	const {data, visibleColumns, pagination, filter, onChangeFilter} = props;
	const [isShowActions, setShowActions] = useState(false);
	const classes = useStyles();

	const handleSortFunction = (rows, selector, direction) => {
		return []
	}
	const handleChangePage = (event, page) => {
		let newFilter = {...filter};
		newFilter.page = page;

		onChangeFilter(newFilter, true);
	}

	const handleChangeSelectable = ({selectedCount, selectedRows}) => {
		setShowActions(Boolean(selectedCount > 0));
	}

	const _columns = () => {
		let columns = [];

		Object.keys(visibleColumns).map((key) => {
			columns.push({
				...visibleColumns[key],
				selector: (row) => _selectorTable(row, key),
			})
		});

		return columns
	}
	const _selectorTable = (row, key) => {
		if (key === "id") {
			return `${ row.screen_id }`
		}
		if (key === "cost" || key === "personal_discount") {
			return `${numberFormat(row[key], 2, ',')} руб.`
		}
		if (key === "customer_phone") {
			return `${phoneFormat(row[key])}`
		}
		if (key === "city") {
			return row?.city?.name
		}
		if (key === "active") {
			return (
				<Grid container spacing={1}>
					<Grid item>
						<Tooltip title="Открыть" arrow>
							<Link to={`/orders/${row.id}`}>
								<IconButton>
									<EditIcon color="primary"/>
								</IconButton>
							</Link>
						</Tooltip>
					</Grid>
				</Grid>
			)
		}
		if (key === "status_label") {
			const statusId = row?.status;

			return (
				<Box
					className={clsx({
						[classes.status]: true,
						[classes.status1]: Boolean(statusId === 1),
						[classes.status2]: Boolean(statusId === 2),
						[classes.status3]: Boolean(statusId === 3),
						[classes.status4]: Boolean(statusId === 4),
						[classes.status5]: Boolean(statusId === 5),
						[classes.status6]: Boolean(statusId === 6),
					})}
				>
					{row.status_label}
				</Box>
			)
		}
		if (key === "created_at") {
			return moment(row[key] * 1000).format("DD.MM.YYYY HH:mm:ss")
		}

		return row[key]
	}
	const _labelCountPagination = () => {
		const startCount = ((+pagination.perPage * filter.page) - (pagination.perPage - 1));
		const endCount = (+pagination.perPage * filter.page);

		return `Показано ${startCount} - ${endCount} из ${pagination.totalCount}`
	}

	return (
		<Box className={classes.table}>
			<DataTable
				columns={_columns()}
				data={data}
				customStyles={customStylesTable}
			/>

			<Box mt={2}>
				<Pagination
					page={Number(filter.page)}
					count={pagination.count || 1}

					onChange={handleChangePage}
				/>
			</Box>

		</Box>
	)
}

const useStyles = makeStyles(() => ({
	table: {
		"& > div:first-child": {
			maxHeight: "calc(100vh - 120px)",
			overflow: "auto",
		},
		"& .rdt_TableHead": {
			position: "sticky",
			top: 0,
			zIndex: 100,
		},
		"& .rdt_TableHeadRow": {
			"& > *": {
				"&:last-child": {
					filter: "drop-shadow(0px 5px 24px rgba(149, 157, 165, 0.25))",
					position: "sticky",
					right: 0,
					zIndex: 20,
					background: "#3855EC",
					width: 60,
					minWidth: 60,
					flexGrow: "initial",
					padding: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}
			}
		},

		"& .rdt_TableBody": {
			"& .rdt_TableRow:nth-child(2n)": {
				"& > *": {
					"&:last-child": {
						background: "#F4F4F4"
					}
				}
			}
		},
		"& .rdt_TableRow": {
			"& > *": {
				"&:last-child": {
					filter: "drop-shadow(0px 5px 24px rgba(149, 157, 165, 0.25))",
					position: "sticky",
					right: 0,
					zIndex: 20,
					background: "#fcfcfc",
					width: 60,
					minWidth: 60,
					flexGrow: "initial",
					padding: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}
			}
		},

    "& .rdt_TableBody .rdt_TableRow:hover": {
      cursor: "pointer",
      "& > * ": {
        background: "#cbd2f8!important"
      }
    },
	},

	status: {
		borderRadius: 3,
		padding: "4px 5px",

		fontSize: 10,
		lineHeight: "11px",
		fontWeight: "700",
		textTransform: "uppercase"
	},
	status1: {
		backgroundColor: "#28a745",
		color: "white"
	},
	status2: {
		backgroundColor: "#17a2b8",
		color: "white"
	},
	status3: {
		backgroundColor: "#62B4FF",
		color: "white"
	},
	status4: {
		backgroundColor: "#3855EC",
		color: "white"
	},
	status5: {
		backgroundColor: "#ffc107",
		color: "#212529"
	},
	status6: {
		backgroundColor: "#e53935",
		color: "#FFF"
	},
}));

export default React.memo(TableComponent)
