import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import {AddBoxOutlined as AddBoxOutlinedIcon, Language as LanguageIcon} from "@mui/icons-material";
import {Link} from "react-router-dom";

const MainInformation = (props) => {
    const {classes} = props;

    return (
        <>
            <Box className={classes.headLogo}>
                <LanguageIcon sx={{color: "#0057FF", fontSize: 42}}/>
                <Typography variant="h1">Управление производителями</Typography>
            </Box>
            <Link to="/brands/create">
                <Button
                    variant="contained"
                    startIcon={<AddBoxOutlinedIcon sx={{color: "white"}}/>}
                >
                    Добавить производителя
                </Button>
            </Link>
        </>
    );
};

export default MainInformation;