import {palette} from "../common";

export default {
	styleOverrides: {
		root: {
			height: 36,
			padding: 0,
			borderRadius: 4,
			boxShadow: "none"
		},
		input: {
			padding: "8px 12px"
		}
	}
}
