import queryString from "query-string";

const lodash = require('lodash');

const rangeNumberKeys = [
    'quantity',
    'price_old',
    'price_new',
    'discount_percent'
];

const filterStringify = (filter = {}) => {
    let filterFull = {};

    Object.keys(filter).forEach((key) => {
        if (!!filter[key]) {
            filterFull[key] = filter[key];
        }
    });

    console.log('filterStringify: ', filter, filterFull);

    return queryString.stringify(filterFull, {
        encode: true,
        arrayFormat: "bracket"
    });
}
const filterParse = (string, initFilter, visibleColumns) => {
    let searchObject = queryString.parse(string, {
        arrayFormat: "bracket"
    });

    Object.keys(searchObject).map((key) => {
        const columnKey = Object.keys(visibleColumns || {}).find((t) => visibleColumns?.[t]?.nameField === key);
        const column = visibleColumns?.[columnKey] || {};
        const columnType = column?.type || "string";
        const keyRange = ((key.split("filter[")?.[1] || "").split("][")?.[0] || "");

        // if (rangeNumberKeys.includes(keyRange)) {
        //     const [from, to] = (searchObject[key] || "").split(":");
        //     searchObject[key] = {from, to};
        // }
        if (key === "filter[extraWebsites]") {
            if (typeof (searchObject[key] || '') === "string") {
                searchObject[key] = (searchObject[key] || '').split(',').map((t) => Number.parseFloat(t));
            }
            if (Array.isArray(searchObject[key])) {
                searchObject[key] = (searchObject[key] || []).map((t) => Number.parseFloat(t));
            }
        }
        if (columnType === "toggleStatus" || columnType === "checkbox") {
            searchObject[key] = Boolean(searchObject[key])
        }
    })

    return {
        ...initFilter,
        ...searchObject
    }
}
const filterToBack = (filter) => {
    let items = [];

    Object.keys(filter)
        .filter((key) => Boolean(filter[key]))
        .map((key) => {
            if (key === 'page') {
                items.push(`${key}=${filter[key]}`)
            }
            else if (key === 'filter[date][gte]' || key === 'filter[date][lte]') {
                items.push(`${key}=${filter[key]}`);
            }
            else if (key === 'filter[extraWebsites]') {
                if ((filter[key] || []).length > 0) {
                    items.push(`${key}=${filter[key].join(',')}`)
                }
            }
            else if ((key || "").indexOf('filter[') <= -1) {
                items.push(`filter[${key}]=${filter[key]}`);
            }
            else {
                items.push(`${key}=${filter[key]}`);
            }
        });

    return `?${items.join('&')}`
}

const filterToStringPreset = (filterObject) => {
    const _ = lodash;
    function tf(object, prev = '', { isArray } = {}) {
        return _.transform(object, (acc, value, key) => {
            if (_.isPlainObject(value)) {
                Object.assign(acc, tf(value, (prev ? prev : '') + `[${ key }]`))
            }
            else if (_.isArray(value)) {
                Object.assign(acc, tf(value, (prev ? prev : '') + `[${ key }]`, { isArray: true }))
            }
            else {
                let _endKey = isArray ? '[]' : `[${ key }]`;
                acc[(prev ? prev : '') + _endKey] = value;
            }
        }, {});
    }

    return tf(filterObject)
}

const objectsMerge = (source, target) => {
    return lodash.merge(source, target);
}

export {
    filterStringify,
    filterParse,
    filterToBack,
    filterToStringPreset,

    objectsMerge
}
