import React from "react";
import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Pagination,
	Select
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const TablePagination = (props) => {
	const {
		filter,
		pagination,

		onChangeFilter
	} = props;
	const classes = useStyles();

	// Пагинация
	const handleChangePage = (event, page) => {
		let newFilter = {...filter}
		newFilter.page = page;
		onChangeFilter(newFilter, true);
	}
	const handleChangeLimit = ({target}) => {
		const {value} = target;
		let newFilter = {...filter};
		newFilter['per-page'] = value;
		onChangeFilter(newFilter, true);
	};

	return (
		<Box className={classes.paginationWrapper} mb={2}>
			<Pagination
				page={Number(filter.page)}
				count={pagination.count || 1}

				onChange={handleChangePage}
			/>
			<Box className={classes.limitSelectWrapper}>
				<FormControl fullWidth>
					<InputLabel sx={{background: "transparent"}}>Кол-во товаров</InputLabel>
					<Select
						label='Кол-во товаров'
						value={filter['per-page']}

						onChange={handleChangeLimit}
					>
						<MenuItem value={10}>10</MenuItem>
						<MenuItem value={20}>20</MenuItem>
						<MenuItem value={40}>40</MenuItem>
						<MenuItem value={60}>60</MenuItem>
						<MenuItem value={80}>80</MenuItem>
						<MenuItem value={100}>100</MenuItem>
						<MenuItem value={200}>200</MenuItem>
					</Select>
				</FormControl>
			</Box>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	table: {
		"& .rdt_TableHeadRow": {
			"& > *": {
				"&:last-child": {
					filter: "drop-shadow(0px 5px 24px rgba(149, 157, 165, 0.25))",
					position: "sticky",
					right: 0,
					zIndex: 20,
					background: "#3855EC",
					width: 180,
					minWidth: 180,
					flexGrow: "initial",
					padding: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}
			}
		},

		"& .rdt_TableBody": {
			"& .rdt_TableRow:nth-child(2n)": {
				"& > *": {
					"&:last-child": {
						background: "#F4F4F4"
					}
				}
			}
		},
		"& .rdt_TableRow": {
			"& > *": {
				"&:last-child": {
					filter: "drop-shadow(0px 5px 24px rgba(149, 157, 165, 0.25))",
					position: "sticky",
					right: 0,
					zIndex: 20,
					background: "#fcfcfc",
					width: 180,
					minWidth: 180,
					flexGrow: "initial",
					padding: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}
			}
		},
	},

	paginationWrapper: {
		display: "flex",
		alignItems: 'center',
	},
	limitSelectWrapper: {
		width: 200,
		marginLeft: 20,
	},


	characteristic: {
		padding: "4px 6px",
		borderRadius: 4,
		fontSize: 12,
		lineHeight: "15px",

		"&.collection": {
			backgroundColor: "#A414A7",
			color: "white"
		},
		"&.product": {
			backgroundColor: "#F9F0FA",
			color: "#A414A7"
		},
	},
}));

export default TablePagination