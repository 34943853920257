import React from "react";
import {
	Box,
	Grid,
	Tooltip,
	Typography
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";

class RowToggleText extends React.PureComponent {
	render() {
		const {
			value,
			classes
		} = this.props;
		const isArray = Array.isArray(value);

		if (isArray) {
			return (
				<Grid container spacing="4px" className={classes.tags}>
					{value.map((item) => (
						<Grid item>
							<Tooltip
								title={item}
								arrow
							>
                <p>{item}</p>
							</Tooltip>
						</Grid>
					))}
				</Grid>
			)

		}

		return (
			<Tooltip
				title={value}
				arrow
			>
				<Typography
					className={classes.text}
					dangerouslySetInnerHTML={{
						__html: value
					}}
				/>
			</Tooltip>
		)
	}
}

const styles = {
  tags: {
    maxWidth: 134,

    "& > div": {
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "& p": {
      padding: "4px 6px",
      borderRadius: "100px",
      backgroundColor: "rgba(0, 0, 0, 0.08)",

      fontSize: 10,
      lineHeight: "11px",
      color: "black",
      textAlign: "center",
      textTransform: "uppercase",
      whiteSpace: "nowrap!important",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "inherit"
    }
  },
	text: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		"-moz-box-orient": "vertical",
		display: "-moz-box",
		display: "-webkit-box",
		"-webkit-box-orient": "vertical",
		boxOrient: "vertical",
		lineClamp: "2",
		"-webkit-line-clamp": "2"
	}
}
const StylesRowToggleText = withStyles(styles)(RowToggleText)

export default StylesRowToggleText
