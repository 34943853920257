import React from "react";
import {
	TableRow,
	TableCell,

	IconButton,
	Tooltip,
	Checkbox
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Delete as DeleteIcon
} from "@mui/icons-material";
import clsx from "clsx";
import agent from "../../../../../agent/agent";
import {numberFormat} from "../../../../../common/Formater";

class ProductRow extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {}
	}

	changePriceCoefficient = (event, value) => {
		this.props.onChangeProductPriceCoefficient({
			...this.props.product,
			price_coefficient: Boolean(value) ? "1.00" : "0.00"
		})
	}

	render () {
		const {
			product,
			classes,
			selectedRows,

			DragHandle,
			shopCharacteristicGroups,

			onRemoveProduct,
			onChangeSelectedRows
		} = this.props;
		const productStatus = Boolean(product.relatedProduct.status);

		return (
			<TableRow
        hover
				className={clsx({
					[classes.rowStatusOff]: !productStatus
				})}
			>
				<TableCell>
					<Checkbox
						checked={Boolean((selectedRows || []).includes(product.id))}
						onChange={onChangeSelectedRows.bind(this, product.id)}
					/>
				</TableCell>
				<TableCell width={50}>
					<img
						className={classes.productImage}
						src={`${process.env.REACT_APP_HOST_API}api/storage/image/${ product?.relatedProduct?.main_photo_id }_w-500_h-500.webp`}
					/>
				</TableCell>
				<TableCell width="35%">
					{ product?.relatedProduct?.name }{!productStatus && " (ОТКЛЮЧЕН)"}
				</TableCell>
				<TableCell>
          { numberFormat(product?.relatedProduct?.price_new) }
				</TableCell>
				<TableCell>
					{ product?.relatedProduct?.code }
				</TableCell>
				<TableCell>
					{(shopCharacteristicGroups || []).find((t) => t.id === product?.relatedProduct?.characteristic_group_id)?.name || "Незадана"}
				</TableCell>
				<TableCell align="center">
					<Checkbox
						value={Boolean(product.price_coefficient !== "0.00")}
						checked={Boolean(product.price_coefficient !== "0.00")}
						onChange={this.changePriceCoefficient}
					/>
				</TableCell>
				<TableCell>
					<DragHandle/>
				</TableCell>
				<TableCell>
					<Tooltip title="Удалить товар" arrow>
						<IconButton onClick={() => onRemoveProduct(product)}>
							<DeleteIcon color="error"/>
						</IconButton>
					</Tooltip>
				</TableCell>
			</TableRow>
		)
	}
}

const styles = {
	rowStatusOff: {
		backgroundColor: "rgba(229, 57, 53, 0.2)!important"
	},

	productCard: {},
	productImage: {
		width: 50,
		height: 50,
		objectFit: "contain",
		borderRadius: 4,
		backgroundColor: "white"
	}
}
ProductRow = withStyles(styles)(ProductRow)

export default ProductRow
