import agent from "../agent/agent";
import urls from "../variables/urls";

const filesUpload = async ({file}) => {

    const formData = new FormData();
    formData.append('folderId', process.env.REACT_APP_FOLDER_ID);
    formData.append('file', file);

    const data = await agent.post(urls.filesUpload, formData).then((res) => {
        return {
            data: res.data,
            error: false
        }
    }).catch((err) => {
        return {
            data: err.response,
            error: true
        }
    });

    return data
}
const getFileId = async (fileId) => {

    return await agent.get(`${urls.getFileId}${fileId}`).then((res) => {
        return res.data.file
    }).catch(() => {
        return null
    })
}

const getImageId = async (imageId, imageCaption) => {
    return await agent.get(`${urls.getImageId}${imageId}`).then((res) => {

        let file = {
            ...res.data.file,
            caption: imageCaption,
            source: res.data.source
        }

        return file
    }).catch(() => {
        return null
    })
}
const imageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return await agent.post(`/api/admin/storage/upload-images`, formData).then((res) => {
        return res.data
    }).catch(() => {
        return {}
    })
}

export {
    filesUpload,
    getFileId,

    getImageId,
    imageUpload
}
