import React, {useEffect, useState} from 'react';
import {ErrorMessage, Formik} from "formik";
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    Grid,
    IconButton,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {
    AttachFileOutlined as AttachFileIcon, Delete as DeleteIcon,
    ExpandLess as LessIcon,
    ExpandMore as MoreIcon, Upload as UploadIcon
} from "@mui/icons-material";
import {getImageId} from "../../../../../common/FilesUpload";
import * as Yup from "yup";

const MainFormComponent = (props) => {
    const {
        refFormik,
        brand,

        isLoading,
        isCreate,
    } = props;
    const classes = useStyles();
    const [openSettings, setOpenSettings] = useState(false);
    const [openSeoSettings, setOpenSeoSettings] = useState(false);
    const [openImageSettings, setOpenImageSettings] = useState(false);
    const handleSubmit = () => {
    };

    const handleChange = ({target}) => {
        const {name, value} = target;

        let newForm = refFormik.current.values || {};
        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleChangeBoolean = ({target}, value) => {
        const {name} = target;

        let newForm = refFormik.current.values;
        newForm[name] = value ? 1 : 0;

        refFormik.current.setValues(newForm);
    }

    const handleChangeImage = (event) => {
        const file = event?.target.files?.[0] || null;

        let newValues = refFormik.current?.values || {};
        let image_id = {...newValues.image_id};
        image_id.file = file;
        image_id.source = URL.createObjectURL(file);

        newValues.image_id = image_id;
        refFormik.current.setValues(newValues);
    };

    const handleDeleteFile = (fieldName) => {
        refFormik.current.setFieldValue(fieldName, null);
    };

    return (
        <>
            <Formik
                innerRef={refFormik}
                initialValues={brand}
                validationSchema={validationSchema}

                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched
                    } = props;

                    return (
                        <>
                            <Grid container alignItems="center" mb={3}>
                                <Grid item xs={2}><Typography variant="h5">Название *</Typography></Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        color="primary"
                                        value={values.name}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                        name="name"
                                        placeholder="Введите название"

                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" mb={3}>
                                <Grid item xs={2}><Typography variant="h5">URL-адрес</Typography></Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        color="primary"
                                        value={values.slug}
                                        error={Boolean(touched.slug && errors.slug)}
                                        helperText={touched.slug && errors.slug}
                                        name="slug"
                                        placeholder="Введите URL-адрес"

                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" mb={3}>
                                <Grid item xs={2}><Typography variant="h5">Описание</Typography></Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        color="primary"
                                        value={values.description}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                        name="description"
                                        placeholder="Введите описание"

                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" mb={3}>
                                <Grid item xs={2}><Typography variant="h5">Альтернативное название</Typography></Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        color="primary"
                                        value={values.second_title}
                                        error={Boolean(touched.second_title && errors.second_title)}
                                        helperText={touched.second_title && errors.second_title}
                                        name="second_title"
                                        placeholder="Введите альтернативное название"

                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" mb={3}>
                                <Grid item xs={2}><Typography variant="h5">Страна</Typography></Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        color="primary"
                                        value={values.country}
                                        error={Boolean(touched.country && errors.country)}
                                        helperText={touched.country && errors.country}
                                        name="country"
                                        placeholder="Введите страну"

                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" mb={3}>
                                <Grid item xs={2}><Typography variant="h5">Сортировка</Typography></Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        color="primary"
                                        value={values.sort}
                                        error={Boolean(touched.sort && errors.sort)}
                                        helperText={touched.sort && errors.sort}
                                        name="sort"
                                        placeholder="Введите сортировку"

                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>

                            <Box className={classes.collapseContainer} mb={3}>
                                <Button
                                    fullWidth
                                    size="large"
                                    sx={{
                                        height: "50px",
                                        justifyContent: "flex-start",
                                    }}
                                    color="primary"
                                    variant="contained"
                                    startIcon={openSettings ? <LessIcon/> : <MoreIcon/>}

                                    onClick={() => setOpenSettings(!openSettings)}
                                >
                                    Настройки
                                </Button>
                                <Collapse in={openSettings} timeout="auto">
                                    <Box className={classes.collapseBox}>
                                        <Grid container alignItems="center" spacing={1} mb={3}>
                                            <Grid item xs={2}>
                                                <Typography variant="h5" fontWeight={400}>
                                                    Статус
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <FormControlLabel
                                                    value={values.status}
                                                    name="status"
                                                    color="primary"
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={Boolean(values.status)}
                                                        />
                                                    }
                                                    label=""

                                                    onChange={handleChangeBoolean}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="h5" fontWeight={400}>
                                                    Показывать в меню
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <FormControlLabel
                                                    value={values.show_in_menu}
                                                    name="show_in_menu"
                                                    color="primary"
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={Boolean(values.show_in_menu)}
                                                        />
                                                    }
                                                    label=""

                                                    onChange={handleChangeBoolean}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Collapse>
                            </Box>

                            <Box className={classes.collapseContainer} mb={3}>
                                <Button
                                    fullWidth
                                    size="large"
                                    sx={{
                                        height: "50px",
                                        justifyContent: "flex-start",
                                    }}
                                    color="primary"
                                    variant="contained"
                                    startIcon={openSeoSettings ? <LessIcon/> : <MoreIcon/>}

                                    onClick={() => setOpenSeoSettings(!openSeoSettings)}
                                >
                                    SEO
                                </Button>
                                <Collapse in={openSeoSettings} timeout="auto">
                                    <Box className={classes.collapseBox}>
                                        <Grid container alignItems="center" spacing={1} mb={3}>
                                            <Grid item xs={2}>
                                                <Typography variant="h5" fontWeight={400}>
                                                    SEO Title
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    color="primary"
                                                    value={values.seo_title}
                                                    name='seo_title'
                                                    placeholder="Введите заголовок SEO"

                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems="center" spacing={1} mb={3}>
                                            <Grid item xs={2}>
                                                <Typography variant="h5" fontWeight={400}>
                                                    SEO Description
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    color="primary"
                                                    value={values.seo_description}
                                                    name='seo_description'
                                                    placeholder="Введите описание SEO"

                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="h5" fontWeight={400}>
                                                    SEO Keywords
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    color="primary"
                                                    value={values.seo_keywords}
                                                    name='seo_keywords'
                                                    placeholder="Введите ключевые слова SEO"

                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Collapse>
                            </Box>

                            <Box className={classes.collapseContainer}>
                                <Button
                                    fullWidth
                                    size="large"
                                    sx={{
                                        height: "50px",
                                        justifyContent: "flex-start",
                                    }}
                                    color="primary"
                                    variant="contained"
                                    startIcon={openImageSettings ? <LessIcon/> : <MoreIcon/>}

                                    onClick={() => setOpenImageSettings(!openImageSettings)}
                                >
                                    Изображения
                                </Button>
                                <Collapse in={openImageSettings} timeout="auto">
                                    <Box className={classes.collapseBox}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="h5" fontWeight={400}>
                                                    Основное изображение
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                {values.image_id
                                                    ? (
                                                        <>
                                                            <CategoryImage imageId={values.image_id} viewImage/>
                                                            <CategoryImage
                                                                imageId={values.image_id}
                                                                viewFile
                                                                handleDeleteImage={handleDeleteFile}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            <label style={{
                                                                cursor: "pointer",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}>
                                                                <UploadIcon sx={{marginRight: "5px"}}/>
                                                                Выберите файл
                                                                <input
                                                                    type="file"
                                                                    name="image_id"
                                                                    accept="image/*"
                                                                    hidden
                                                                    onChange={(event) => handleChangeImage(event)}
                                                                />
                                                            </label>
                                                        </Button>
                                                    )
                                                }
                                                <ErrorMessage
                                                    name="image_id"
                                                    render={(errorMessage) => (
                                                        <Typography
                                                            className={classes.errorMessage}>{errorMessage}</Typography>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Collapse>
                            </Box>


                        </>
                    );
                }}
            </Formik>
        </>
    );
};

const CategoryImage = React.memo((props) => {
    const {
        imageId,
        viewImage,
        viewFile,

        handleDeleteImage
    } = props;
    const [image, setImage] = useState([]);

    const convertImage = async () => {
        if (typeof imageId !== "object") {
            let convertedImageObj;
            if (imageId) {
                convertedImageObj = await getImageId(imageId);
            }
            setImage(convertedImageObj);
        }
    }

    useEffect(() => {
        (async () => {
            await convertImage();
        })();
    }, [imageId])
    return (<>
        {Boolean(viewImage)
            && (
                <Box
                    width={300}
                    height={300}
                    sx={{
                        backgroundColor: "rgba(0,87,255, 0.05)",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    mb={2}
                >
                    <img src={imageId.source || image.source} style={{
                        display: "block", width: 250, objectFit: "cover", borderRadius: 5,
                    }} alt=""/>
                </Box>
            )
        }
        {Boolean(viewFile)
            && (
                <Box display="flex">
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                            background: "#3855EC",
                            borderRadius: "4px",
                            color: "#fff",
                            fontSize: "14px",
                            justifyContent: "center",
                            marginRight: "5px"
                        }}
                    >
                        <AttachFileIcon
                            sx={{
                                marginRight: "5px",
                            }}
                        />
                        {imageId?.file?.name || image.name_original}
                    </Box>
                    <IconButton
                        color="error"

                        onClick={() => handleDeleteImage('image_id')}
                    >
                        <Tooltip title="Удалить файл">
                            <DeleteIcon/>
                        </Tooltip>
                    </IconButton>
                </Box>
            )
        }
    </>);
});


const useStyles = makeStyles({
    collapseContainer: {
        border: "1px solid #0057FF",
        borderRadius: "6px",
    },
    collapseBox: {
        padding: "20px",
    },

    errorMessage: {
        color: "#e53935",
        fontSize: "11px",
        letterSpacing: "0.33px",
        lineHeight: "13px",
        fontWeight: "400",
        textAlign: "left",
        marginTop: "4px",
        marginRight: "14px",
        marginBottom: 0,
        marginLeft: "14px",
    }
})

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Заполните поле'),
});

export default MainFormComponent;