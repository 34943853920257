import React from "react";
import {
    Checkbox
} from "@mui/material";
import {

} from "@mui/styles";

class RowToggleStatus extends React.PureComponent {
    render() {
        return (
            <Checkbox checked={this.props.value} />
        )
    }
}

export default RowToggleStatus
