import React from "react";
import {
	Box,
	Grid,
	Button,
	TextField,
	Typography
} from "@mui/material";
import {
	Formik
} from "formik";

class VideosForm extends React.PureComponent {

	handleSubmit = () => {}

	addVideoLink = () => {
		let values = {...(this.props.innerRef?.current?.values || {})};
		let video_link = [...(values?.video_link || [])];
		video_link.push({ value: '' });
		values.video_link = video_link;
		this.props.innerRef?.current.setValues(values);
	}
	removeVideoLink = (index) => {
		let values = {...(this.props.innerRef?.current?.values || {})};
		let video_link = [...(values?.video_link || [])];
		video_link.splice(index, 1);
		values.video_link = video_link;
		this.props.innerRef?.current.setValues(values);
	}
	changeVideoLink = (index, { target }) => {
		const { value } = target;
		let values = {...(this.props.innerRef?.current?.values || {})};
		let video_link = [...(values?.video_link || [])];
		video_link[index].value = value;
		values.video_link = video_link;
		this.props.innerRef?.current.setValues(values);
	}

	render () {
		const {
			innerRef,
			initialValues
		} = this.props;

		return (
			<Formik
				innerRef={innerRef}
				initialValues={initialValues}
				onSubmit={this.handleSubmit}
			>{(props) => {
				const {
					values
				} = props;

				return (
					<Box px={2} py={2} bgcolor="white" borderRadius={2}>
						<Box mb={2}>
							<Typography variant="h3">Видео</Typography>
						</Box>
						<Grid container spacing={2}>
							{(values.video_link || []).map((videoItem, index) => (
								<Grid item container spacing={2} xs={12}>
									<Grid item sx={{flex: 1}}>
										<TextField
											value={videoItem.value || ''}
											fullWidth
											placeholder="..."
											label="Ссылка на видео"
											onChange={this.changeVideoLink.bind(this, index)}
										/>
									</Grid>
									<Grid item>
										<Button
											color="error"
											variant="outlined"
											sx={{height: 36, color: '#e53935', borderColor: '#e53935'}}
											onClick={this.removeVideoLink.bind(this, index)}
										>
											Удалить
										</Button>
									</Grid>
								</Grid>
							))}
							<Grid item xs={12}>
								<Button variant="outlined" fullWidth onClick={this.addVideoLink}>
									Добавить видео
								</Button>
							</Grid>
						</Grid>
					</Box>
				)
			}}</Formik>
		)
	}
}

export default VideosForm
