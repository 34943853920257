import React from 'react';
import {
	FilterBaseString,
	FilterBaseSelectArray,
	FilterBaseTaggablePlugin, AutocompleteSites
} from "../../../../../components";
import {
	Box,
	Grid,
	Button,
	Checkbox,
	FormControlLabel, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import {makeStyles} from "@mui/styles";

const Filter = React.memo((props) => {
	const {
		filter,
		productsAttributes,
		shopCharacteristicGroups,

		onChange,
		onSearch,
	} = props;
	const classes = useStyles();

	const changeCommon = React.useCallback(({target}) => {
		const {value, name} = target;

		let newData = {...filter}
		newData[name] = value;

		onChange(newData, false)
	}, [filter])
	const changeSelectArray = React.useCallback(async ({target}) => {
		const {value, name} = target;

		let newData = {...filter}
		newData[name] = value;

		await onChange(newData, true);
	}, [filter])

	const formSearch = (event) => {
		var keyCode = event ? (event.which ? event.which : event.keyCode) : event.keyCode;
		if (keyCode == 13) {
			onSearch();
		}
	}
	const changeFilter = ({target}) => {
		const { name, value } = target;

		let newFilter = {...filter};
		newFilter[name] = value;

		onChange(newFilter);
	}

	return (
		<form
			onKeyDown={formSearch}
		>
			<Box bgcolor="white" borderRadius={2} py={2} px={2}>
				<Grid container spacing={2} className={classes.grid} alignItems="flex-end">
					<Grid item xs={2} className={classes.filterItem}>
						<FilterBaseString
							name="filter[name][like]"
							label="Наименование"
							value={filter['filter[name][like]']}
							onChange={changeCommon}
						/>
					</Grid>
					<Grid item xs={2} className={classes.filterItem}>
						<FilterBaseString
							name="filter[code][like]"
							label="Артикул"
							value={filter['filter[code][like]']}
							onChange={changeCommon}
						/>
					</Grid>
					<Grid item xs={2} className={classes.filterItem}>
						<FilterBaseSelectArray
							{...productsAttributes.find((t) => t.nameField === "brand_id")}
							label="Производитель"
							nameField="filter[brand_id]"
							value={filter['filter[brand_id]']}
							onChange={changeSelectArray}
						/>
					</Grid>
					<Grid item xs={2} className={classes.filterItem}>
						<FilterBaseSelectArray
							{...productsAttributes.find((t) => t.nameField === "characteristic_group_id")}
							label="Тип товара"
							nameField="filter[characteristic_group_id]"
							value={filter['filter[characteristic_group_id]']}
							onChange={changeSelectArray}
						/>
					</Grid>
					<Grid item xs={2} className={classes.filterItem}>
						<FilterBaseTaggablePlugin
							{...productsAttributes.find((t) => t.nameField === "extraProductSetParents")}
							label="Входит в комплект"
							nameField="filter[extraProductSetParents]"
							value={filter['filter[extraProductSetParents]']}
							onChange={changeSelectArray}
						/>
					</Grid>
					<Grid item xs={2} className={classes.filterItem}>
						<FormControlLabel
							control={<Checkbox/>}
							value={filter.showOnlyMainPhoto}
							checked={filter.showOnlyMainPhoto}
							name="showOnlyMainPhoto"
							label="Показывать только главное фото"
							sx={{marginTop: "14px"}}
							onChange={(event, value) => changeFilter({target: {name: 'showOnlyMainPhoto', value}})}
						/>
					</Grid>
					<Grid item xs={2} className={classes.filterItem}>
						<FormControl fullWidth>
							<InputLabel>Статус</InputLabel>
							<Select
								value={filter['filter[status]']}
								label="Статус"
								name="filter[status]"
								onChange={changeCommon}
							>
								<MenuItem value="">Все</MenuItem>
								<MenuItem value="1">Активные</MenuItem>
								<MenuItem value="0">Отключенные</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2} className={classes.filterItem}>
						<AutocompleteSites
							value={filter['filter[extraWebsites]'] || []}
							name="filter[extraWebsites]"
							onChange={changeCommon}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{marginTop: "1px"}}>
					<Grid item>
						<Button sx={{paddingX: "100px"}} variant="contained" onClick={onSearch}>Поиск</Button>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
});

const useStyles = makeStyles({
	root: {},
	title: {
		fontWeight: 500,
		fontSize: 20,
		lineHeight: "23px",
		color: "#000000"
	},
	filterItem: {
		"& > .MuiBox-root": {
			flexDirection: "column",
			background: "transparent",
			padding: 0,

			"& > .MuiTypography-root": {
				fontWeight: 400,
				fontSize: 14,
				lineHeight: "17px",
				marginBottom: 2,
				marginLeft: 6,
			}
		}
	},

	grid: {
		"& .MuiBox-root": {
			width: "100%",
			maxWidth: "initial"
		},
		"& .BaseToggleStatus-root-83": {
			paddingTop: 17,
			flexDirection: "row"
		}
	}
})

export default Filter;
