const checkValidImageLoading = async (file) => {

  // Проверка на максимальный размер файла (5 мегабайт)
  if ((file?.size || 0) > 5242880) {
    return {error: "Превышен максимальный размер файла (5 мб)"}
  }

  // Проверка на размер изображения
  const imageFile = new Image();
  imageFile.src = URL.createObjectURL(file);
  await imageFile.decode();
  if (imageFile.naturalHeight > 3000 || imageFile.naturalWidth > 3000) {
    return {error: "Максимальное разрешение фото 3000x3000"}
  }

  return {}
}
const checkValidImagesLoading = (files) => {}

export {
  checkValidImageLoading,
  checkValidImagesLoading
}
