import React, {useState, useEffect} from "react";
import {
    Autocomplete,
    TextField
} from "@mui/material";
import agent from "../../agent/agent";
import urls from "../../variables/urls";

const keyValue = "value";

const TextFieldComponent = (props) => {
    const { itemKey } = props;
    const [options, setOptions] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        ( async () => {
            if ((props.value || []).length > 0) {
                await handleGetOptions();
            }
        })();
    }, []);

    const handleGetOptions = async () => {

        if (isLoading || options.length > 0) {
            return
        }

        setLoading(true);

        const data = await agent.get(`${ urls.getCharacteristicVariants }/${ itemKey }`).then((res) => {
            return res.data
        }).catch(() => {
            return []
        });

        setOptions(data)
    }
    const handleOnChange = (value, name) => {
        value = value.map((t) => {
            if (typeof t !== "object") {
                return t
            }

            return t[keyValue]
        });

        props.onChange({
            target: {
                name,
                value: value
            }
        })
    }

    const _options = () => {
        return options
    }
    const _filterOptions = (options) => {
        return options
    }
    const _getOptionLabel = (row) => {
        if (typeof row !== 'object') {
            return options.find((t) => String(t[keyValue]) === String(row))?.value
        }

        return row.value
    }

    return (
        <Autocomplete
            {...props}
            value={props.value || []}
            disablePortal
            options={_options()}
            onChange={(event, value) => handleOnChange(value, itemKey)}
            name={itemKey}
            variant="outlined"
            fullWidth
            multiple
            autoComplete={true}
            loading={isLoading}
            getOptionLabel={_getOptionLabel}
            onOpen={handleGetOptions}
            filterOptions={_filterOptions}
            limitTags={1}
            renderInput={(params) => <TextField
                {...params}
                label={props.name}
                fullWidth
            />}
        />
    )
}

export default TextFieldComponent
