import React from 'react';
import {Box, Button, Grid} from "@mui/material";
import {
    ArrowDropDownCircleOutlined as BackIcon,
    DeleteOutlineOutlined as DeleteIcon,
    LayersOutlined as DoubleIcon,
    ReplyOutlined as ResetIcon,
    CheckCircleOutlineOutlined as CheckIcon,
    SaveOutlined as SaveIcon,

} from '@mui/icons-material';
import {makeStyles} from "@mui/styles";

const ControlProductEdit = (props) => {
    const {
        duplicateProduct,
        onEditProduct,
        onDeleteProduct,
        userRoles
    } = props;
    const classes = useStyles();
    return (
        <Box sx={{
            bgcolor: '#ffffff',
            padding: '8px 9px',
            marginBottom: '12px',
            borderRadius: 1,
        }}>
            <Grid container justifyContent='space-between' spacing={1}>
                {Boolean(Array.prototype.contains(['admin'], userRoles)) && (
                  <Grid item xs={1}>
                      <Button
                        variant="contained"
                        size="medium"
                        color="error"
                        fullWidth
                        startIcon={<DeleteIcon/>}
                        onClick={onDeleteProduct}
                      >
                          Удалить
                      </Button>
                  </Grid>
                )}
                <Grid item xs>
                    <Button
                        className={classes.Button}
                        variant="outlined"
                        size="medium"
                        startIcon={<DoubleIcon sx={{
                            transform: 'rotate(-135deg)',
                        }}/>}

                        onClick={() => duplicateProduct(false)}
                    >
                        Дублировать
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <Button className={classes.Button} variant="contained" size="medium"
                            startIcon={<CheckIcon/>} onClick={() => onEditProduct(false)}>
                        Сохранить и продолжить
                    </Button>
                </Grid>
                <Grid item xs>
                    <Button className={classes.Button} color="success" variant="contained" size="medium"
                            startIcon={<SaveIcon/>} onClick={() => onEditProduct(true)}>
                        Сохранить
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};


const useStyles = makeStyles({
    Button: {
        width: "100%",
        '&:disabled': {
            background: '#62B4FF',
            color: '#ffffff',
            '& svg': {
                fill: '#ffffff',
                opacity: .5,
            },
        }
    },
});

export default ControlProductEdit;