import React from "react";
import agent from "../../../../../agent/agent";
import urls from "../../../../../variables/urls";
import {Notification, NotificationTypes} from "../../../../../common/Notification";
import {
	Box, Button,
	Dialog, DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel, MenuItem,
	Select,
	Typography
} from "@mui/material";
import {numberFormat} from "../../../../../common/Formater";

class ModalSetOptions extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedOptions: {},

			product: null,
			onSubmit: null,

			isOpen: false
		}
	}

	open = ({ product, onSubmit }) => {
		this.setState({
			product,
			onSubmit,

			isOpen: true
		}, async () => {
			await this.getProductOptions();
		})
	}
	close = () => {
		this.setState({
			selectedOptions: {},

			product: null,
			onSubmit: null,

			isOpen: false
		})
	}

	getProductOptions = async () => {
		'relatedProduct.options,relatedProduct.options.variants';
		const expand = 'expand=extraOptions';
		const fields = 'fields=extraOptions,extraOptions.sort,extraOptions.optionVariant,extraOptions.optionVariant.size_height,extraOptions.optionVariant.size_depth,extraOptions.optionVariant.size_length,packages,extraOptions.optionVariant.price_old_value,extraOptions.optionVariant.option_preset_disable_sync_prices,extraOptions.optionVariant.option_preset_disable_sync_status';
		const ngrestCallType = "ngrestCallType=update";

		const search = [ngrestCallType, fields, expand].join('&')
		let res = await agent.get(`${urls.getProduct}/${this.state.product?.relatedProduct?.id}?${search}`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});

		let product = {
			...this.state.product,
			relatedProduct: {
				...this.state.product.relatedProduct,
				options: res?.extraOptions
			}
		}
		this.setState({
			product
		})
	}
	changeSelectedOptions = ({ target }) => {
		const { name, value } = target;
		let selectedOptions = {...this.state.selectedOptions};
		selectedOptions[name] = value;
		this.setState({selectedOptions});
	}

	onSubmit = () => {
		const { product, selectedOptions } = this.state;
		const options = product?.relatedProduct?.options || [];

		if (Object.keys(selectedOptions || {}).length !== options.length) {
			Notification({
				type: NotificationTypes.error,
				message: "Выберите опции товара"
			})
			return
		}

		this.state.onSubmit(product, selectedOptions)
	}

	render () {
		const {
			selectedOptions,
			product,
			isOpen
		} = this.state;
		const options = product?.relatedProduct?.options || [];

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3">Выбор опция товара</Typography>
				</DialogTitle>

				<DialogContent>
					<Box py={1}>
						<Grid container spacing={2}>
							{options.map((option) => (
								<Grid key={`option-${option.id}`} item xs={12}>
									<FormControl fullWidth>
										<InputLabel>{ option.name }</InputLabel>
										<Select
											value={selectedOptions[option.id]}
											name={option.id}
											label={option.name}
											onChange={this.changeSelectedOptions}
										>
											{(option?.variants || []).map((variant) => (
												<MenuItem key={`option-${option.id}-${variant.id}`} value={variant.id}>
													{variant.name} ({numberFormat(variant.price_value || 0)} р.)
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							))}
						</Grid>
					</Box>
				</DialogContent>

				<DialogActions>
					<Grid alignItems="flex-end" justifyContent="flex-end" container spacing={2} px={1}>
						<Grid item>
							<Button variant="outlined" onClick={this.close}>Закрыть</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" onClick={this.onSubmit}>Выбрать</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

export default ModalSetOptions
