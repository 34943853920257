import agent from "../agent/agent";

const UPDATE_USER = 'app/UPDATE_USER'
const SET_USER_ROLES = 'app/SET_USER_ROLES'
const SET_CURRENT_BUILD_VERSION = 'app/SET_CURRENT_BUILD_VERSION'
const SET_ADMIN_LOCKED = 'app/SET_ADMIN_LOCKED'

const initialState = {
	user: null,
	userRoles: [], // content | admin | manager

	adminLocked: [],

	currentBuildVersion: null
};

export function setUser(user) {
	return async function (dispatch) {
		const userRoles = await agent.get(`/admin/api-admin-user/${user?.id}?expand=groups`).then((res) => {
			return (res.data?.groups || []).map((t) => {
				if (t.name === "Administrator") {
					return "admin"
				}
				if (t.name === "Manager" || t.name === "Client Manager") {
					return "manager"
				}
				if (t.name === "Content" || t.name === "Content Manager" || t.name === "Product Content Manager") {
					return "content"
				}
			})
		}).catch(() => {
			return ""
		})

		dispatch({ type: UPDATE_USER, user })
		dispatch({ type: SET_USER_ROLES, userRoles })
	}
}
export function setAdminLocked (adminLocked) {
	return {
		type: SET_ADMIN_LOCKED,
		adminLocked
	}
}
export function setCurrentBuildVersion(currentBuildVersion) {
	return {
		type: SET_CURRENT_BUILD_VERSION,
		currentBuildVersion
	}
}

export default function AppState(state = initialState, action = {}) {
	switch (action.type) {
		case UPDATE_USER: {
			let user = action.user

			return {
				...state,
				user
			}
		}
		case SET_USER_ROLES: {
			let userRoles = action.userRoles

			return {
				...state,
				userRoles
			}
		}
		case SET_CURRENT_BUILD_VERSION: {
			let currentBuildVersion = action.currentBuildVersion

			return {
				...state,
				currentBuildVersion
			}
		}
		case SET_ADMIN_LOCKED: {
			let adminLocked = action.adminLocked

			return {
				...state,
				adminLocked
			}
		}
		default:
			return state;
	}
}
