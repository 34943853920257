import React from "react";
import {
	Box, svgIconClasses,
	Tooltip
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	LocationOn as LocationOnIcon
} from "@mui/icons-material";
import {
	Rnd
} from "react-rnd";
import clsx from "clsx";

const ProductDot = (props) => {
	const {
		item,
		config,
		product,
		onRemove,
		onChange,
	} = props;
	const classes = useStyles();
	const isOffProduct = !Boolean(product?.relatedProduct?.status);
	const productHasOptions = Boolean(product?.relatedProduct?.has_options);

	const [position, setPosition] = React.useState({ x: 0, y: 0 });
	React.useEffect(() => {
		setPosition({
			x: (config.width / 100) * item.left,
			y: (config.height / 100) * item.top
		})
	}, [item, config]);


	const handleRemove = (event) => {
		event.preventDefault();
		onRemove();
		return false
	}
	const handleChangePosition = (e, d) => {
		onChange({left: d.x, top: d.y});
		setPosition({x: d.x, y: d.y});
	}

	return (
		<Rnd
			size={{width: 4, height: 4}}
			position={{x: position.x, y: position.y}}
			enableResizing={false}
			lockAspectRatio={1}
			onDragStop={handleChangePosition}
			bounds="#image-annotations-image-container"
		>
			<Tooltip title={product?.relatedProduct?.name} arrow>
				<Box
					className={clsx({
						[classes.dotCard]: true,
						'--off-product': Boolean(isOffProduct),
						'--error-options': Boolean(!!productHasOptions && !item.option),
					})}
					onContextMenu={handleRemove}
				>
					<LocationOnIcon sx={{ color: "#A414A7", fontSize: 42 }}/>
				</Box>
			</Tooltip>
		</Rnd>
	)
};

const useStyles = makeStyles(() => ({
	dotCard: {
		width: 4,
		height: 4,
		borderRadius: "100%",
		background: "white",
		position: "relative",

		"& svg": {
			position: "absolute",
			left: "50%", bottom: 0,
			transform: "translateX(-50%)"
		},
		"&.--error-options": {
			"& svg": {
				color: "red"
			},
			"&:after": {
				content: "'Для товара необходимо выбрать опции. Удалите метку и добавьте товар снова'",
				position: "absolute",
				top: "100%", left: "50%",
				transform: "translateX(-50%)",
				width: "160px",
				padding: "6px 8px",
				boxSizing: "border-box",
				background: "red",
				borderRadius: "10px",

				fontSize: 12,
				lineHeight: "120%",
				color: "white"
			}
		},
		"&.--off-product": {
			"& svg": {
				color: "#717171"
			},
			"&:after": {
				content: "'Данный товар отключен'",
				position: "absolute",
				top: "100%", left: "50%",
				transform: "translateX(-50%)",
				width: "160px",
				padding: "6px 8px",
				boxSizing: "border-box",
				background: "#717171",
				borderRadius: "10px",

				fontSize: 12,
				lineHeight: "120%",
				color: "white"
			}
		},
	}
}));

export default ProductDot
