import React from "react";
import {
    Box,
    Grid,
    Typography,
    TextField,
    Switch,
    Divider,
    Button
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";
import {
    RemoveCircleOutline as RemoveCircleOutlineIcon,
    SaveOutlined as SaveOutlinedIcon
} from "@mui/icons-material";
import {
    Notification,
    NotificationTypes
} from "../../common/Notification";
import clsx from "clsx";

class ApplyDiscount extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            percentDiscount: "",

            calculateDiscountCilek: false,
            roundDown100: false,
        }
    }

    changePercentDiscount = ({target}) => {
        let { value } = target;

        if (+value < 0) {
            value = 0
        }
        if (+value > 100) {
            value = 100
        }

        this.setState({
            percentDiscount: value
        })
    }
    changeCalculateDiscountCilek = () => {
        this.setState({
            calculateDiscountCilek: !this.state.calculateDiscountCilek
        })
    }
    changeRoundDown100 = () => {
        this.setState({
            roundDown100: !this.state.roundDown100
        })
    }

    resetForm = () => {
        this.setState({
            percentDiscount: "",
            calculateDiscountCilek: false,
            roundDown100: false,
        })
    }

    submit = () => {
        const {
            percentDiscount,
            calculateDiscountCilek,
            roundDown100
        } = this.state;

        if (!percentDiscount) {
            Notification({
                type: NotificationTypes.warning,
                message: "Введите процент скидки для товаров"
            })

            return
        }

        this.props.onSubmit({
            percent: percentDiscount,
            isDiscountCilek: calculateDiscountCilek,
            isDiscountRound: roundDown100
        })
    }

    render() {
        const {
            classes
        } = this.props;
        const {
            percentDiscount,
            calculateDiscountCilek,
            roundDown100
        } = this.state;

        return (
            <Box>

                <Box className={clsx([classes.graySection, classes.percentDiscount])}>
                    <Typography className={classes.graySectionLabel}>Процент скидки (число)</Typography>
                    <TextField
                        value={percentDiscount}
                        type="number"

                        onChange={this.changePercentDiscount}
                    />
                </Box>

                <Box mt="5px"/>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Box className={clsx([classes.graySection, classes.switchSection])} onClick={this.changeCalculateDiscountCilek}>
                            <Typography className={classes.graySectionLabel}>Рассчитать скидку по форме Cilek (1350 — 1399)</Typography>
                            <Switch checked={calculateDiscountCilek}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={clsx([classes.graySection, classes.switchSection])} onClick={this.changeRoundDown100}>
                            <Typography className={classes.graySectionLabel}>Рассчитать скидку по форме Cilek (1350 — 1399)</Typography>
                            <Switch checked={roundDown100}/>
                        </Box>
                    </Grid>
                </Grid>

                <Box mt="12px"/>

                <Divider/>

                <Box mt="12px"/>

                <Grid container spacing>
                    <Grid xs={6} item>
                        <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<RemoveCircleOutlineIcon/>}
                            onClick={this.resetForm}
                        >
                            Отмена
                        </Button>
                    </Grid>
                    <Grid xs={6} item>
                        <Button
                            variant="contained"
                            fullWidth
                            startIcon={<SaveOutlinedIcon sx={{color: "#62B4FF"}}/>}
                            onClick={this.submit}
                        >
                            Сохранить
                        </Button>
                    </Grid>
                </Grid>

            </Box>
        );
    }
}

const styles = {
    graySection: {
        backgroundColor: "#F4F4F4",
        borderRadius: 6
    },
    graySectionLabel: {
      fontSize: 16,
      lineHeight: "20px",
      color: "#000000"
    },

    percentDiscount: {
        display: "flex",
        alignItems: "center",
        padding: 24,

        "& $graySectionLabel": {
            width: 300
        },
        "& .MuiFormControl-root": {
            flex: 1
        },
        "& .MuiFormControl-root .MuiOutlinedInput-root": {
            fontWeight: "700"
        },
    },

    switchSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 15,
        cursor: "pointer",
        userSelect: "none"
    }
}
const StylesApplyDiscount = withStyles(styles)(ApplyDiscount)

export default StylesApplyDiscount
