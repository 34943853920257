import React from "react";
import {
	Autocomplete,
	TextField
} from "@mui/material";
import {
	IMaskInput
} from "react-imask";
import {
	Notification,
	NotificationTypes
} from "../../common/Notification";
import PropTypes from "prop-types";
import agent from "../../agent/agent";

const AutocompleteCreated = (props) => {
	const {
		value,
		options: initOptions,
		name,
		size,
		disabled,

		postUrl,
		postName,

		onChange
	} = props;
	const refAutocomplete = React.createRef();
	const [loading, setLoading] = React.useState(false);
	const [addedOptions, setAddedOptions] = React.useState([]);
	const options = [...initOptions, ...addedOptions]

	const handleChange = (event, newValue) => {
		if (typeof newValue === 'string') {
			handleSetValue({
				label: newValue,
			});
		}
		else if (newValue && newValue.inputValue) {
			// Create a new value from the user input
			handleSetValue({
				label: newValue.inputValue,
			});
		}
		else {
			handleSetValue(newValue);
		}
	}
	const handleSetValue = (value) => {
		if (value !== null && typeof value?.value === "undefined") {
			(async () => {
				await handleCreateValue(value);
			})();

			return
		}

		onChange({
			target: {
				name: name,
				value: value?.value
			}
		})
	}
	const handleCreateValue = async (value) => {
		let valueLabel = value?.label || "";

		const characteristic = props?.characteristic || {};
		if (characteristic.is_float && !/(\d+(?:[\.](?![\.])\d+)*)/.test(valueLabel)) {
			Notification({
				type: NotificationTypes.error,
				message: "Заполните корректно значение (только число с плавающей точкой)",
			})
			return
		}
		if (characteristic.is_float) {
			valueLabel = (value?.label || "").replaceAll(",", ".");
		}
		if (characteristic.is_integer && !/(^\d+?$)/.test(valueLabel)) {
			Notification({
				type: NotificationTypes.error,
				message: "Заполните корректно значение (только целое число)",
			})
			return
		}
		if (typeof valueLabel === "undefined" || typeof valueLabel === null) {
			Notification({
				type: NotificationTypes.error,
				message: "Заполните корректно значение",
			})
			return
		}

		setLoading(true);

		const body = {
			[postName]: name,
			value: valueLabel
		}
		const response = await agent.post(postUrl, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (response.error) {
			setLoading(false);

			Notification({
				type: NotificationTypes.error,
				message: response.error?.data?.message || (response.error?.data || []).map((t) => t?.message || "").filter((t) => !!t).join("<br/>") || "Ошибка сервера"
			})

			return null
		}

		setLoading(false);

		onChange({
			target: {
				name: name,
				value: response.id
			}
		}, {
			isUpdateAll: true
		})

		let newAddedOptions = [...addedOptions];
		newAddedOptions.push({label: valueLabel, value: response.id});
		setAddedOptions(newAddedOptions);

		Notification({
			type: NotificationTypes.success,
			message: "Запись была успешно сохранена"
		})

		document.body.focus();
	}

	const _filterOptions = (options, params) => {
		const { inputValue } = params;
		const searchInput = inputValue.toLowerCase();

		const filtered = [...options]
			.filter((t) => ((t?.label || "").toLowerCase()).indexOf(searchInput) > -1)
			.sort((a, b) => {
				const labelA = (a?.label || "").toLowerCase();
				const labelB = (b?.label || "").toLowerCase();

				if (labelA < searchInput || labelB < searchInput) {
					return -1
				}
				if (labelA > searchInput || labelB > searchInput) {
					return 1
				}
				return 0
			});

		const isExisting = options.some((option) => inputValue === option.label);
		if (inputValue !== '' && !isExisting) {
			filtered.push({
				inputValue,
				label: `Добавить "${inputValue}"`,
			});
		}

		return filtered;
	}
	const _getOptionLabel = (option) => {
		if (typeof option === 'string') {
			return option;
		}
		if (option.inputValue) {
			return option.inputValue;
		}
		if (option.label) {
			return option.label
		}

		return (options || []).find((t) => t.value === option)?.label || "";
	}
	const _renderInput = (params) => {
		return (
			<TextField
				{...params}
				size={size}
			/>
		)
	}

	return (
		<Autocomplete
			value={value}
			options={options}
			loading={loading}
			disabled={disabled}

			autoComplete={true}
			autoHighlight={true}
			fullWidth={true}
			blurOnSelect={true}
			autoSelect={false}

			onChange={handleChange}
			filterOptions={_filterOptions}
			getOptionLabel={_getOptionLabel}
			renderInput={_renderInput}

			renderOption={(props, option) => <li {...props}>{option.label}</li>}
		/>
	)
}

AutocompleteCreated.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	size: PropTypes.string,
	options: PropTypes.array.isRequired,

	postUrl: PropTypes.string.isRequired,
	postName: PropTypes.string.isRequired,

	onChange: PropTypes.func.isRequired
}
AutocompleteCreated.defaultProps = {
	size: "small"
}

export default React.memo(AutocompleteCreated)
