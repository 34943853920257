// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Brands from './Brands';

export default compose(
    connect(
        state => ({}),
        dispatch => ({}),
    ),
)(Brands);