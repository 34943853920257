import React, {Component, useEffect} from 'react';

import {
    Box,
    Checkbox,
    Grid,
    IconButton,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {
    AddBoxOutlined as AddBoxOutlinedIcon,
    ListAltOutlined as ListIcon,
    MoreVert as MoreIcon
} from "@mui/icons-material";


import moment from 'moment';
import {getImageId} from "../../../../../common/FilesUpload";
import {TableRowToggleImage} from "../../../../../components";
import {makeStyles} from "@mui/styles";


const TableComponent = (props) => {

    const {
        isLoading,
        categories,
        filter,
        pagination,

        onChangeFilter,
        onChangeStatusCategory,
        onChangeShowInMenu,
        onRouteEditPage,
        onRouteProductsPage
    } = props;
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newFilter = {...filter};
        newFilter.page = page;

        onChangeFilter(newFilter, true);
    }

    const handleChangeChecked = (row, e) => {
        e.stopPropagation();
        const checked = e.target.checked ? 1 : 0;
        onChangeStatusCategory(row, checked);
    }

    const handleChangeShowInMenu = (e, row) => {
        e.stopPropagation();
        const checked = e.target.checked ? 1 : 0;
        onChangeShowInMenu(row, checked);
    }

    const handleRouteProductsPage = (event, id) => {
        event.stopPropagation();
        onRouteProductsPage(id);
    }

    return (

        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center" width="50px">Основное изображение</TableCell>
                            <TableCell align="center">Название</TableCell>
                            <TableCell align="center">URL-адрес</TableCell>
                            <TableCell align="center">Заголовок</TableCell>
                            <TableCell align="center">Статус</TableCell>
                            <TableCell align="center">Показать в меню</TableCell>
                            <TableCell align="center">Кол-во товаров</TableCell>
                            <TableCell align="center">Цена товаров, от</TableCell>
                            <TableCell/>
                            <TableCell align="center">Обновлена</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Boolean(isLoading) ? (<>
                                {[0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    )
                                )}
                            </>

                        ) : (
                            <>
                                {

                                    categories.map((row, index) => (
                                            <TableRow
                                              hover
                                                key={`row-item-${index}`}

                                                onClick={() => onRouteEditPage(row.id)}
                                            >
                                                <TableCell align="center">{row.id || ''}</TableCell>
                                                <TableCell align="center">
                                                    <Box className={classes.imageContainer}>
                                                        <TableRowToggleImage value={row.image_id}/>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center">{row.extraName ?
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: row.extraName}}/> : ''}</TableCell>
                                                <TableCell align="center">{row.slug || ''}</TableCell>
                                                <TableCell align="center">{row.title || ''}</TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.status}

                                                        onClick={(e) => handleChangeChecked(row, e)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={row.show_in_menu}

                                                        onClick={(e) => handleChangeShowInMenu(e, row)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">{row.productCount || ''}</TableCell>
                                                <TableCell align="center">{row.product_price_from || ''}</TableCell>
                                                <TableCell align="center">
                                                    {row.extraUrl
                                                        ? <span
                                                            dangerouslySetInnerHTML={{__html: row.extraUrl}}

                                                            onClick={(e) => e.stopPropagation()}
                                                        />
                                                        : ''
                                                    }</TableCell>
                                                <TableCell align="center">
                                                    {moment(row.updated_at * 1000).format('DD.MM.YYYY HH:mm')}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Перейти к товарам этой категории">
                                                        <IconButton onClick={(e) => handleRouteProductsPage(e, row.id)}>
                                                            <ListIcon color="primary"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={2}>
                <Pagination
                    page={Number(filter.page)}
                    count={pagination.count || 1}

                    onChange={handleChangePage}
                />
            </Box>
        </>
    );
};

const useStyles = makeStyles({
    imageContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
})

export default TableComponent;
