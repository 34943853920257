import React from "react";
import {
	Box,
	Button,
	Popover,
	Divider,
	MenuItem,
	Collapse,
	Typography,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	FilterAlt as FilterAltIcon,
	LocalOffer as LocalOfferIcon,
	StarRate as StarRateIcon,
	Edit as EditIcon
} from "@mui/icons-material";
import {
	convertorCaseWords
} from "../../../../../helper/convertor";
import clsx from "clsx";
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state';

import FavoritesContent from "./FavoritesContent";
import FilterContent from "./FilterContent";
import AttributesContent from "./AttributesContent";

class Controls extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			openContent: "",
		}
	}

	// Логика для выбранных строк
	onSelectAllRows = () => {
		const {selected, products} = this.props;

		let newSelected = {...selected};
		newSelected.isAll = true;
		newSelected.ids = [];

		this.props.onChangeSelectedRows(newSelected);
	}
	onTakeOffAllRows = () => {
		const {selected} = this.props;

		let newSelected = {...selected};
		newSelected.isAll = false;
		newSelected.ids = [];

		this.props.onChangeSelectedRows(newSelected);
	}
	onSelectVisibleRows = () => {
		const {selected, products} = this.props;

		let newSelected = {...selected};

		if (newSelected.isAll) {
			newSelected.ids = (newSelected.ids || []).filter((id) => !Boolean(products.find((t) => t.id === id)));
		} else {
			newSelected.ids = newSelected.ids = [...newSelected.ids, ...products]
				.map((product) => product.id || product)
				.filter((element, index, array) => {
					return array.indexOf(element) === index;
				});
		}

		this.props.onChangeSelectedRows(newSelected);
	}
	onTakeOffVisibleRows = () => {
		const {selected, products} = this.props;

		let newSelected = {...selected};
		if (newSelected.isAll) {
			newSelected.ids = [...newSelected.ids, ...products]
				.map((product) => product.id || product)
				.filter((element, index, array) => {
					return array.indexOf(element) === index;
				});
		} else {
      const productsIds = (products || []).map((t) => t.id);
			newSelected.ids = [...newSelected.ids].filter((productId) => {
        return !Boolean(productsIds.includes(productId))
      });
		}

		this.props.onChangeSelectedRows(newSelected);
	}

	onOpenContent = (openContent) => {
		if (this.state.openContent === openContent) {
			openContent = ""
		}

		this.setState({openContent})
	}

	_labelTotalVisible = () => {
		const {totalCount, perPage, count} = this.props?.pagination || {};
		const {page} = this.props?.filter || {};

		if (totalCount <= 0) {
			return `Показано 0—0 из 0`
		}

		const startCount = (+page > 1) ? ((+page - 1) * +perPage + 1) : 1;
		const endCount = (+page === +count) ? totalCount : (+page === 1) ? (page * perPage) : (1 * perPage * page);
		return `Показано ${startCount}—${endCount} из ${totalCount}`
	}
	_labelSelectedRows = () => {
		const {ids, isAll} = this.props?.selected || {};
		const {count, totalCount, perPage} = this.props?.pagination || {};

		let totalCountItems = (ids || []).length;
		if (isAll && +count >= 1) {
			totalCountItems = totalCount - (ids || []).length;
		}
		if (isAll && +count < 1) {
			totalCountItems = ids.length;
		}

		const message = convertorCaseWords(totalCountItems, [
			"позиция выбрана",
			"позиции выбрано",
			"позиций выбрано",
		])
		return `${totalCountItems} ${message}`
	}

	_disableButtonEdit = () => {
		const {ids, isAll} = this.props?.selected || {};
		const {totalCount} = this.props?.pagination || {};

		const count = Boolean(!isAll) ? (ids || []).length : totalCount - (ids || []).length;

		return Boolean(count <= 0)
	}

	render() {
		const {
			openContent
		} = this.state;
		const {
			classes,
			filter,
			visibleColumns,
			popularFilter,
			userRoles,

			onSearch,
			onSearchFilter,
			onResetFilter,
			onChangeFilter,
			onChangePopularFilter,
			onChangeVisibleColumns,
			onDelete,
			onSetDiscount,
			onChangePrice,
			onCancelDiscount,
			onChangeMultiAttributes,
			onDeleteMultiProducts,
			onChangeAttributes,
			onResetDefaultSettings
		} = this.props;

		return (
			<>
				<Box className={clsx({
					[classes.root]: true,
					[classes.rootEnd]: Boolean(openContent === "multiAction" && !this._disableButtonEdit()),
				})}>
					{!Boolean(openContent === "multiAction" && !this._disableButtonEdit()) && (
						<Box className={classes.rootLeft}>
							<Typography className={classes.grayText}>
								{this._labelTotalVisible()}
							</Typography>
							<Button className={classes.buttonText} variant="text" onClick={this.onSelectAllRows}>
								Выбрать всё
							</Button>
							<Button className={classes.buttonText} variant="text" onClick={this.onTakeOffAllRows}>
								Снять всё
							</Button>
							<Button className={classes.buttonText} variant="text" onClick={this.onSelectVisibleRows}>
								Выбрать видимые
							</Button>
							<Button className={classes.buttonText} variant="text" onClick={this.onTakeOffVisibleRows}>
								Снять выбор с видимых
							</Button>
							<Typography className={classes.grayText}>
								{this._labelSelectedRows()}
							</Typography>
						</Box>
					)}
					<Box className={classes.rootRight}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => this.onOpenContent("filter")}
							startIcon={<FilterAltIcon sx={{color: "#0057FF"}}/>}
							className={clsx({
								[classes.buttonWhite]: true,
								[classes.buttonWhiteOpen]: Boolean(openContent === "filter"),
							})}
						>
							Фильтр
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => this.onOpenContent("attributes")}
							startIcon={<LocalOfferIcon sx={{color: "#0057FF"}}/>}
							className={clsx({
								[classes.buttonWhite]: true,
								[classes.buttonWhiteOpen]: Boolean(openContent === "attributes"),
							})}>
							Колонки
						</Button>
						{Boolean(false) && (
							<Button
								variant="contained"
								color="secondary"
								onClick={() => this.onOpenContent("favorites")}
								startIcon={<StarRateIcon sx={{color: "#0057FF"}}/>}
								className={clsx({
									[classes.buttonWhite]: true,
									[classes.buttonWhiteOpen]: Boolean(openContent === "favorites"),
								})}>
								Избранное
							</Button>
						)}


						<PopupState variant="popover" popupId="demo-popup-popover">
							{(popupState) => (
								<div>
									<Button
										variant="contained"
										color="secondary"
										startIcon={<EditIcon sx={{color: "#0057FF"}}/>}
										disabled={this._disableButtonEdit()}
										className={clsx({
											[classes.buttonWhite]: true,
											[classes.buttonWhiteOpen]: Boolean(openContent === "multiAction" && !this._disableButtonEdit()),
										})}
										{...bindTrigger(popupState)}
									>
										Действия
									</Button>
									<Popover
										{...bindPopover(popupState)}
										anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
										transformOrigin={{vertical: 'top', horizontal: 'right'}}
									>
										<Box sx={{padding: "8px 0"}}>
											<MenuItem onClick={() => onChangeMultiAttributes(false, {})}>Массовое изменение атрибутов</MenuItem>
											<Divider/>
											<MenuItem onClick={() => onSetDiscount(false, 1, {}, "percent")}>Применить скидку (процент)</MenuItem>
											<MenuItem onClick={() => onSetDiscount(false, 1, {}, "fixed")}>Применить скидку (рубли)</MenuItem>
											<MenuItem onClick={onCancelDiscount}>Отменить скидку</MenuItem>
											<Divider/>
											<MenuItem onClick={() => onChangePrice(false, {}, "percent")}>Изменить цену (процент)</MenuItem>
											<MenuItem onClick={() => onChangePrice(false, {}, "fixed")}>Изменить цену (рубли)</MenuItem>

											{Boolean(Array.prototype.contains(['admin'], userRoles)) && (
												<>
													<Divider/>
													<MenuItem onClick={onDeleteMultiProducts.bind(this, false)}>Удалить товары</MenuItem>
												</>
											)}
										</Box>
									</Popover>
								</div>
							)}
						</PopupState>
					</Box>
				</Box>

				<Collapse in={Boolean(openContent)} timeout="auto">
					<Box className={classes.content}>

						{Boolean(openContent === "favorites") && (
							<FavoritesContent
								data={popularFilter}
								onChange={onChangePopularFilter}
							/>
						)}

						{Boolean(openContent === "attributes") && (
							<AttributesContent
								data={visibleColumns}
								onChange={onChangeVisibleColumns}
								onResetDefaultSettings={onResetDefaultSettings}
							/>
						)}

						{Boolean(openContent === "filter") && (
							<FilterContent
								data={filter}
								visibleColumns={visibleColumns}

								onChange={onChangeFilter}
								onResetFilter={onResetFilter}
								onChangeVisibleColumns={onChangeVisibleColumns}
								onSearch={onSearch}
								onSearchFilter={onSearchFilter}
							/>
						)}

					</Box>
				</Collapse>
				{Boolean(openContent === "multiAction" && !this._disableButtonEdit()) && (
					<Box className={classes.rootLeft} mt="20px">
						<Typography className={classes.grayText}>
							{this._labelTotalVisible()}
						</Typography>
						<Button className={classes.buttonText} variant="text" onClick={this.onSelectAllRows}>
							Выбрать всё
						</Button>
						<Button className={classes.buttonText} variant="text" onClick={this.onTakeOffAllRows}>
							Снять всё
						</Button>
						<Button className={classes.buttonText} variant="text" onClick={this.onSelectVisibleRows}>
							Выбрать видимые
						</Button>
						<Button className={classes.buttonText} variant="text" onClick={this.onTakeOffVisibleRows}>
							Снять выбор с видимых
						</Button>
						<Typography className={classes.grayText}>
							{this._labelSelectedRows()}
						</Typography>
					</Box>
				)}
			</>
		);
	}
}

const styles = {
	root: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "space-between"
	},
	rootEnd: {
		justifyContent: "flex-end",
	},
	rootLeft: {
		display: "flex",
		alignItems: "center",
		"& > *": {
			marginLeft: 8,
			"&:first-child": {
				marginLeft: 0
			}
		}
	},
	rootRight: {
		display: "flex",
		alignItems: "center",
		"& > *": {
			marginLeft: 8,
			"&:first-child": {
				marginLeft: 0
			}
		}
	},

	grayText: {
		fontSize: 14,
		lineHeight: "18px",
		color: "#787878",
	},
	buttonText: {
		fontSize: 14,
		lineHeight: "18px",
		fontWeight: "400",
		color: "#0057FF",
		textTransform: "initial"
	},

	buttonWhite: {
		fontWeight: "700"
	},
	buttonWhiteOpen: {
		position: "relative",
		borderRadius: "4px 4px 0 0",
		"&:after": {
			content: "''",
			position: "absolute",
			width: "100%",
			left: 0,
			height: 8,
			top: "100%",
			background: "white"
		}
	},

	content: {
		display: "flex",
		flexDirection: "column",

		backgroundColor: "#FFFFFF",
		boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
		borderRadius: 6,
		padding: "12px 16px",
		boxSizing: "border-box",
		marginTop: 6,
		maxHeight: 370
	}
}
const StylesControls = withStyles(styles)(Controls);

export default StylesControls
