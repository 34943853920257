import React from 'react';
import {
    Box,
    Button,
    Grid
} from "@mui/material";
import {
    CheckCircleOutlineOutlined as CheckIcon,
    LayersOutlined as DoubleIcon,
    SaveOutlined as SaveIcon,
} from '@mui/icons-material';
import {makeStyles} from "@mui/styles";

const ControlCharacteristicCreate = (props) => {
    const {
        onSave,
    } = props;
    const classes = useStyles();
    return (
        <Box className={classes.ControlsContainer}>
            <Grid container justifyContent='space-between' spacing={1}>
                <Grid item xs>
                    <Button
                        className={classes.Button}
                        variant="contained"
                        size="medium"
                        startIcon={<DoubleIcon sx={{
                            transform: 'rotate(-135deg)',
                        }}/>}
                    >
                        Дублировать
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        className={classes.Button}
                        variant="contained"
                        size="medium"
                        startIcon={<CheckIcon/>}
                    >
                        Сохранить и продолжить
                    </Button>
                </Grid>
                <Grid item xs>
                    <Button
                        className={classes.Button}
                        variant="contained"
                        size="medium"
                        startIcon={<SaveIcon/>}

                        onClick={onSave}
                    >
                        Сохранить
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};


const useStyles = makeStyles({
    ControlsContainer: {
        backgroundColor: '#ffffff',
        padding: '8px 9px',
        marginBottom: '12px',
        borderRadius: 1,
    },
    Button: {
        width: "100%",
        '&:disabled': {
            background: '#62B4FF',
            color: '#ffffff',
            '& svg': {
                fill: '#ffffff',
                opacity: .5,
            },
        }
    },
});

export default ControlCharacteristicCreate;