import React from 'react';
import {Box, Button, Grid} from "@mui/material";
import {
    ArrowDropDownCircleOutlined as BackIcon,
    CheckCircleOutlineOutlined as CheckIcon,
    DeleteOutlineOutlined as DeleteIcon,
    LayersOutlined as DoubleIcon,
    ReplyOutlined as ResetIcon,
    SaveOutlined as SaveIcon
} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";

const ControlRegionalPriceCreate = (props) => {
    const {
        onBack,
        onCreate,
    } = props;
    const classes = useStyles();

    const onCreateAndRoute = async () => {
        await onCreate();
        onBack();
    }

    const onCreateAndContinue = async () => {
        await onCreate(true);
    }

    return (
        <>
            <Box className={classes.ControlsContainer}>
                <Grid container justifyContent='space-between' spacing={1}>
                    <Grid item xs>
                        <Button
                            className={classes.Button}
                            variant="contained"
                            size="medium"
                            startIcon={<CheckIcon/>}

                            onClick={onCreateAndContinue}
                        >
                            Создать и продолжить
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button
                            className={classes.Button}
                            variant="contained"
                            size="medium"
                            startIcon={<SaveIcon/>}
                            onClick={onCreateAndRoute}
                        >
                            Создать
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

const useStyles = makeStyles({
    ControlsContainer: {
        backgroundColor: '#ffffff',
        padding: '8px 9px',
        marginBottom: '12px',
        borderRadius: 1,
    },
    Button: {
        width: "100%",
        '&:disabled': {
            background: '#62B4FF',
            color: '#ffffff',
            '& svg': {
                fill: '#ffffff',
                opacity: .5,
            },
        }
    },
});

export default ControlRegionalPriceCreate;