import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Switch,
	TextField,
	Typography, FormControl, Select, MenuItem, FormControlLabel, Checkbox
} from "@mui/material";
import {IMaskInput} from "react-imask";
import {
	Notification,
	NotificationTypes
} from "../../../../../common/Notification";
import priceRoundingMethod from "../../../../../constants/priceRoundingMethod";

const initForm = {
	value: "",
	column: "price_old",
	operation: "increase",
	price_rounding_method: "",
	applyParameters: false
}

class DialogMultiChangePrice extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: { ...initForm },

			isOpen: false
		}
	}

	open = ({ type, onSubmit }) => {
		this.setState({
			type: type,
			onSubmit,
			isOpen: true
		})
	}
	close = () => {
		this.setState({
			form: { ...initForm },
			isOpen: false
		})
	}

	changeForm = ({ target }) => {
		const { name, value } = target;

		let form = { ...this.state.form }
		form[name] = value;

		this.setState({ form })
	}
	changeFormOperation = ({ target }, bool) => {
		const value = Boolean(bool) ? "increase" : "decrease";

		let form = { ...this.state.form }
		form.operation = value;

		this.setState({ form })
	}
	changeFormBoolean = ({ target }, value) => {
		const { name } = target;

		let form = { ...this.state.form }
		form[name] = value;

		this.setState({ form });
	}

	onSubmit = () => {
		const error = this.validateForm();
		if (!!error) {
			Notification({
				type: NotificationTypes.error,
				message: error
			})

			return
		}

		const form = {
			...this.state.form,
			type: this.state.type
		}

		this.state.onSubmit(false, form, this.state.type);
	}
	validateForm = () => {
		const { form } = this.state;

		if (!form.value) {
			return "Введите новую стоимость"
		}
		if (!form.price_rounding_method) {
			return "Выберите \"Способ округления\""
		}

		return
	}

	render () {
		const {
			type,
			form,
			isOpen
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3">
						Изменить стоимость ({Boolean(type === "percent") ? "процент" : "рубли"})
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container rowSpacing={2}>
						<Grid item xs={12}>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									<Typography variant="subtitle1">
										Действие:
									</Typography>
								</Grid>
								<Grid item>
									<Grid container spacing={1} alignItems="center">
										<Grid item>
											<Typography
												variant="overline"
												sx={{color: Boolean(form.operation === "decrease") ? "#3855EC" : "#546e7a"}}
											>Уменьшить цену</Typography>
										</Grid>
										<Grid item>
											<Switch
												value={Boolean(form.operation === "increase")}
												checked={Boolean(form.operation === "increase")}
												onChange={this.changeFormOperation}
											/>
										</Grid>
										<Grid item>
											<Typography
												variant="overline"
												sx={{color: Boolean(form.operation === "increase") ? "#3855EC" : "#546e7a"}}
											>Увеличить цену</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle1" sx={{marginBottom: "4px"}}>
								{Boolean(type === "percent") ? "Процент" : "Сумма"}
							</Typography>
							<TextField
								value={form.value}
								name="value"
								placeholder={Boolean(type === "percent") ? "Введите процент (от 1 до 100)" : "Введите суммы"}
								fullWidth

								InputProps={{
									inputComponent: TextMaskCustom
								}}
								inputProps={{
									scale: 2,
									max: Boolean(type === "percent") ? 100 : null
								}}

								onChange={this.changeForm}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle1" sx={{marginBottom: "4px"}}>Способ округления</Typography>

							<FormControl fullWidth>
								<Select
									value={form.price_rounding_method}
									name="price_rounding_method"
									variant="outlined"
									fullWidth
									onChange={this.changeForm}
								>
									{priceRoundingMethod.map((item, index) => (
										<MenuItem value={ item.value }>
											{ item.label }
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								value={form.applyParameters}
								checked={form.applyParameters}
								name="applyParameters"
								label="Применять к параметрам"
								control={<Checkbox/>}
								onChange={this.changeFormBoolean}
							/>
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Grid container justifyContent="flex-end" spacing={2}>
						<Grid item>
							<Button
								variant="outlined"
								onClick={this.close}
							>Отменить</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								onClick={this.onSubmit}
							>Применить</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
	const {onChange, ...other} = props;

	const handleOnAccept = (value) => {
		onChange({target: {name: props.name, value}})
	}

	return (
		<IMaskInput
			{...other}
			mask={Number}
			thousandsSeparator=" "
			radix="."
			mapToRadix={[',']}

			inputRef={ref}
			unmask={true}
			onAccept={handleOnAccept}
		/>
	);
});

export default DialogMultiChangePrice
