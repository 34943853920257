import React from "react";
import {
	Box,
	Button,
	Tooltip
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	CameraAlt as CameraAltIcon
} from "@mui/icons-material";
import PropTypes from "prop-types";
import clsx from "clsx";
import {uploadImages} from "../../helper/upload-images";
import {checkValidImageLoading} from "../../helper/check-valid-image-loading";
import {Notification, NotificationTypes} from "../../common/Notification";

class UploadImages extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {}
	}

	onChangeImage = async ({target}) => {
		const {files} = target;

		const checkFile = await checkValidImageLoading(files[0]);
		if (checkFile.error) {
			Notification({
				title: "",
				message: checkFile.error,
				type: NotificationTypes.error
			})
			return
		}

		const imageServer = await uploadImages({
			file: files[0],
			folderId: this.props.folderId,
		});

		this.props.onChange(imageServer?.image?.id);
	}
	onRemoveImage = () => {
		this.props.onChange(null);
	}

	render() {
		const {
			value,
			size,
			multiple,
			children,

			disabled,

			classes
		} = this.props;

		return (
			<>

				{Boolean(!value) && (
					<label>
						<input disabled={disabled} type="file" accept="image/jpg,image/jpeg,image/png" hidden onChange={this.onChangeImage}/>
						<Button
							variant="contained"
							size="small"
							component="small"
							disabled={disabled}
							className={clsx({
								[classes.buttonSmall]: Boolean(size === "small"),
								[classes.buttonMedium]: Boolean(size === "medium"),
								[classes.buttonLarge]: Boolean(size === "large"),
							})}
							startIcon={<CameraAltIcon/>}
						>
							<span className="label">Выберите файл</span>
						</Button>
					</label>
				)}

				{Boolean(!!value) && (
					<Box className={clsx({
						[classes.imageContent]: true,
						[classes.imageContentSmall]: Boolean(size === "small"),
						[classes.imageContentMedium]: Boolean(size === "medium"),
						[classes.imageContentLarge]: Boolean(size === "large"),
					})}>
						<Tooltip title={<React.Fragment>
							<img src={`${process.env.REACT_APP_HOST_API}api/storage/image/${value}_w-400.webp`} className={classes.tooltipImage}/>
						</React.Fragment>} arrow placement="right">
							<img src={`${process.env.REACT_APP_HOST_API}api/storage/image/${value}_w-100.webp`}/>
						</Tooltip>

						<Tooltip title="Удалить файл" arrow>
							<Button
								variant="contained"
								size="small"
								component="small"
								fullWidth
								disabled={disabled}
								className={clsx({
									[classes.buttonSmall]: Boolean(size === "small"),
									[classes.buttonMedium]: Boolean(size === "medium"),
									[classes.buttonLarge]: Boolean(size === "large"),
								})}
								onClick={this.onRemoveImage}
							>
								Удалить
							</Button>
						</Tooltip>
					</Box>
				)}

			</>
		)
	}
}

const styles = {
	buttonSmall: {
		padding: "4px 6px",
		height: "auto",

		fontSize: 12,
		lineHeight: "12px",
		textTransform: "initial",
		whiteSpace: "nowrap",
	},
	buttonMedium: {},
	buttonLarge: {},

	imageContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",

		padding: "6px",
		borderRadius: 4,
		backgroundColor: "rgba(0, 87, 255, 0.05)",
	},
	imageContentSmall: {
		"& img": {
			width: 64,
			height: 64,
			objectFit: "cover",
			marginBottom: 4
		}
	},
	imageContentMedium: {},
	imageContentLarge: {},

	tooltipImage: {
		width: 300,
		maxHeight: 300
	}
}
UploadImages = withStyles(styles)(UploadImages)

UploadImages.propTypes = {
	value: PropTypes.any.isRequired,
	folderId: PropTypes.string,
	multiple: PropTypes.bool,
	size: PropTypes.oneOf([
		'small', 'medium', 'large'
	]),

	onChange: PropTypes.func
}
UploadImages.defaultProps = {
	multiple: false,
	folderId: process.env.REACT_APP_FOLDER_ID
}

export default UploadImages
