import React from "react";
import {
	Box,
	Grid,

	Checkbox,
	TextField,
	FormControl,
	FormControlLabel,

	Autocomplete,
	FormLabel,
	FormGroup,
} from "@mui/material";
import {
	Formik
} from "formik";
import * as Yup from "yup";
import {makeStyles} from "@mui/styles";

const CategoryManufacturerForm = (props) => {
	const {
		innerRef,
		initialValues,

		brandId,
		categoryId,
		extraCategories
	} = props;
	const classes = useStyles();
	const [ extraCategoriesSearch, setExtraCategoriesSearch ] = React.useState("");

	const handleSubmit = () => {
	}

	const handleChange = ({target}) => {
		const {name, value} = target;

		let newForm = {...innerRef.current.values};
		newForm[name] = value;

		innerRef.current.setValues(newForm);
	}
	const handleChangeAutocomplete = (value, name) => {
		let newForm = {...innerRef.current.values};
		newForm[name] = value?.value || null;

		innerRef.current.setValues(newForm);
	}
	const handleChangeExtraCategories = ({ target }, value) => {
		const { name } = target;

		let newValues = {...innerRef.current.values};
		let extraCategories = newValues.extraCategories || [];

		const indexExtraCategory = extraCategories.findIndex((t) => +t.value === +name);
		if (indexExtraCategory > -1) {
			extraCategories.splice(indexExtraCategory, 1);
		} else {
			extraCategories.push({
				value: name
			})
		}

		newValues.extraCategories = extraCategories;
		innerRef.current.setValues(newValues);
	}

	const _categoryLabel = (value) => {
		return categoryId.find((t) => t.value === value)?.label || ""
	}

	const _extraCategoriesList = () => {
		const list = [...extraCategories];
		list.splice(list.findIndex(t => t.value === 1), 1);

		if (!extraCategoriesSearch) {
			return [...list]
		}

		const search = extraCategoriesSearch.toLowerCase();
		return [...list]
			.filter((t) => {
				return (t.label || "").toLowerCase().indexOf(search) > -1
			})
	}

	return (
		<Formik
			innerRef={innerRef}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					values,
					errors,
					touched
				} = props;

				return (
					<Grid container rowSpacing={2}>
						<Grid item xs={12}>
							<Box px={2} py={2} bgcolor="white" borderRadius={2}>
								<Autocomplete
									disabled={true}

									fullWidth
									disableClearable
									disablePortal
									name="category_id"
									options={categoryId}
									value={{value: values.category_id, label: _categoryLabel(values.category_id)}}
									error={Boolean(touched.category_id && errors.category_id)}
									helperText={touched.category_id && errors.category_id}
									id="value"
									onChange={(event, value) => handleChangeAutocomplete(value, 'category_id')}
									renderInput={(params) => <TextField
										{...params}
										className={classes.input}
										InputLabelProps={{shrink: true}}
										label="Категория"
										fullWidth
									/>}
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box px={3} py={2} bgcolor="white" borderRadius={2}>
								<Box mb={2}>
									<TextField
										disabled={true}
										value={extraCategoriesSearch}
										fullWidth
										size="small"
										placeholder="Поиск"
										onChange={({target}) => setExtraCategoriesSearch(target.value || "")}
									/>
								</Box>
								<FormControl component="fieldset" variant="standard">
									<FormLabel>Дополнительные категории</FormLabel>
									<FormGroup>
										{_extraCategoriesList().map((item, index) => (
											<FormControlLabel
												disabled={true}
												key={`_extraCategories-item-${ index }`}
												control={(
													<Checkbox
														disabled={true}
														checked={Boolean((values.extraCategories || []).find((t) => +t.value === +item.value))}
														name={item.value}
														onChange={handleChangeExtraCategories}
													/>
												)}
												label={item.label}
											/>
										))}
									</FormGroup>
								</FormControl>
							</Box>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	)
}

const useStyles = makeStyles({
	input: {
		"& .MuiOutlinedInput-input.MuiAutocomplete-input": {
			paddingTop: 0,
			paddingBottom: 0,
		}
	}
})

const validationSchema = Yup.object().shape({});

export default React.memo(CategoryManufacturerForm)
