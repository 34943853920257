import React from 'react';
import {withStyles} from "@mui/styles";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import {Box} from "@mui/material";
import {Controls, MainInformation} from "./components";
import {filterParse, filterStringify} from "../../../helper/filter";
import TableComponent from "./components/Table";
import {DialogConfirmation} from "../../../components";
import {Notification, NotificationTypes} from "../../../common/Notification";


const initFilter = {
    page: 1,
};

class Brands extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brands: [],

            filter: {...initFilter},
            pagination: {},

            isLoading: true,
        }

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.setFilterPage();
        // --------------------------------
        await this.getBrands();
    }

    //Установка страницы при обновлении страницы
    setFilterPage = () => {
        const filter = this._getFilterPage();

        this.setState({filter});
    }

    // Управление производителями
    getBrands = async () => {
        const {filter} = this.state;
        const {data, headers} = await agent.get(`${urls.getBrands}?page=${filter.page}`).catch((err) => {
            return {
                data: [],
                headers: {}
            }
        });

        const pagination = {
            count: headers?.['x-pagination-page-count'] || 1,
            perPage: headers?.['x-pagination-per-page'] || 20,
            totalCount: headers?.['x-pagination-total-count'] || 0
        }

        this.setState({
            pagination,
            brands: data,
            isLoading: false,
        });
    }

    //Логика изменение статуса
    changeBrandStatus = async (id, status) => {
        const body = {
            status: status,
        }

        const response = await agent.put(`/admin/api-shop-brands/${id}?ngrestCallType=update&fields=status`, body)
            .then()
            .catch()

        await this.getBrands();
    }
    //Логика изменения показа в меню
    changeBrandShowInMenu = async (id, status) => {
        const body = {
            show_in_menu: status,
        }

        const response = await agent.put(`/admin/api-shop-brands/${id}?ngrestCallType=update&fields=show_in_menu`, body)
            .then()
            .catch();

        await this.getBrands();
    }

    //Удаление производителя
    deleteBrand = async (id, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы точно хотите удалить производителя № ${id}?`,
                acceptButtonTitle: "Да, удалить",
                acceptButtonAction: this.deleteBrand.bind(this, id, true),
            })

            return
        }

        const response = await agent.delete(`/admin/api-shop-brands/${id}`)
            .then()
            .catch((err) => {
                return {error: err.response};
            });
        if (response.error) {
            Notification({
                message: "Ошибка удаления производителя",
                type: NotificationTypes.error,
            })

            return
        }

        Notification({
            message: "Производитель успешно удален",
            type: NotificationTypes.success,
        })

        await this.getBrands();
    }

    // Работы с фильтром страницы
    _changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        const urlFilter = filterStringify(this.state.filter);
        window.history.replaceState(null, null, `/brands?${urlFilter}`);

        if (!isFastStart) {
            return null
        }

        await this.getBrands();
    }

    //Получение страницы при обновлении страницы
    _getFilterPage = () => {
        const search = window.location?.search || '';

        let filter = {};

        filter = {
            ...filter,
            ...initFilter,
            ...filterParse(search, initFilter, {})
        }

        return filter
    }


    //Функция для перехода на страницу редактирования
    _routePageEdit = (id) => {
        this.props.history.push(`/brands/edit/${id}`)
    }

    render() {
        const {
            brands,
            filter,
            pagination,
            isLoading,
        } = this.state;
        const {
            classes
        } = this.props;
        return (
            <>
                <Box className={classes.head}>
                    <MainInformation classes={classes}/>
                </Box>
                <Box>
                    <Controls
                        filter={filter}
                        pagination={pagination}
                    />
                </Box>
                <Box>
                    <TableComponent
                        brands={brands}
                        isLoading={isLoading}
                        pagination={pagination}
                        filter={filter}

                        routePageEdit={this._routePageEdit}
                        onChangeFilter={this._changeFilter}

                        onChangeBrandStatus={this.changeBrandStatus}
                        onChangeShowInMenu={this.changeBrandShowInMenu}
                        onDeleteBrand={this.deleteBrand}
                    />
                </Box>

                <DialogConfirmation ref={this.refDialogConfirmation}/>
            </>
        );
    }
}

const styles = {
    head: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16
    },
    headLogo: {
        display: "flex", alignItems: "center",

        "& h1": {
            marginLeft: 12
        }
    },
}

const BrandsStyles = withStyles(styles)(Brands);

export default BrandsStyles
