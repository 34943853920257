import React from "react";
import {Box, List, ListItemButton, ListItemText} from "@mui/material";
import {palette} from "../../../../../theme/common";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const Navigations = (props) => {
	const {
		list,
		active,
		onChange,
		editError,
		isCollection
	} = props;
	const classes = useStyles();

	const _isTabError = (params) => {
		const {tab, ref} = params;
		return Boolean(
			Object.keys(editError[tab] || {}).length > 0 ||
			Object.keys(editError[ref] || {}).length > 0
		)
	}


	return (
		<Box px={1} bgcolor="white" borderRadius={2}>

			<List component="nav">
				{list.map((item, idx) => {
					const type = item?.type;
					if (
						(type === "product" && !!isCollection) ||
						(type === "collection" && !isCollection)
					) {
						return null
					}

					return (
						<ListItemButton
							className={classes.ListItemButton}
							selected={Boolean(active === item.tab)}
							key={`product-navigation-${idx}`}
							disabled={item.disabled}

							onClick={() => onChange(item.tab)}
						>
							<ListItemText>
                            <span style={{color: Boolean(_isTabError(item)) && palette.error.main}}>
                                {item.label}
                            </span>
							</ListItemText>
						</ListItemButton>
					)
				})}
			</List>

		</Box>
	)
}

const useStyles = makeStyles({
	ListItemButton: {
		borderRadius: 2,
		padding: '4px 16px',
		borderBottom: "1px solid #E6E6E6",
		transition: "all .1s linear",
		'& span': {
			transition: "all .2s linear",
			fontSize: '14px',
			fontWeight: 400,
			textTransform: "uppercase",
		},
		"&:hover": {
			background: 'none',
			"& span": {
				fontWeight: 500,
				color: "#0057FF",
			}
		},
		'&.Mui-selected': {
			backgroundColor: "#0057FF",
			borderBottom: "1px solid #0057FF",
			borderRadius: "6px",
			transition: "all .5s linear",
			'& span': {
				transition: "all .2s linear",
				fontWeight: 700,
				color: '#ffffff',
			},
			'&:hover': {
				backgroundColor: "#003db4",
				"& span": {
					fontWeight: 700,
					color: '#ffffff',
				}
			}
		}
	},
})

export default Navigations
