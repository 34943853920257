import React from "react";
import {
    Box, Button,
    Checkbox, Chip, Divider,
    FormControlLabel,
    Grid, MenuItem, Select, Switch,
    Table, TableBody, TableCell, TableRow, TextField

} from "@mui/material";
import {
    GridOnOutlined as GridIcon,
    HighlightOffOutlined as DeleteIcon, RemoveCircleOutline as RemoveCircleOutlineIcon, SaveOutlined as SaveOutlinedIcon
} from "@mui/icons-material";
import {makeStyles, withStyles} from "@mui/styles";
import {Formik} from "formik";
import {palette} from "../../theme/common";
import {Autocomplete} from "@mui/lab";
import clsx from "clsx";

const initialState = {
    regCoefficient: "",
    redirectIfOff: "",
    firstInCategory: "",
    inheritValues: "",
    showInMenu: "",
    uploadMainArticle: "",
    productGroup: "",
    article: "",
    sku: [],
    weight: "",
    newOrderDateStart: "",
    newOrderDateEnd: "",
    brand: "",
}

const sku = [
    {title: "МОДА"},
    {title: "ПЛАСТИК"},
    {title: "ДЕРЕВО"},
    {title: "ЖЕЛЕЗО"},
    {title: "МЕТАЛЛ"},
    {title: "АЛЛЮМИНИЙ"},
    {title: "АЛМАЗ"},
]

class MassChangeGoods extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {}

        this.refFormik = React.createRef();
    }

    handleSubmit = () => {
    };

    handleChange = ({target}) => {
        const {name, value} = target;

        let newForm = this.refFormik.current?.values || {};
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    handleChangeBoolean = ({target}, value) => {
        const {name} = target;

        let newForm = this.refFormik.current.values;
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    };

    handleChangeAutocomplete = (value) => {
        let newForm = this.refFormik.current.values;
        let newValue = value.filter(val => !newForm.sku.find(sku => sku.title === val.title));

        newForm.sku = [...newForm.sku, ...newValue];

        this.refFormik.current.setValues(newForm);
    }
    handleDeleteTag = (index) => {
        let newForm = this.refFormik.current.values;
        newForm.sku.splice(index, 1);
        this.refFormik.current.setValues(newForm);
    }


    render() {
        const {
            classes
        } = this.props;

        return (
            <>
                <Formik
                    innerRef={this.refFormik}
                    initialValues={initialState}
                    onSubmit={this.handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched
                        } = props;

                        return (
                            <>
                                <Table className={classes.switchTable}>
                                    <TableBody>
                                        <>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>Учитывать региональный коэффициент для цены</Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                value={values.regCoefficient}
                                                                name='regCoefficient'
                                                                control={<Switch/>}
                                                                label=""
                                                                labelPlacement="start"
                                                                onChange={this.handleChangeBoolean}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>Редирект, если отключен</Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                value={values.redirectIfOff}
                                                                name="redirectIfOff"
                                                                control={<Switch/>}
                                                                label=""
                                                                labelPlacement="start"
                                                                onChange={this.handleChangeBoolean}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>Первый в категории</Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                value={values.firstInCategory}
                                                                name="firstInCategory"
                                                                control={<Switch/>}
                                                                label=""
                                                                labelPlacement="start"
                                                                onChange={this.handleChangeBoolean}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>Наследовать значения фильтра коллекции (цвет, мтрл,
                                                            стиль)</Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                value={values.inheritValues}
                                                                name="inheritValues"
                                                                control={<Switch/>}
                                                                label=""
                                                                labelPlacement="start"
                                                                onChange={this.handleChangeBoolean}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>Показывать на главной</Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                value={values.showInMenu}
                                                                name="showInMenu"
                                                                control={<Switch/>}
                                                                label=""
                                                                labelPlacement="start"
                                                                onChange={this.handleChangeBoolean}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>Выгружать основной артикул фабрики в CRM</Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                value={values.uploadMainArticle}
                                                                name="uploadMainArticle"
                                                                control={<Switch/>}
                                                                label=""
                                                                labelPlacement="start"
                                                                onChange={this.handleChangeBoolean}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    </TableBody>
                                </Table>
                                <Table className={classes.mainTable}>
                                    <TableBody>
                                        <>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs={2}>Группа товаров</Grid>
                                                        <Grid item xs>
                                                            <Select
                                                                classes={{
                                                                    root: classes.inputText
                                                                }}
                                                                fullWidth
                                                                color="primary"
                                                                value={values.productGroup}
                                                                name="productGroup"
                                                                size="small"
                                                                displayEmpty

                                                                onChange={this.handleChange}
                                                            >
                                                                <MenuItem value="1" className={classes.inputText}>
                                                                    Шкафы и стеллажи
                                                                </MenuItem>
                                                                <MenuItem value="2" className={classes.inputText}>
                                                                    Диваны
                                                                </MenuItem>
                                                                <MenuItem value="3" className={classes.inputText}>
                                                                    Кресла
                                                                </MenuItem>
                                                                <MenuItem value="4" className={classes.inputText}>
                                                                    Столы
                                                                </MenuItem>
                                                                <MenuItem value="5" className={classes.inputText}>
                                                                    Стулья
                                                                </MenuItem>
                                                            </Select>

                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs={2}>Артикул <span
                                                            style={{color: "#0057FF"}}>*</span></Grid>
                                                        <Grid item xs>
                                                            <TextField
                                                                className={classes.inputText}
                                                                fullWidth
                                                                size="small"
                                                                value={values.article}
                                                                name="article"
                                                                placeholder="Введите артикул"

                                                                onChange={this.handleChange}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs={2}>SKU Manufacturer</Grid>
                                                        <Grid item xs>
                                                            <Autocomplete
                                                                multiple
                                                                size="small"
                                                                freeSolo
                                                                value={values.sku}
                                                                options={sku}
                                                                onChange={(e, val) => this.handleChangeAutocomplete(val)}
                                                                getOptionLabel={(option) => option.title}
                                                                renderTags={(tagValue, getTagProps) =>
                                                                    tagValue.map((option, index) => (
                                                                        <Chip
                                                                            className={classes.chip}
                                                                            size="small"
                                                                            color="primary"
                                                                            label={option.title}
                                                                            deleteIcon={<DeleteIcon/>}

                                                                            onDelete={() => this.handleDeleteTag(index)}
                                                                        />
                                                                    ))}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        className={classes.inputText}
                                                                        color="primary"
                                                                        placeholder="Выберите теги"
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs={2}>Вес, граммы <span
                                                            style={{color: "#0057FF"}}>*</span></Grid>
                                                        <Grid item xs>
                                                            <TextField
                                                                className={classes.inputText}
                                                                value={values.weight}
                                                                name='weight'
                                                                fullWidth
                                                                placeholder="Введите вес"
                                                                size="small"

                                                                onChange={this.handleChange}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs={2}>Отображать как Новый товар с</Grid>
                                                        <Grid item xs>
                                                            <Grid container alignItems="center"
                                                                  justifyContent="space-between">
                                                                <Grid item xs>
                                                                    <TextField
                                                                        className={clsx({
                                                                            [classes.dateInput]: true,
                                                                        })}
                                                                        fullWidth
                                                                        color="primary"
                                                                        size="small"
                                                                        value={values.newOrderDateStart}
                                                                        name="newOrderDateStart"
                                                                        type="date"
                                                                        InputProps={{
                                                                            endAdornment: <GridIcon
                                                                                className={classes.dateInputIcon}
                                                                                color="primary"/>,
                                                                        }}

                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1} textAlign="center">по</Grid>
                                                                <Grid item xs>
                                                                    <TextField
                                                                        className={clsx({
                                                                            [classes.dateInput]: true,
                                                                        })}
                                                                        fullWidth
                                                                        color="primary"
                                                                        size="small"
                                                                        value={values.newOrderDateEnd}
                                                                        name="newOrderDateEnd"
                                                                        type="date"
                                                                        InputProps={{
                                                                            endAdornment: <GridIcon
                                                                                className={classes.dateInputIcon}
                                                                                color="primary"/>,
                                                                        }}

                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell width="85%">
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs={2}>Производитель</Grid>
                                                        <Grid item xs>
                                                            <Select
                                                                classes={{
                                                                    root: classes.inputText
                                                                }}
                                                                fullWidth
                                                                color="primary"
                                                                value={values.brand}
                                                                name="brand"
                                                                size="small"
                                                                displayEmpty

                                                                onChange={this.handleChange}
                                                            >
                                                                <MenuItem className={classes.inputText} value="1">
                                                                    ANRK23T09
                                                                </MenuItem>
                                                                <MenuItem className={classes.inputText} value="2">
                                                                    NIKE
                                                                </MenuItem>
                                                                <MenuItem className={classes.inputText} value="3">
                                                                    ADIDAS
                                                                </MenuItem>
                                                                <MenuItem className={classes.inputText} value="4">
                                                                    Клюква
                                                                </MenuItem>
                                                                <MenuItem className={classes.inputText} value="5">
                                                                    Смородина
                                                                </MenuItem>
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel control={<Checkbox/>} label="ИЗМЕНИТЬ"/>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    </TableBody>
                                </Table>
                            </>
                        );
                    }}
                </Formik>

                <Box mt="12px"/>
                <Divider/>
                <Box mt="12px"/>
                <Grid container spacing>
                    <Grid xs={6} item>
                        <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<RemoveCircleOutlineIcon/>}
                        >
                            Отмена
                        </Button>
                    </Grid>
                    <Grid xs={6} item>
                        <Button
                            variant="contained"
                            fullWidth
                            startIcon={<SaveOutlinedIcon sx={{color: "#62B4FF"}}/>}
                        >
                            Сохранить
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const styles = {
    switchTable: {
        backgroundColor: 'transparent',
        borderCollapse: 'separate',
        borderSpacing: "0 5px",
        '& .MuiTableBody-root': {
            height: "100%",
            '& .MuiTableRow-root': {
                backgroundColor: "#F4F4F4",
                cursor: "default",
            },
            '& .MuiTableCell-body': {
                borderRight: '1px solid #E6E6E6',
                borderLeft: '1px solid #E6E6E6',
                fontWeight: 400,
                fontSize: 16,
                color: '#434343',
                borderBottom: 0,
                padding: "5px 12px",
                borderRadius: "6px",
                "&:first-child": {
                    borderLeft: 0,
                },
                "&:last-child": {
                    borderRight: 0,
                },
                "& .MuiCheckbox-root": {
                    color: "#C8C8C8",
                },
                "& .Mui-checked": {
                    color: palette.primary.main,
                },
            }
        }
    },

    mainTable: {
        backgroundColor: 'transparent',
        borderCollapse: 'collapse',
        borderSpacing: "0 0",
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
                height: "100%",
                position: "static",
                backgroundColor: "#F4F4F4",
                cursor: "default",
                borderBottom: "1px solid #E6E6E6",

                '&:first-child': {
                    '& .MuiTableCell-body': {
                        borderLeft: 0,
                        borderRadius: "6px 6px 0 0",
                    }
                },

                '&:last-child': {
                    borderBottom: 0,
                    '& .MuiTableCell-body': {
                        borderLeft: 0,
                        borderRadius: "0 0 6px 6px",
                    }
                },

                "&:nth-child(2n)": {
                    backgroundColor: "#F4F4F4",
                },
            },
            '& .MuiTableCell-body': {
                height: "100%",
                borderRight: '1px solid #E6E6E6',
                borderLeft: '1px solid #E6E6E6',
                fontWeight: 400,
                fontSize: 16,
                color: '#434343',
                padding: "24px 12px",

                "& .MuiCheckbox-root": {
                    color: "#C8C8C8",
                },
                "& .Mui-checked": {
                    color: palette.primary.main,
                },
            }
        }
    },

    inputText: {
        fontSize: 16,
        fontWeight: 700,
        color: "#000000",
    },

    dateInput: {
        "& input": {
            fontSize: 16,
            fontWeight: 700,
            color: "#000000",
            position: "relative",
            '&::-webkit-calendar-picker-indicator': {
                opacity: 0,
                position: "absolute",
                right: "0",
                zIndex: 10000000,
            }
        }
    },
    dateInputIcon: {
        width: "18px",
        height: "18px",
        position: "absolute",
        right: "10px",
        pointerEvents: "none",
        zIndex: 1,
    },

    chip: {
        borderRadius: "4px",
        marginRight: "6px",
        fontSize: "13px",
        fontWeight: 700,
    }

}

const StylesMassChangeGoods = withStyles(styles)(MassChangeGoods)

export default StylesMassChangeGoods
