import React from 'react';
import {Box, Button, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {DeleteOutlineOutlined as DeleteIcon, KeyboardBackspace as KeyboardBackspaceIcon} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";

const HeaderCategoryEdit = React.memo((props) => {
    const {
        brand,
        onBack,
        isCreate
    } = props;
    const classes = useStyles();
    return (
        <>
            <Box mb={2}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Tooltip title="Вернуться в категории">
                                    <IconButton onClick={onBack}>
                                        <KeyboardBackspaceIcon color="primary"/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h1">{isCreate ? "Создание производителя" : `Редактирование производителя '${brand.name}'`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {Boolean(!isCreate) && (
                        <Grid item>
                            <Button
                                className={classes.Button}
                                variant="contained"
                                size="medium"
                                startIcon={<DeleteIcon/>}
                                color="error"
                            >
                                удалить
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
});

const useStyles = makeStyles({
    Button: {
        width: "100%",
        '&:disabled': {
            background: '#62B4FF',
            color: '#ffffff',
            '& svg': {
                fill: '#ffffff',
                opacity: .5,
            },
        }
    },
});

export default HeaderCategoryEdit;