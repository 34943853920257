import React, {useRef} from 'react';
import {
    Box,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Typography
} from "@mui/material";
import {
    ArrowDropDown as DownIcon,
} from '@mui/icons-material';
import {Formik} from "formik";
import {makeStyles} from "@mui/styles";
import agent from "../../../../../agent/agent";
import {Notification, NotificationTypes} from "../../../../../common/Notification";
import {TableRowToggleImage} from "../../../../../components";

const headCells = [
    {
        id: 1,
        value: "main_photo_id",
        label: "Основное изображение",
        align: "left",
        width: "50px",
        isSort: false,
    },
    {
        id: 2,
        value: "product_id",
        label: "ID Продукта",
        align: "left",
        width: "auto",
        isSort: true,
    },
    {
        id: 3,
        value: "category_id",
        label: "ID Категории",
        align: "left",
        width: "auto",
        isSort: true,
    },
    {
        id: 4,
        value: "sort",
        label: "Sort",
        align: "left",
        width: "auto",
        isSort: true,
    }
]

const TableComponent = (props) => {
    const {
        products,

        filter,
        pagination,

        isLoading,
        onChangeFilter
    } = props;

    const handleSortTable = (event, headValue) => {
        let newFilter = {...filter};
        if (newFilter.sort) {
            if (newFilter.sort === `-${headValue}`) {
                newFilter.sort = headValue;
            } else {
                newFilter.sort = `-${headValue}`;
            }
        } else {
            newFilter.sort = `-${headValue}`;
        }

        onChangeFilter(newFilter, true);
    }

    const handleChangePage = (event, page) => {
        let newFilter = {...filter};
        newFilter.page = page;

        onChangeFilter(newFilter, true);
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <EnhancedTableHead
                        filter={filter}
                        changeSort={handleSortTable}
                    />
                    <TableBody>
                        {Boolean(isLoading) ? (
                            <>
                                {[0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    )
                                )}
                            </>
                        ) : (
                            <>
                                {
                                    products.length > 0
                                        ? (
                                            <>
                                                {
                                                    products.map((row) => (
                                                            <TableRowComponent
                                                                key={row.product_id}
                                                                product={row}
                                                            />
                                                        )
                                                    )}
                                            </>
                                        )
                                        : (
                                            <TableRow>
                                                <TableCell colSpan={8}>
                                                    <Typography variant="h2" textAlign="center">
                                                        Товары в этой категории не найдены
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                }
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={2}>
                <Pagination
                    page={Number(filter.page)}
                    count={pagination.count || 1}

                    onChange={handleChangePage}
                />
            </Box>
        </>
    );
};

const EnhancedTableHead = (props) => {
    const {
        filter,
        changeSort,
    } = props;
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        width={headCell.width}
                    >
                        {headCell.isSort
                            ? (
                                <TableSortLabel
                                    className={classes.tableSortLabel}
                                    active={filter.sort === headCell.value || filter.sort === `-${headCell.value}`}
                                    direction={filter.sort === headCell.value ? "asc" : "desc"}
                                    onClick={(event) => changeSort(event, headCell.value)}
                                    IconComponent={DownIcon}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            )
                            : headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};


let timeOut = null;

const TableRowComponent = (props) => {
    const {
        product
    } = props;
    const classes = useStyles();
    const refFormik = useRef(null);

    const handleSubmit = () => {
    };

    const handleChangeSort = (e) => {
        clearTimeout(timeOut);
        const {value, name} = e.target;
        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues(newForm);

        if (value.length >= 1) {
            timeOut = setTimeout(async () => {
                await _changeSort();
            }, 500);
        } else {
            clearTimeout(timeOut);
        }
    }

    const _changeSort = async () => {
        clearTimeout(timeOut);
        const body = {
            product_id: refFormik.current.values.product_id,
            category_id: refFormik.current.values.category_id,
            sort: refFormik.current.values.sort
        }

        timeOut = setTimeout(async () => {
            await agent.put(`/admin/api-shop-category-assignment/${product.product_id},${product.category_id}`, body).then((res) => {
                Notification({
                    message: `Сорт успешно изменен`,
                    type: NotificationTypes.success,
                })
            }).catch((err) => {
                Notification({
                    message: `Ошибка изменения сорта`,
                    type: NotificationTypes.error,
                })
            });
        }, 500)
    }

    return (
        <>
            <TableRow hover>
                <Formik
                    innerRef={refFormik}
                    initialValues={product}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched
                        } = props;

                        return (
                            <>
                                <TableCell>
                                    <Box className={classes.imageContainer}>
                                        <TableRowToggleImage value={values.main_photo_id}/>
                                    </Box>
                                </TableCell>
                                <TableCell>{values.product_name}</TableCell>
                                <TableCell>{values.category_name}</TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        className={classes.textField}
                                        size="small"
                                        color="primary"
                                        value={values.sort}
                                        name="sort"

                                        onClick={(e) => e.stopPropagation()}
                                        onChange={handleChangeSort}
                                    />
                                </TableCell>
                            </>
                        );
                    }}
                </Formik>
            </TableRow>
        </>
    );
};

const useStyles = makeStyles({
    textField: {
        '& input': {
            textAlign: "right",
        },
    },

    tableSortLabel: {
        "&.MuiButtonBase-root.MuiTableSortLabel-root": {
            "&:hover": {
                color: "rgba(255,255,255, .5)",
                "& svg, path": {
                    fill: "rgba(255,255,255, .5)",
                }
            },

            "&	.MuiTableSortLabel-icon": {
                fontSize: "32px",
            },

            "&.Mui-active": {
                color: "#ffffff",
                "& svg, path": {
                    fill: "#ffffff",
                },
                "&:hover": {
                    color: "rgba(255,255,255, .5)",
                    "& svg, path": {
                        fill: "rgba(255,255,255, .5)",
                    }
                }
            }
        },
    },

    imageContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
})


export default TableComponent;
