// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import ProductEdit from './ProductEdit';

export default compose(
  connect(
    state => ({
      userRoles: state?.global?.userRoles || []
    }),
    dispatch => ({}),
  ),
)(ProductEdit);
