// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Order from './Order';

export default compose(
  connect(
    state => ({
      adminLocked: (state?.global?.adminLocked || []).filter((t) => t.lock_table === "{{%shop_orders}}")
    }),
    dispatch => ({}),
  ),
)(Order);
