const convertorNumber = (value, decimalCount = 0, decimal = " ", thousands = " ") => {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = value < 0 ? "-" : "";

  let i = parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalCount)).toString();
  let j = (i.length > 3) ? i.length % 3 : 0;

  return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(value - i).toFixed(decimalCount).slice(2) : "");
}
const convertorNumberCircumcision = (value, decimalCount = 2, decimal = ".") => {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const bitDepth = +`1${Array.apply(null, Array(decimalCount)).map(Number.prototype.valueOf,0).join('')}`;
  const negativeSign = value < 0 ? "-" : "";
  value = Math.floor((value) * bitDepth) / bitDepth;

  return `${negativeSign}${Math.abs(value)}`
}
const convertorCaseWords = (number, words) => {
  if (typeof number !== "number") {
    number = Number(number)
  }

  number = Math.abs(number)
  if (Number.isInteger(number)) {
    const cases = [2, 0, 1, 1, 1, 2]
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
  }
  return words[1]
}

export {
  convertorNumber,
  convertorCaseWords,
  convertorNumberCircumcision
}
