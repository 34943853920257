import React from "react";
import {
    Box,
    Checkbox,
    Typography
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";

class BaseCheckbox extends React.PureComponent {
    render() {
        const {
            classes,
            label,
            isOnlyForm,

            ...otherProps
        } = this.props;

        if (!!isOnlyForm) {
            return (
              <Checkbox
                checked={otherProps.value}
                {...otherProps}
              />
            )
        }

        return (
            <Box className={classes.root}>
                <Typography className={classes.label}>
                    { label }
                </Typography>
                <Box className={classes.content}>
                    <Checkbox
                        checked={otherProps.value}
                        {...otherProps}
                    />
                </Box>
            </Box>
        )
    }
}

const styles = {
    root: {
        display: "flex",
        alignItems: "center",

        width: "100%",
        backgroundColor: "#F4F4F4",
        borderRadius: 6,
        padding: "10px 12px",
        boxSizing: "border-box"
    },

    label: {
        flex: 1,
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: "500",
        color: "#000000"
    },
    content: {}
}
const StylesBaseCheckbox = withStyles(styles)(BaseCheckbox)

export default StylesBaseCheckbox
