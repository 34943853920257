import React from "react";
import {
    Autocomplete, Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import agent from "../../../../../agent/agent";
import urls from "../../../../../variables/urls";

class CharacteristicComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            options: [],

            isLoadOption: false
        }
    }

    getOptions = async () => {
        if (this.state.isLoadOption || this.state.options.length > 0) {
            return
        }

        this.setState({ isLoadOption: true });

        const options = await agent.get(`${ urls.getCharacteristicVariants }/${ this.props.attribute.name }`).then((res) => {
            return res.data
        }).catch(() => {
            return []
        });

        this.setState({
            isLoadOption: false,
            options
        });
    }

    _options = () => {
        return this.state.options
    }
    _getOptionLabel = (row) => {
        if (typeof row !== 'object') {
            return this.state.options.find((t) => String(t[this.props.keyValue]) === String(row))?.value
        }

        return row.value
    }
    _filterOptions = (data) => {
        return data
    }

    onChangeAutocomplete = (value) => {
        let newValue = {...this.props.item};
        newValue.value = value;

        this.props.onChange(newValue);
    }
    onChangeCheckbox = (event, value) => {
        let newValue = {...this.props.item};
        newValue.apply = value;

        this.props.onChange(newValue);
    }

    render() {
        const {
            item,
            attribute
        } = this.props;
        const {
            isLoadOption
        } = this.state;

        return (
            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12} item sx={{flex: 1}}>
                        <Typography variant="h4" style={styleTitle} sx={{marginBottom: 1}}>{ attribute?.label }</Typography>
                        <Autocomplete
                            {...this.props}
                            value={this.props.item?.value || []}
                            options={this._options()}
                            onChange={(event, value) => this.onChangeAutocomplete(value)}
                            name={attribute.name}
                            variant="outlined"
                            fullWidth
                            multiple
                            size="small"
                            autoComplete={true}
                            loading={isLoadOption}
                            getOptionLabel={this._getOptionLabel}
                            onOpen={this.getOptions}
                            filterOptions={this._filterOptions}
                            renderInput={(params) => <TextField
                                {...params}
                                label={this.props.name}
                                fullWidth
                            />}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <FormControlLabel control={<Checkbox/>} checked={item.apply} value={item.apply} name="apply" label="Изменить" onChange={this.onChangeCheckbox}/>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const styleTitle = {
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.02em',
    fontFeatureSettings: "'ss03' on, 'ss06' on"
}

export default CharacteristicComponent
