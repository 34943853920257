import React, {Component} from 'react';
import {Box, Grid, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import {
    AddOutlined as AddIcon,
    AttachFileOutlined as AttachFileIcon,
    BurstMode,
    Delete as DeleteIcon
} from "@mui/icons-material";
import Dropzone from "react-dropzone";

class DropZoneFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [...props.value],
        }
    }

    onDrop = (acceptedFiles) => {
        this.setState({
            files: [...this.state.files, ...acceptedFiles.map((file) => file)]
        }, () => {
            if (!!this.props.onChange) {
                this.props.onChange(this.state.files);
            }
        })
    };

    handleDeleteFile = (file) => {
        const fileId = file?.id;
        const lastModified = file.lastModified;

        let files = [...this.state.files];
        if (!!fileId) {
            files = files.filter(file => file.id !== fileId)
        }
        if (!!lastModified) {
            files = files.filter(file => file.lastModified !== lastModified)
        }

        this.setState({files: files}, () => {
            if (!!this.props.onChange) {
                this.props.onChange(this.state.files);
            }
        });

    }

    render() {
        const {files} = this.state;

        return (
            <>
                {files.length > 0 &&
                    <>
                        <Grid container>
                            <Grid item xs>
                                {files.map((file) => (
                                    <Grid
                                        container
                                        spacing={3}
                                        mb={2} pb={3}
                                        sx={{
                                            borderBottom: "1px solid #E6E6E6",
                                        }}
                                    >
                                        <Grid item>
                                            <Box display="flex">
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "5px",
                                                        background: "#3855EC",
                                                        borderRadius: "4px",
                                                        color: "#fff",
                                                        fontSize: "14px",
                                                        justifyContent: "center",
                                                        marginRight: "5px"
                                                    }}
                                                >
                                                    <AttachFileIcon
                                                        sx={{
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                    {file.name || file.name_original}
                                                </Box>
                                                <IconButton
                                                    color="error"

                                                    onClick={() => this.handleDeleteFile(file)}
                                                >
                                                    <Tooltip title="Удалить файл">
                                                        <DeleteIcon/>
                                                    </Tooltip>
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField key={file.name} fullWidth sx={{
                                                height: 84,
                                                '& .MuiOutlinedInput-root': {
                                                    height: 84,
                                                }
                                            }} value={file.caption} placeholder="Описание"/>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </>
                }
                <Dropzone onDrop={this.onDrop}>
                    {({getRootProps, getInputProps}) => (
                        <Box
                            {...getRootProps({className: "dropzone"})}
                            sx={{
                                cursor: 'pointer',
                            }} mt={3} borderRadius={2} height={107}
                            border='1px solid rgba(0, 87, 255, 0.5)'
                            bgcolor="rgba(0, 87, 255, 0.05);" p={2}>
                            <Box
                                height={107}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <input {...getInputProps()} />
                                <BurstMode sx={{fill: "#0057FF"}}/>
                                <Typography sx={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    textTransform: 'uppercase',
                                    letterSpacing: "0.1em",
                                    color: "#0057FF",
                                    marginLeft: '8px',
                                }} variant="h5">Перетащите или выберите файлы</Typography>
                            </Box>
                        </Box>)}
                </Dropzone>
            </>
        );
    }
}

export default DropZoneFiles;
