import React from 'react';

import {
    Box,
    Checkbox,
    Grid,
    IconButton,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";


const TableComponent = (props) => {

    const {
        isLoading,
        brands,
        filter,
        pagination,

        onChangeFilter,
        routePageEdit,

        onChangeBrandStatus,
        onChangeShowInMenu,
        onDeleteBrand
    } = props;

    const handleClickRow = (row, event) => {
        const isDisableRoute = Boolean(event.target.closest(".disable-route"));
        if (isDisableRoute) {
            return
        }

        routePageEdit(row.id);
    }

    const handleChangePage = (event, page) => {
        let newFilter = {...filter};
        newFilter.page = page;

        onChangeFilter(newFilter, true);
    }

    const handleChangeStatus = (e, id) => {
        e.stopPropagation();

        const {checked} = e.target;
        const status = checked ? 1 : 0;

        onChangeBrandStatus(id, status);
    };

    const handleChangeShowInMenu = (e, id) => {
        e.stopPropagation();

        const {checked} = e.target;
        const status = checked ? 1 : 0;

        onChangeShowInMenu(id, status);
    }

    const handleDelete = (e, id) => {
        e.stopPropagation();

        onDeleteBrand(id);
    };

    return (<>

        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Название</TableCell>
                        <TableCell align="center">URL-адрес</TableCell>
                        <TableCell align="center">Статус</TableCell>
                        <TableCell align="center">Показать в меню</TableCell>
                        <TableCell align="center">Ссылка</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {Boolean(isLoading) ? (<>
                            {[0, 1, 2, 3, 4, 5].map((item) => (
                                    <TableRow key={`row-item-load-${item}`}>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                    </TableRow>
                                )
                            )}
                        </>
                    ) : (
                        <>
                            {
                                brands.map((row, index) => (
                                        <TableRow
                                          hover
                                            key={`row-item-${index}`}
                                            onClick={(event) => handleClickRow(row, event)}
                                        >
                                            <TableCell align="center">{row.id}</TableCell>
                                            <TableCell align="center">{row.name}</TableCell>
                                            <TableCell align="center">{row.slug}</TableCell>
                                            <TableCell align="center">
                                                <Checkbox
                                                    color="primary"
                                                    checked={row.status}

                                                    onClick={(e) => handleChangeStatus(e, row.id)}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Checkbox
                                                    color="primary"
                                                    checked={row.show_in_menu}

                                                    onClick={(e) => handleChangeShowInMenu(e, row.id)}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{row.slug}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Удалить производителя" arrow>
                                                    <IconButton onClick={(e) => handleDelete(e, row.id)}>
                                                        <DeleteIcon sx={{color: "red"}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>

        <Box mt={2}>
            <Pagination
                page={Number(filter.page)}
                count={pagination.count || 1}

                onChange={handleChangePage}
            />
        </Box>

    </>);
};

export default TableComponent;
