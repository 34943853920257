import React from "react";
import {
	Box,
	Grid,
	Checkbox,
	IconButton,
	Pagination,
	Tooltip, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Edit as EditIcon,
	ContentCopy as CopyIcon,

	Check as CheckIcon,
	HorizontalRuleRounded as HorizontalRuleRoundedIcon,
	OpenInNewOutlined as OpenInNewOutlinedIcon
} from "@mui/icons-material";
import {
	TableRowToggleStatus,
	TableRowToggleText,
	TableRowToggleImage,
	TableRowToggleFile,
	TableRowTaggablePlugin,
	TableRowSelectArray
} from "../../../../../components";
import DataTable from "react-data-table-component";
import ButtonOpenOnWebsite from "./ButtonOpenOnWebsite";

import customStylesTable from "../../../../../theme/common/table";
import moment from "moment";
import clsx from "clsx";

let timeOutColumnOrderChange = null;
const TableComponent = (props) => {
	const {
		data,
		filter,
		selected,
		pagination,
		visibleColumns,

		onChangeFilter,
		onChangeSelected,
		onChangePagination,
		onChangeVisibleColumns,

		routePageEdit,
		duplicateProduct,
		adminLocked,

		isLoad
	} = props;
	const refDataTable = React.createRef(null);
	const [columns, setColumns] = React.useState([]);
	const classes = useStyles();

	React.useEffect(() => {
		let newColumns = [];

		Object.keys(visibleColumns)
			.map((key, index) => {
				const itemColumn = visibleColumns[key];

				newColumns.push({
					...visibleColumns[key],
					id: itemColumn.nameField,
					name: itemColumn.label,
					selector: (row, index) => _renderRowSpecification(itemColumn, row, key, index),
					reorder: false,
					sortable: true,
					width: (row, index) => _getWidthColumnTable(itemColumn, row, key, index)
				})
			})

		newColumns.push({
			key: "extraWebsites",
			name: "WEB сайты",
			sortable: true,
			selector: (row, index) => _renderRowSpecification('extraWebsites', row, 'extraWebsites')
		});
		newColumns.push({
			key: "action",
			right: "true",

			selector: (row) => _renderRowSpecification(row, null, 'action'),
		});

		setColumns(newColumns)
	}, [visibleColumns]);
	const _renderRowSpecification = (column, row, key, index) => {
		let value = row?.[column?.nameField];
		if (column === 'extraWebsites') {
			value = row?.extraWebsites || [];
		}

		if (key === "action") {
			const disabledEdit = adminLocked.find((t) => String(t.lock_pk) === String(column.id));
			const isDisabledEdit = Boolean(Object.keys(disabledEdit || {}).length > 0);

			return (
				<Grid container spacing={1} wrap="nowrap">
					<Grid item>
						<ButtonOpenOnWebsite
							item={column}
						/>
					</Grid>
					<Grid item>
						<Tooltip title="Дублирование товара" arrow>
							<IconButton onClick={() => duplicateProduct(column)}>
								<CopyIcon color="primary"/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip
							title={isDisabledEdit ? `Кто-то уже редактирует: ${disabledEdit?.user?.firstname}` : 'Редатировать товар'}
							arrow
						>
							<div>
								<IconButton onClick={() => routePageEdit(column.id)} disabled={isDisabledEdit} color="primary">
									<EditIcon/>
								</IconButton>
							</div>
						</Tooltip>
					</Grid>
				</Grid>
			)
		}

		if (column.nameField === "price_new" && row.price_old === row.price_new) {
			return ""
		}


		if (column.type === "toggleStatus" || column.type === "checkbox") {
			const isNot = Boolean(!value || value === 'Нет' || value === 'нет');

			if (value === null) {
				return 'Не задано'
			}
      if (!isNot) {
          return (
            <CheckIcon sx={{color: "green"}}/>
          )
      }
			return (
				<HorizontalRuleRoundedIcon sx={{color: "red"}}/>
			)
		}
		if (
			column?.nameField === 'id' ||
			column.type === "text" ||
			column.type === "textarea" ||
			column.type === "string" ||
			column.type === "number" ||
			column.type === "decimal" ||
			column.type === `sitis\\plugins\\admin\\plugins\\EditPlugin`
		) {
			return (
				<TableRowToggleText
					{...column}
					value={value}
				/>
			)
		}
		if ( column.type === "date" || column.type === "datetime" ) {
			return (
				<TableRowToggleText
					{...column}
					value={moment(value * 1000).format("DD.MM.YYYY HH:mm:ss")}
				/>
			)
		}
		if (column.type === "textArray") {
			return "textArray"
		}
		if (
			column.type === "sitis\\shop\\admin\\plugins\\TaggablePlugin" ||
			column === "extraWebsites"
		) {
			return (
				<TableRowTaggablePlugin
					{...column}
					value={value}
				/>
			)
		}
		if (column.type === "selectArray" && column.nameField === "characteristic_group_id") {
			return (
				<CharacteristicGroupId
					{...column}
					value={value}
					row={row}
				/>
			)
		}
		if (column.type === "selectArray") {
			return (
				<TableRowSelectArray
					{...column}
					value={value}
				/>
			)
		}
		if (column.type === "imageArray" || column.type === "image") {
			return (
				<TableRowToggleImage
					{...column}
					value={value}
				/>
			)
		}
		if (column.type === "file" || column.type === "fileArray") {
			return (
				<TableRowToggleFile
					{...column}
					value={value}
				/>
			)
		}

		if (index === 1) {
		}

		return (
			<></>
		)
	}
	const _getWidthColumnTable = (column) => {
		if (['id', 'main_photo_id', 'status'].includes(column.nameField)) {
			return "80px"
		}
		if (['price_new', 'price_old'].includes(column.nameField)) {
			return "120px"
		}

		return ""
	}

	const getData = () => {
		return [...data]
	}

	// Логика работы выделения строк
	const handleSelectedRowsChange = (id, isAll = false) => {
		let newSelected = {...selected};
		let ids = [...newSelected.ids];

		if (isAll) {
			const isAllSelectedVisible = Boolean(ids.filter((t) => data.find((item) => item.id === t)).length === data.length);

			let newIds = [...ids, ...data]
				.map((t) => t.id || t)
				.filter((element, index, array) => {
				return array.indexOf(element) === index;
			});

			if (isAllSelectedVisible) {
				newIds = newIds.filter((newId) => !Boolean(data.find((product) => product.id === newId)));
			}

			ids = newIds;
		} else {
			let idIndex = ids.findIndex((t) => t === id);
			if (idIndex <= -1) {
				ids.push(id)
			} else {
				ids.splice(idIndex, 1)
			}
		}

		newSelected.ids = ids;
		onChangeSelected(newSelected);
	}
	const _selectableRowSelected = (row) => {
		const {ids, isAll} = selected;
		const rowId = row.id;

		if (isAll) {
			return !ids.includes(rowId)
		}

		return ids.includes(rowId)
	}
	const _renderSelectedCheckbox = (row) => {
		if (row.name === "select-all-rows") {
			const visibleSelectedCount = (selected.ids || []).filter((id) => data.find((product) => product.id === id)).length;

			let checked = Boolean(data.length === visibleSelectedCount);
			let indeterminate = Boolean(visibleSelectedCount > 0 && data.length !== visibleSelectedCount);

			if (selected.isAll) {
				checked = Boolean(0 === visibleSelectedCount);
			}

			return (
				<Checkbox
					checked={checked}
					indeterminate={indeterminate}
					onChange={handleSelectedRowsChange.bind(this, null, true)}
				/>
			)
		}

		const id = Number.parseFloat((row.name || "").split("-").pop());

		return (
			<Checkbox
				checked={_selectableRowSelected({ id })}
				onChange={handleSelectedRowsChange.bind(this, id, false)}
			/>
		)
	}

	const _onColumnOrderChange = (cols) => {
		clearTimeout(timeOutColumnOrderChange);

		let newCols = {...cols};
		const indexActionColumn = Object.keys(newCols).find((t) => cols[t]?.key === "action");
		delete newCols[indexActionColumn];

		timeOutColumnOrderChange = setTimeout(() => {
			onChangeVisibleColumns(newCols);
		}, 1000);
	}

	// Сортировка
	const handleSortFunction = (head, selector) => {
		let sortValue = `${Boolean(selector === 'asc') ? '' : '-'}${head.key}`;

		let newFilter = {...filter};
		newFilter.sort = sortValue;

		props.onChangeFilter(newFilter, true);
	}
	const _defaultSortAsc = () => {
		return !Boolean((filter.sort || '')[0] === '-')
	}
	const _defaultSortFieldId = () => {
		let sortKey = filter.sort;
		let sortAsk = _defaultSortAsc();

		if (!sortAsk) {
			sortKey = (sortKey || '').slice(1);
		}

		let indexSortField = columns.find((t) => t.key === sortKey);

		return indexSortField?.nameField || "id"
	}

	// Пагинация
	const handleChangePage = (event, page) => {
		let newFilter = {...filter}
		newFilter.page = page;
		onChangePagination(newFilter, true);
	}

	const handleChangeLimit = ({target}) => {
		const {value} = target;
		let newFilter = {...filter};
		newFilter['per-page'] = value;
		onChangeFilter(newFilter, true);
	};

	if (visibleColumns.length <= 0 || columns.length <= 1) {
		return null
	}

	return (
		<Box className={classes.table}>

			<DataTable
				ref={refDataTable}

				columns={columns}
				data={getData()}
				customStyles={customStylesTable}

				sortServer
				onSort={handleSortFunction}
				defaultSortAsc={_defaultSortAsc()}
				defaultSortFieldId={_defaultSortFieldId()}
				progressPending={isLoad}

				selectableRows
				selectableRowsComponent={_renderSelectedCheckbox}
				// selectableRowSelected={_selectableRowSelected}
				selectableRowsPreSelectedField="FieldIWantToUseForPreSelection"
				// onSelectedRowsChange={handleSelectedRowsChange}
				selectableRowsHighlight

				onColumnOrderChange={_onColumnOrderChange}
			/>

			<Box className={classes.paginationWrapper} mt={2}>
				<Pagination
					page={Number(filter.page)}
					count={pagination.count || 1}

					onChange={handleChangePage}
				/>
				<Box className={classes.limitSelectWrapper}>
					<FormControl fullWidth>
						<InputLabel sx={{background: "transparent"}}>Кол-во товаров</InputLabel>
						<Select
							label='Кол-во товаров'
							value={filter['per-page']}

							onChange={handleChangeLimit}
						>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={20}>20</MenuItem>
							<MenuItem value={40}>40</MenuItem>
							<MenuItem value={60}>60</MenuItem>
							<MenuItem value={80}>80</MenuItem>
							<MenuItem value={100}>100</MenuItem>
							<MenuItem value={200}>200</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</Box>

		</Box>
	);
}
const CharacteristicGroupId = React.memo((props) => {
	const {
		row,
		value,
		options,
	} = props;
	const classes = useStyles();
	const label = options?.[value];
	const isProductSet = Boolean(row?.is_product_set);
	const tooltip = Boolean(isProductSet) ? "Товар является коллекцией" : "Товар является продуктом";

	return (
		<Tooltip title={tooltip} arrow>
			<Box className={clsx({
				[classes.characteristic]: true,
				"collection": isProductSet,
				"product": !isProductSet,
			})}>
				{ label }
			</Box>
		</Tooltip>
	)
})

const useStyles = makeStyles(() => ({
	table: {
		"& .rdt_TableHeadRow": {
			"& > *": {
				"&:last-child": {
					filter: "drop-shadow(0px 5px 24px rgba(149, 157, 165, 0.25))",
					position: "sticky",
					right: 0,
					zIndex: 20,
					background: "#3855EC",
					width: 180,
					minWidth: 180,
					flexGrow: "initial",
					padding: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}
			}
		},

		"& .rdt_TableBody": {
			"& .rdt_TableRow:nth-child(2n)": {
				"& > *": {
					"&:last-child": {
						background: "#F4F4F4"
					}
				}
			}
		},
    "& .rdt_TableBody .rdt_TableRow:hover": {
      cursor: "pointer",
      "& > * ": {
        background: "#cbd2f8!important"
      }
    },
		"& .rdt_TableRow": {
			"& > *": {
				"&:last-child": {
					filter: "drop-shadow(0px 5px 24px rgba(149, 157, 165, 0.25))",
					position: "sticky",
					right: 0,
					zIndex: 20,
					background: "#fcfcfc",
					width: 180,
					minWidth: 180,
					flexGrow: "initial",
					padding: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}
			}
		},
	},

	paginationWrapper: {
		display: "flex",
		alignItems: 'center',
	},
	limitSelectWrapper: {
		width: 200,
		marginLeft: 20,
	},


	characteristic: {
		padding: "4px 6px",
		borderRadius: 4,
		fontSize: 12,
		lineHeight: "15px",

		"&.collection": {
			backgroundColor: "#A414A7",
			color: "white"
		},
		"&.product": {
			backgroundColor: "#F9F0FA",
			color: "#A414A7"
		},
	},
}));

export default TableComponent
