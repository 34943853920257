import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Tooltip,
	Collapse,
	IconButton,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	KeyboardArrowUp as KeyboardArrowUpIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import agent from "../../../../../agent/agent";
import clsx from "clsx";

class DialogSelectImage extends React.PureComponent {
	constructor(props) {
		super(props);

		const productId = (window?.location?.pathname || '').split("/").pop();
		this.state = {
			files: [],
			productId
		};
	}

	componentDidMount = async () => {
		await this.getImages();
	}

	getImages = async () => {
		const res = await agent.get(`/api/vendor-option-images/get/${this.state.productId}`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});

		let files = [];
		Object.keys(res).map((sectionKey) => {
			let list = res[sectionKey].map((t) => {
				return {
					...t,
					isPriority: Boolean(t.option_name === this.props?.option?.name)
				}
			});
			files.push({
				label: sectionKey,
				list: list,
				isPriority: Boolean(sectionKey === this.props?.extraOption?.name)
			})
		});
		this.setState({
			files
		})
	}
	selectImage = (image) => {
		this.props.onChange(image.image_id);
	}

	render () {
		const {
			files
		} = this.state;
		const {
			onClose
		} = this.props;

		return (
			<Dialog
				open={true}
				fullWidth
				maxWidth="sm"
				onClose={onClose}
			>
				<DialogTitle>
					<Typography variant="h3">Выбор изображения</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						{files.map((section) => (
							<Grid item xs={12}>
								<TypeSection
									section={section}
									onSelect={this.selectImage}
								/>
							</Grid>
						))}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Button variant="outlined" onClick={onClose}>
								Закрыть
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
};
const TypeSection = React.memo((props) => {
	const { section, onSelect } = props;
	const classes = useStyles();
	const [isOpen, setOpen] = React.useState(section.isPriority);
	const IconArrow = Boolean(isOpen) ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

	const changeOpen = () => {
		setOpen(!isOpen)
	}

	return (
		<Box>
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Typography variant="h4">{ section.label }</Typography>
				</Grid>
				<Grid item>
					<IconButton onClick={changeOpen}>
						<IconArrow/>
					</IconButton>
				</Grid>
			</Grid>
			<Collapse in={isOpen}>
				{Boolean(isOpen) && (
					<Box mt={2}>
						<Grid container spacing={1}>
							{(section.list || []).map((item) => (
								<Grid item>
									<Tooltip arrow title={item.option_name}>
										<Box
											className={clsx({
												[classes.sectionImage]: true,
												'--priority': Boolean(item.isPriority)
											})}
											onClick={onSelect.bind(this, item)}
										>
											<img
												src={`${process.env.REACT_APP_HOST_API}api/storage/image/${item.image_id}_w-42.webp`}
											/>
										</Box>
									</Tooltip>
								</Grid>
							))}
						</Grid>
					</Box>
				)}
			</Collapse>
		</Box>
	)
})

const useStyles = makeStyles(() => ({
	sectionImage: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",

		width: 42,
		height: 42,
		borderRadius: 4,
		background: "rgba(0,0,0,0.1)",
		position: "relative",
		overflow: "hidden",
		cursor: "pointer",

		"&.--priority": {
			boxShadow: "0 0 0 2px #3855EC"
		}
	}
}));

export default DialogSelectImage
