import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Pagination,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {filterParse, filterStringify, filterToStringPreset} from "../../../helper/filter";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import {Notification, NotificationTypes} from "../../../common/Notification";
import {
	Table as TableComponent,
	Filter as FilterComponent,
	DialogMultiChangeParams as DialogMultiChangeParamsComponent
} from "./components";
import axios from "axios";

const initFilter = {
	page: 1,

	"filter[extraWebsites]": [],
	"filter[name][like]": "",
	"filter[code][like]": "",
	"filter[brand_id]": "",
	"filter[extraProductSetParents]": "",
	"filter[status]": "",
	"filter[characteristic_group_id]": "",

	"sort": "-id",
	'per-page': 10,
	"showOnlyMainPhoto": false
};
const pageName = "photo-cropping";

class PhotoCropping extends Component {
	constructor(props) {
		super(props);

		this.state = {
			products: [],
			productsAttributes: [],
			productsSelected: [],

			filter: {...initFilter},
			pagination: {},

			isLoadProducts: true,
			isShowBackdrop: false,
		};

		this.refDialogMultiChangeParams = React.createRef();
	}

	componentDidMount = async () => {
		await this.setFilterPage();
		await this.getProducts();
		await this.getColumns();
	}
	componentDidUpdate = async (prevProps) => {
		if (JSON.stringify(prevProps.activePreset) !== JSON.stringify(this.props.activePreset)) {
			const filter = this._getFilterPage();
			await this._replaceFilterUrl({
				...filter,
				preset: this.props.activePreset?.slug,
			}, true);
			await this.setColumnsPage();
		}
		if (prevProps.location.search !== this.props.location.search && this.props.location.search === "") {
			await this.props.setActivePreset({});
			await this.setColumnsPage();
			await this.setFilterPage();
			await this.getProducts();
		}
	}

	// Управление товарами
	getProducts = async () => {
		this.setState({isLoadProducts: true})

		const formData = this._getFilterBody();
		const {data, headers} = await agent.post('/api/admin/products/index-ngrest', formData).catch((err) => {
			Notification({
				message: err.response.data[0].message,
				type: NotificationTypes.error
			});

			return {data: [], headers: {}}
		});

		let products = [];
		data.map((item) => {
			let images = [item.main_photo_id, ...(item.images || []).map((t) => t.imageId)];

			images.map((t) => {
				products.push({
					productId: item.id,
					productName: item.name,
					productCode: item.code,
					productBrandId: item.brand_id,
					productExtraProductSetParents: item.extraProductSetParents,
					imageId: t,

					isMainPhotoId: Boolean(t === item.main_photo_id)
				})
			})
		})

		this.setState({
			products,
			productsSelected: [],
			isLoadProducts: false,
		});
		await this._changePagination(headers);
	}
	getColumns = async () => {
		let initPageColumns = await agent.get(urls.getProductsColumns).then((res) => {
			let data = res.data;

			if (!data.find((t) => t.name === 'id')) {
				data.unshift({
					label: "ID",
					name: "id"
				})
			}

			return data
		}).catch(() => {
			return []
		});
		initPageColumns = initPageColumns.map((column, idx) => {
			const isTypeObject = Boolean(typeof column.type !== "string");

			return {
				...column,
				type: isTypeObject ? (column?.type?.["0"] || column?.type?.['class']) : column?.type,
				name: column.label,
				nameField: column.name,
				key: column.name,
				options: column?.type?.data || column?.options || column?.data || [],
				omit: true,

				objectType: Boolean(isTypeObject) ? column.type : {}
			}
		});

		this.setState({
			productsAttributes: initPageColumns
		})
	}

	setFilterPage = async () => {
		let filter = this._getFilterPage();

		await this.setState({filter})
	}

	// Множественное управление
	multiChangeParams = async (params = null) => {
		if (!params) {
			this.refDialogMultiChangeParams.current.open({
				onSubmit: this.multiChangeParams.bind(this)
			})

			return
		}

		this.setState({ isShowBackdrop: true })

		let body = {}
		if (params.fit !== null) {
			body.default_fit_value = params.fit;
		}
		if (params.border !== null) {
			body.default_border_value = `${params.border},fff,expand`;
		}

		let products = this.state.products;
		if (this.state.productsSelected.length > 0) {
			products = this.state.productsSelected;
		}

		let errors = [];
		await Promise.all(products.map(async (product) => {
			await agent.put(`/api/admin/storage/image/${product.imageId}`, body).then((res) => {
				return res.data
			}).catch((err) => {
				const data = err.response?.data;
				errors.push(`При изменении параметров в товаре "${ product.productName }" возникла ошика - ${ data?.message || "Ошибка сервера" }`)
			})
		}));

		await this.getProducts();
		this.refDialogMultiChangeParams.current.close();
		this.setState({ isShowBackdrop: false })

		if (errors.length > 0) {
			Notification({
				message: errors.join("<br/><br/>"),
				type: NotificationTypes.error
			})
		}
	}


	changePage = (event, page) => {
		let filter = { ...this.state.filter }
		filter.page = page;
		this._changeFilter(filter, true);
	}
	changeProductsSelected = (product) => {
		let productsSelected = [...this.state.productsSelected];

		const spliceIndex = productsSelected.findIndex((t) => t.imageId === product.imageId);
		if (spliceIndex > -1) {
			productsSelected.splice(spliceIndex, 1);
		} else {
			productsSelected.push(product);
		}

		this.setState({
			productsSelected
		})
	}


	_changePagination = async (headers) => {
		let pagination = {...this.state.pagination};
		pagination.count = headers?.['x-pagination-page-count'] || 1;
		pagination.perPage = headers?.['x-pagination-per-page'] || 20;
		pagination.totalCount = headers?.['x-pagination-total-count'] || 0;

		this.setState({pagination});
	}
	_getFilterPage = () => {
		const search = window.location?.search || '';

		let filter = {
			...initFilter,
			...filterParse(search, initFilter, {}),
		};

		return filter
	}
	_getFilterBody = ({isObject} = {}) => {
		const {filter} = this.state;

		// Инициализация тела запроса
		const filterFormData = new FormData();
		const filterFormObject = {};
		let filterObject = [];

		// Инициализация и добавление полей для таблицы
		filterFormData.append("fields", ['id', 'main_photo_id', 'images', 'name', 'brand_id', 'extraProductSetParents', 'code'].join(','));
		filterFormData.append("expand", ['extraProductSetParents'].join(','));

		if (isObject) {
			return filterFormObject
		}
		Object.keys(filter).map((key) => {
			if (key === "filter[extraWebsites]") {
				if (filter[key].length > 0) {
					filterFormData.append(key, filter[key].join(','));
					filterObject.push(`${key}=${filter[key].join(',')}`)
				}
			}
			else if (!!filter[key]) {
				filterFormData.append(key, filter[key]);
				filterObject.push(`${key}=${filter[key]}`)
			}
		})

		window.history.replaceState(null, null, `/${pageName}?${filterObject.join("&")}`);

		return filterFormData
	}
	_changeFilter = (filter, isFastStart) => {
		this.setState({
			filter
		}, async () => {
			if (!isFastStart) {
				return null
			}

			const urlFilter = filterStringify(this.state.filter, this.state.visibleColumns);
			window.history.replaceState(null, null, `/products?${urlFilter}`);

			await this.getProducts();
		});
	}

	render() {
		const {
			filter,
			products,
			pagination,
			productsSelected,
			productsAttributes,

			isLoadProducts,
			isShowBackdrop
		} = this.state;

		return (
			<>

				<FilterComponent
					filter={filter}
					productsAttributes={productsAttributes}
					onChange={this._changeFilter}
					onSearch={this.getProducts}
				/>

				<Box mt="20px"/>

				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item>
						<Pagination
							page={filter.page}
							count={pagination?.count || 1}
							onChange={this.changePage}
						/>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							size="small"
							disabled={products.length <= 0}
							onClick={this.multiChangeParams.bind(this, null)}
						>
							Массовое изменение ({productsSelected.length || products.length} шт.)
						</Button>
					</Grid>
				</Grid>

				<Box mt="12px"/>

				<TableComponent
					data={products}
					filter={filter}
					isLoad={isLoadProducts}
					productsSelected={productsSelected}
					productsAttributes={productsAttributes}

					onChangeProductsSelected={this.changeProductsSelected}
				/>

				<Box mt="12px"/>

				<Pagination
					page={filter.page}
					count={pagination?.count || 1}
					onChange={this.changePage}
				/>




				<DialogMultiChangeParamsComponent
					ref={this.refDialogMultiChangeParams}
					totalCount={productsSelected.length || products.length}
				/>



				<Backdrop open={isShowBackdrop}>
					<CircularProgress/>
				</Backdrop>

			</>
		);
	}
}

export default PhotoCropping
