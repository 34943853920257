import React from "react";
import {
	Box,
	Typography
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Formik
} from "formik";
import DropZone from "../ImagesForm/DropZon";

class PhotosFromClientsForm extends React.Component {
	constructor(props) {
		super(props);

	}

	changeImagesList = async (images) => {
		this.props.innerRef.current.setValues(images);
	}

	render() {
		const {
			innerRef,
			initialValues
		} = this.props;

		return (
			<Formik
				innerRef={innerRef}
				initialValues={initialValues}
			>{(params) => {
				const {
					values,
				} = params;
				return (
					<Box px={2} py={2} bgcolor="white" borderRadius={2}>
						{Boolean(values.length  <= 0) && (
							<>
								<Typography variant="h3" sx={{lineHeight: "42px"}}>Фото от клиентов</Typography>
								<Box mb={2}/>
							</>
						)}
						<DropZone
							label="Фото от клиентов"
							images={values}
							onChange={this.changeImagesList}
						/>
					</Box>
				)
			}}</Formik>
		)
	}
}

const styles = {};
PhotosFromClientsForm = withStyles(styles)(PhotosFromClientsForm);

export default PhotosFromClientsForm
