import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	TextField,
	Typography,

	FormControl,
	MenuItem,
	Select,

	FormControlLabel,
	Checkbox
} from "@mui/material";
import {

} from "@mui/styles";
import {
	Notification,
	NotificationTypes
} from "../../../../../common/Notification";
import {IMaskInput} from "react-imask";
import priceRoundingMethod from "../../../../../constants/priceRoundingMethod";

const initForm = {
	value: "",
	priceRoundingMethod: "",
	applyParameters: true
}

class DialogDiscountSetting extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: {...initForm},

			type: "",
			isOpen: false,

			onSubmit: null
		}
	}

	open = ({ onSubmit, type }) => {
		this.setState({
			type,
			onSubmit,
			isOpen: true
		})
	}
	close = () => {
		this.setState({
			type: "",
			isOpen: false,
			form: { ...initForm }
		})
	}

	changeForm = ({ target }) => {
		const { name, value } = target;

		let form = { ...this.state.form }
		form[name] = value;

		this.setState({ form });
	}
	changeFormBoolean = ({ target }, value) => {
		const { name } = target;

		let form = { ...this.state.form }
		form[name] = value;

		this.setState({ form });
	}

	onSubmit = () => {
		const error = this.validateForm();
		if (!!error) {
			Notification({
				message: error,
				type: NotificationTypes.error
			})

			return
		}

		this.state.onSubmit(true, 1, this.state.form, this.state.type)
	}
	validateForm = () => {
		const { form } = this.state;

		if (!form.value) {
			return "Укажите процет скидки"
		}
		if (!form.priceRoundingMethod) {
			return "Выберите \"Способ округления\""
		}

		return
	}

	render () {
		const {
			type,
			form,

			isOpen
		} = this.state;

		return (
			<Dialog
				fullWidth
				maxWidth="sm"
				open={isOpen}
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3">
						Применить скидку ({Boolean(type === "percent") ? "процент" : "рубли"})
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container rowSpacing={2}>
						<Grid item xs={12}>
							<Typography variant="subtitle1" sx={{marginBottom: "4px"}}>
								{Boolean(type === "percent") ? "Процент скидки" : "Сумма скидки"}
							</Typography>
							<TextField
								value={form.value}
								fullWidth
								variant="outlined"
								name="value"
								placeholder={Boolean(type === "percent") ? "Введите процент скидки (от 1 до 100)" : "Введите суммы скидки"}
								InputProps={{
									inputComponent: TextMaskCustom
								}}
								inputProps={{
									scale: 2,
									max: Boolean(type === "percent") ? 100 : null
								}}

								onChange={this.changeForm}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle1" sx={{marginBottom: "4px"}}>Способ округления</Typography>

							<FormControl fullWidth>
								<Select
									value={form.priceRoundingMethod}
									name="priceRoundingMethod"
									variant="outlined"
									fullWidth
									onChange={this.changeForm}
								>
									{priceRoundingMethod.map((item, index) => (
										<MenuItem value={ item.value }>
											{ item.label }
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								value={form.applyParameters}
								checked={form.applyParameters}
								name="applyParameters"
								label="Применять к параметрам"
								control={<Checkbox/>}
								onChange={this.changeFormBoolean}
							/>
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Grid container justifyContent="flex-end" spacing={2}>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								onClick={this.close}
							>Отменить</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								size="small"
								onClick={this.onSubmit}
							>Применить</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
	const {onChange, ...other} = props;

	const handleOnAccept = (value) => {
		onChange({target: {name: props.name, value}})
	}

	return (
		<IMaskInput
			{...other}
			mask={Number}
			thousandsSeparator=" "
			radix="."

			inputRef={ref}
			unmask={true}
			onAccept={handleOnAccept}
		/>
	);
});

export default DialogDiscountSetting
