import React from "react";
import {
  Box,
  IconButton,
  Typography
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Close as CloseIcon
} from "@mui/icons-material";

const QueueCard = React.memo((props) => {
  const {
    notification,
    onRemoveNotification
  } = props;
  const classes = styles();

  return (
    <Box className={classes.card}>
      <Typography className={classes.cardTitle}>
        {notification?.description}
      </Typography>
      <Typography className={classes.cardMessage}>
        Текущий прогресс: {notification?.progress?.percent}%;<br/>
        Пользователь: {[notification?.user?.firstname, notification?.user?.lastname].filter((t) => !!t).join(' ')};<br/>
      </Typography>

      <IconButton
        className={classes.cardRemoveNotification}
        onClick={onRemoveNotification.bind(this, notification.id)}
      >
        <CloseIcon sx={{color: "rgba(0,0,0,0.4)"}}/>
      </IconButton>
    </Box>
  )
})

const styles = makeStyles(() => ({
  card: {
    width: "100%",
    backgroundColor: "#F4F4F4",
    borderRadius: 10,
    padding: "15px",
    position: "relative"
  },
  cardTitle: {
    fontSize: "20px",
    lineHeight: "25px",
    fontFeatureSettings: "'pnum' on, 'lnum' on",
    color: "black"
  },
  cardMessage: {
    marginTop: "10px",

    fontSize: "16px",
    lineHeight: "18px",
    fontFeatureSettings: "'pnum' on, 'lnum' on",
    color: "black"
  },
  cardRemoveNotification: {
    position: "absolute",
    top: 0, right: 0
  }
}));

export default QueueCard
