import React, {useEffect, useRef, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Formik} from "formik";
import agent from "../../../../../agent/agent";
import {Notification, NotificationTypes} from "../../../../../common/Notification";
import {AutocompleteSites, FilterBaseSelectArray} from "../../../../../components";
import {makeStyles} from "@mui/styles";

const FilterComponent = (props) => {
    const {
        filter,
        productsAttributes,

        onChangeFilter,
        onClearFilter,
    } = props;
    const classes = useStyles();
    const refFormik = useRef(null);
    const [initialState, setInitialState] = useState({
        'filter[extraWebsites]': [],
        ...filter,
        extraProductSetParents: filter.extraProductSetParents || [],
        brand_id: filter.brand_id || [],
    });

    const handleSubmit = async () => {
        const newFormik = refFormik.current.values;
        let newFilter = {};
        Object.keys(newFormik).forEach(key => {
            if (key === "extraProductSetParents" || key === "brand_id") {
                newFilter[key] = [...newFormik[key].map(item => Number(item.id))];
            }
            else {
                newFilter[key] = newFormik[key];
            }
        })

        await onChangeFilter(newFilter, true);
    };

    const handleClearFilters = () => {
        refFormik.current.setValues({
            page: Number(filter.page),
            extraProductSetParents: [],
            brand_id: [],
        });

        onClearFilter();
    };

    const handleChange = ({target}) => {
        const {name, value} = target;

        let newForm = refFormik?.current?.values;
        newForm[name] = value;

        refFormik.current?.setValues(newForm);
    }

    const handleFormEnter = (event) => {
        var keyCode = event ? (event.which ? event.which : event.keyCode) : event.keyCode;
		if (keyCode == 13) {
			handleSubmit();
		}
    }

    return (
        <form onKeyDown={handleFormEnter}>
            <Box px={2} py={4} bgcolor="white" borderRadius={2}>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialState}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            handleSubmit
                        } = props;
                        return (
                            <>
                                <Grid container spacing={3} mb={3}>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            value={values.id || ''}
                                            name="id"
                                            size="small"
                                            label="ID"
                                            placeholder="Введите ID товара"

                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            value={values.name || ''}
                                            name="name"
                                            size="small"
                                            label="Наименование"
                                            placeholder="Введите наименование товара"

                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            value={values.code || ''}
                                            name="code"
                                            size="small"
                                            label="Артикул"
                                            placeholder="Введите артикул"

                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            value={values.sku || ''}
                                            name="sku"
                                            size="small"
                                            label="Артикул поставщика"
                                            placeholder="Введите артикул поставщика"

                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <CollectionsAutocomplete
                                            refFormik={refFormik}
                                            values={values.extraProductSetParents}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <BrandsAutocomplete
                                            refFormik={refFormik}
                                            values={values.brand_id}
                                        />
                                    </Grid>
                                    <Grid item xs className={classes.filterItem}>
                                        <FilterBaseSelectArray
                                          {...productsAttributes.find((t) => t.nameField === "characteristic_group_id")}
                                          label="Тип товара"
                                          nameField="characteristic_group_id"
                                          value={filter['characteristic_group_id']}
                                          isOnlyForm={true}
                                          onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>Статус</InputLabel>
                                            <Select
                                                value={values.status || 'default'}
                                                name="status"
                                                label="Статус"
                                                placeholder="Выберите статус товара"

                                                onChange={handleChange}
                                            >
                                                <MenuItem value="default" disabled>Выберите статус</MenuItem>
                                                <MenuItem value="0">Неактивный</MenuItem>
                                                <MenuItem value="1">Активный</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs>
                                        <AutocompleteSites
                                          value={values['filter[extraWebsites]'] || []}
                                          name="filter[extraWebsites]"
                                          onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            size="small"

                                            onClick={handleSubmit}
                                        >
                                            Поиск
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            size="small"

                                            onClick={handleClearFilters}
                                        >
                                            Сбросить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        );
                    }}
                </Formik>
            </Box>
        </form>
    );
};

let timeOutCollections = null;
const CollectionsAutocomplete = (props) => {
    const {
        refFormik,
        values,
    } = props;
    const [searchCollectionValue, setSearchCollectionValue] = useState('');
    const [collections, setCollections] = useState([]);
    const [searchCollectionLoading, setSearchCollectionLoading] = useState(false);

    const initializeCollections = async (id, index) => {
        const newForm = refFormik.current.values;
        const newCollection = await agent.get(`/api/admin/products?filter[is_product_set]=1&filter[id]=${id}`)
            .then(res => res.data);

        newForm.extraProductSetParents.splice(index, 1, {
            id: Number(id),
            name: newCollection[0].name,
        });

        refFormik.current.setValues(newForm);
    }

    //Логика поиска коллекций
    const onChangeSearchCollections = (e) => {
        clearTimeout(timeOutCollections);
        if (e.target.value.length >= 2) {
            timeOutCollections = setTimeout(() => {
                const searchValue = e.target.value;
                setCollections([]);
                setSearchCollectionValue(searchValue);
                setSearchCollectionLoading(true);
                timeOutCollections = setTimeout(async () => {
                    if (searchCollectionValue.length >= 2) {
                        await getCollections(searchCollectionValue);
                    } else {
                        setCollections([]);
                    }
                }, 1000);
            }, 100);
        } else {
            setCollections([]);
            setSearchCollectionLoading(false);
        }
    }

    const getCollections = async (searchValue) => {
        const collections = await agent.get(`/api/admin/products?filter[is_product_set]=1&filter[name][like]=${searchValue}`)
            .then(res => res.data)
            .catch((err) => {
                Notification({
                    message: err.response.data[0].message,
                    type: NotificationTypes.error
                });
                return [];
            });

        setCollections(collections);
        setSearchCollectionLoading(false);
    }

    const clearSearchTagValue = () => {
        setSearchCollectionValue("");
        setSearchCollectionLoading(false);
        setCollections([]);
    }

    const handleChange = (value) => {
        let newForm = refFormik.current.values;
        if (newForm.extraProductSetParents) {
            let newValue = value.filter(val => !newForm.extraProductSetParents.find(collection => collection.id === val.id));
            if (newValue.length > 0) {
                newForm.extraProductSetParents = [...newForm.extraProductSetParents, {
                    id: newValue[0].id,
                    name: newValue[0].name,
                }];
            }
        } else {
            newForm.extraProductSetParents = [{
                id: value[0].id,
                name: value[0].name,
            }];
        }

        refFormik.current.setValues(newForm);
        clearSearchTagValue();
    };

    const handleDelete = (index) => {
        const newForm = refFormik.current.values;

        newForm.extraProductSetParents.splice(index, 1);

        refFormik.current.setValues(newForm);
    };


    useEffect(() => {
        values.forEach(async (value, index) => {
            await initializeCollections(value.id || value, index);
        })
    }, [values])
    return (
        <Autocomplete
            multiple
            value={values}
            options={collections}
            loading={searchCollectionLoading}
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => handleChange(value)}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            renderTags={(tagValue) =>
                tagValue.map((option, index) => (
                    <Chip
                        size="small"
                        color="primary"
                        label={option.name}

                        onDelete={() => handleDelete(index)}
                    />
                ))
            }
            freeSolo
            size="small"
            renderInput={(params) => (
                <TextField
                    {...params}
                    onInput={(e) => onChangeSearchCollections(e)}
                    InputLabelProps={{shrink: true}}
                    label="Коллекции"
                    placeholder="Введите название коллекции"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: searchCollectionLoading ?
                            <CircularProgress color="primary" size={20}/> : null
                    }}
                />
            )}
        />
    );
};

let timeOutBrand = null;
const BrandsAutocomplete = (props) => {
    const {
        refFormik,
        values,
    } = props;
    const [searchBrandsValue, setSearchBrandsValue] = useState("");
    const [brands, setBrands] = useState([]);
    const [searchBrandsLoading, setSearchBrandsLoading] = useState(false);

    const initializeBrands = async (id, index) => {
        const newForm = refFormik.current.values;

        const newBrand = await agent.get(`api/brands?filter[id]=${id}`)
            .then(res => res.data);

        newForm.brand_id.splice(index, 1, {
            id: Number(id),
            name: newBrand[0].name,
        });

        refFormik.current.setValues(newForm);
    }

    const onChangeSearchBrands = (e) => {
        clearTimeout(timeOutBrand);
        if (e.target.value.length > 1) {
            timeOutBrand = setTimeout(() => {
                const searchValue = e.target.value;
                setSearchBrandsValue(searchValue);
                setBrands([]);
                setSearchBrandsLoading(true);
                timeOutBrand = setTimeout(async () => {
                    if (searchBrandsValue.length > 1) {
                        await getBrands(searchBrandsValue);
                    } else {
                        setBrands([]);
                    }
                }, 1000);
            }, 100);
        } else {
            setBrands([]);
            setSearchBrandsLoading(false);
        }
    }

    const getBrands = async (searchValue) => {
        const brands = await agent.get(`api/brands?filter[name][like]=${searchValue}`)
            .then(res => res.data)
            .catch((err) => {
                Notification({
                    message: err.response.data[0].message,
                    type: NotificationTypes.error
                });
                return [];
            })

        setBrands(brands);
        setSearchBrandsLoading(false);
    };

    const clearSearchBrandsValue = () => {
        setSearchBrandsValue("");
        setBrands([]);
        setSearchBrandsLoading(false);
    }

    const handleChange = (value) => {
        let newForm = refFormik.current.values;
        if (newForm.brand_id) {
            let newValue = value.filter(val => !newForm.brand_id.find(brand => brand.id === val.id));
            if (newValue.length > 0) {
                newForm.brand_id = [...newForm.brand_id, {
                    id: newValue[0].id,
                    name: newValue[0].name,
                }];
            }
        } else {
            newForm.brand_id = [{
                id: value[0].id,
                name: value[0].name,
            }];
        }

        refFormik.current.setValues(newForm);
        clearSearchBrandsValue();
    };


    const handleDelete = (index) => {
        const newForm = refFormik.current.values;

        newForm.brand_id.splice(index, 1);

        refFormik.current.setValues(newForm);
    };

    useEffect(() => {
        values.forEach(async (value, index) => {
            await initializeBrands(value.id || value, index);
        })
    }, [values])

    return (
        <>
            <Autocomplete
                multiple
                value={values}
                options={brands}
                loading={searchBrandsLoading}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => handleChange(value)}
                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                renderTags={(tagValue) =>
                    tagValue.map((option, index) => (
                        <Chip
                            size="small"
                            color="primary"
                            label={option.name}

                            onDelete={() => handleDelete(index)}
                        />
                    ))
                }
                freeSolo
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onInput={(e) => onChangeSearchBrands(e)}
                        InputLabelProps={{shrink: true}}
                        label="Фабрики"
                        placeholder="Введите название фабрики"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: searchBrandsLoading ?
                                <CircularProgress color="primary" size={20}/> : null
                        }}
                    />
                )}
            />
        </>
    );
};

const useStyles = makeStyles({
    filterItem: {
        "& > .MuiBox-root": {
            flexDirection: "column",
            background: "transparent",
            padding: 0,

            "& > .MuiTypography-root": {
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "17px",
                marginBottom: 2,
                marginLeft: 6,
            }
        }
    }
})

export default FilterComponent;
