export default {

    // Users
    apiUsertokenLogin: "admin/api-usertoken-login",
    userMe: "admin/user/me",
    userSession: "admin/api-admin-user/session",

    // Products
    getProducts: "api/admin/products/index",
    getProductsColumns: "api/admin/products/attributes",
    getProduct: "api/admin/products",
    getProductInfo: "admin/api-shop-products",
    editProduct: "api/admin/products",
    editMultiProduct: "api/admin/products/multiple-update",


    // Orders
    // getOrders: "api/exchange/orders",
    getOrders: "api/admin/orders",
    // getOrder: "api/exchange/orders",
    getOrder: "api/admin/orders",

    // Brands
    getBrands: "admin/api-shop-brands/list",
    brandsApiShop: "admin/api-shop-brands",

    // Files
    filesUpload: "admin/api-admin-storage/files-upload",
    getFileId: "admin/api-admin-storage/file-info?id=",
    getImageId: "admin/api-admin-storage/image-info?id=",

    // Services
    getServices: "admin/api-shop-products/services?ngrestCallType=services&fields=id",
    getCharacteristicVariants: "api/admin/products/characteristic-variants"

}
