import React from "react";
import agent from "../../../../../agent/agent";
import {Notification, NotificationTypes} from "../../../../../common/Notification";
import {TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {IMaskInput} from "react-imask";

let timeOutSetValue = null;
const CharacteristicGroupComponent = (props) => {
	const {
		productId,
		extraValue,
		characteristics,
		сharacteristicId
	} = props;
	const classes = useStyles();
	const [value, setValue] = React.useState(() => {
		const сharacteristic = (characteristics || []).find((t) => t.id === сharacteristicId) || {};
		return сharacteristic?.values?.[0]?.value || '';
	});
	const [isUpdate, setIsUpdate] = React.useState(false);

	React.useEffect(() => {
		const timer = setTimeout(async () => {
			await updateCharacteristic();
		}, 1000);
		return () => clearTimeout(timer);
	}, [value]);

	const changeValue = async (newValue) => {
		setValue(newValue);
	}
	const updateCharacteristic = async () => {
		if (!isUpdate) {
			setIsUpdate(true);

			return
		}

		const postValue = value;
		let newCharacteristicId = await agent.post(`/admin/api-shop-characteristic-variants`, {
			"characteristic_id": сharacteristicId,
			value: postValue
		}).then((res) => {
			return res.data?.id || null
		}).catch((err) => {
			return null
		});
		if (!newCharacteristicId) {
			const search = [
				'page=1',
				'sort=-id',
				`query=${postValue}`,
				'arrayIndex=0',
				'fields=id,value',
				'ngrestCallType=list',
				`id=${сharacteristicId}`,
				'modelClass=c2l0aXNcc2hvcFxjb3JlXGVudGl0aWVzXFNob3BcX25ncmVzdFxDaGFyYWN0ZXJpc3RpY1xDaGFyYWN0ZXJpc3RpYw=='
			].join('&');
			const searchFullChar = await agent(`/admin/api-shop-characteristic-variants/relation-call?${ search }`).then((res) => {
				return res.data
			}).catch((err) => {
				return []
			});
			newCharacteristicId = (searchFullChar || []).find((t) => String(t.value) === String(postValue))?.id || null;
		}
		if (!newCharacteristicId) {
			Notification({
				message: "Ошибка изменения параметра",
				type: NotificationTypes.error
			})

			return null
		}

		setValue(postValue);
		await agent.put(`/api/admin/products/${ productId }/characteristics/${ сharacteristicId }`, {
			variant_ids: [newCharacteristicId]
		})
		Notification({
			message: "Успешно изменено",
			type: NotificationTypes.success
		})
	}

	return (
		<TextField
			className={classes.textField}
			size="small"
			value={String(value)}
			placeholder="0.00"
			InputProps={{
				inputComponent: CustomInputAmount
			}}

			onClick={(e) => e.stopPropagation()}
			onChange={({target}) => changeValue(target.value)}
		/>
	)
}
const CustomInputAmount = ({inputRef, ...rest}) => (
	<IMaskInput
		ref={inputRef}
		{...rest}

		mask={Number}
		thousandsSeparator=""
		radix="."
		mapToRadix={[',']}
		scale={rest?.scale || 0}
		unmask={true}
	/>
)

const useStyles = makeStyles({
	table: {
		'& .MuiTableBody-root': {
			'& .MuiTableRow-root': {
				"&:nth-child(2n)": {
					background: "none",
				},
				"&:nth-child(4n - 1)": {
					backgroundColor: "#F4F4F4",
				}
			},
			'& .MuiTableCell-body': {
				padding: "0 4px",
			}
		}
	},
	subTable: {
		margin: "5px 0",
		borderCollapse: "collapse",

		"& .MuiTableHead-root .MuiTableCell-root": {
			padding: "4px 6px",
			fontSize: 12,
			lineHeight: "14px",
			fontWeight: "500",
			backgroundColor: "#3855EC",
		},
		"& .MuiTableBody-root .MuiTableCell-root": {
			padding: "0 6px",
			fontSize: 14,
			lineHeight: "14px",
			fontWeight: "500"
		},
		"& .MuiTableBody-root .MuiTableRow-root": {
			backgroundColor: "white!important",
			borderBottom: "1px solid #E6E6E6",
			cursor: "default",
		},
	},

	textField: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			padding: "2px 6px"
		},
	}
})

export default CharacteristicGroupComponent
