import React from "react";
import {
	Tooltip,
	Popover,
	MenuList,
	MenuItem,
	IconButton,
	ListItemIcon,
	ListItemText
} from "@mui/material";
import PopupState, {
	bindTrigger,
	bindPopover
} from 'material-ui-popup-state';
import {
	OpenInNewOutlined as OpenInNewOutlinedIcon
} from "@mui/icons-material";

const ButtonOpenOnWebsite = ({ item }) => {
	const [links] = React.useState(() => {
		const extraWebsites = item?.extraWebsites || [];
		if (extraWebsites.length <= 0) {
			return []
		}

		const url = (item?.absoluteUrl || '').split(process.env.REACT_APP_HOST_API)?.pop() || '';
		return extraWebsites.map((_host) => {
			return {
				label: _host,
				url: ['https:/', _host, url].join('/')
			}
		});
	});

	const handleLinkMenuItem = (item, popupState) => {
		popupState.close();
		window.open(item.url, "_blank");
	}

	if (links.length <= 0) {
		return null
	}
	if (links.length <= 1) {
		return (
			<Tooltip title="Открыть страницу товара" arrow>
				<IconButton
					component="a"
					target="_blank"
					href={links[0]?.url}
				>
					<OpenInNewOutlinedIcon color="primary"/>
				</IconButton>
			</Tooltip>
		)
	}
	return (
		<PopupState variant="popover" popupId="demo-popup-popover">
			{(popupState) => (
				<div>
					<Tooltip title="Открыть страницу товара" arrow>
						<IconButton {...bindTrigger(popupState)}>
							<OpenInNewOutlinedIcon color="primary"/>
						</IconButton>
					</Tooltip>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					>
						<MenuList>
							{links.map((_item, index) => (
								<MenuItem
									onClick={handleLinkMenuItem.bind(this, _item, popupState)}
								>
									<ListItemIcon>
										<OpenInNewOutlinedIcon/>
									</ListItemIcon>
									<ListItemText>
										{ _item.label }
									</ListItemText>
								</MenuItem>
							))}
						</MenuList>
					</Popover>
				</div>
			)}
		</PopupState>
	)
}

export default ButtonOpenOnWebsite
