// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Promotions from './Promotions';

export default compose(
    connect(
        state => ({}),
        dispatch => ({}),
    ),
)(Promotions);