import React from "react";
import {
	TextField,
	Box
} from "@mui/material";
import {
	DateRangePicker
} from "@mui/lab";

class FilterDateRange extends React.PureComponent {
	onChange = (date) => {

		const [dateStart, dateEnd] = date || [];

		if (!dateStart && !dateEnd) {
			return
		}

		this.props.onChange(date);
	}

	render() {
		const {
			value
		} = this.props;

		return (
			<>

				<DateRangePicker
					startText="Дата (от)"
					endText="Дата (до)"

					value={value}

					onChange={this.onChange}
					renderInput={(startProps, endProps) => (
						<React.Fragment>
							<TextField {...startProps} size="small" fullWidth error={false}/>
							<Box sx={{ mx: 1 }}> — </Box>
							<TextField {...endProps} size="small" fullWidth error={false}/>
						</React.Fragment>
					)}

				/>

			</>
		);
	}
}

FilterDateRange.defaultProps = {
	value: ["", ""]
};

export default FilterDateRange
