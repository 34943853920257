import React from "react";
import {
    Box,
    Switch,
    Typography
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";

class BaseToggleStatus extends React.PureComponent {
    render() {
        const {
            classes,
            label,
            value,
            onChange,
            nameField,
            isOnlyForm
        } = this.props;

        if (!!isOnlyForm) {
            return (
              <Switch
                value={value}
                checked={value}
                name={nameField}
                onChange={onChange}
              />
            )
        }

        return (
            <Box className={classes.root}>
                <Typography className={classes.label}>
                    { label }
                </Typography>
                <Box className={classes.content}>
                    <Switch
                        value={value}
                        checked={value}
                        name={nameField}
                        onChange={onChange}
                    />
                </Box>
            </Box>
        )
    }
}

const styles = {
    root: {
        display: "flex",
        alignItems: "center",

        width: "100%",
        backgroundColor: "#F4F4F4",
        borderRadius: 6,
        padding: "10px 12px",
        boxSizing: "border-box"
    },

    label: {
        flex: 1,
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: "500",
        color: "#000000"
    },
    content: {
    }
}
const StylesBaseToggleStatus = withStyles(styles)(BaseToggleStatus)

export default StylesBaseToggleStatus
