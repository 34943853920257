import React from "react";
import {
    Checkbox,
    FormControlLabel
} from "@mui/material";

const CheckboxComponent = (props) => {
    const { itemKey, value } = props;

    const handleOnChange = (event, value) => {
        props.onChange({
            target: {
                name: itemKey,
                value
            }
        })
    }

    return (
        <FormControlLabel
            control={<Checkbox
                name={itemKey}
                checked={value}
                value={value}
            />}
            checked={value}
            value={value}
            name={itemKey}
            label={props.label}
            labelPlacement="end"

            onChange={handleOnChange}
        />
    )
}

export default CheckboxComponent
