import React, {Component} from 'react';
import agent from "../../../agent/agent";
import {filterParse, filterStringify} from "../../../helper/filter";
import {Box, Button, Typography} from "@mui/material";
import {AddBoxOutlined as AddBoxOutlinedIcon, MonetizationOnOutlined as MonetizationIcon} from "@mui/icons-material";
import {withStyles} from "@mui/styles";
import {Controls, TableComponent} from "./components";

const initFilter = {
    page: 1,
}

class RegionalPrices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionalPrices: [],

            filter: {...initFilter},
            pagination: {},

            isLoading: true,
        }
    }

    componentDidMount = async () => {
        await this.setFilterPage();
        // -------------------------------------
        await this.getRegionalPrices();
    }


    //Установка страницы при обновлении страницы
    setFilterPage = () => {
        const filter = this._getFilterPage();

        this.setState({filter});
    }

    //Логика получения региональных цен
    getRegionalPrices = async () => {
        const {filter} = this.state;
        const {
            data,
            headers
        } = await agent.get(`/admin/api-shop-price-rule/list?ngrestCallType=list&fields=id%2Cid%2Cstarted_at%2Cended_at%2Cname%2Cproduct_compilation_id%2Cprice_value%2Cprice_rounding_method%2Cstatus&sort=id&page=${filter.page}`).catch((err) => {
            return {
                data: [],
                headers: {}
            }
        });

        const pagination = {
            count: headers?.['x-pagination-page-count'] || 1,
            perPage: headers?.['x-pagination-per-page'] || 20,
            totalCount: headers?.['x-pagination-total-count'] || 0,
        }

        this.setState({
            regionalPrices: data,
            pagination,
            isLoading: false,
        });
    }

    //Логика изменения статуса категории
    _onChangeRegionalPriceStatus = async (row, activeStatus) => {
        const body = {
            status: activeStatus,
        }

        const response = await agent.put(`/admin/api-shop-price-rule/${row.id}?ngrestCallType=update&fields=status`, body)
            .then().catch();

        await this.getRegionalPrices();
    }

    //Получение номера страницы при обновлении страницы
    _getFilterPage = () => {
        const search = window.location?.search || '';

        let filter = {};

        filter = {
            ...filter,
            ...initFilter,
            ...filterParse(search, initFilter, {})
        }

        return filter
    }

    // Работы с фильтром страницы
    _changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        const urlFilter = filterStringify(this.state.filter);
        window.history.replaceState(null, null, `/categories?${urlFilter}`);

        if (!isFastStart) {
            return null
        }

        await this.getRegionalPrices();
    }


    _routePageCreate = () => {
        this.props.history.push(`/regionalPrices/create`);
    }
    //Перенаправление на страницу редактирования категории
    _routePageEdit = (id) => {
        this.props.history.push(`/regionalPrices/edit/${id}`);
    }

    render() {
        const {classes} = this.props;
        const {
            regionalPrices,
            filter,
            pagination,
            isLoading,
        } = this.state;

        return (
            <>
                <Box className={classes.head}>
                    <Box className={classes.headLogo}>
                        <MonetizationIcon sx={{color: "#0057FF", fontSize: 42}}/>
                        <Typography variant="h1">Управление региональными ценами</Typography>
                    </Box>
                    <Button
                        variant="contained"
                        startIcon={<AddBoxOutlinedIcon sx={{color: "white"}}/>}

                        onClick={this._routePageCreate}
                    >
                        Добавить региональную цену
                    </Button>
                </Box>
                <Box>
                    <Controls filter={filter} pagination={pagination}/>
                </Box>

                <Box>
                    <TableComponent
                        regionalPrices={regionalPrices}

                        filter={filter}
                        pagination={pagination}

                        isLoading={isLoading}

                        onChangeFilter={this._changeFilter}
                        routePageEdit={this._routePageEdit}
                        onChangeRegionalPriceStatus={this._onChangeRegionalPriceStatus}
                    />
                </Box>
            </>
        );
    }
}

const styles = {
    head: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16
    }, headLogo: {
        display: "flex", alignItems: "center",

        "& h1": {
            marginLeft: 12
        }
    },
}

const RegionalPricesStyles = withStyles(styles)(RegionalPrices);

export default RegionalPricesStyles