import React from "react";
import {
	Box,
	Tooltip
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	HideImage as HideImageIcon
} from "@mui/icons-material";

class RowToggleImage extends React.PureComponent {
	render() {
		const {
			type,
			value,
			classes
		} = this.props;
		let items = Boolean(type === "imageArray") ? [...value] : [{imageId: value}];

		return (
			<Box className={classes.images}>
				{items.map((image, index) => {
					const isImageNull = Boolean(image === null || image?.imageId === null);

					if (isImageNull) {
						return (
							<Box display="flex" alignItems="center" justifyContent="center">
								<HideImageIcon
									color="error"
									fontSize="medium"
								/>
							</Box>
						)
					}

					return (
						<Tooltip
							title={(
								<React.Fragment>
									<img
										src={`${process.env.REACT_APP_HOST_API}api/storage/image/${image?.imageId || image}_w-400.webp`}
										className={classes.tooltipImage}
									/>
								</React.Fragment>
							)}
							arrow
							placement="right"
						>
							<img
								src={`${process.env.REACT_APP_HOST_API}api/storage/image/${image?.imageId || image}_w-100.webp`}
								style={{maxWidth: "100%", maxHeight: "100%"}}
							/>
						</Tooltip>
					)
				})}
			</Box>
		)
	}
}

const styles = {
	images: {
		display: "flex",
		flexWrap: "wrap",
		marginLeft: -4,
		marginBottom: 4,

		"& > img": {
			width: 32,
			height: 32,
			objectFit: "cover",
			borderRadius: 4,
			marginTop: 4,
			marginLeft: 4,
		}
	},

	tooltipImage: {
		maxWidth: 300,
		maxHeight: 300
	}
}
const StylesRowToggleImage = withStyles(styles)(RowToggleImage)

export default StylesRowToggleImage
