import React, {useRef} from 'react';
import {
    Box, Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Formik} from "formik";
import agent from "../../../../../agent/agent";
import {Notification, NotificationTypes} from "../../../../../common/Notification";

const TableComponent = (props) => {
    const {
        variants,
        filter,
        pagination,
        isLoading,
        onChangeFilter,
    } = props;

    const handleChangePage = (event, page) => {
        let newFilter = {...filter};
        newFilter.page = page;

        onChangeFilter(newFilter, true);
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Характеристика</TableCell>
                            <TableCell>Значение</TableCell>
                            <TableCell>URL - адрес</TableCell>
                            <TableCell>Картинка</TableCell>
                            <TableCell>Сортировка</TableCell>
                            <TableCell>Кол-во товаров</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(isLoading) ? (
                            <>
                                {[0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>
                                    )
                                )}
                            </>
                        ) : (
                            <>
                                {
                                    variants.length > 0
                                        ? (
                                            <>
                                                {
                                                    variants.map((variant) => (
                                                            <TableRowComponent
                                                                key={variant.id}
                                                                variant={variant}
                                                            />
                                                        )
                                                    )}
                                            </>
                                        )
                                        : (
                                            <TableRow>
                                                <TableCell colSpan={8}>
                                                    <Typography variant="h2" textAlign="center">
                                                        Товары в этой категории не найдены
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                }
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={2}>
                <Pagination
                    page={Number(filter.page)}
                    count={pagination.count || 1}

                    onChange={handleChangePage}
                />
            </Box>
        </>
    );
};

let timeOut = null;
const TableRowComponent = (props) => {
    const {
        variant
    } = props;
    const classes = useStyles();
    const refFormik = useRef(null);

    const handleSubmit = () => {
    };

    const handleChange = (e) => {
        clearTimeout(timeOut);
        const {value, name} = e.target;
        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues(newForm);

        if (value.length >= 1) {
            timeOut = setTimeout(async () => {
                await _changeVariant(name);
            }, 500);
        } else {
            clearTimeout(timeOut);
        }
    }

    const _changeVariant = async (name) => {
        clearTimeout(timeOut);
        const body = {
            id: refFormik.current.values.id,
            characteristic_id: refFormik.current.values.characteristic_id,
            value: refFormik.current.values.value,
            slug: refFormik.current.values.slug,
            image_id: refFormik.current.values.image_id,
            additional_description: refFormik.current.values.additional_description,
            sort: refFormik.current.values.sort
        }

        timeOut = setTimeout(async () => {
            await agent.put(`/admin/api-shop-characteristic-variants/${variant.id}`, body)
                .then((res) => {
                    Notification({
                        message: `Изменения в поле '${getNameField(name)}' внесены успешно`,
                        type: NotificationTypes.success,
                    })
                }).catch((err) => {
                    Notification({
                        message: err.response.data[0].message,
                        type: NotificationTypes.error,
                    })
                });
        }, 500)
    }

    const getNameField = (name) => {
        switch (name) {
            case "value":
                return "Значение";
            case "sort":
                return "Сортировка";
            default:
                return "Неопределенное поле";
        }
    }

    return (
        <TableRow hover>
            <Formik
                innerRef={refFormik}
                initialValues={variant}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values
                    } = props;

                    return (
                        <>
                            <TableCell>{values.id}</TableCell>
                            <TableCell>{values.characteristic_id}</TableCell>
                            <TableCell>
                                <Tooltip
                                    title={values.value}
                                >
                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        size="small"
                                        color="primary"
                                        value={values.value}
                                        name="value"

                                        onClick={(e) => e.stopPropagation()}
                                        onChange={handleChange}
                                    />
                                </Tooltip>
                            </TableCell>
                            <TableCell>{values.slug}</TableCell>
                            <TableCell>{values.image_id}</TableCell>
                            <TableCell>
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    size="small"
                                    color="primary"
                                    value={values.sort}
                                    name="sort"

                                    onClick={(e) => e.stopPropagation()}
                                    onChange={handleChange}
                                />
                            </TableCell>
                            <TableCell>{values.productsCount}</TableCell>

                        </>
                    );
                }}
            </Formik>
        </TableRow>
    );
};

const useStyles = makeStyles({
    textField: {
        "& > div": {
            backgroundColor: "transparent",
            padding: "8px 0",
        },
        "& fieldset": {
            border: 0,
        },
        '& input': {
            textAlign: "left",
            wordWrap: "break-word",
        },
        "& .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused": {
            "& fieldset": {
                borderColor: "transparent",
                borderWidth: 0,
            }
        },
    }
})


export default TableComponent;
