import React from "react";
import {
  Box,
  Grid,
  Badge,
  Tooltip,
  IconButton
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Notifications as NotificationsIcon
} from "@mui/icons-material";
import {
  Link,
  withRouter
} from "react-router-dom";
import {compose} from "recompose";
import {connect} from "react-redux";
import {
  setUser
} from "../../../../states/global";
import clsx from "clsx";
import UserProfile from "./UserProfile";
import {Search} from "./components";
import agent from "../../../../agent/agent";
import {deleteCookie} from "../../../../helper/cookie";
import notifications, {changeOpen} from "../../../../states/notifications";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchData: [],
      searchValue: '',
      searchLoading: false,

      isLoadingSearch: true,
    }

    this.timeOutSearch = null;
  }

  userExit = () => {
    localStorage.removeItem("token");
    deleteCookie('adminToken', {
      domain: ".miasofiahome.ru",
      path: "/"
    });
    this.props.setUser(null)
  }

  _checkActiveRoute = (route) => {
    const {rootPath} = route;
    const {path} = this.props?.match || {};

    return Boolean(path.includes(rootPath))
  }

  //Логика поиска
  onChangeSearch = (event, searchValue) => {
    clearTimeout(this.timeOutSearch);

    this.setState({searchData: []});
    this.setState({searchValue});

    if (searchValue.length === 0) {
      this.onClearSearch();
    } else if (searchValue.length >= 2) {
      this.setState({searchLoading: true});

      this.timeOutSearch = setTimeout(async () => {
        await this.onGetSearchResult();
      }, 1000)
    }
  }

  onGetSearchResult = async () => {
    const {searchValue} = this.state;

    if (!searchValue) {
      return
    }
    const response = await agent.get(`/api/catalog/search?query=${searchValue}`)
      .then((res) => {
        const results = res.data.results;
        let items = []

        Object.keys(results).map((key) => {
          items.push({
            group: key,
            ...results[key]
          })
        });

        return items;
      })
      .catch(() => {
        return {};
      })

    await this.setState({searchData: response});

    this.setState({searchLoading: false});
  }

  onClearSearch = () => {
    this.setState({searchLoading: false});
    this.setState({searchData: []});
  }


  openNotifications = () => {
    this.props.openNotifications();
  }

  _routeEditPage = (item) => {
    if (item.group === "products") {
      window.location.href = `/products/edit/${item.id}`;
    } else {
      window.location.href = `/${item.group}/${item.id}`;
    }
  }

  render() {
    const {
      classes,
      user,
      userRoles,
      routes,
      newCount,
      currentBuildVersion
    } = this.props;
    const {
      searchData,
      searchLoading,
      searchValue
    } = this.state;

    return (
      <Box className={classes.header}>

        <Box className={classes.menu}>
          {routes.map((route, index) => {
            if (!userRoles.includes('admin') && !Array.prototype.contains((route?.roles), userRoles) || route.disabled) {
              return null
            }

            return (
              <Link
                to={route.path}
                className={clsx({
                  [classes.menuItem]: true,
                  [classes.menuItemActive]: this._checkActiveRoute(route),
                })}
              >
                <route.icon sx={{color: "white"}}/>
                <span>{route.label}</span>
              </Link>
            )
          })}
        </Box>

        <Box ml="auto">
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <Box className={classes.currentBuildVersion}>
                Версия от {currentBuildVersion}
              </Box>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Search
                data={searchData}
                searchValue={searchValue}
                loading={searchLoading}
                onChangeSearch={this.onChangeSearch}
                onRouteEditPage={this._routeEditPage}
              />
            </Grid>

            {Boolean(true) && (
              <Grid item>
                <Tooltip title="Открыть панель уведмолений" arrow>
                  <Badge badgeContent={newCount} color="secondary">
                    <IconButton onClick={this.openNotifications}>
                      <NotificationsIcon sx={{color: "white"}}/>
                    </IconButton>
                  </Badge>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box className={classes.profile}>
          <UserProfile
            user={user}

            onUserExit={this.userExit}
          />
        </Box>

      </Box>
    );
  }
}

const styles = {
  header: {
    display: "flex",
    alignItems: "center",

    height: 52,
    backgroundColor: "#3855EC",
    padding: "8px 14px",
    boxSizing: "border-box"
  },

  menu: {
    display: "flex"
  },
  menuItem: {
    display: "flex",
    alignItems: "center",

    height: 36,
    padding: "0 12px",
    borderRadius: 2,

    fontSize: 18,
    lineHeight: "23px",
    fontWeight: "500",
    color: "white",
    textDecoration: "none",

    "& span": {
      marginLeft: 8
    }
  },
  menuItemActive: {
    backgroundColor: "rgba(98, 180, 255, 0.5)"
  },
  currentBuildVersion: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "white",
    fontWeight: "600",
    opacity: "0.9"
  },

  profile: {
    marginLeft: "20px",
  }
}

const HeaderStyles = withStyles(styles)(Header);
const HeaderStylesRoute = withRouter(HeaderStyles);

export default compose(
  connect(
    state => ({
      user: state?.global?.user || {},
      userRoles: state?.global?.userRoles || [],
      currentBuildVersion: state?.global?.currentBuildVersion || "",

      newCount: state?.notifications?.newCount
    }),
    dispatch => ({
      setUser: (user) => dispatch(setUser(user)),
      openNotifications: () => dispatch(changeOpen(true))
    }),
  ),
)(HeaderStylesRoute)
