import {palette} from "../common";

export default {
    styleOverrides: {
        root: {
            padding: '16px 20px',
            borderTop: '1px solid rgba(0,0,0,0.1)'
        }
    }
}
