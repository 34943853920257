import React from "react";
import {
	Autocomplete,
	TextField,

	Box,
	MenuItem
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import agent from "../../../../../../agent/agent";

class AutocompleteCustom extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			options: [],

			isLoadOptions: true
		}
	}

	componentDidMount = () => {
		this.initOptions();
	}
	componentDidUpdate = (prevProps) => {
		if ((prevProps?.initOptions || []).length !== (this.props?.initOptions || []).length) {
			this.initOptions();
		}
	}

	initOptions = () => {
		const { options } = this.state;
		const { initOptions } = this.props;
		if (initOptions.length <= 0 || options.length > 0) {
			return
		}

		this.setState({
			options: [...initOptions]
		})
	}
	loadOptions = async () => {
		const { isLoadOptions } = this.state;
		if (!isLoadOptions) {
			return
		}

		const { name } = this.props;
		const res = await agent.get(`/api/admin/products/characteristic-variants-by-id/${ name }`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		});
		this.setState({
			options: res,
			isLoadOptions: false
		});
	}

	onChange = (event, value) => {
		const multiple = this.props.multiple || "";
		const name = this.props.name || "";

		const functionGetValueOption = function (option) {
			if (typeof option !== "object") {
				return option
			}

			return option?.id
		}

		if (Array.isArray(value)) {
			value = value.map((item) => {
				return functionGetValueOption(item)
			})
		} else {
			value = functionGetValueOption(value)
		}
		this.props.onChange({
			target: {
				name: name,
				value: value
			}
		})
	}

	_getOptionLabel = (item) => {
		if (typeof item !== "object") {
			const { options } = this.state;
			const findOption = (options || []).find((t) => (t.value === item || t.id === item));

			return findOption?.label || findOption?.value || ""
		}

		return item?.label || item?.value || ""
	}
	_getOptionDisabled = (item) => {
		const { value } = this.props;

		if (!this.props.multiple) {
			return false
		}

		return Boolean(
			(value || []).includes(item.value) ||
			(value || []).includes(item.id)
		)
	}

	_renderOption = (params, option) => {
		return (
			<MenuItem {...params}>
				{Boolean(option.image_id) && (
					<img
						src={`${process.env.REACT_APP_HOST_API}api/storage/image/${ option.image_id }_w-32_h-32.webp`}
						className={this.props.classes.image}
						loading="lazy"
					/>
				)}

				{ option?.label || option?.value }
			</MenuItem>
		)
	}


	render () {
		const { classes, ...other } = this.props;
		const {
			options,
			isLoadOptions
		} = this.state;

		return (
			<Autocomplete
				{...other}

				options={options}

				size="small"

				className={classes.root}
				getOptionLabel={this._getOptionLabel}
				getOptionDisabled={this._getOptionDisabled}

				loading={isLoadOptions}
				loadingText="Загружаем список..."

				onOpen={this.loadOptions}
				onChange={this.onChange}

				renderInput={(params) => (
					<TextField
						{ ...params }
					/>
				)}
				renderOption={this._renderOption}
			/>
		)
	}
}

const styles = {
	root: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		}
	},

	image: {
		width: 16,
		height: 16,
		borderRadius: "4px",
		marginRight: "6px",
		objectFit: "cover",
		border: "1px solid rgba(0,0,0,0.2)"
	},
}
AutocompleteCustom = withStyles(styles)(AutocompleteCustom)

export default AutocompleteCustom
