import React from "react";
import {
	Autocomplete,
	Box,
	TextField,
	Typography
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import clsx from "clsx";
import agent from "../../agent/agent";

class Characteristic extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			value: [],
			options: [],

			searchValue: "",

			isLoad: false
		}
	}

	componentDidMount = async () => {
		const { value } = this.props;

		if (!!value) {
			await this.initValue();
		}
	}
	componentDidUpdate = async (prevProps) => {
		if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
			await this.initValue();
		}
	}

	initValue = async () => {
		const value = this.props.value;

		if (!value) {
			this.setState({
				value: []
			})
			
			return
		}
		if (Array.isArray(value)) {
			this.setState({
				value: value
			})

			return
		}

		const splitValue = value.split("[]");

		await this.getOptions();
		const newValue = (this.state.options || []).filter((t) => Boolean(splitValue.includes(t.value)));
		this.setState({
			value: newValue
		});
	}

	getOptions = async () => {
		const { isLoad, options } = this.state;
		const { name } = this.props;

		if (isLoad || options.length > 0) {
			return
		}

		this.setState({ isLoad: true })

		const newOptions = await agent.get(`api/admin/products/characteristic-variants/${ name }`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		await this.setState({
			options: newOptions,
			isLoad: false
		})
	}

	onChange = (value) => {
		const { name } = this.props;

		this.props.onChange({
			target: {
				name: name,
				value: value
			}
		});
	}

	_options = () => {
		return []
	}
	_filterOptions = (options) => {
		return options
	}
	_getOptionLabel = (option) => {
		return option?.value
	}

	render () {
		const {
			value,
			options,

			isLoad
		} = this.state;
		const {
			label,
			classes,
			otherProps
		} = this.props;

		return (
			<Box className={classes.root}>
				<Typography className={classes.label}>
					{label}
				</Typography>
				<Box className={clsx({
					[classes.content]: true,
					[classes.contentMultiline]: true,
				})}>
					<Autocomplete
						{...otherProps}
						value={value || []}
						disablePortal
						options={options}
						onChange={(event, value) => this.onChange(value)}
						variant="outlined"
						fullWidth
						multiple
						autoComplete={true}
						loading={isLoad}
						getOptionLabel={this._getOptionLabel}
						onOpen={this.getOptions}
						filterOptions={this._filterOptions}
						limitTags={1}
						size="small"
						renderInput={(params) => <TextField
							{...params}
							size="small"
							fullWidth
						/>}
					/>
				</Box>
			</Box>
		)
	}
}

const styles = {
	root: {
		display: "flex",

		height: "100%",
		width: "100%",
		backgroundColor: "#F4F4F4",
		borderRadius: 6,
		padding: "10px 12px",
		boxSizing: "border-box"
	},

	label: {
		flex: 1,
		fontSize: 16,
		lineHeight: "20px",
		fontWeight: "500",
		color: "#000000"
	},

	content: {
		width: "100%",
		maxWidth: 260
	},
	contentMultiline: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		},
	},
}
Characteristic = withStyles(styles)(Characteristic)

export default Characteristic
