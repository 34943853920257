import React, {useEffect, useState} from 'react';
import {CircularProgress, ListItem, TextField, Typography} from "@mui/material";
import {Autocomplete} from "@mui/lab";

import {Search as SearchIcon} from '@mui/icons-material'
import {withStyles} from "@mui/styles";

const Search = (props) => {
    const {
        data,
        searchValue,
        loading,
        onChangeSearch,
        onRouteEditPage,
        classes,
    } = props;

    const [isOpen, setIsOpen] = useState(false);


    const handleClose = () => {
        onChangeSearch({}, '');
        if (data.length === 0) {
            setIsOpen(false);
            return
        }

        setIsOpen(false);
    }

    useEffect(() => {
        if (data.length > 0) {
            setIsOpen(true)
        }
    }, [data])

    return (
        <Autocomplete
            open={isOpen}
            onClose={handleClose}
            onInputChange={onChangeSearch}
            sx={{
                width: 412
            }}
            classes={
                {
                    paper: classes.root,
                }
            }
            inputValue={searchValue}
            freeSolo
            getOptionLabel={(option) =>
                (option.items.map(item => {
                    return item.name;
                }))
            }

            groupBy={(option) => `${option.name} - ${option.items.length}`}
            options={data}
            renderOption={(props, option) => (
                option.items.map(item => (
                    <ListItem
                        {...props}
                        className={classes.MuiAutocomplete_option}
                        key={item.id}

                        onClick={() => onRouteEditPage({
                            ...item,
                            group: option.group,
                        })}
                    >
                        <Typography variant="h5" fontWeight={400}>{item.name}</Typography>
                    </ListItem>
                ))
            )}
            renderInput={(params) => (<TextField
                {...params}
                sx={{
                    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                        padding: "0 0 0 5px",
                    }
                }}
                InputProps={{
                    ...params.InputProps,
                    type: 'text',
                    startAdornment: <SearchIcon sx={{fill: "#0057FF", fontSize: 20}}/>,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>)
                }}
            />)}
        />);
};

const styles = {
    root: {
        "& .MuiListSubheader-root": {
            background: "rgba(0,87,255,1)",
            color: "#fff",
            fontWeight: 600,
            fontSize: 18,
        },
    },
    MuiAutocomplete_option: {
        display: "flex",
        overflow: "hidden",
        boxPack: "start",
        justifyContent: "flex-start",
        boxAlign: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: "10px 16px 10px 24px",
        transition: "all .2s linear",
        '&:hover': {
            backgroundColor: "rgba(0,87,255, .1)",
        },
        "& Mui-focused": {
            background: 'none',
        }
    }
}

const SearchStyles = withStyles(styles)(Search);


export default SearchStyles