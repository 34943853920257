import React from "react";
import {
	Grid,
	Button,
	Tooltip,
	Typography, Box
} from "@mui/material";
import {

} from "@mui/styles";
import {compose} from "recompose";
import {connect} from "react-redux";
import {
	setActivePreset

} from "../../../../../states/products";

class ProductsPresets extends React.PureComponent {

	setActivePreset = (preset) => {
		localStorage.removeItem("products-visible-columns");

		const activePreset = this.props.preset;
		if (activePreset.slug === preset.slug) {
			this.props.setActivePreset({});

			return null
		}

		this.props.setActivePreset(preset);
	}

	render () {
		const {
			preset,
			presets,
			disabled
		} = this.props;

		return (
			<Grid container spacing={1}>
				{presets.map((item, index) => (
					<Grid item key={`products-presets-${ index }`}>
						<Tooltip title={(<React.Fragment>
							<Box textAlign="center">
								{item.description}<br/>(при переходе сбросятся все колонки)
							</Box>
						</React.Fragment>)} arrow>
							<Button
								variant="contained"
								disabled={disabled}
								color={Boolean(item.slug === preset.slug) ? "primary" : "secondary"}
								onClick={() => this.setActivePreset(item)}
							>
								{ item.name }
							</Button>
						</Tooltip>
					</Grid>
				))}
			</Grid>
		)
	}
}

export default compose(
	connect(
		state => ({
			preset: state?.products?.preset || {},
			presets: state?.products?.presets || [],
		}),
		dispatch => ({
			setActivePreset: (preset) => dispatch(setActivePreset(preset))
		}),
	),
)(ProductsPresets);
