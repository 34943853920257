import agent from "../agent/agent";

const filesUpload = async ({ file, folderId }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("folderId", folderId || '1');

  return await agent.post(`/admin/api-admin-storage/files-upload`, formData).then((res) => {
    return res.data
  }).catch((err) => {
    return { error: err.response }
  })
}
const filesGetFromUpload = async ({ fileId }) => {
  return await agent.post(`/admin/api-admin-storage/file-info?id=${fileId}`).then((res) => {
    return res.data
  }).catch((err) => {
    return { error: err.response }
  })
}

export {
  filesUpload,
  filesGetFromUpload
}
