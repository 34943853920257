import React from "react";
import {
	Box,
	TextField,
	Typography,
	Autocomplete,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import agent from "../../agent/agent";

class BaseTaggablePlugin extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			initValueLabel: "",

			search: "",

			isLoad: false
		}

		this.timeOutSearch = null;
	}

	componentDidMount = async () => {
		if (!!this.props.value) {
			await this.initValue();
		}
	}

	initValue = async () => {
		const { objectType, value } = this.props;
		const response = await agent.get(`/shopadmin/taggable?api=${objectType.api}&labelField=${ objectType.searchField }&valueField=id&ids=${ value }`).then((res) => {
			return res.data?.[0]
		}).catch(() => {
			return ""
		})

		this.setState({
			initValueLabel: response
		})
	}

	getSearchItems = async () => {
		const { objectType } = this.props;
		const { search } = this.state;

		if (!search) {
			return
		}
		const response = await agent.get(`/admin/${ objectType.api }/search?fields=id,${ objectType.searchField }&query=${ search }`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		this.setState({
			items: response,
			isLoad: false
		})
	}
	changeSearch = async (event, search, type) => {
		if (type === "input") {
			this.setState({ isLoad: Boolean(!!search) })

			clearTimeout(this.timeOutSearch)

			this.setState({ search });

			this.timeOutSearch = setTimeout( async () => {
				await this.getSearchItems();
			}, 500)
		}
		if (type === "reset") {
			this.setState({ search })
		}
	}

	onChange = (event, value) => {
		this.props.onChange({
			target: {
				name: this.props.nameField,
				value: value?.id || undefined
			}
		});
		this.setState({
			initValueLabel: value?.[this.props.objectType.searchField]
		})
	}

	render() {
		const {
			items,
			search,
			isLoad,
			initValueLabel
		} = this.state;
		const {
			classes,
			label,
			value,
			onChange,
			nameField,
			isOnlyForm,
			objectType
		} = this.props;

		return (
			<Box className={classes.root}>
				<Typography className={classes.label}>
					{ label }
				</Typography>
				<Box className={classes.content}>
					<ComponentAutocomplete
						value={value}
						items={items}
						search={search}
						objectType={objectType}
						initValueLabel={initValueLabel}

						isLoad={isLoad}

						onChangeSearch={this.changeSearch}
						onChange={this.onChange}
					/>
				</Box>
			</Box>
		)
	}
}
const ComponentAutocomplete = React.memo((props) => {
	const {
		value,
		items,
		search,
		objectType,
		initValueLabel,

		isLoad,

		onChangeSearch,
		onChange
	} = props;

	const _getOptionLabel = (option) => {
		return option?.[objectType.searchField] || initValueLabel || option
	}

	return (
		<Autocomplete
			value={value || null}
			options={items}
			inputValue={search}
			loading={isLoad}
			fullWidth={true}
			multiple={false}
			limitTags={1}

			size="small"

			getOptionLabel={_getOptionLabel}
			onInputChange={onChangeSearch}
			onChange={onChange}

			renderInput={(params) => <TextField {...params}/>}
		/>
	)
})

const styles = {
	root: {
		display: "flex",

		width: "100%",
		backgroundColor: "#F4F4F4",
		borderRadius: 6,
		padding: "10px 12px",
		boxSizing: "border-box"
	},

	label: {
		flex: 1,
		fontSize: 16,
		lineHeight: "20px",
		fontWeight: "500",
		color: "#000000"
	},
	content: {
		width: "100%",
		maxWidth: 260
	}
}
BaseTaggablePlugin = withStyles(styles)(BaseTaggablePlugin)

export default BaseTaggablePlugin