import React from "react";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import {
  makeStyles,
  withStyles
} from "@mui/styles";
import TabsSites from "./TabsSites";
import ModalOptions from "./ModalOptions";
import ImageSection from "./ImageSection";
import agent from "../../../../../agent/agent";

const settingsSiteImage = {
  "1": { width: 870, height: 620 },
  "2": { width: 1296, height: 713 },
  "3": { width: 1079, height: 758 },
};

class ImageAnnotations extends React.PureComponent {
  constructor(props) {
    super(props);

    const extraWebsites = props?.initialValues?.extraWebsites || [];
    this.state = {
      // Все товары которые принадлежатт коллекции
      products: [],
      isLoadProducts: true,

      // Активный вид для сайта
      apiCmsWebsite: [],
      activeView: extraWebsites?.[0] || null
    };

    this.refModalOptions = React.createRef();
  }

  componentDidMount = async () => {
    await this.getApiCmsWebsite();
    await this.getProducts();
  }

  // Логика по активной пропорции
  getApiCmsWebsite = async () => {
    const options = await agent.get('/admin/api-cms-website/list?ngrestCallType=index').then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });
    this.setState({
      apiCmsWebsite: options
    });
  }
  changeActiveView = (activeView) => {
    this.setState({ activeView });
  }

  // Логика по товарам коллекции
  getProducts = async () => {
    const {initialValues} = this.props;

    const expand = ['relatedProduct', 'relatedProduct'];
    const fields = [
      'id',
      'sort',
      'status',
      'quantity',
      'price_new',
      'product_id',
      'options',
      'related_product_id',
      'price_coefficient',
      'relatedProduct.name',
      'relatedProduct.id',
      'relatedProduct.price_new',
      'relatedProduct.price_old',
      'relatedProduct.main_photo_id',
      'relatedProduct.code',
      'relatedProduct.status',
      'relatedProduct.has_options',
    ];
    const url = `/admin/api-shop-product-set/list?per-page=500&filter[product_id]=${initialValues.id}&expand=${expand.join(',')}&fields=${fields.join(',')}`
    const response = await agent.get(url).then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });
    this.setState({
      products: response,
      isLoadProducts: false
    });
  }

  // Логика по точками
  createPoint = (item, option) => {
    let _product = {
      top: 50,
      left: 50,
      product_id: item.related_product_id
    };
    if (item?.relatedProduct?.has_options && !option) {
      this.refModalOptions.current.open({
        product: item,
        onSubmit: this.createPoint.bind(this)
      })
      return
    }
    if (option) {
      _product.option = option;
    }
    this.refModalOptions.current.close();

    const { activeView } = this.state;
    let annotations = [...this.props.annotations];
    let annotation = annotations.find((t) => String(t.website_id) === String(activeView))?.dots;
    annotation.push(_product);
    annotations.find((t) => String(t.website_id) === String(activeView)).dots = annotation;
    this.props.onChange(annotations);
  }
  removePoint = (index) => {
    const { activeView } = this.state;

    let annotations = [...this.props.annotations];
    let annotation = annotations.find((t) => String(t.website_id) === String(activeView))?.dots;
    annotation.splice(index, 1);
    annotations.find((t) => String(t.website_id) === String(activeView)).dots = annotation;

    this.props.onChange(annotations);
  }
  changePoint = (index, value) => {
    const { activeView } = this.state;

    let annotations = [...this.props.annotations];
    let annotation = annotations.find((t) => String(t.website_id) === String(activeView))?.dots;
    annotation[index] = this._convertorPxToPercent({
      ...annotation[index],
      ...value
    });
    annotations.find((t) => String(t.website_id) === String(activeView)).dots = annotation;

    this.props.onChange(annotations);
  }
  _convertorPxToPercent = (value) => {
    const config = settingsSiteImage[this.state.activeView];

    value.top = (value.top / config.height) * 100;
    value.left = (value.left / config.width) * 100;

    return value
  }

  render () {
    const {
      classes,
      annotations,
      initialValues,
      extraWebsites,
    } = this.props;
    const {
      products,
      activeView,
      apiCmsWebsite,
      isLoadProducts,
    } = this.state;

    return (
      <Box className={classes.rootSection}>

        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3">Метки на фото</Typography>
          </Grid>
          <Grid item>
            <TabsSites
              value={activeView}
              options={apiCmsWebsite}
              avaible={extraWebsites}
              onChange={this.changeActiveView}
            />
          </Grid>
        </Grid>

        <VisibleContent visible={Boolean(activeView)}>
          <Box mt={2}/>
          <ImageSection
            products={products}
            activeView={activeView}
            annotations={annotations.find((t) => String(t.website_id) === String(activeView))?.dots || []}
            isLoadProducts={isLoadProducts}
            imageId={initialValues.main_photo_id}
            config={settingsSiteImage[String(activeView)]}

            onCreate={this.createPoint}
            onRemove={this.removePoint}
            onChange={this.changePoint}
          />
        </VisibleContent>

        <ModalOptions
          ref={this.refModalOptions}
        />

      </Box>
    )
  }
};
const VisibleContent = (({ visible, children }) => {
  if (!visible) return null
  return children
})

const styles = {
  rootSection: {
    borderRadius: 8,
    background: "white",
    padding: "16px"
  }
};
ImageAnnotations = withStyles(styles)(ImageAnnotations);

export default ImageAnnotations
