import React, {Component} from 'react';
import agent from "../../../agent/agent";
import {ControlComponent, HeaderComponent, MainFormComponent} from "./components";
import {Box} from "@mui/material";
import urls from "../../../variables/urls";
import {Notification, NotificationTypes} from "../../../common/Notification";


const initialState = {
    name: "",
    slug: "",
    status: 1,
    image_id: "",
    description: "",
    second_title: "",
    country: "",
    sort: 0,
    show_in_menu: 0,

    seo_title: "",
    seo_description: "",
    seo_keywords: "",
}

class BrandEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brand: {},
            brandId: props.match.params.id || null,

            isLoading: true,

            isCreate: false,
        }

        this.refFormik = React.createRef();
    }

    componentDidMount = async () => {
        await this.getBrand();
    }


    getBrand = async () => {
        this.setState({
            brand: {},
            isLoading: true,
        });
        const {brandId} = this.state;
        const url = `/admin/api-shop-brands/${brandId}`;

        if (brandId !== null) {
            const brand = await agent.get(url)
                .then(res => res.data)
                .catch(err => {
                    return {};
                });

            this.setState({
                brand,
                isLoading: false,
                isCreate: false,
            })
        } else {
            this.setState({
                brand: initialState,
                isLoading: false,
                isCreate: true,
            })
        }
    }

    _onSave = async (onRouteBack = false) => {
        const {
            isCreate,
            brandId
        } = this.state;

        if (isCreate) {
            const form = await this._getFormData();

            const response = await agent.post(urls.brandsApiShop, form)
                .then(res => res.data)
                .catch(err => {
                    return {error: err.response.data}
                });
            if (response.error) {
                let messages = response.error.map((item) => item.message);

                Notification({
                    type: NotificationTypes.error,
                    message: messages.join(`\n`)
                })
                onRouteBack = false;

                return null
            } else {
                Notification({
                    type: NotificationTypes.success,
                    message: "Производитель успешно создан"
                })

                if (onRouteBack) {
                    this._routeBrandsPage();
                } else {
                    this._routeBrandEditPage(response.id);
                }
            }

        } else {
            const form = await this._getFormData();

            const response = await agent.put(`${urls.brandsApiShop}/${brandId}`, form).then(res => {
                return res.data
            }).catch((err) => {
                return {error: err.response.data}
            });

            if (response.error) {
                let messages = response.error.map((item) => item.message);

                Notification({
                    type: NotificationTypes.error,
                    message: messages.join(`\n`)
                })

                return null
            } else {
                Notification({
                    type: NotificationTypes.success,
                    message: "Данные успешно изменены"
                })

                if (onRouteBack) {
                    this._routeBrandsPage();
                } else {
                    await this.getBrand();
                }
            }
        }
    };

    _onDuplicate = () => {
    };

    _getFormData = async () => {
        const formData = {};
        const formikData = this.refFormik.current.values;
        //------------------------------------------------------
        formData.name = formikData.name;
        formData.slug = formikData.slug;
        formData.status = formikData.status;
        formData.description = formikData.description;
        formData.second_title = formikData.second_title;
        formData.country = formikData.country;
        formData.sort = formikData.sort;
        formData.show_in_menu = formikData.show_in_menu;
        formData.seo_title = formikData.seo_title;
        formData.seo_description = formikData.seo_description;
        formData.seo_keywords = formikData.seo_keywords;
        //---------------------------------------------------------------------------------
        //IMAGE UPLOAD
        if (formikData.image_id?.file) {
            const imageForm = new FormData();
            imageForm.append('file', formikData.image_id.file);
            const imageId = await agent.post(`/api/storage/upload-images`, imageForm)
                .then(res => res.data?.image)
                .catch(err => {
                    Notification({
                        type: NotificationTypes.error,
                        message: "Изображение не загружено, повторите позже"
                    })

                    return null
                });

            formData.image_id = imageId?.id || null;
        }
        //---------------------------------------------------------------------------------

        return formData;
    }

    _routeBrandsPage = () => {
        this.props.history.push(`/brands`)
    }

    _routeBrandEditPage = (id) => {
        this.props.history.push(`/brands/edit/${id}`)
    }

    render() {
        const {
            brand,
            brandId,

            isLoading,
            isCreate
        } = this.state;
        return (
            <>
                <HeaderComponent
                    brand={brand}
                    onBack={this._routeBrandsPage}
                    isCreate={isCreate}
                />

                <Box>
                    <ControlComponent
                        onBack={this._routeBrandsPage}
                        onDuplicate={this._onDuplicate}
                        onSave={this._onSave}
                        isCreate={isCreate}
                    />
                    <Box px={2} py={4} bgcolor="white" borderRadius={2}>
                        {Boolean(!isLoading) && (
                            <MainFormComponent
                                refFormik={this.refFormik}
                                brand={brand}
                                isLoading={isLoading}
                                isCreate={isCreate}
                            />
                        )}

                        {Boolean(isLoading) && (
                            <>Loading</>
                        )}
                    </Box>
                </Box>
            </>
        );
    }
}

export default BrandEdit;