import React, {Component} from 'react';

import agent from "../../../agent/agent";
import {filterParse, filterStringify} from "../../../helper/filter";
import {Notification, NotificationTypes} from "../../../common/Notification";

import {Box, Button, Typography} from "@mui/material";
import {AddBoxOutlined as AddBoxOutlinedIcon, ListAltOutlined as ListIcon} from "@mui/icons-material";
import {withStyles} from "@mui/styles";

import {Controls, TableComponent} from "./components";
import {DialogConfirmation} from "../../../components";
import {getImageId} from "../../../common/FilesUpload";


const initFilter = {
    page: 1,
}

class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],

            filter: {...initFilter},
            pagination: {},

            isLoading: true,
        }

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.setFilterPage();
        // --------------------------------
        await this.getCategories();
    }

    //Установка страницы при обновлении страницы
    setFilterPage = () => {
        const filter = this._getFilterPage();

        this.setState({filter});
    }

    // Управление производителями
    getCategories = async () => {
        const {filter} = this.state;
        const {
            data,
            headers
        } = await agent.get(`/admin/api-shop-categories/list?ngrestCallType=list&expand=extraName%2CproductCount%2CextraUrl&fields=id%2Cid%2Cimage_id%2CextraName%2Cslug%2Ctitle%2Cstatus%2Cshow_in_menu%2CproductCount%2Cproduct_price_from%2CextraUrl%2Cupdated_at&sort=id&page=${filter.page}`).catch((err) => {
            return {
                data: [],
                headers: {}
            }
        });

        const pagination = {
            count: headers?.['x-pagination-page-count'] || 1,
            perPage: headers?.['x-pagination-per-page'] || 20,
            totalCount: headers?.['x-pagination-total-count'] || 0,
        }

        this.setState({
            categories: data,
            pagination,
            isLoading: false,
        });
    }

    // Работы с фильтром страницы
    _changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        const urlFilter = filterStringify(this.state.filter);
        window.history.replaceState(null, null, `/categories?${urlFilter}`);

        if (!isFastStart) {
            return null
        }

        await this.getCategories();
    }

    //Логика изменения статуса категории
    _onChangeCategoryStatus = async (row, activeStatus) => {

        this.setState({isLoading: true});

        const response = await agent.post(`/admin/api-articles-category/${row.id}?ngrestCallType=update&fields=is_active`, {
            is_active: activeStatus
        }).then((res) => {
            return true
        }).catch((err) => {
            return {error: err.response || true}
        })
        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка сети",
                type: NotificationTypes.error
            })

            return
        }

        await this.getCategories();

        Notification({
            message: `Статус категории ${row.id} успешно изменен`,
            type: NotificationTypes.success
        })
    }
    _onChangeShowInMenu = async (row, status) => {
        const body = {
            show_in_menu: status,
        }

        const response = await agent.put(`/admin/api-shop-categories/${row.id}?ngrestCallType=update&fields=show_in_menu`, body)
            .then()
            .catch();

        await this.getCategories();
    }

    //Получение страницы при обновлении страницы
    _getFilterPage = () => {
        const search = window.location?.search || '';

        let filter = {};

        filter = {
            ...filter,
            ...initFilter,
            ...filterParse(search, initFilter, {})
        }

        return filter
    }

    //Перенаправление на страницу редактирования категории
    _routePageEdit = (id) => {
        this.props.history.push(`/categories/edit/${id}`)
    }

    //Перенаправление на страницу редактирования категории
    _routePageCreate = () => {
        this.props.history.push(`/categories/create`)
    }

    _routeCategoryProductsPage = (id) => {
        this.props.history.push(`/categories/category_products/${id}`);
    }

    render() {
        const {classes} = this.props;
        const {categories, filter, pagination, isLoading} = this.state;
        return (
            <>
                <Box className={classes.head}>
                    <Box className={classes.headLogo}>
                        <ListIcon sx={{color: "#0057FF", fontSize: 42}}/>
                        <Typography variant="h1">Управление категориями</Typography>
                    </Box>
                    <Button
                        variant="contained"
                        startIcon={<AddBoxOutlinedIcon sx={{color: "white"}}/>}

                        onClick={this._routePageCreate}
                    >
                        Добавить категорию
                    </Button>
                </Box>
                <Box>
                    <Controls filter={filter} pagination={pagination}/>
                </Box>
                <Box>
                    <TableComponent
                        categories={categories}
                        filter={filter}
                        pagination={pagination}
                        isLoading={isLoading}

                        onChangeFilter={this._changeFilter}
                        onChangeStatusCategory={this._onChangeCategoryStatus}
                        onChangeShowInMenu={this._onChangeShowInMenu}
                        onRouteEditPage={this._routePageEdit}
                        onRouteProductsPage={this._routeCategoryProductsPage}
                    />
                </Box>

                <DialogConfirmation ref={this.refDialogConfirmation}/>
            </>
        );
    }
}

const styles = {
    head: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16
    }, headLogo: {
        display: "flex", alignItems: "center",

        "& h1": {
            marginLeft: 12
        }
    },
}

const CategoriesStyles = withStyles(styles)(Categories);

export default CategoriesStyles