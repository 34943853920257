import React from "react";
import {
	Box,
	Grid,

	Tabs,
	Tab,

	TextField,

	Button,

	LinearProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	FilterBaseCheckbox,
	FilterBaseString,
	FilterBaseToggleStatus,
	FilterBaseSelectArray,
	FilterBaseTaggablePlugin,
	FilterCharacteristic,
	FilterRangeNumber,

	PagesVisibleColumns, FilterBaseEditPlugin
} from "../../../../../components";

const allowedFilterComponents = [
	'text',
	'checkbox',
	'number',
	'toggleStatus',
	'selectArray',
	'sitis\\shop\\admin\\plugins\\TaggablePlugin'
];
const rangeFilterComponent = [
	'quantity',
	'price_old',
	'price_new',
	'discount_percent'
];

class FilterContent extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			sectionsFilter: [],
			sectionsFilterSettings: [],

			localData: {...this.props.data},

			search: "",
			activeTab: "filter",

			isLoadSectionsFilter: true
		}

		this.timeOutChangeSearch = null;
		this.timeOutChangeRootFilter = null;
	}

	componentDidMount = () => {

		this.timeOutChangeSearch = setTimeout(() => {
			this.setSectionsFilter();
		}, 500);
	}
	componentDidUpdate = (prevProps) => {
		if (JSON.stringify(this.props.data || {}) !== JSON.stringify(prevProps.data || {})) {
			this.setState({
				localData: this.props.data
			})
		}
	}

	changeCommon = ({target}) => {
		const {value, name} = target;

		let data = {...this.state.localData}
		data[name] = value;

		this.setState({localData: data}, () => {
			clearTimeout(this.timeOutChangeRootFilter);
			this.timeOutChangeRootFilter = setTimeout(() => {
				this.changeRootFilter();
			}, 1000);
		})
	}
	changeRange = (name, values) => {
		let [from, to] = values;

		let data = {...this.state.localData};
		data[name] = { from, to };

		this.setState({localData: data}, () => {
			clearTimeout(this.timeOutChangeRootFilter);
			this.timeOutChangeRootFilter = setTimeout(() => {
				this.changeRootFilter();
			}, 1000);
		})
	}
	changeBoolean = ({target}, value) => {
		const {name} = target;

		let data = {...this.state.localData}
		data[name] = value;

		this.setState({localData: data}, () => {
			clearTimeout(this.timeOutChangeRootFilter);
			this.timeOutChangeRootFilter = setTimeout(() => {
				this.changeRootFilter();
			}, 1000);
		});
	}
	changeVisibleFilter = ({target}, value) => {
		const {name} = target;

		let visibleColumns = [...this.props.visibleColumns];
		visibleColumns[name].hideFilter = !value;

		this.props.onChangeVisibleColumns(visibleColumns);
	}
	changeRootFilter = () => {
		this.props.onChange(this.state.localData);
	}

	setAllOnFilter = () => {
		let visibleColumns = [...this.props.visibleColumns];
		visibleColumns = visibleColumns.map((item) => {
			return {
				...item,
				hideFilter: false
			}
		});
		this.props.onChangeVisibleColumns(visibleColumns);
	}
	setAllOffFilter = () => {
		let visibleColumns = [...this.props.visibleColumns];
		visibleColumns = visibleColumns.map((item) => {
			return {
				...item,
				hideFilter: true
			}
		});
		this.props.onChangeVisibleColumns(visibleColumns);
	}

	_renderFilterItem = (key) => {
		const {localData} = this.state;
		const {visibleColumns} = this.props;
		const item = visibleColumns[key];

		item.name = item.nameField;
		item.value = localData?.[item.nameField];

		if (rangeFilterComponent.includes(item.key)) {
			return (
				<FilterBaseEditPlugin
					{...item}
					name={item.key}
					valueGte={localData[`filter[${ item.key }][gte]`] || ""}
					valueLte={localData[`filter[${ item.key }][lte]`] || ""}
					onChange={this.changeCommon}
				/>
			)
		}
		if (item.group === "characteristic") {
			return (
				<FilterCharacteristic
					{...item}

					onChange={this.changeCommon}
				/>
			)
		}
		if (item.type === "text" || item.type === "number") {
			return (
				<FilterBaseString
					{...item}
					onChange={this.changeCommon}
				/>
			)
		}
		if (item.type === "checkbox") {
			return (
				<FilterBaseCheckbox
					{...item}
					onChange={this.changeBoolean}
				/>
			)
		}
		if (item.type === "toggleStatus") {
			return (
				<FilterBaseToggleStatus
					{...item}
					onChange={this.changeBoolean}
				/>
			)
		}
		if (item.type === "selectArray") {
			return (
				<FilterBaseSelectArray
					{...item}
					onChange={this.changeCommon}
				/>
			)
		}
		if (item.type === "sitis\\shop\\admin\\plugins\\TaggablePlugin") {
			return (
				<FilterBaseTaggablePlugin
					{...item}
					onChange={this.changeCommon}
				/>
			)
		}
	}
	_labelVisibleFilter = () => {
		const {visibleColumns} = this.props;
		const total = Object.keys(visibleColumns).length;
		const onCount = Object.keys(visibleColumns).filter((key) => !visibleColumns[key].hideFilter).length;

		return `Включенно ${onCount} из ${total}`
	}


	// Логика по секциям фильтров
	changeSearch = async ({target}) => {
		clearTimeout(this.timeOutChangeSearch);
		this.setState({
			search: target.value,
			isLoadSectionsFilter: true
		});
		this.timeOutChangeSearch = setTimeout(() => {
			this.setSectionsFilter();
			this.setSectionsFilterSettings();
		}, 500);
	}
	setSectionsFilter = () => {
		const visibleColumns = this.props.visibleColumns;
		const {
			search
		} = this.state;

		let filter = [];
		Object.keys(visibleColumns).map((key) => {
			const item = visibleColumns[key];
			const lowerLabel = (item.label).toLowerCase();
			const lowerSearch = (search).toLowerCase();
			if (!rangeFilterComponent.includes(item?.key) && !allowedFilterComponents.includes(item.type) || item.hideFilter) {
				return
			}
			if (!!lowerSearch && lowerLabel.indexOf(lowerSearch) === -1) {
				return null
			}

			filter.push({
				...item,
				itemKey: key
			})
		})

		this.setState({
			sectionsFilter: filter,
			isLoadSectionsFilter: false
		})
	}
	setSectionsFilterSettings = () => {
		const visibleColumns = this.props.visibleColumns;
		const { search } = this.state;

		let filter = [];
		Object.keys(visibleColumns).map((key) => {
			const item = visibleColumns[key];
			const lowerSearch = (search).toLowerCase();
			const lowerLabel = (item.label).toLowerCase();
			if (!!lowerSearch && lowerLabel.indexOf(lowerSearch) === -1) {
				return null
			}

			filter.push({
				...item,
				itemKey: key
			})
		})

		this.setState({
			sectionsFilterSettings: filter,
			isLoadSectionsFilter: false
		})
	}
	submitSearchFilter = () => {
		this.props.onSearchFilter(this.state.localData);
	}


	render() {
		const {
			classes,
			visibleColumns,
			onResetFilter
		} = this.props;
		const {
			sectionsFilter,

			search,
			activeTab,

			isLoadSectionsFilter
		} = this.state;

		return (
			<Box className={classes.root}>
				<Box className={classes.head}>
					<Box>
						<Tabs
							value={activeTab}
							className={classes.tabs}
							onChange={(event, activeTab) => this.setState({activeTab})}
						>
							<Tab label="Все фильтры" value="filter"/>
							<Tab label="Управление фильтром" value="settings"/>
						</Tabs>
					</Box>
					<Box className={classes.headControls}>
						<TextField
							value={search}
							sx={{marginRight: 2}}
							size="small"
							placeholder="Введите наименование фильтра"
							onChange={this.changeSearch}
						/>
						<span>{this._labelVisibleFilter()}</span>
						<Button variant="text" onClick={this.setAllOffFilter}>
							Выкл все фильтры
						</Button>
						<Button variant="text" onClick={this.setAllOnFilter}>
							Вкл все фильтры
						</Button>
						<Button variant="text" onClick={onResetFilter}>
							Сбросить фильтры
						</Button>
						<Button variant="contained" onClick={this.submitSearchFilter}>
							Поиск
						</Button>
					</Box>
				</Box>
				<Box className={classes.body}>
					{Boolean(isLoadSectionsFilter) && (
						<LinearProgress sx={{marginBottom: 2}}/>
					)}

					{Boolean(activeTab === "filter") && (
						<Grid container spacing={2}>
							{sectionsFilter.map((element) => (
								<Grid key={element?.itemKey} item sm={4}>
									{this._renderFilterItem(element?.itemKey)}
								</Grid>
							))}
						</Grid>
					)}
					{Boolean(activeTab === "settings") && (
						<Grid container spacing={2}>
							{sectionsFilter.map((element) => (
								<Grid key={`attribute-${element?.itemKey}`} item sm={4}>
									<PagesVisibleColumns
										name={element?.itemKey}
										title={element?.label}
										value={visibleColumns[element?.itemKey]?.hideFilter || false}

										onChange={this.changeVisibleFilter}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Box>
			</Box>
		)
	}
}

const styles = {
	root: {
		display: "flex",
		flexDirection: "column",
		marginTop: -12,
		overflow: "auto",
	},
	head: {
		display: "flex",
		alignItems: "center",
		borderBottom: "1px solid #E6E6E6",
		marginBottom: 12
	},
	body: {
		overflow: "auto",
		paddingRight: "12px",

		'&::-webkit-scrollbar': {
			width: '8px',
		},
		'&::-webkit-scrollbar-track': {
			background: "none",
		},
		'&::-webkit-scrollbar-thumb': {
			background: "#0057FF",
			borderRadius: "8px",
		}
	},

	tabs: {
		"& .MuiTab-root": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",

			padding: 0,
			height: 44,
			textTransform: "initial",
			marginLeft: 24,

			fontSize: 18,
			lineHeight: "23px",
			color: "#C8C8C8",
			fontWeight: "500",

			"&:first-child": {
				marginLeft: 0,
			},
			"&.Mui-selected": {
				color: "#000000"
			}
		}
	},

	headControls: {
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",

		"& span": {
			fontSize: 14,
			lineHeight: "18px",
			color: "#787878"
		},
		"& button": {
			fontSize: 14,
			lineHeight: "18px",
			color: "#3855EC",
			marginLeft: 8,
			textTransform: "initial",
			fontWeight: "400",

			"&.MuiButton-contained": {
				color: "white",
				fontWeight: "700"
			}
		},
	}
}
const StylesFilterContent = withStyles(styles)(FilterContent)

export default StylesFilterContent
