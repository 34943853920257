import React from "react";
import {
	Header as HeaderComponent
} from "./components";
import {
	Box
} from "@mui/material";
import {
	MenuBook as MenuBookIcon,
	MonetizationOnOutlined as MonetizationIcon,
	PhotoSizeSelectLarge as PhotoSizeSelectLargeIcon,
	PriceChangeOutlined as PriceChangeIcon,
	ShoppingBasket as ShoppingBasketIcon
} from "@mui/icons-material";
import {
	SideBarNotifications as SideBarNotificationsComponent
} from "../../components";

const routes = [
	{
		label: "Товары",
		icon: MenuBookIcon, path: "/products",
		rootPath: "/products",
		roles: ['admin', 'content']
	},
	{
		label: "Заказы",
		icon: ShoppingBasketIcon,
		path: "/orders",
		rootPath: "/orders",
		roles: ['admin', 'manager']
	},
	{
		label: "Региональные цены",
		icon: MonetizationIcon,
		path: "/regionalPrices",
		rootPath: "/regionalPrices",
    roles: ['admin', 'content'],
		disabled: true
	},
	{
		label: "Редактирование цен",
		icon: PriceChangeIcon,
		path: "/price-editing",
		rootPath: "/price-editing",
    roles: ['admin', 'content']
	},
	{
		label: "Обрезка фотографий",
		icon: PhotoSizeSelectLargeIcon,
		path: "/photo-cropping",
		rootPath: "/photo-cropping",
    roles: ['admin', 'content']
	},
];

class Default extends React.Component {
	render() {
		const {
			classes
		} = this.props;

		return (
			<Box>
				<HeaderComponent
					routes={routes}
				/>

				<Box height="calc(100vh - 64px)" overflow="auto" px={2} py={1} boxSizing="border-box">
					{this.props.children}
				</Box>


				<SideBarNotificationsComponent/>
			</Box>
		)
	}
}

export default Default
