import React from "react";
import { Store } from 'react-notifications-component';

const Notification = (
    {
        title = 'Системное уведомление',
        message = '',
        type = 'info',
        duration = 3000
    }
) => {
    Store.addNotification({
        title: title,
        message: (<React.Fragment>
            <div dangerouslySetInnerHTML={{
                __html: message
            }}/>
        </React.Fragment>),
        type: type,
        insert: 'top',
        container: 'top-right',
        dismiss: {
            duration: duration,
            onScreen: false,
            pauseOnHover: true,
            delay: 0
        }
    })
}

const NotificationTypes = {
    info: "info",
    success: "success",
    warning: "warning",
    error: "danger",
};

export {
    Notification,
    NotificationTypes
}
