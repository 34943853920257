// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Products from './Products';
import {
  getPageProductsSettings, setActivePreset
} from "../../../states/products"

export default compose(
  connect(
    state => ({
      commonColumns: state?.products?.commonColumns || [],
      activePreset: state?.products?.preset || {},

      adminLocked: (state?.global?.adminLocked || []).filter((t) => t.lock_table === "{{%shop_products}}"),
      userRoles: state?.global?.userRoles || [],
    }),
    dispatch => ({
      getPageProductsSettings: () => dispatch(getPageProductsSettings()),
      setActivePreset: () => dispatch(setActivePreset()),
    }),
  ),
)(Products);
