// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Characteristics from './Characteristics';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Characteristics);
