import {filterParse} from "../helper/filter";

const commonInitState = ({ initFilter, initVisibleColumns, initFavoriteFilters, name }) => {
    const filter = filterParse(window.location?.search, initFilter);

    const productsVisibleColumns = localStorage.getItem(`${name}-visible-columns`);
    let _visibleColumns = {...initVisibleColumns};
    if (!!productsVisibleColumns) {
        _visibleColumns = {
            ..._visibleColumns,
            ...JSON.parse(productsVisibleColumns)
        }
    }

    const favoriteFilters = localStorage.getItem(`${name}-popular-filter`);
    let _favoriteFilters = [];
    if (favoriteFilters) {
        _favoriteFilters = JSON.parse(favoriteFilters);
    }

    return {
        filter,
        visibleColumns: _visibleColumns,
        favoriteFilters: _favoriteFilters
    }
}

export default commonInitState
