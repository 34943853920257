import React, {Component} from 'react';
import {withStyles} from "@mui/styles";
import {Box, Typography} from "@mui/material";
import {PriceChangeOutlined as PriceChangeIcon} from "@mui/icons-material";
import {FilterComponent, TableComponent} from "./components";
import {filterParse, filterStringify} from "../../../helper/filter";
import agent from "../../../agent/agent";
import {Notification, NotificationTypes} from "../../../common/Notification";
import urls from "../../../variables/urls";

const initFilter = {
    'filter[extraWebsites]': [],
    page: 1,
}

class PricesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            shopCharacteristicGroups: [],
            productsAttributes: [],

            filter: {...initFilter},
            pagination: {},

            isLoading: true,
            isLoadingFilters: true,
        };

        this.timeOutSearch = null;
    }

    componentDidMount = async () => {
        await this.setFilterPage();
        await this.getColumns();
        await this.getProducts();
        await this.getCharacteristicGroup();
    }

    setFilterPage = async () => {
        await this.setState({isLoadingFilters: true})
        const filter = this._getFilterPage();
        await this.setState({filter, isLoadingFilters: false})
    };

    getProducts = async () => {
        const formData = this._getFilterBody();
        this.setState({isLoading: true})

        const expand = [
          'brands',
          'extraValues',
          'extraOptions',
          'extraProductSetParents',
        ].join(',');
        const {data, headers} = await agent.get(`/api/admin/products/index-ngrest?sort=code&expand=${ expand }&${formData.join("&")}`)
            .catch((err) => {
                Notification({
                    message: err.response.data[0].message,
                    type: NotificationTypes.error
                });

                return {
                    data: [],
                    headers: {},
                }
            });

        const products = (data || []).map((product) => {
            const price_old = Boolean(!product.price_old) ? product.price_new : product.price_old;
            const price_special = Boolean(product.price_new === price_old) ? "" : product.price_new;
            const extraOptions = (product?.extraOptions || []).map((extraOption) => {
                const variants = (extraOption?.variants || []).map((variant) => {
                    const price_old_value = Boolean(!variant.price_old_value) ? variant.price_value : variant.price_old_value;
                    const price_value = Boolean(variant.price_value === price_old_value) ? "" : variant.price_value;

                    return {
                        ...variant,
                      price_value: (price_value || '').replace(/,/g, '.'),
                      price_old_value: (price_old_value || '').replace(/,/g, '.')
                    }
                })

                return {
                    ...extraOption,
                    variants
                }
            });

            return {
                ...product,
                price_old: (price_old || '').replace(/,/g, '.'),
                price_special: (price_special || '').replace(/,/g, '.'),
                extraOptions
            }
        })
        const pagination = {
            count: headers?.['x-pagination-page-count'] || 1,
            perPage: headers?.['x-pagination-per-page'] || 20,
            totalCount: headers?.['x-pagination-total-count'] || 0,
        }

        this.setState({
            products: products,
            pagination,
            isLoading: false,
        })
    }
    getCharacteristicGroup = async () => {
        // const {product} = this.state;
        //
        // if (!product.characteristic_group_id) {
        //     Notification({
        //         type: NotificationTypes.warning,
        //         message: "У товара не заполнен \"Тип товара\""
        //     })
        //
        //     return
        // }
        // let expand = [
        //     'sections',
        //     'sections.characteristicAssignments',
        //     'sections.characteristicAssignments.characteristic',
        //     'sections.characteristicAssignments.characteristic.decodedVariants'
        // ].join(',')
        // const urlQuery = `/admin/api-shop-characteristic-groups/${product.characteristic_group_id}?expand=${expand}`
        //
        // const response = await agent.get(urlQuery).then((res) => {
        //     return res.data?.sections || []
        // }).catch(() => {
        //     return []
        // })
        //
        // this.setState({
        //     shopCharacteristicGroups: response
        // })
    }
    getColumns = async () => {
        let initPageColumns = await agent.get(urls.getProductsColumns).then((res) => {
            let data = res.data;

            if (!data.find((t) => t.name === 'id')) {
                data.unshift({
                    label: "ID",
                    name: "id"
                })
            }

            return data
        }).catch(() => {
            return []
        });
        initPageColumns = initPageColumns.map((column, idx) => {
            const isTypeObject = Boolean(typeof column.type !== "string");

            return {
                ...column,
                type: isTypeObject ? (column?.type?.["0"] || column?.type?.['class']) : column?.type,
                name: column.label,
                nameField: column.name,
                key: column.name,
                options: column?.type?.data || column?.options || column?.data || [],
                omit: true,

                objectType: Boolean(isTypeObject) ? column.type : {}
            }
        });

        this.setState({
            productsAttributes: initPageColumns
        })
    }

    //Получение номера страницы при обновлении страницы
    _getFilterPage = () => {
        const search = window.location?.search || '';

        let filter = {};

        filter = {
            ...filter,
            ...initFilter,
            ...filterParse(search, initFilter, {})
        }

        return filter
    }

    // Работы с фильтром страницы
    _changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        const urlFilter = filterStringify(this.state.filter);
        window.history.replaceState(null, null, `/price-editing?${urlFilter}`);

        await this.getProducts();
    }
    _clearFilter = async () => {
        const urlFilter = filterStringify(initFilter);
        await window.history.replaceState(null, null, `/price-editing?${urlFilter}`);

        const filter = this._getFilterPage();
        await this.setState({filter});

        await this.getProducts();
    }

    _getFilterBody = () => {
        const {filter} = this.state;
        const activeFilterKeys = Object.keys({...filter}).filter((t) => filter[t]);

        let filterObject = [];

        activeFilterKeys.map((key) => {
            if (key === 'name' || key === 'code') {
                filterObject.push(`filter[${key}][like]=${filter[key]}`);
            }
            else if (key === "filter[extraWebsites]") {
                if ((filter[key] || [])?.length > 0) {
                    filterObject.push(`${key}=${filter[key].join(',')}`)
                }
            }
            else if (key === "page") {
                filterObject.push(`${key}=${filter[key]}`);
            }
            else if (key === 'extraProductSetParents') {
                filter[key].forEach(value => {
                    filterObject.push(`filter[extraProductSetParents][]=${value.id || value}`)
                })
            }
            else if (key === 'brand_id') {
                filter[key].forEach(value => {
                    filterObject.push(`filter[brand_id][]=${value.id || value}`)
                })
            }
            else {
                filterObject.push(`filter[${key}]=${filter[key]}`);
            }
        })

        return filterObject
    }

    render() {
        const {classes} = this.props;
        const {
            products,
            productsAttributes,

            filter,
            pagination,

            isLoading,
            isLoadingFilters,
        } = this.state;
        return (
            <>
                <Box className={classes.head}>
                    <Box className={classes.headLogo}>
                        <PriceChangeIcon sx={{color: "#0057FF", fontSize: 42}}/>
                        <Typography variant="h1">Редактирование цен</Typography>
                    </Box>
                </Box>

                {Boolean(!isLoadingFilters) && (
                    <>
                        <FilterComponent
                            filter={filter}
                            productsAttributes={productsAttributes}

                            onChangeFilter={this._changeFilter}
                            onClearFilter={this._clearFilter}
                        />
                    </>
                )}

                <TableComponent
                    products={products}
                    filter={filter}
                    pagination={pagination}
                    isLoading={isLoading}

                    onChangePagination={this._changeFilter}
                />
            </>
        );
    }
}

const styles = {
    head: {
        display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16
    }, headLogo: {
        display: "flex", alignItems: "center",

        "& h1": {
            marginLeft: 12
        }
    },
}

const PricesEditStyles = withStyles(styles)(PricesEdit);

export default PricesEditStyles
