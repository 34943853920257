import React from 'react';
import {Box, Button, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {DeleteOutlineOutlined as DeleteIcon, KeyboardBackspace as KeyboardBackspaceIcon} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";

const HeaderProductEdit = (props) => {
    const {
        product,

        onDeleteProduct,
        onBack
    } = props;
    const classes = useStyles();
    return (
        <>
            <Box mb={2}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Tooltip title="Вернуться в каталог товаров">
                                    <IconButton onClick={onBack}>
                                        <KeyboardBackspaceIcon color="primary"/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Typography variant="h1">{product?.name}</Typography>
                            </Grid>
                            <Grid item>
                                {
                                    product?.main_photo_id
                                        ? <Box sx={{
                                            width: 36,
                                            height: 36,
                                            borderRadius: 1,
                                        }}>
                                            <img src={`${process.env.REACT_APP_HOST_API}api/storage/image/${ product?.main_photo_id }_w-100_h-100.webp`} style={{
                                                width: 36,
                                                height: 36,
                                                objectFit: "cover",
                                                borderRadius: 1,
                                            }} alt=""/>
                                        </Box>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    {Boolean(false) && (
                      <Grid item>
                          <Button
                            className={classes.Button}
                            variant="contained"
                            size="medium"
                            startIcon={<DeleteIcon/>}
                            color="error"

                            onClick={() => onDeleteProduct(false)}
                          >
                              удалить
                          </Button>
                      </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
};

const useStyles = makeStyles({
    Button: {
        width: "100%",
        '&:disabled': {
            background: '#62B4FF',
            color: '#ffffff',
            '& svg': {
                fill: '#ffffff',
                opacity: .5,
            },
        }
    },
});

export default HeaderProductEdit;
