import React from 'react';
import {
	FilterBaseCheckbox,
	FilterBaseSelectArray,
	FilterBaseString,
	FilterBaseTaggablePlugin,
	FilterBaseEditPlugin,
	AutocompleteSites
} from "../../../../../components";
import {Box, Button, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const popularFilters = ["name", "code", "sku", "brand_id", "characteristic_group_id", "status", "extraProductSetParents", "price_old", "price_new"];
let timeOutUpdateRootFilter = null;
let timeOutEnterSearch = null;

const ProductsPopularFilters = React.memo((props) => {
	const {
		filter,
		visibleColumns,

		onChange,
		onSearch,
		onSearchFilter
	} = props;
	const classes = useStyles();
	const [listItems, setListItems] = React.useState([]);
	const [localFilter, setLocalFilter] = React.useState({...filter});

	React.useEffect(() => {
		let items = visibleColumns.filter(column => popularFilters.includes(column.key));

		setListItems(items);
	}, [visibleColumns]);
	React.useEffect(() => {
		setLocalFilter(filter);
	}, [filter])

	const changeCommon = React.useCallback(({target}) => {
		clearTimeout(timeOutUpdateRootFilter);
		const {value, name} = target;
		let newData = {...localFilter}
		newData[name] = value;
		setLocalFilter(newData);
		timeOutUpdateRootFilter = setTimeout(() => {
			updateRootFilter(newData);
		}, 300);
	}, [localFilter])
	const changeSelectArray = React.useCallback(async ({target}) => {
		clearTimeout(timeOutUpdateRootFilter);
		const {value, name} = target;

		let newData = {...localFilter}
		newData[name] = value;
		setLocalFilter(newData);

		onSearchFilter(newData);
	}, [localFilter])
	const changeBoolean = React.useCallback(({target}, value) => {
		clearTimeout(timeOutUpdateRootFilter);
		const {name} = target;

		let newData = {...localFilter}
		newData[name] = value;
		setLocalFilter(newData);

		timeOutUpdateRootFilter = setTimeout(() => {
			updateRootFilter(newData);
		}, 300);
	}, [localFilter])
	const updateRootFilter = (newData) => {
		clearTimeout(timeOutUpdateRootFilter);
		onChange(newData);
	}
	const formSearch = (event) => {
		var keyCode = event ? (event.which ? event.which : event.keyCode) : event.keyCode;
		if (keyCode == 13) {
			clearTimeout(timeOutUpdateRootFilter);
			onSearchFilter(localFilter);
		}
	}

	return (
		<form onKeyDown={formSearch} autoComplete={false}>
			<Box bgcolor="white" borderRadius={2} py={2} px={2}>
				<Grid container spacing={2} className={classes.grid}>
					{(listItems).map((item, index) => (
						<Grid item xs={2} key={`ProductsPopularFilter-${ index }`} className={classes.filterItem}>
							<VisibleContent visible={item.type === "text" || item.type === "number"}>
								<FilterBaseString
									{...item}
									name={item.nameField}
									value={localFilter?.[item.nameField] || ""}
									onChange={changeCommon}
								/>
							</VisibleContent>
							<VisibleContent visible={item.type === "checkbox"}>
								<FilterBaseCheckbox
									{...item}
									name={item.nameField}
									value={localFilter?.[item.nameField] || false}
									onChange={changeBoolean}
								/>
							</VisibleContent>
							<VisibleContent visible={item.type === "toggleStatus"}>
								<FilterBaseSelectArray
									{...item}
									name={item.nameField}
									value={String(localFilter?.[item.nameField])}
									options={{
										0: "Выключен",
										1: "Включен",
									}}
									onChange={changeSelectArray}
								/>
							</VisibleContent>
							<VisibleContent visible={item.type === "selectArray"}>
								<FilterBaseSelectArray
									{...item}
									name={item.nameField}
									value={localFilter?.[item.nameField] || ""}
									onChange={changeSelectArray}
								/>
							</VisibleContent>
							<VisibleContent visible={item.type === "sitis\\shop\\admin\\plugins\\TaggablePlugin"}>
								<FilterBaseTaggablePlugin
									{...item}
									name={item.nameField}
									value={localFilter?.[item.nameField] || ""}
									isCollection={Boolean(true)}

									onChange={changeSelectArray}
								/>
							</VisibleContent>
							<VisibleContent visible={item.type === "sitis\\plugins\\admin\\plugins\\EditPlugin"}>
								<FilterBaseEditPlugin
									{...item}
									name={item.nameField}
									valueGte={localFilter[`filter[${ item.nameField }][gte]`] || ""}
									valueLte={localFilter[`filter[${ item.nameField }][lte]`] || ""}
									onChange={changeCommon}
								/>
							</VisibleContent>
						</Grid>
					))}
					<Grid item xs={2}>
						<Box display="flex" alignItems="flex-end" height="100%">
							<AutocompleteSites
								value={filter['filter[extraWebsites]'] || []}
								name="filter[extraWebsites]"
								onChange={changeSelectArray}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
});
const VisibleContent = React.memo((props) => {
	if (!props.visible) {
		return null
	}

	return props.children
})

const useStyles = makeStyles({
	root: {},
	title: {
		fontWeight: 500,
		fontSize: 20,
		lineHeight: "23px",
		color: "#000000"
	},
	filterItem: {
		"& > .MuiBox-root": {
			flexDirection: "column",
			background: "transparent",
			padding: 0,

			"& > .MuiTypography-root": {
				fontWeight: 400,
				fontSize: 14,
				lineHeight: "17px",
				marginBottom: 2,
				marginLeft: 6,
			}
		}
	},

	grid: {
		"& .MuiBox-root": {
			width: "100%",
			maxWidth: "initial"
		},
		"& .BaseToggleStatus-root-83": {
			paddingTop: 17,
			flexDirection: "row"
		}
	}
})

export default ProductsPopularFilters;
