import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Box,
	Grid,
	Button,
  Tooltip,
  Checkbox,
  Skeleton,
  Typography,
  ButtonGroup
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import agent from "../../../../../agent/agent";
import {data} from "autoprefixer";
import {TableRowTaggablePlugin} from "../../../../../components";
import clsx from "clsx";

const TableCustom = (props) => {
	const {
		data,
		isLoad,
		filter,
		productsSelected,
		productsAttributes,

		onChangeProductsSelected
	} = props;
	const classes = useStyles();

	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell width={10}/>
					<TableCell width={220}>Наименование</TableCell>
					<TableCell width={120}>Производитель</TableCell>
					<TableCell width={140}>Входит в комплект</TableCell>
					<TableCell>Артикул</TableCell>
					<TableCell>Фото "Схема"</TableCell>
					<TableCell>Разрешение (Ш * В)</TableCell>
					<TableCell align="right">Исходное изображение</TableCell>
					<TableCell width={320} align="center">Управление</TableCell>
					<TableCell align="left">Итоговое изображение</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<VisibleContent visible={!isLoad}>
					{data.map((item) => {
						return (
							<RowComponent
								key={`RowComponent-${item.productId}-${item.imageId}`}
								data={item}
								filter={filter}
								productsSelected={productsSelected}
								productsAttributes={productsAttributes}

								onChangeProductsSelected={onChangeProductsSelected}
							/>
						)
					})}
				</VisibleContent>
				<VisibleContent visible={isLoad}>
					<TableRow>
						<TableCell>
							<Skeleton/>
						</TableCell>
						<TableCell>
							<Skeleton/>
						</TableCell>
						<TableCell>
							<Skeleton/>
						</TableCell>
						<TableCell>
							<Skeleton/>
						</TableCell>
						<TableCell>
							<Skeleton/>
						</TableCell>
						<TableCell>
							<Skeleton/>
						</TableCell>
						<TableCell>
							<Skeleton/>
						</TableCell>
						<TableCell>
							<Skeleton width={210} height={150} variant="rectangular" style={{marginLeft: "auto"}}/>
						</TableCell>
						<TableCell align="center">
							<Skeleton width={320} height={60} style={{margin: "0 auto"}}/>
							<Skeleton width={226} height={60} style={{margin: "0 auto"}}/>
						</TableCell>
						<TableCell>
							<Skeleton width={210} height={150} variant="rectangular"/>
						</TableCell>
					</TableRow>
				</VisibleContent>
			</TableBody>
		</Table>
	)
}
const VisibleContent = React.memo(({visible, children}) => {
	if (!visible) {
		return null
	}

	return children
})
const RowComponent = React.memo((props) => {
	const {
		data,
		filter,
		productsSelected,
		productsAttributes,

		onChangeProductsSelected
	} = props;
	const classes = useStyles();
	const refImageChanged = React.useRef();
	const [fit, setFit] = React.useState("");
	const [border, setBorder] = React.useState("");
	const [cropCenter, setCropCenter] = React.useState(null);
	const [schemeCharacteristic, setSchemeCharacteristic] = React.useState(false);
	const [isSending, setSending] = React.useState(false);
	const [isDisableScheme, setDisableScheme] = React.useState(true);
	const [brandOptions, setBrandOptions] = React.useState([]);
	const [stringResolution, setStringResolution] = React.useState('');

	React.useEffect(() => {
		(async () => {
			await handleInitData();
			await handleInitSchemeCharacteristic();
		})();
	}, []);
	React.useEffect(() => {
		setBrandOptions((productsAttributes || []).find((t) => t.nameField === "brand_id")?.options || {});
	}, [productsAttributes]);

	const handleInitData = async () => {
		const response = await agent.get(`/api/admin/storage/image/${data.imageId}`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});
		const newFit = response?.default_fit_value || null;
		const newBorder = (response?.default_border_value || "").split(',')?.[0] || null;
    const newCropCenter = Boolean((response?.default_fit_value || '').indexOf('-') > -1)
      ? [response?.default_fit_value.split('-')?.[1], response?.default_fit_value.split('-')?.[2]] : null;

		setFit(newFit);
		setBorder(newBorder);
    setCropCenter(newCropCenter);
		setStringResolution([response.resolution_width, response.resolution_height].join(' * '));

		if (!refImageChanged.current) {
			return
		}
		if (!newFit && !newBorder) {
			return null
		}

		refImageChanged.current.src = `${process.env.REACT_APP_HOST_API}api/storage/image/${data.imageId}_w-420_h-300_fit-default_border-default.webp?${new Date().getTime()}`
	}
	const handleInitSchemeCharacteristic = async () => {
		if (!Boolean(data.isMainPhotoId)) {
			return
		}

		const url = `/api/admin/products/${ data.productId }/characteristics/${ process.env.REACT_APP_SCHEME_CHARACTERISTIC_id }`;
		const response = await agent.get(url).then((res) => {
			return res.data
		}).catch(() => {
			return []
		});

		setSchemeCharacteristic(Boolean(response?.[0] === +process.env.REACT_APP_SCHEME_CHARACTERISTIC_SELECTED));
		setDisableScheme(false);
	}

	const handleChangeFit = async (value) => {
		setSending(true);

		const response = await agent.put(`/api/admin/storage/image/${data.imageId}`, {
			default_fit_value: value
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})


		await handleInitData();
		setSending(false);
	}
	const handleChangeBorder = async (value) => {
		setSending(true);

		const response = await agent.put(`/api/admin/storage/image/${data.imageId}`, {
			default_border_value: !value ? null : `${value},fff,expand`
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		await handleInitData();
		setSending(false);
	}
  const handleChangeCrop = async (item, event) => {
    const { offsetX, offsetY } = event?.nativeEvent;
    const widthX = 210;
    const widthY = 150;

    const resultX = offsetX / widthX * 100;
    const resultY = offsetY / widthY * 100;

    setSending(true);
    await agent.put(`/api/admin/storage/image/${data.imageId}`, {
      default_fit_value: `crop-${Math.floor(resultX)}-${Math.floor(resultY)}`
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    })
    await handleInitData();
    setSending(false);
  }

	const handleChangeSchemeCharacteristic = async () => {
		setDisableScheme(true)

		const url = `/api/admin/products/${ data.productId }/characteristics/${ process.env.REACT_APP_SCHEME_CHARACTERISTIC_id }`;
		const variantIds = [
			Boolean(schemeCharacteristic) ? process.env.REACT_APP_SCHEME_CHARACTERISTIC_REMOVED : process.env.REACT_APP_SCHEME_CHARACTERISTIC_SELECTED
		];
		const body = {
			variant_ids: variantIds
		}

		const response = await agent.put(url, body).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})

		setDisableScheme(false);
		setSchemeCharacteristic(Boolean(response?.[0] === +process.env.REACT_APP_SCHEME_CHARACTERISTIC_SELECTED));
	}

	if (!Boolean(data.isMainPhotoId) && filter.showOnlyMainPhoto) {
		return null
	}

	return (
		<TableRow hover>
			<TableCell align="center">
				<Checkbox
					checked={Boolean(productsSelected.find((t) => t.imageId === data.imageId))}
					onChange={() => onChangeProductsSelected(data)}
					size="small"
				/>
			</TableCell>
			<TableCell>{data.productName}</TableCell>
			<TableCell>{brandOptions[data.productBrandId] || "-"}</TableCell>
			<TableCell style={{ position: "relative" }}>
				<TableRowTaggablePlugin
					{...(productsAttributes.find((t) => t.nameField === "extraProductSetParents"))}
					value={data.productExtraProductSetParents}
				/>
			</TableCell>
			<TableCell>{data.productCode || "-"}</TableCell>
			<TableCell align="center">
				{Boolean(data.isMainPhotoId) && (
					<Checkbox
						disabled={isDisableScheme}
						checked={schemeCharacteristic}
						onChange={handleChangeSchemeCharacteristic}
					/>
				)}
			</TableCell>
			<TableCell>{stringResolution || "-"}</TableCell>
			<TableCell align="right">
				{Boolean(data.isMainPhotoId) && (
					<Box bgcolor="#3855EC" color="white" px="8px" py="4px" fontSize="16px" borderRadius="4px" textAlign="center" fontWeight="500" mb={1}>
						Главное фото
					</Box>
				)}

        <Tooltip title="Изменить центра обрезки" arrow>
          <div
            className={classes.clearImageContent}
            onClick={handleChangeCrop.bind(this, data)}
          >
            <img
              src={`${process.env.REACT_APP_HOST_API}api/storage/image/${data.imageId}_w-210_h-150_fit-contain.webp`}
              className={classes.image}
            />

            {Boolean(cropCenter) && (
              <Box className="dot" style={{top: `${cropCenter?.[1]}%`, left: `${cropCenter?.[0]}%`}}/>
            )}
          </div>
        </Tooltip>
			</TableCell>
			<TableCell>
				<Grid container spacing={2} alignItems="center" justifyContent="center" flexDirection="column">
					<Grid item>
						<ButtonGroup disabled={isSending} className={classes.buttonGroup} >
							<Button
								variant={Boolean(fit === null) ? "contained" : "outlined"}
								color="inherit"
								disabled={!data.imageId}
								onClick={handleChangeFit.bind(this, null)}
							>Не выбрано</Button>
							<Button
								variant={Boolean(fit === "crop") ? "contained" : "outlined"}
								disabled={!data.imageId}
								onClick={handleChangeFit.bind(this, "crop")}
							>Обрезать</Button>
							<Button
								variant={Boolean(fit === "contain") ? "contained" : "outlined"}
								disabled={!data.imageId}
								onClick={handleChangeFit.bind(this, "contain")}
							>Не обрезать</Button>
						</ButtonGroup>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1" textAlign="center" mb={0.5}>Добавить поля</Typography>
						<ButtonGroup className={classes.buttonGroup}>
							<Button
								variant={Boolean(border === null) ? "contained" : "outlined"}
								onClick={handleChangeBorder.bind(this, null)}
								color="inherit"
								disabled={!data.imageId}
							>0px</Button>
							<Button
								variant={Boolean(+border === 5) ? "contained" : "outlined"}
								onClick={handleChangeBorder.bind(this, 5)}
								disabled={!data.imageId}
							>5px</Button>
							<Button
								variant={Boolean(+border === 10) ? "contained" : "outlined"}
								onClick={handleChangeBorder.bind(this, 10)}
								disabled={!data.imageId}
							>10px</Button>
							<Button
								variant={Boolean(+border === 15) ? "contained" : "outlined"}
								onClick={handleChangeBorder.bind(this, 15)}
								disabled={!data.imageId}
							>15px</Button>
							<Button
								variant={Boolean(+border === 30) ? "contained" : "outlined"}
								onClick={handleChangeBorder.bind(this, 30)}
								disabled={!data.imageId}
							>30px</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			</TableCell>
			<TableCell align="left">
				<div className={classes.imageNew}>
					<img
						ref={refImageChanged}
						src={
							Boolean(fit || border) ? `${process.env.REACT_APP_HOST_API}api/storage/image/${data.imageId}_w-420_h-300_fit-default_border-default.webp` :
								`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`
						}
						style={{border: "1px solid red"}}
						className={clsx([classes.image])}
					/>
				</div>
			</TableCell>
		</TableRow>
	)
})

const useStyles = makeStyles(() => ({
	table: {
		"& .MuiTableHead-root": {
			position: "sticky",
			top: -10,
			zIndex: 100,
		},
		"& .MuiCheckbox-root": {
			padding: 0
		}
	},

	image: {
		width: 210,
		height: 150,
		objectFit: "contain"
	},
	imageNew: {
		position: "relative",
		backgroundColor: "white",
		"&:after": {
			content: "''",
			position: "absolute",
			left: 0,
			top: 0,
			width: "100%",
			height: "100%",
			backgroundColor: "rgba(0,0,0,0.03)"
		}
	},

  clearImageContent: {
    width: 210,
    height: 150,
    objectFit: "contain",
    border: "1px solid #3855EC",
    margin: "0 auto",
    position: "relative",
    cursor: "crosshair",
    "& > .dot": {
      position: "absolute",
      width: 10,
      height: 10,
      borderRadius: "100%",
      backgroundColor: "#3855EC",
      border: "1px solid red"
    }
  },

	buttonGroup: {
		"& .MuiButton-root": {
			fontSize: "12px",
			padding: "2px 4px",
			whiteSpace: "nowrap"
		},
		"& .MuiButton-contained.MuiButton-colorInherit": {
			backgroundColor: "#adadad"
		}
	}
}));

export default React.memo(TableCustom)
