import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
	RouteWithLayout
} from "../components";
import {
	Default as DefaultLayout
} from "../layouts";
import {
	Products as ProductsPage,
	ProductEdit as ProductEditPage,
	ProductMultiEdit as ProductMultiEditPage,

	Orders as OrdersPage,
	Order as OrderPage,
	OrderEdit as OrderEditPage,

	Characteristics as CharacteristicsPage,
	CharacteristicVariants as CharacteristicVariantsPage,
	CharacteristicCreate as CharacteristicCreatePage,

	Brands as BrandsPage,
	BrandEdit as BrandEditPage,

	Categories as CategoriesPage,
	CategoryEdit as CategoryEditPage,
	CategoryProducts as CategoryProductsPage,

	Promotions as PromotionsPage,

	RegionalPrices as RegionalPricesPage,
	RegionalPriceEdit as RegionalPriceEditPage,
	RegionalPriceCreate as RegionalPriceCreatePage,

	PricesEdit as PricesEditPage,

	PhotoCropping as PhotoCroppingPage
} from "../pages";
import {compose} from "recompose";
import {connect} from "react-redux";

const pages = [
	{
		path: '/products',
		component: ProductsPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/products/edit/:id',
		component: ProductEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/products/create',
		component: ProductEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/products/multi-edit/:sessionId',
		component: ProductMultiEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},

	{
		path: '/orders',
		component: OrdersPage,
		layout: DefaultLayout,
		rules: ['admin', 'manager'],
		exact: true
	},
	{
		path: '/orders/:id',
		component: OrderPage,
		layout: DefaultLayout,
		rules: ['admin', 'manager'],
		exact: true
	},
	{
		path: '/orders/edit/:id',
		component: OrderEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'manager'],
		exact: true
	},

	{
		path: '/characteristics',
		component: CharacteristicsPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/characteristics/characteristic_variants/:id',
		component: CharacteristicVariantsPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/characteristics/characteristic_create/:id',
		component: CharacteristicCreatePage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},

	{
		path: '/brands',
		component: BrandsPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/brands/create',
		component: BrandEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/brands/edit/:id',
		component: BrandEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},

	{
		path: '/categories',
		component: CategoriesPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/categories/category_products/:id',
		component: CategoryProductsPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/categories/edit/:id',
		component: CategoryEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/categories/create',
		component: CategoryEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},


	{
		path: '/promotions',
		component: PromotionsPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},

	{
		path: '/regionalPrices',
		component: RegionalPricesPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/regionalPrices/edit/:id',
		component: RegionalPriceEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/regionalPrices/create',
		component: RegionalPriceCreatePage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
	{
		path: '/regionalPrices/create/:id',
		component: RegionalPriceCreatePage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},

	{
		path: '/price-editing',
		component: PricesEditPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},

	{
		path: '/photo-cropping',
		component: PhotoCroppingPage,
		layout: DefaultLayout,
		rules: ['admin', 'content'],
		exact: true
	},
];

const MainRoutes = (props) => {
	const {
		userRoles
	} = props;

	return (
		<Switch>
			{
				pages.map((page, idx) => {
					if (!userRoles.includes('admin') && !Array.prototype.contains((page.rules), userRoles)) {
						return null
					}

					return (
						<RouteWithLayout
							key={'page-' + idx}
							{...page}
						/>
					)
				})
			}

			{Boolean((userRoles || []).includes("content") || (userRoles || []).includes("admin")) && (
				<Redirect to="/products"/>
			)}
			{Boolean((userRoles || []).includes("manager")) && (
				<Redirect to="/orders"/>
			)}
		</Switch>
	);
};

export default compose(
	connect(
		state => ({
			userRoles: state?.global?.userRoles || []
		}),
		dispatch => ({}),
	),
)(MainRoutes)
