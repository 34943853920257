import React from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    TextareaAutosize,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {ErrorMessage, Formik} from "formik";
import {
    AttachFileOutlined as AttachFileIcon,
    Delete as DeleteIcon,
    Upload as UploadIcon
} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";

const initialState = {
    value: "",
    slug: "",
    image_id: "",
    additional_description: "",
    sort: ""
}

const MainForm = (props) => {
    const {
        refFormik,
        createCharacteristicVariant
    } = props;
    const classes = useStyles();

    const handleSubmit = () => {
    };

    const handleChange = ({target}) => {
        const {name, value} = target;

        let newForm = refFormik.current.values || {};
        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleChangeFile = (event) => {
        const file = event?.target.files?.[0] || null;

        let newValues = refFormik.current?.values || {};
        let image_id = newValues.image_id || {};
        image_id.file = file;
        image_id.source = URL.createObjectURL(file);

        newValues.image_id = image_id;
        refFormik.current.setValues(newValues);
    };

    const handleDeleteFile = (fieldName) => {
        refFormik.current.setFieldValue(fieldName, null);
    };

    return (
        <Formik
            innerRef={refFormik}
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => {
                const {
                    values,
                    errors,
                    touched
                } = props;

                return (
                    <>
                        <Grid container alignItems="center" mb={3}>
                            <Grid item xs={2}><Typography variant="h5">Значение</Typography></Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    size="small"
                                    color="primary"
                                    value={values.value}
                                    error={Boolean(touched.value && errors.value)}
                                    helperText={touched.value && errors.value}
                                    name="value"
                                    placeholder="Введите значение"

                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center" mb={3}>
                            <Grid item xs={2}><Typography variant="h5">URL-адрес</Typography></Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    size="small"
                                    color="primary"
                                    value={values.slug}
                                    error={Boolean(touched.slug && errors.slug)}
                                    helperText={touched.slug && errors.slug}
                                    name="slug"
                                    placeholder="Введите URL-адрес"

                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center" mb={3}>
                            <Grid item xs={2}><Typography variant="h5">Картинки</Typography></Grid>
                            <Grid item xs>
                                {values.image_id
                                    ? (
                                        <>
                                            <MainFormImage
                                                imageId={values.image_id}
                                                viewImage
                                                viewFile
                                                handleDeleteImage={handleDeleteFile}
                                            />
                                        </>
                                    )
                                    : (
                                        <Button
                                            variant="contained"
                                            color={Boolean(touched.image_id && errors.image_id) ? "error" : "primary"}
                                        >
                                            <label style={{
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                                <UploadIcon sx={{marginRight: "5px"}}/>
                                                Выберите изображение
                                                <input
                                                    type="file"
                                                    name="image_id"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(event) => handleChangeFile(event)}
                                                />
                                            </label>
                                        </Button>
                                    )
                                }
                            </Grid>
                        </Grid>

                        <Grid container mb={3}>
                            <Grid item xs={2}><Typography variant="h5">Дополнительное описание</Typography></Grid>
                            <Grid item xs>
                                <TextareaAutosize
                                    className={classes.textArea}
                                    value={values.additional_description}
                                    name="additional_description"
                                    minRows={8}
                                    placeholder="Введите дополнительное описание"
                                    style={{
                                        borderColor: Boolean(touched.additional_description && errors.additional_description) && "#e53935"
                                    }}

                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="additional_description"
                                    render={(errorMessage) => (
                                        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center">
                            <Grid item xs={2}><Typography variant="h5">Сортировка</Typography></Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    size="small"
                                    color="primary"
                                    value={values.sort}
                                    error={Boolean(touched.sort && errors.sort)}
                                    helperText={touched.sort && errors.sort}
                                    name="sort"
                                    placeholder="Введите сортировку"

                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </>
                );
            }}
        </Formik>
    );
};

const MainFormImage = React.memo((props) => {
    const {
        imageId,
        viewImage,
        viewFile,

        handleDeleteImage
    } = props;
    const classes = useStyles();
    return (<>
        {Boolean(viewImage) && (
            <Box
                className={classes.imageContainer}
                mb={2}
            >
                <img src={imageId.source} style={{
                    display: "block", width: 250, objectFit: "cover", borderRadius: 5,
                }} alt=""/>
            </Box>
        )}
        {Boolean(viewFile) && (
            <Box display="flex">
                <Box
                    className={classes.fileContainer}
                >
                    <AttachFileIcon
                        sx={{
                            marginRight: "5px",
                        }}
                    />
                    {imageId?.file?.name}
                </Box>
                <IconButton
                    color="error"

                    onClick={() => handleDeleteImage('image_id')}
                >
                    <Tooltip title="Удалить файл">
                        <DeleteIcon/>
                    </Tooltip>
                </IconButton>
            </Box>
        )}
    </>);
});

const useStyles = makeStyles({
    imageContainer: {
        width: "300px",
        height: "300px",
        backgroundColor: "rgba(0,87,255, 0.05)",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    fileContainer: {
        display: "flex",
        alignItems: "center",
        padding: "5px",
        background: "#3855EC",
        borderRadius: "4px",
        color: "#fff",
        fontSize: "14px",
        justifyContent: "center",
        marginRight: "5px"
    },

    textArea: {
        width: "100%",
        resize: "none",
        fontSize: "16px",
        padding: "8px 12px",
        boxSizing: "border-box",
        borderRadius: "4px",
        border: "2px solid rgba(0, 0, 0, .1)",
        "&:focus": {
            borderColor: "#0057FF",
            outline: "none",
        }
    },

    errorMessage: {
        color: "#e53935",
        fontSize: "11px",
        letterSpacing: "0.33px",
        lineHeight: "13px",
        fontWeight: "400",
        textAlign: "left",
        marginTop: "4px",
        marginRight: "14px",
        marginBottom: 0,
        marginLeft: "14px",
    }
})

const validationSchema = Yup.object().shape({
    value: Yup.string().required('Заполните поле'),
    slug: Yup.string().required('Заполните поле'),
    // image_id: Yup.mixed().required('Выберите изображение'),
    additional_description: Yup.string().required('Заполните поле'),
    sort: Yup.string().required('Заполните поле'),
});

export default MainForm;