import React from "react";
import {
	Box,
	Tooltip,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	numberFormat
} from "../../../../../common/Formater";
import {
	Notification,
	NotificationTypes
} from "../../../../../common/Notification";
import clsx from "clsx";
import ModalSetOptions from "./ModalOptions";

const ProductCard = (props) => {
	const {
		item,
		onCreate
	} = props;
	const classes = useStyles();
	const product = item?.relatedProduct;
	const [priceInfo] = React.useState(() => {
		const _price = numberFormat(product.price_new, 0, '.');
		if (product.has_options) {
			return `от ${ _price } ₽`
		}
		return `${ _price } ₽`
	});
	const [imageUrl] = React.useState(() => {
		return `${process.env.REACT_APP_HOST_API}api/storage/image/${product.main_photo_id}_border-default_fit-default_w-${ 140 }_h-${ 140 }.webp`
	});

	const clickProductCard = () => {
		if (product?.status === 0) {
			Notification({
				type: NotificationTypes.warning,
				message: "Товар отключен"
			})
			return
		}
		onCreate(item);
	}

	return (
		<Tooltip title="Добавить товар" arrow>
			<Box
				className={clsx({
					[classes.productCard]: true,
					'--disabled': Boolean(product?.status === 0)
				})}
				onClick={clickProductCard}
			>
				<Box className={classes.productCardImage}>
					<img src={imageUrl}/>
				</Box>
				<Box className={classes.productCardBody}>
					<Typography className={classes.productCardName}>
						{ product.name }
					</Typography>
					<Typography className={classes.productCardPrice}>
						{ priceInfo }
					</Typography>
				</Box>
			</Box>
		</Tooltip>
	)
}

const useStyles = makeStyles(() => ({
	productCard: {
		display: "flex",
		height: "100%",
		background: "#F8F8F8",
		borderRadius: "4px",
		position: "relative",
		overflow: "hidden",
		padding: "4px",
		boxSizing: "border-box",
		cursor: "pointer",

		"&.--disabled": {
			opacity: "0.4",
			cursor: "not-allowed"
		}
	},
	productCardImage: {
		width: "64px",
		height: "64px",
		borderRadius: "6px",
		position: "relative",
		overflow: "hidden",
		background: "#F4F4F4",

		"& img": {
			position: "absolute",
			top: 0, left: 0,
			width: "100%", height: "100%",
			objectFit: "cover"
		}
	},
	productCardBody: {
		display: "flex",
		flexDirection: "column",
		padding: "8px 16px",
		boxSizing: "border-box",
		flex: 1
	},
	productCardName: {
		fontSize: "16px",
		lineHeight: "120%",
		fontWeight: "500",
		marginBottom: "auto"
	},
	productCardPrice: {
		fontSize: "14px",
		lineHeight: "120%",
		fontWeight: "500",
		marginTop: "12px"
	},
}));

export default ProductCard
