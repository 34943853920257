import React from "react";
import {
    Box,
    Grid,

    Select,
    Checkbox,
    MenuItem,
    Typography,
    FormControl,
    FormControlLabel
} from "@mui/material";
import {
    Formik
} from "formik";
import * as Yup from "yup";
import CharacteristicComponent from "./CharacteristicComponent";

const SpecificationsForm = (props) => {
    const {
        innerRef,

        attributes
    } = props;
    const [initialValues, setInitialValues] = React.useState({});

    React.useEffect(() => {
        let characteristicAttributes = attributes.filter((t) => t.group === "characteristic");
        let values = {};

        characteristicAttributes.map((item) => {
            values[item.name] = {
                value: [],
                apply: false
            };
        });

        setInitialValues(values);
    }, []);

    const handleSubmit = () => {}

    const handleOnChange = (value, name) => {
        let newValues = {...innerRef.current.values};
        newValues[name] = value;

        innerRef.current.setValues(newValues);
    }

    if (Object.keys(initialValues).length <= 0) {
        return null
    }

    return (
        <Formik
            innerRef={innerRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => {
                const {
                    values,
                    errors,
                    touched
                } = props;

                return (
                    <Box px={2} py={4} bgcolor="white" borderRadius={2}>
                        <Grid container spacing={4}>

                            {Object.keys(values || {}).map((key) => {
                                const item = values[key];
                                const attribute = attributes.find((t) => t.name === key);

                                return (
                                    <CharacteristicComponent
                                        item={item}
                                        attribute={attribute}

                                        onChange={(value) => handleOnChange(value, key)}
                                    />
                                )
                            })}

                        </Grid>
                    </Box>
                )
            }}
        </Formik>
    )
}

const validationSchema = Yup.object().shape({});

export default SpecificationsForm
