import React from "react";
import {
    Box,
    Grid,
    Typography,

    Checkbox,
    TextField,
    FormControlLabel
} from "@mui/material";
import {
    Formik
} from "formik";
import * as Yup from "yup";

const AdditionalCategoriesForm = (props) => {
    const {
        innerRef,
        initialValues,

        extraCategories
    } = props;
    const [search, setSearch] = React.useState("");

    const handleSubmit = () => {}

    const handleChangeBoolean = (value, id) => {
        let newForm = {...innerRef.current.values};
        let extraCategories = [...newForm.extraCategories];

        if (value) {
            extraCategories.push({value: id});
        } else {
            extraCategories.splice(extraCategories.findIndex((t) => +t.value === +id), 1);
        }

        newForm.extraCategories = extraCategories;
        innerRef.current.setValues(newForm);
    }

    const handleChangeSearch = ({target}) => {
        setSearch(target.value);
    }

    const _extraCategories = () => {
        return (extraCategories || []).filter((t) => (t.label||'').toLowerCase().indexOf(search.toLowerCase()) > -1)
    }

    return (
        <Formik
            innerRef={innerRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => {
                const {
                    values
                } = props;

                return (
                    <Box px={2} py={2} bgcolor="white" borderRadius={2}>

                        <Box mb={2}>
                            <Typography variant="h3">Категории</Typography>
                        </Box>

                        <Box mb={1}>
                            <TextField
                                fullWidth
                                value={search}
                                placeholder="Поиск..."
                                onChange={handleChangeSearch}
                            />
                        </Box>

                        <Grid container>
                            {_extraCategories().map((category) => (
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        checked={Boolean(values.extraCategories.find((t) => +t.value === +category.value))}
                                        value={Boolean(values.extraCategories.find((t) => +t.value === +category.value))}
                                        label={category.label}
                                        onChange={(event, value) => handleChangeBoolean(value, category.value)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )
            }}
        </Formik>
    )
}

const validationSchema = Yup.object().shape({

});

export default AdditionalCategoriesForm
