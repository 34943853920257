import React from "react";
import {
	Box,
	Grid,
	Button,
	Checkbox,
	TextField,
	IconButton,
	Typography,
	FormControlLabel,


	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Delete as DeleteIcon
} from "@mui/icons-material";
import * as Yup from "yup";
import {
	Formik
} from "formik";
import {
	IMaskInput
} from "react-imask";

const newPackage = {
	name: "",
	count: "",
	weight: "",
	width: "",
	height: "",
	depth: "",
}

const PackageForm = (props) => {
	const {
		innerRef,
		initialValues
	} = props;

	const handleSubmit = () => {
	}

	const handleAddPackage = () => {
		let newForm = {...innerRef?.current?.values || {}};
		let packages = [...(newForm.packages || [])];
		packages.push({
			key: Math.random().toString(36).substring(12),
			...newPackage
		});
		newForm.packages = packages;
		innerRef.current?.setValues(newForm);
	}
	const handleRemovePackage = (index) => {
		let newForm = {...innerRef?.current?.values || {}};
		let packages = [...(newForm.packages || [])];
		packages.splice(index, 1);
		newForm.packages = packages;
		innerRef.current?.setValues(newForm);
	}

	const handleChange = ({target}) => {
		const {name, value} = target;

		let newForm = {...innerRef?.current?.values || {}};
		newForm[name] = value;

		innerRef.current?.setValues(newForm);
	}
	const handleChangeTable = ({ target }, index) => {
		const { name, value } = target;
		let newForm = {...innerRef?.current?.values || {}};
		let packages = [...(newForm.packages || [])];
		packages[index][name] = value;
		newForm.packages = packages;
		innerRef.current?.setValues(newForm);
	}


	return (
		<Formik
			innerRef={innerRef}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					values,
					errors,
					touched
				} = props;
				const packages = values?.packages || [];

				return (
					<>

						<Box px={2} py={2} bgcolor="white" borderRadius={2}>
							<Grid container spacing={2}>
								<Grid item xs="auto">
									<FormControlLabel
										label="Логист. данные точные"
										checked={Boolean(values.use_default_packages)}
										control={<Checkbox size="medium"/>}
										onChange={(event, value) => handleChange({target: {name: "use_default_packages", value: value}})}
									/>
								</Grid>
								<Grid item sx={{flex: 1}}>
									<TextField
										value={values.volume}
										error={Boolean(touched.volume && errors.volume)}
										helperText={touched.volume && errors.volume}
										name="volume"
										label="Объем, м³"
										fullWidth
										size='small'

										onChange={handleChange}
									/>
								</Grid>
								<Grid item sx={{flex: 1}}>
									<TextField
										value={values.weight}
										error={Boolean(touched.weight && errors.weight)}
										helperText={touched.weight && errors.weight}
										name="weight"
										label="Вес, кг"
										fullWidth
										size='small'

										onChange={handleChange}
									/>
								</Grid>
								<Grid item sx={{flex: 1}}>
									<TextField
										value={values.seats}
										error={Boolean(touched.seats && errors.seats)}
										helperText={touched.seats && errors.seats}
										name="seats"
										label="Кол-во мест"
										fullWidth
										size='small'

										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</Box>

						<Box mt="12px"/>

						<Box px={2} py={2} bgcolor="white" borderRadius={2}>
							<Grid container alignItems="center" justifyContent="space-between">
								<Grid>
									<Typography variant="h4">Упаковки</Typography>
								</Grid>
								<Grid>
									<Button
										variant="outlined"
										onClick={handleAddPackage.bind(this)}
									>
										Добавить упаковку
									</Button>
								</Grid>
							</Grid>

							<Box mt="12px"/>

							<PackagesTable
								data={packages}
								errors={errors?.packages || []}

								onChange={handleChangeTable.bind(this)}
								onRemove={handleRemovePackage.bind(this)}
							/>
						</Box>

					</>
				)
			}}
		</Formik>
	)
}

let timeOutChange = null;
const PackagesTable = React.memo((props) => {
	const {
		data,
		errors,

		onChange,
		onRemove
	} = props;
	const classes = useStyles();

	const handleOnChange = (name, index, value) => {
		clearTimeout(timeOutChange);

		timeOutChange = setTimeout(() => {
			onChange({
				target: {
					name,
					value
				}
			}, index);
		}, 100)
	}

	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell>Название / Артикул / Номер</TableCell>
					<TableCell>Высота, см</TableCell>
					<TableCell>Ширина, см</TableCell>
					<TableCell>Глубина, см</TableCell>
					<TableCell>Вес, кг</TableCell>
					<TableCell>Кол-во</TableCell>
					<TableCell align="right"/>
				</TableRow>
			</TableHead>

			<TableBody>
				{data.map((item, index) => (
					<TableRow hover key={item.key}>
						<TableCell>
							<TextField
								value={item.name}
								name="name"
								fullWidth

								error={!!errors[index]?.["name"]}
								helperText={errors[index]?.["name"]}

								onChange={(event) => onChange(event, index)}
							/>
						</TableCell>
						<TableCell>
							<TextField
								value={String(item.height)}
								name="height"
								placeholder="0"
								fullWidth

								error={!!errors[index]?.["height"]}
								helperText={errors[index]?.["height"]}

								InputProps={{
									inputComponent: CustomInputAmount
								}}
								inputProps={{
									scale: 2,
									onAccept: handleOnChange.bind(this, "height", index)
								}}
							/>
						</TableCell>
						<TableCell>
							<TextField
								value={String(item.width)}
								name="width"
								placeholder="0"
								fullWidth

								error={!!errors[index]?.["width"]}
								helperText={errors[index]?.["width"]}

								InputProps={{
									inputComponent: CustomInputAmount
								}}
								inputProps={{
									scale: 2,
									onAccept: handleOnChange.bind(this, "width", index)
								}}
							/>
						</TableCell>
						<TableCell>
							<TextField
								value={String(item.depth)}
								name="depth"
								placeholder="0"
								fullWidth

								error={!!errors[index]?.["depth"]}
								helperText={errors[index]?.["depth"]}

								InputProps={{
									inputComponent: CustomInputAmount
								}}
								inputProps={{
									scale: 2,
									onAccept: handleOnChange.bind(this, "depth", index)
								}}
							/>
						</TableCell>
						<TableCell>
							<TextField
								value={String(item.weight)}
								name="weight"
								placeholder="0"
								fullWidth

								error={!!errors[index]?.["weight"]}
								helperText={errors[index]?.["weight"]}

								InputProps={{
									inputComponent: CustomInputAmount
								}}
								inputProps={{
									scale: 2,
									onAccept: handleOnChange.bind(this, "weight", index)
								}}
							/>
						</TableCell>
						<TableCell>
							<TextField
								value={String(item.count)}
								name="count"
								placeholder="0"
								fullWidth
								InputProps={{
									inputComponent: CustomInputAmount
								}}
								inputProps={{
									scale: 0,
									onComplete: handleOnChange.bind(this, "count", index)
								}}
							/>
						</TableCell>
						<TableCell width={40} align="right">
							<IconButton color="error" onClick={onRemove.bind(this, index)}>
								<DeleteIcon/>
							</IconButton>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	)
})
const CustomInputAmount = ({inputRef, ...rest}) => (
	<IMaskInput
		ref={inputRef}
		scale={4}
		{...rest}

		mask={Number}
		radix="."
		mapToRadix={[',']}
	/>
)

const useStyles = makeStyles(() => ({
	table: {
		"& .MuiTableHead-root .MuiTableCell-root": {
			padding: "4px 6px",
			fontSize: 13,
			lineHeight: "16px"
		},
		"& .MuiTableBody-root .MuiTableCell-root": {
			padding: "4px 6px"
		},
	}
}));
const validationSchema = Yup.object().shape({
	slug: Yup.string().required('Заполните поле'),
	packages: Yup.array().of(Yup.object().shape({
		name: Yup.string().required('Заполните поле'),
		weight: Yup.string().required('Заполните поле'),
		width: Yup.string().required('Заполните поле'),
		height: Yup.string().required('Заполните поле'),
		depth: Yup.string().required('Заполните поле'),
	}))
});

export default React.memo(PackageForm)
