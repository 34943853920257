import React, {PureComponent} from 'react';
import Dropzone from 'react-dropzone';

import {
    Box, Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {BurstMode, Delete as DeleteIcon, Menu as MenuIcon, ListAlt as ListIcon} from '@mui/icons-material';

class DropZoneSecond extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        }
    }

    onDrop = (acceptedFiles) => {
        const files = this.state.files;
        const newFiles = acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        this.setState({
            files: [...files, newFiles],
        })
    };

    onDeleteImage = (indexFiles, index) => {
        const files = this.state.files;
        files[indexFiles].splice(index, 1);

        if (files[indexFiles].length === 0) {
            files.splice(indexFiles, 1);
        }

        this.setState({files: [...files]});
    }

    render() {
        const {files} = this.state;
        const {
            notShowTitle
        } = this.props;

        return (
            <>
                {files.length > 0 &&
                    <>
                        <Box>
                            {!Boolean(notShowTitle) && (
                                <Box mb={2}>
                                    <Typography variant="h3">Дополнительные изображения</Typography>
                                </Box>
                            )}
                            {files.map((files, indexFiles) => (
                                files.map((file, index) => (
                                    <>
                                        <Grid key={file.name} container alignItems="center" maxHeight={148} pb={3}
                                              mb={3}
                                              borderBottom="1px solid #E6E6E6;">
                                            <Grid item mr={3} height={126}>
                                                <IconButton key={file.name} sx={{
                                                    height: 124,
                                                    width: 38,
                                                    borderRadius: 1,
                                                    background: "rgba(0, 87, 255, 0.05)",
                                                    border: "1px solid rgba(0, 87, 255, 0.7)",
                                                }}>
                                                    <MenuIcon color='primary'/>
                                                </IconButton>
                                            </Grid>
                                            <Grid item mr={3} height={126}>
                                                <div key={file.name} style={{
                                                    borderRadius: "4px",
                                                }}>
                                                    <img width={124} style={{
                                                        objectFit: 'cover',
                                                        borderRadius: "4px"
                                                    }} height={124} src={file.preview} alt=""/>
                                                </div>
                                            </Grid>
                                            <Grid item alignSelf='flex-start' height={126}>
                                                <Grid>
                                                    <Grid item mb={1}>
                                                        <TextareaAutosize
                                                            key={file.name}
                                                            value={file.caption}
                                                            placeholder="Описание"
                                                            style={{
                                                                width: 488,
                                                                height: 124,
                                                                resize: "none",
                                                                fontSize: "16px",
                                                                padding: "12px 24px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        />
                                                    </Grid>
                                                    {/*<Grid item>*/}
                                                    {/*    <Button fullWidth sx={{*/}
                                                    {/*        border: "1px solid rgba(0, 87, 255, 0.7)",*/}
                                                    {/*    }}>*/}
                                                    {/*        <ListIcon/>*/}
                                                    {/*        <Typography sx={{*/}
                                                    {/*            fontSize: 13,*/}
                                                    {/*            fontWeight: 500,*/}
                                                    {/*            textTransform: 'uppercase',*/}
                                                    {/*            letterSpacing: "0.1em",*/}
                                                    {/*            color: "#0057FF",*/}
                                                    {/*            marginLeft: '8px',*/}
                                                    {/*        }} variant="h5">Показать Редактор WYSIWYG</Typography>*/}
                                                    {/*    </Button>*/}
                                                    {/*</Grid>*/}
                                                </Grid>
                                            </Grid>
                                            <Grid item ml='auto'>
                                                <IconButton
                                                    key={file.name}
                                                    sx={{
                                                        width: 124,
                                                        height: 124,
                                                        background: "rgba(0, 87, 255, 0.05)",
                                                        borderRadius: 1,
                                                        border: "1px solid rgba(0, 87, 255, 0.7)",
                                                    }}

                                                    onClick={() => this.onDeleteImage(indexFiles, index)}
                                                >
                                                    <DeleteIcon color="primary"/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            ))}
                        </Box>
                    </>
                }
                <Dropzone accept='image/*' onDrop={this.onDrop}>
                    {({getRootProps, getInputProps}) => (
                        <Box
                            {...getRootProps({className: "dropzone"})}
                            sx={{
                                cursor: 'pointer',
                            }} borderRadius={2} height={107}
                            border='1px solid rgba(0, 87, 255, 0.5)'
                            bgcolor="rgba(0, 87, 255, 0.05);" p={2}>
                            <Box
                                height={107}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <input {...getInputProps()} />
                                <BurstMode sx={{fill: "#0057FF"}}/>
                                <Typography sx={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    textTransform: 'uppercase',
                                    letterSpacing: "0.1em",
                                    color: "#0057FF",
                                    marginLeft: '8px',
                                }} variant="h5">Перетащите или выберите изображения</Typography>
                            </Box>
                        </Box>)}
                </Dropzone>
            </>
        );
    }
}

export default DropZoneSecond;
