import {palette} from "../common";

export default {
    styleOverrides: {
        root: {
            "& .MuiInputLabel-root": {
                left: 6,
                top: -12,
                background: "#ffffff",
                padding: "0 8px",
                transform: "none"
            }
        }
    }
}
