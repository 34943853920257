import React from "react";
import {
	Box,
	Grid,
	Popover
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import PopupState, {
	bindTrigger,
	bindPopover
} from "material-ui-popup-state";
import agent from "../../agent/agent";


class RowTaggablePluginApiProduct extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			apiValues: [],
			apiValuesObject: {},
		}
	}

	componentDidMount = async () => {
		await this.getApiValues();
	}
	getApiValues = async () => {
		let _filter = [];
		[...(this.props.value || [])].map((t) => {
			_filter.push(`filter[${this.props.apiFilterName}][]=${t}`)
		})

		const apiMethod = [String(this.props.apiMethod || ''), _filter.join('&')].join('?');
		const res = await agent.get(apiMethod).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		});

		let apiValuesObject = {};
		res.map((item) => {
			apiValuesObject[item.id] = item?.name;
		})
		this.setState({ apiValuesObject })
	}

	render () {
		const { value, classes } = this.props;
		const { apiValuesObject } = this.state;
		const collections = (value || []).filter((t) => !!t);

		if (collections.length <= 0) {
			return null
		}
		return (
			<PopupState variant="popover">
				{(popupState) => (
					<>
						<Box className={classes.root} {...bindTrigger(popupState)}>
							<Box className={classes.cardCollection}>
								{apiValuesObject?.[collections[0]]}

								{Boolean(collections.length > 1) && (
									<Box className={classes.countAdditional}>
										+ {collections.length - 1}
									</Box>
								)}
							</Box>
						</Box>

						<Popover
							{...bindPopover(popupState)}
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
							transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							elevation={0}
							classes={{
								paper: classes.popoverContent
							}}
						>
							<Grid container spacing={1} wrap="wrap">
								{collections.map((collection, index) => (
									<Grid item>
										<Box
											key={`RowTaggablePlugin--collection-${ index }`}
											className={classes.cardCollection}
										>
											{ apiValuesObject?.[collection] }
										</Box>
									</Grid>
								))}
							</Grid>
						</Popover>
					</>
				)}
			</PopupState>
		)
	}
}

const styles = {
	root: {},

	cardCollection: {
		backgroundColor: "#0057FF",
		borderRadius: 3,
		padding: 4,

		fontSize: 10,
		lineHeight: "11px",
		fontWeight: "700",
		color: "#FFFFFF",
		textTransform: "uppercase"
	},
	countAdditional: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		background: "linear-gradient(270deg, #F4F4F4 28.75%, rgba(244, 244, 244, 0) 100%)",
		cursor: "pointer",
		position: "absolute",
		right: 0,
		top: 0,
		bottom: 0,
		left: 0,
		padding: "10px",

		fontSize: 18,
		lineHeight: "23px",
		color: "#3855EC",
		textAlign: "right",
		letterSpacing: "-0.01em",
		fontWeight: "700",
		fontFeatureSettings: "'tnum' on, 'lnum' on"
	},

	popoverContent: {
		marginTop: -10,
		border: "1px solid #E6E6E6",
		borderRadius: 6,
		padding: 8,
		backgroundColor: "white",
		boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)"
	}
}
RowTaggablePluginApiProduct = withStyles(styles)(RowTaggablePluginApiProduct)

export default RowTaggablePluginApiProduct
